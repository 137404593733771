import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { TableHeader, StyledTableRow } from "../../Base/TableHeader";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TripCreationStyle from "../../../assets/css/TripCreationStyle";
import { SuccessButton } from "../../Button/Button";
import { getComparator, sortArray } from "../../../helpers/sorting";
import axiosRequest from "../../../helpers/axios";
import { TextField } from "@material-ui/core";
import SnackBarComponent from "../../../helpers/SnackBarComponent";

const headers = [
  {
    id: "month_name",
    align: "center",
    label: "Month Name",
    sortable: true,
  },
  {
    id: "fms_location_name",
    align: "center",
    label: "FMS Location Name",
    sortable: true,
  },
  {
    id: "vendor_name",
    align: "center",
    label: "Vendor Name",
    sortable: true,
  },
  {
    id: "trip_cost_fms",
    align: "center",
    label: "Trip Cost (FMS Estimate)",
    sortable: true,
  },
  {
    id: "trip_cost_ntm",
    align: "center",
    label: "Trip Cost (NTM Estimate)",
    sortable: true,
  },
  {
    id: "total_other_charges",
    align: "center",
    label: "Other Charges",
    sortable: true,
  },
  {
    id: "damage_charges",
    align: "center",
    label: "Damage Charges",
    sortable: true,
  },
  {
    id: "total_freight_cost",
    align: "center",
    label: "Total Freight Cost",
    sortable: true,
  },
  {
    id: "fixed_cost",
    align: "center",
    label: "Fixed Cost",
    sortable: true,
  },
  {
    id: "monthly_discount",
    align: "center",
    label: "Monthly Discount",
    sortable: true,
  },
  {
    id: "invoice_amount",
    align: "center",
    label: "Invoice Amount",
    sortable: true,
  },
];

const useStyles = makeStyles(TripCreationStyle);
export default function MonthlyVendorSummary(props) {
  const classes = useStyles();

  const {
    MonthVendor,
    handleDiscount,
    handleConfirm,
    startMonth,
    endMonth,
    fmsLocations,
  } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("month_name");
  const [severity, setSeverity] = React.useState("");
  const [content, setContent] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);
  const handleClose = () => {
    setOpensb(false);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const insertMonthlyDiscount = async (row) => {
    await axiosRequest({
      url: "tripCommercials/monthlyDiscount",
      method: "POST",
      data: {
        vendor_id: row.vendor_id,
        month:
          row.year +
          "-" +
          (row.month < 10 ? "0" + row.month : row.month) +
          "-01",
        monthly_discount: row.monthly_discount_entered,
      },
    }).then((data) => {
      if (data.status === 200) {
        handleConfirm(startMonth, endMonth, fmsLocations);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const submitFinance = (row) => {
    if (row.monthly_discount_entered > 0) {
      insertMonthlyDiscount(row);
    } else {
      alert("Please check the monthly discount value");
    }
  };

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };
  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TableContainer>
          <Table stickyHeader>
            <TableHeader
              headers={headers}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {sortArray(MonthVendor, getComparator(order, orderBy)).map(
                (row, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <TableCell align="center" className={classes.fs12}>
                        {row.month_name}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.fms_location_name}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.vendor_name}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        &#8377; {row.trip_cost_fms.toFixed(2)}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        &#8377; {row.trip_cost_ntm.toFixed(2)}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        &#8377; {row.total_other_charges.toFixed(2)}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        &#8377; {row.damage_charges.toFixed(2)}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        &#8377; {row.total_freight_cost.toFixed(2)}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        &#8377; {row.fixed_cost.toFixed(2)}
                      </TableCell>
                      <TableCell align="center">
                        &#8377; {row.monthly_discount.toFixed(2)}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        &#8377; {row.invoice_amount.toFixed(2)}
                      </TableCell>
                    </StyledTableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </Grid>
  );
}
