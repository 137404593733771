import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { TableHeader, StyledTableRow } from "../../Base/TableHeader";
import Grid from "@material-ui/core/Grid";
import { getComparator, sortArray } from "../../../helpers/sorting";

const headCells = [
  {
    id: "trip_rate",
    label: "Trip Rate",
    sortable: true,
    align: "right",
  },
  {
    id: "start_km",
    label: "Start Km",
    sortable: true,
    align: "right",
  },
  {
    id: "end_km",
    label: "End Km",
    sortable: true,
    align: "right",
  },
  {
    id: "type",
    label: "Type",
    sortable: true,
    align: "right",
  },
];

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: 440,
  },
  fs12: {
    fontSize: "12px",
  },
}));

export default function TripRateTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("bol_no");
  const {tripratedetails}=props
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <React.Fragment>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TableContainer className={classes.container}>
          <Table stickyHeader>
            <TableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={headCells}
            />
            <TableBody>
              {sortArray(tripratedetails, getComparator(order, orderBy)).map(
                (row, index) => {
                  return (
                    <StyledTableRow role="checkbox" tabIndex={-1} key={index}>
                      <TableCell className={classes.fs12} align="right">
                        {row.trip_rate}
                      </TableCell>
                      <TableCell className={classes.fs12} align="right">
                        {row.start_km}
                      </TableCell>
                      <TableCell className={classes.fs12} align="right">
                        {row.end_km}
                      </TableCell>
                      <TableCell className={classes.fs12} align="right">
                        {row.type}
                      </TableCell>
                    </StyledTableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </React.Fragment>
  );
}
