import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Normal from "../../Typography/Normal";
import CloseIcon from "@material-ui/icons/Close";
import TripCreationStyle from "../../../assets/css/TripCreationStyle";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { Button, DialogActions, Divider } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { SuccessButton } from "../../Button/Button";
import Input from "@material-ui/core/Input";
import axiosRequest from "../../../helpers/axios";
import SnackBarComponent from "../../../helpers/SnackBarComponent";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles(TripCreationStyle);
const useStyles1 = makeStyles((theme) => ({
  root: {
    "& .MuiFormLabel-root": {
      fontSize: "12px",
      lineHeight: 0,
    },
  },
}));

export default function AddContractMasterDialog(props) {
  const classes = useStyles();
  const classes1=useStyles1();
  const { open, DialogClose} = props;
  const [vendordata, setVendorData] = React.useState([]);
  const [vendorid, setVendorId] = React.useState([]);
  const [vendor, setVendor] = React.useState("");
  const [vendorName, setVendorName] = React.useState("");
  const [contract, setContract] = React.useState("");
  const [payload, setPayload] = React.useState("");
  const [totalvehicle, setTotalVehicle] = React.useState("");
  const [contractstartdate, setContractStartDate] = React.useState("");
  const [contractenddate, setContractEndDate] = React.useState("");
  const [variablerate, setVariableRate] = React.useState("0.0");
  const [routename, setRouteName] = React.useState("0.0");
  const [routecost, setRouteCost] = React.useState("0.0");
  const [fixedcost, setFixedCost] = React.useState("0.0");
  const [minagreedkm, setMinAgreedKm] = React.useState("0");
  const [fixedrate, setFixedRate] = React.useState("0.0");
  const [fromkm, setFromKm] = React.useState("");
  const [tokm, setToKm] = React.useState("");
  const [triprate1, setTripRate1] = React.useState("");
  const [triprate2, setTripRate2] = React.useState("");
  const [fromkm1, setFromKm1] = React.useState("");
  const [tokm1, setToKm1] = React.useState("");
  const [triprate11, setTripRate11] = React.useState("");
  const [triprate21, setTripRate21] = React.useState("");
  const [content, setContent] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);
  let vendorID = [];
  let VendorName;
  let Payload = [
    1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5,
    7, 7.5, 8, 8.5, 9
  ];
  const handleClose = () => {
    setOpensb(false);
  };

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };

  const handleVendorId = (event,value) => {
    setVendor(value);
    if (value == "") {
      setVendorName("");
    } else {
      for (let i in vendordata) {
        if (vendordata[i]["vendor_id"] ==value) {
          VendorName = vendordata[i]["vendor_name"];
        }
        setVendorName(VendorName);
      }
    }
  };

  const handleContractType = (event) => {
    setContract(event.target.value);
  };
  const handlePayload = (event) => {
    setPayload(event.target.value);
  };
  const handleTotalVehicles = (event) => {
    setTotalVehicle(event.target.value);
  };
  const handleContractStartDate = (event) => {
    setContractStartDate(event.target.value);
  };
  const handleContractEndDate = (event) => {
    setContractEndDate(event.target.value);
  };
  const handleVariableRate = (event) => {
    setVariableRate(event.target.value);
  };
  const handleRouteName = (event) => {
    setRouteName(event.target.value);
  };
  const handleRouteRate = (event) => {
    setRouteCost(event.target.value);
  };
  const handleFixedCost = (event) => {
    setFixedCost(event.target.value);
  };
  const handleMinAgreedKm = (event) => {
    setMinAgreedKm(event.target.value);
  };
  const handleFixedRate = (event) => {
    setFixedRate(event.target.value);
  };
  const handleFromKm = (event) => {
    setFromKm(event.target.value);
  };
  const handleToKm = (event) => {
    setToKm(event.target.value);
  };
  const handleTrip1Rate = (event) => {
    setTripRate1(event.target.value);
  };
  const handleTrip2Rate = (event) => {
    setTripRate2(event.target.value);
  };

  const handleFromKm1 = (event) => {
    setFromKm1(event.target.value);
  };
  const handleToKm1 = (event) => {
    setToKm1(event.target.value);
  };
  const handleTrip1Rate1 = (event) => {
    setTripRate11(event.target.value);
  };
  const handleTrip2Rate1 = (event) => {
    setTripRate21(event.target.value);
  };

  const getVendorData = async () => {
    await axiosRequest({
      url: "master/vendor/",
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        setVendorData(data.data);
        for (let i in data.data) {
          vendorID.push(data.data[i]["vendor_id"]);
        }
        console.log(vendorID);
        setVendorId(vendorID);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const addContractMasterData = async () => {
    await axiosRequest({
      url: "master/add_contractmastdetails/",
      method: "POST",
      data: {
        vendor_id:  Number(vendor),
        per_trip: contract,
        payload: payload,
        total_vehicles: Number(totalvehicle),
        contract_start_date:String("26/"+contractstartdate.split("-")[1]+"/"+contractstartdate.split("-")[0]),
        contract_end_date:String("25/"+contractenddate.split("-")[1]+"/"+contractenddate.split("-")[0]),
        variable_rate: variablerate,
        route_name:routename,
        route_cost:routecost,
        fixed_cost: fixedcost,
        fixed_rate: fixedrate,
        min_agreed_km: minagreedkm,
        slab1_from: Number(fromkm),
        slab1_to: Number(tokm),
        slab2_from: Number(fromkm1),
        slab2_to: Number(tokm1),
        slab1_trip1_rate:triprate1==""?"":Number(triprate1),
        slab1_trip2_rate:triprate2==""?"":Number(triprate2),
        slab2_trip1_rate:triprate11==""?"":Number(triprate11) ,
        slab2_trip2_rate:triprate21==""?"":Number(triprate21) ,
      },

    }).then((data) => {
      if (data.status === 200) {
        DialogClose();
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  React.useEffect(() => {
    getVendorData();
  }, []);
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      aria-labelledby="confirmation-dialog-title"
      classes={{ paperWidthSm: classes.contractMasterDialog }}
    >
      <DialogTitle id="confirmation-dialog-title">
        <div className="flex justify-between items-center">
          <Normal>
            <h3 className={classes.textColor}>Add Contract Rate</h3>
          </Normal>
          <IconButton>
            <CloseIcon onClick={DialogClose} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <div className="flex flex-row justify-between">
          <Autocomplete
            className={clsx(classes.w200p,classes1.root,"mr2 mb4")}
            autoComplete
            options={vendorid}
            getOptionLabel={option =>String(option)}
            getOptionSelected={(option, value) =>
                              option === value
                            }
            // onInputChange={(event,value)=>handleVendorId(event,value)}
            onChange={(event,value)=>handleVendorId(event,value)}
            value={vendor}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Vendor ID"
                variant="outlined"
                key={params}
                value={params}

              />
            )}
          />
          {/* <select
            onChange={handleVendorId}
            className={clsx(classes.fs12, classes.SelectStyle, "mr2 mb4")}
          >
            <option value="" className={classes.fs12}>
              Vendor id
            </option>
            {vendorid !== undefined
              ? vendorid.map((vendor) => {
                  return (
                    <option
                      value={vendor}
                      key={vendor}
                      className={clsx(classes.fs12, classes.underline)}
                    >
                      {vendor}
                    </option>
                  );
                })
              : ""}
          </select> */}
          <TextField
            id="outlined-basic"
            label="Vendor Name"
            className={clsx(classes.inputmargin,classes1.root)}
            variant="outlined"
            value={vendorName !== undefined ? vendorName : ""}
            
          />
          <select
            onChange={handleContractType}
            className={clsx(classes.fs12, classes.SelectStyle, "mr2 mb4")}
          >
            <option value="" className={classes.fs12}>
              Contract Type
            </option>
            <option value="Per Trip" className={classes.fs12}>
              Per Trip
            </option>
            <option value="Per Km" className={classes.fs12}>
              Per Km
            </option>
            <option value="Fixed+Variable" className={classes.fs12}>
              Fixed+variable
            </option>
            <option
              value="Fixed+Variable with min km guarantee"
              className={classes.fs12}
            >
              Fixed+variable with min km guarantee
            </option>
            <option
              value="Route"
              className={classes.fs12}
            >
              Route
            </option>
          </select>
          <select
            onChange={handlePayload}
            className={clsx(classes.fs12, classes.SelectStyle, "mr2 mb4")}
          >
            <option value="-1" className={classes.fs12}>
              Payload
            </option>
            {Payload !== undefined
              ? Payload.map((pay) => {
                  return (
                    <option
                      value={pay}
                      className={clsx(classes.fs12, classes.underline)}
                    >
                      {pay}
                    </option>
                  );
                })
              : ""}
          </select>
          {contract == "Fixed+variable with min km guarantee" ||
          contract == "Fixed+variable" ? (
            <React.Fragment>
              <TextField
                id="outlined-basic"
                label="Total Vehicles"
                variant="outlined"
                className={clsx(classes.inputmargin,classes1.root)}
                onChange={handleTotalVehicles}
              />
            </React.Fragment>
          ) : (
            ""
          )}
        </div>
        <div className="flex flex-row">
          <label>
            Contract_Start_Date
            <div className={clsx(classes.bgInputGray,"mr3")}>
              <Input
                type="month"
                value={contractstartdate}
                onChange={handleContractStartDate}
                className={clsx(classes.underline, classes.fs12)}
              />
            </div>
          </label>
          <label>
            Contract_End_Date
            <div className={classes.bgInputGray}>
              <Input
                type="month"
                value={contractenddate}
                onChange={handleContractEndDate}
                className={clsx(classes.underline, classes.fs12)}
              />
            </div>
          </label>
        </div>
        <div className="flex flex-row justify-between mt3">
          {contract == "Fixed+Variable" ? (
            <React.Fragment>
              <TextField
                id="outlined-basic"
                label="Variable Rate"
                variant="outlined"
                className={clsx(classes.inputmargin,classes1.root)}
                onChange={handleVariableRate}
              />
              <TextField
                id="outlined-basic"
                label="Fixed Cost"
                variant="outlined"
                className={clsx(classes.inputmargin,classes1.root)}
                onChange={handleFixedCost}
              />
            </React.Fragment>
          ) : (
            ""
          )}
          {contract == "Route" ? (
            <React.Fragment>
              <TextField
                id="outlined-basic"
                label="Route Name"
                variant="outlined"
                className={clsx(classes.inputmargin,classes1.root)}
                onChange={handleRouteName}
              />
              <TextField
                id="outlined-basic"
                label="Route Cost"
                variant="outlined"
                className={clsx(classes.inputmargin,classes1.root)}
                onChange={handleRouteRate}
              />
            </React.Fragment>
          ) : (
            ""
          )}
          {contract == "Per Km" ? (
            <React.Fragment>
              <TextField
                id="outlined-basic"
                label="Variable Rate"
                variant="outlined"
                className={clsx(classes.inputmargin,classes1.root)}
                onChange={handleVariableRate}
              />
            </React.Fragment>
          ) : (
            ""
          )}
          {contract == "Fixed+Variable with min km guarantee" ? (
            <React.Fragment>
              <TextField
                id="outlined-basic"
                label="Variable Rate"
                variant="outlined"
                className={clsx(classes.inputmargin,classes1.root)}
                onChange={handleVariableRate}
              />
              <TextField
                id="outlined-basic"
                label="Fixed Cost"
                variant="outlined"
                className={clsx(classes.inputmargin,classes1.root)}
                onChange={handleFixedCost}
              />
              <TextField
                id="outlined-basic"
                label="Min.agreed KM "
                variant="outlined"
                className={clsx(classes.inputmargin,classes1.root)}
                onChange={handleMinAgreedKm}
              />
              <TextField
                id="outlined-basic"
                label="Fixed Rate"
                variant="outlined"
                className={clsx(classes.inputmargin,classes1.root)}
                onChange={handleFixedRate}
              />
            </React.Fragment>
          ) : (
            ""
          )}
        </div>
        <div className="flex flex-row justify-between mt3">
          {contract == "Per Trip" ? (
            <React.Fragment>
              <label> Trip Rate </label>
              <TextField
                disabled
                id="filled-disabled"
                defaultValue="Slab1"
                className={clsx(classes.inputmargin,classes1.root)}
                variant="filled"
              />
              <TextField
                id="outlined-basic"
                label="From (KM)"
                variant="outlined"
                className={clsx(classes.inputmargin,classes1.root)}
                onChange={handleFromKm}
              />
              <TextField
                id="outlined-basic"
                label="To (KM)"
                variant="outlined"
                className={clsx(classes.inputmargin,classes1.root)}
                onChange={handleToKm}
              />
              <TextField
                id="outlined-basic"
                label="Trip 1 Rate "
                variant="outlined"
                className={clsx(classes.inputmargin,classes1.root)}
                onChange={handleTrip1Rate}
              />
              <TextField
                id="outlined-basic"
                label="Trip 2 Rate"
                variant="outlined"
                className={clsx(classes.inputmargin,classes1.root)}
                onChange={handleTrip2Rate}
              />
            </React.Fragment>
          ) : (
            ""
          )}
        </div>
        <div className="flex flex-row justify-between mt3">
          {contract == "Per Trip" ? (
            <React.Fragment>
              <TextField
                disabled
                id="filled-disabled"
                defaultValue="Slab2"
                className={clsx(classes.inputmargin,classes1.root)}
                variant="filled"
              />
              <TextField
                id="outlined-basic"
                label="From (KM)"
                variant="outlined"
                className={clsx(classes.inputmargin,classes1.root)}
                onChange={handleFromKm1}
              />
              <TextField
                id="outlined-basic"
                label="To (KM)"
                variant="outlined"
                className={clsx(classes.inputmargin,classes1.root)}
                onChange={handleToKm1}
              />
              <TextField
                id="outlined-basic"
                label="Trip 1 Rate "
                variant="outlined"
                className={clsx(classes.inputmargin,classes1.root)}
                onChange={handleTrip1Rate1}
              />
              <TextField
                id="outlined-basic"
                label="Trip 2 Rate"
                variant="outlined"
                className={clsx(classes.inputmargin,classes1.root)}
                onChange={handleTrip2Rate1}
              />
            </React.Fragment>
          ) : (
            ""
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <div>
          <p>*New rates will be applicable only from the next billing period</p>
        </div>
        <div className="flex justify-center">
          <SuccessButton
            onClick={addContractMasterData}
            variant="contained"
            disableElevation
          >
            <Typography className={clsx(classes.fs12, "ttn")}>
              Save Contract
            </Typography>
          </SuccessButton>
        </div>
      </DialogActions>
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </Dialog>
  );
}
