import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { TableHeader, StyledTableRow } from "../../Base/TableHeader";
import { getComparator, sortArray } from "../../../helpers/sorting";
import Grid from "@material-ui/core/Grid";
import TripCreationStyle from "../../../assets/css/TripCreationStyle";
import { Paper } from "@material-ui/core";
import axiosRequest from "../../../helpers/axios";
import SnackBarComponent from "../../../helpers/SnackBarComponent";
import { getLocationId } from "../../../helpers/RouteValidation";
const headers = [
  {
    id: "fms_origin_id",
    sortable: true,
    align: "center",
    label: "Origin_ID",
  },
  {
    id: "origin_name",
    sortable: true,
    align: "center",
    label: "Origin_Name",
  },
  {
    id: "fms_destination_id",
    sortable: true,
    align: "center",
    label: "Destination_ID",
  },
  {
    id: "destination_name",
    sortable: true,
    align: "center",
    label: "Destination_Name",
  },
  {
    id: "tag",
    sortable: true,
    align: "center",
    label: "Tag",
  },

  {
    id: "city_zone",
    sortable: true,
    align: "center",
    label: "City Zone",
  },
  {
    id: "order_of_delivery",
    sortable: true,
    align: "center",
    label: "Order Of Delivery",
  },
  {
    id: "dc_leave_time",
    sortable: true,
    align: "center",
    label: "DC_leaving_time",
  },

  {
    id: "return_dc_tim",
    sortable: false,
    align: "center",
    label: "Return_To_DC_time",
  },
  {
    id: "highest_cap_veh",
    sortable: true,
    align: "center",
    label: "Highest Capacity Vehicle in MT",
  },

  {
    id: "Actions",
    sortable: false,
    align: "center",
    label: "Actions",
  },
];

const useStyles = makeStyles(TripCreationStyle);

export function OriginDestinationDetails(props) {
  const classes = useStyles();
  const { OriginDetails } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("trip_id");
  const [tableData, setTableData] = React.useState([]);
  const [content, setContent] = React.useState("");
  const [severity,setSeverity]=React.useState("")
  const [opensb, setOpensb] = React.useState(false);
  const handleClose = () => {
    setOpensb(false);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };


  const getOriginDestinationDetailsData = async () => {
    let fmsLocationId = getLocationId();
    await axiosRequest({
      url: "master/origin_destination_details/" + fmsLocationId,
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        setTableData(data.data);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  React.useEffect(() => {
    getOriginDestinationDetailsData();
  }, []);

  return (
    <Paper elevation={0} square>
      <Grid container spacing={2} justify="center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TableContainer className={classes.costingContainer}>
            <Table stickyHeader>
              <TableHeader
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={tableData.length}
                headers={headers}
                isCheckboxEnabled={false}
              />
              <TableBody>
                {sortArray(tableData, getComparator(order, orderBy)).map(
                  (row, index) => {
                    return (
                      <StyledTableRow key={index}>
                        <TableCell align="center" className={classes.fs12}>
                          {row.fms_origin_id}
                        </TableCell>
                        <TableCell align="center" className={classes.fs12}>
                          {row.origin_name}
                        </TableCell>
                        <TableCell align="center" className={classes.fs12}>
                          {row.fms_destination_id}
                        </TableCell>
                        <TableCell align="center" className={classes.fs12}>
                          {row.destination_name}
                        </TableCell>
                        <TableCell align="center" className={classes.fs12}>
                          {row.tag}
                        </TableCell>
                        <TableCell align="center" className={classes.fs12}>
                          {row.city_zone}
                        </TableCell>
                        <TableCell align="center" className={classes.fs12}>
                          {row.order_of_delivery}
                        </TableCell>
                        <TableCell align="center" className={classes.fs12}>
                          {row.dc_leave_time}
                        </TableCell>
                        <TableCell align="center" className={classes.fs12}>
                          {row.return_dc_tim}
                        </TableCell>
                        <TableCell align="center" className={classes.fs12}>
                          {row.highest_cap_veh}
                        </TableCell>
                        <TableCell align="center" className={classes.fs12}>
                          {row.Actions}
                        </TableCell>
                      </StyledTableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </Paper>
  );
}
