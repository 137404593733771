import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { QontoConnector, QontoStepIcon } from "../../Base/StepperStyle";
import TripSummary from "./TripSummary/TripSummary";
import StoreSummary from "./StoreSummary/StoreSummary";
import Trips from "./Trips/Trips";
import Button from "@material-ui/core/Button";
import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import { MoreButton } from "../../Button/Button";
import TripCreationStyle from "../../../assets/css/TripCreationStyle";
import exportToCsv from "../../../helpers/exportToCsv";
import SnackBarComponent from "../../../helpers/SnackBarComponent";

const useStyles = makeStyles(TripCreationStyle);

function getSteps() {
  return ["Trips", "Trip Summary", "Store Summary"];
}

function StepperHeader(props) {
  const classes = useStyles();
  const { activeStep, steps } = props;
  return (
    <Stepper activeStep={activeStep} connector={<QontoConnector />}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel
            StepIconComponent={QontoStepIcon}
            classes={{
              completed: classes.completedStepLabel,
            }}
          >
            <Typography className={classes.fs12}>{label}</Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

function StepperButton(props) {
  const classes = useStyles();
  const { handleBack, downloadReport } = props;
  return (
    <React.Fragment>
      <Button
        onClick={handleBack}
        className={classes.button}
        variant="contained"
        disableElevation
        startIcon={<ChevronLeftRoundedIcon fontSize="small" />}
      >
        <Typography className={clsx(classes.fs12, "ttn")}>Back</Typography>
      </Button>
      <MoreButton
        className={classes.button}
        variant="contained"
        disableElevation
        onClick={downloadReport}
        startIcon={<GetAppRoundedIcon fontSize="small" />}
      >
        <Typography className={clsx(classes.fs12, "ttn")}>Download</Typography>
      </MoreButton>
    </React.Fragment>
  );
}

export default function TripInitiationStepper(props) {
  const classes = useStyles();

  const { proceedToTripCreation, selectedStores, selectedBOL } = props;

  // Trip Initiation Stepper
  const [activeStep, setActiveStep] = React.useState(0);
  const [hideStepper, setHideStepper] = React.useState(false);
  const [tripSummaryData, setTripSummaryData] = React.useState([]);
  const [storeSummaryData, setStoreSummaryData] = React.useState([]);
  const [severity, setSeverity] = React.useState("");
  const [content, setContent] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);

  const handleClose = () => {
    setOpensb(false);
  };

  const handleTripSummaryData = (data) => {
    setTripSummaryData(data);
  };

  const handleStoreSummaryData = (data) => {
    setStoreSummaryData(data);
  };

  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepper = (value) => {
    setHideStepper(value);
  };

  const downloadReport = () => {
    if (activeStep === 1) {
      let csvData = [];
      let title = [
        "trip_id",
        "totalFnvWgt",
        "totalLpnWgt",
        "totalWgtDemand",
        "totalFnvVol",
        "totalLpnVol",
        "totalVolDemand",
        "store_drops",
        "trip_dist_fms_est",
      ];
      let rows = tripSummaryData.map((x) => [
        x[title[0]],
        x[title[1]],
        x[title[2]],
        x[title[3]],
        x[title[4]],
        x[title[5]],
        x[title[6]],
        x[title[7]],
        x[title[8]],
      ]);
      csvData = [title, ...rows];
      exportToCsv("Trip Summary Report on " + new Date() + ".csv", csvData);
      handleSnackBar("Downloaded Successfully", "success");
    } else {
      let csvData = [];
      let title = [
        "trip_id",
        "tag",
        "city_zone",
        "store_id",
        "fms_location_name",
        "totalFnvWgt",
        "totalLpnWgt",
        "totalWgtDemand",
        "totalFnvVol",
        "totalLpnVol",
        "totalVolDemand",
        "lpncount",
        "fnvCrateCount",
        "store_eta",
        "store_etd",
      ];
      let rows = storeSummaryData.map((x) => [
        x[title[0]],
        x[title[1]],
        x[title[2]],
        x[title[3]],
        x[title[4]],
        x[title[5]],
        x[title[6]],
        x[title[7]],
        x[title[8]],
        x[title[9]],
        x[title[10]],
        x[title[11]],
        x[title[12]],
        x[title[13]],
        x[title[14]],
      ]);
      csvData = [title, ...rows];
      exportToCsv("Store Summary Report on " + new Date() + ".csv", csvData);
      handleSnackBar("Downloaded Successfully", "success");
    }
  };

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Trips
            handleNext={handleNext}
            handleStepper={handleStepper}
            verifiedBOL={selectedBOL}
            selectedStores={selectedStores}
          />
        );
      case 1:
        return (
          <TripSummary
            handleNext={handleNext}
            handleTripSummaryData={handleTripSummaryData}
          />
        );
      case 2:
        return (
          <StoreSummary
            handleNext={handleNext}
            proceedToTripCreation={proceedToTripCreation}
            handleStoreSummaryData={handleStoreSummaryData}
          />
        );
      default:
        return "";
    }
  };

  return (
    <div>
      <Paper elevation={0} square>
        <Grid
          container
          justify="space-between"
          className={hideStepper ? classes.dn : ""}
        >
          <Grid item>
            <StepperHeader activeStep={activeStep} steps={steps} />
          </Grid>
          <Grid item className="self-center">
            {activeStep !== 0 ? (
              <StepperButton
                handleBack={handleBack}
                downloadReport={downloadReport}
              />
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={0} square>
        {hideStepper ? "" : <Divider />}
        <React.Fragment>{getStepContent(activeStep)}</React.Fragment>
      </Paper>
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </div>
  );
}
