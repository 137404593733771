import React from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Divider from "@material-ui/core/Divider";
import TripCreationStyle from "../../../assets/css/TripCreationStyle";
import Grid from "@material-ui/core/Grid";
import EWayBill from "./eWayBill";
import VehicleImageUpload from "./vehicleImageUpload";
import { QontoConnector, QontoStepIcon } from "../../Base/StepperStyle";
import Button from "@material-ui/core/Button";
import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";
import clsx from "clsx";
import GetAppIcon from "@material-ui/icons/GetApp";
import { MoreButton } from "../../Button/Button";
import { getLocationId,checkUserDetails} from "../../../helpers/RouteValidation";
import axiosRequest from "../../../helpers/axios";
import SnackBarComponent from "../../../helpers/SnackBarComponent";
import exportToCsv from "../../../helpers/exportToCsv";
import TripSheet from "./Tripsheet";

const useStyles = makeStyles(TripCreationStyle);

function getSteps() {
  return ["eWaybill", "Vehicle Image Upload"];
}

function StepperHeader(props) {
  const classes = useStyles();
  const { activeStep, steps } = props;
  return (
    <Stepper activeStep={activeStep} connector={<QontoConnector />}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel
            StepIconComponent={QontoStepIcon}
            classes={{
              completed: classes.completedStepLabel,
            }}
          >
            <Typography className={classes.fs12}>{label}</Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

function StepperButton(props) {
  const classes = useStyles();
  const { handleBack, activeStep, download } = props;
  return (
    <React.Fragment>
      {activeStep == 0 ? (
        <MoreButton
          onClick={download}
          className={classes.button}
          variant="contained"
          disableElevation
          startIcon={<GetAppIcon fontSize="small" />}
        >
          <Typography className={clsx(classes.fs12, "ttn")}>
            Download
          </Typography>
        </MoreButton>
      ) : (
        <Button
          onClick={handleBack}
          className={classes.button}
          variant="contained"
          disableElevation
          startIcon={<ChevronLeftRoundedIcon fontSize="small" />}
        >
          <Typography className={clsx(classes.fs12, "ttn")}>Back</Typography>
        </Button>
      )}
    </React.Fragment>
  );
}

export default function EWayBillPartB(props) {
  const { proceedToTripEnd } = props;
  const [activeStep, setActiveStep] = React.useState(0);
  const [tableData, setTableData] = React.useState([]);
  const [severity, setSeverity] = React.useState("");
  const [content, setContent] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);
  const [tripsheet, setTripsheet] = React.useState(false);
  const [tripid, setTripid] = React.useState("");
  let userrole=checkUserDetails()
  const handleClose = () => {
    setOpensb(false);
  };
  const handleTripSheet = () => {
    setTripsheet(true);
  };

  const handleBackToVehicleImage = () => {
    setTripsheet(false);
  };

  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getBOLNumbers = async () => {
    let fmsLocationId = getLocationId();
    await axiosRequest({
      url: "eWayBillPartB/getBolNo/" + fmsLocationId,
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        setTableData(data.data);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

   const getnewBOLNumbers = async () => {
    let fmsLocationId = getLocationId();
    await axiosRequest({
      url: "eWayBillPartB/getnewBolNo/" + fmsLocationId,
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        setTableData(data.data);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  React.useEffect(() => {
  {userrole.role=="logisticsirn"?(getnewBOLNumbers()):(getBOLNumbers())}
  }, []);

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };

  const download = () => {
    let csvData = [];
    let title = [
      "trip_id",
      "bol_no",
      "vehicle_number",
      "ewaybill_no",
      "ewaybill_file",
      "einvoice_required",
      "irn_number",
    ];
    let rows = tableData.map((x) => [
      x[title[0]],
      x[title[1]],
      x[title[2]],
      x[title[3]] == "0.0" ? null : x[title[3]],
      x[title[4]],
      x[title[5]] === "Y" ? "Yes" : "No",
      x[title[6]],
    ]);
    csvData = [title, ...rows];
    exportToCsv("E-Way Bill Report on " + new Date() + ".csv", csvData);
    handleSnackBar("Downloaded Successfully", "success");
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <EWayBill
            handleNext={handleNext}
            eWayBillData={tableData}
            getBOLNumbers={getBOLNumbers}
            getnewBOLNumbers={getnewBOLNumbers}
          />
        );
      case 1:
        return (
          <VehicleImageUpload
            proceedToTripEnd={proceedToTripEnd}
            handleTripSheet={handleTripSheet}
            setTripid={setTripid}
          />
        );
      default:
        return "";
    }
  };

  return (
    <div>
      <Paper elevation={0} square>
        <Grid container justify="space-between">
          <Grid item>
            <StepperHeader activeStep={activeStep} steps={steps} />
          </Grid>
          <Grid item className="self-center">
            <StepperButton
              handleBack={handleBack}
              activeStep={activeStep}
              download={download}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={0} square>
        <Divider />
        <React.Fragment>
          {tripsheet == false ? (
            getStepContent(activeStep)
          ) : (
            <TripSheet
              tripid={tripid}
              handleBackToVehicleImage={handleBackToVehicleImage}
            />
          )}
        </React.Fragment>
      </Paper>
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </div>
  );
}
