import axios from "axios";

const client = axios.create({
  baseURL: "https://fms.moreconnect.in/fmsapi",
});
export default function axiosRequest(options) {
  const onSuccess = function (response) {
    return response;
  };

  const onError = function (error) {
    if (error.response) {
      return error.response.data.length > 100
        ? error.response.data.substring(0, 100) + " ..."
        : error.response.data;
    } else {
      return error.message.length > 100
        ? error.message.substring(0, 100) + " ..."
        : error.message;
    }
  };

  return client(options).then(onSuccess).catch(onError);
}
