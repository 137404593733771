import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Normal from "../../Typography/Normal";
import CloseIcon from "@material-ui/icons/Close";
import TripCreationStyle from "../../../assets/css/TripCreationStyle";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { MoreButton } from "../../Button/Button";


const useStyles = makeStyles(TripCreationStyle);

const useStyles1 = makeStyles((theme) => ({
  root: {
    "& .MuiFormLabel-root": {
      fontSize: "12px",
      lineHeight: 0,
    },
  },
}));


export default function AddItemSufMasterDialog(props) {
  const classes = useStyles();
  const classes1=useStyles1();
  const { open, DialogClose,downloadReport,downloadItemReport,downloadCrateData,downloadCsv} = props;
 
 
 
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      aria-labelledby="confirmation-dialog-title"
      classes={{ paperWidthSm: classes.downloadDialog }}
    >
      <DialogTitle id="confirmation-dialog-title">
        <div className="flex justify-between items-center">
          <Normal>
            <h3 className={classes.textColor}>Download the data you required</h3>
          </Normal>
          <IconButton>
            <CloseIcon onClick={DialogClose} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <div className="flex flex-column justify-between mb3">
        <MoreButton
          variant="contained"
          disableElevation
          onClick={downloadReport}
          className={classes.button}
        >
          <Typography className={clsx(classes.fs12, "ttn")}>
           Download item suf master
          </Typography>
        </MoreButton>
        </div>
        <div className="flex flex-column justify-between mb3">

        <MoreButton
          variant="contained"
          disableElevation
          onClick={downloadItemReport}
          className={classes.button}
        >
          <Typography className={clsx(classes.fs12, "ttn")}>
            Download fnv item master
          </Typography>
        </MoreButton>
        </div>
        <div className="flex flex-column justify-between mb3">

        <MoreButton
          variant="contained"
          disableElevation
          onClick={downloadCrateData}
          className={classes.button}
        >
          <Typography className={clsx(classes.fs12, "ttn")}>
            Download crate master
          </Typography>
        </MoreButton>
        </div>
        <div className="flex flex-column justify-between mb3">

          <MoreButton
          variant="contained"
          disableElevation
          onClick={downloadCsv}
          className={classes.button}
        >
          <Typography className={clsx(classes.fs12, "ttn")}>
            Download template
          </Typography> 
        </MoreButton>
        </div>
      </DialogContent>
    </Dialog>
  );
}
