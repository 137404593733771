import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { TableHeader, StyledTableRow } from "../Base/TableHeader";
import { getComparator, sortArray } from "../../helpers/sorting";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import { MoreButton } from "../Button/Button";
import TripCreationStyle from "../../assets/css/TripCreationStyle";
import axiosRequest from "../../helpers/axios";
import Link from "@material-ui/core/Link";
import TripStoreDetailDialog from "../Base/TripStoreDetailDialogue";
import SnackBarComponent from "../../helpers/SnackBarComponent";
import exportToCsv from "../../helpers/exportToCsv";
import { Input } from "@material-ui/core";
import {getLocationId} from "../../helpers/RouteValidation";

const headers = [
  {
    id: "fms_location_name",
    sortable: true,
    align: "center",
    label: "FMS Location",
  },
  {
    id: "trip_created_time",
    sortable: true,
    align: "center",
    label: "Trip Date",
  },
  {
    id: "trip_id",
    sortable: true,
    align: "center",
    label: "Trip ID",
  },
  {
    id: "trip_status",
    sortable: true,
    align: "center",
    label: "Trip Status",
  },
  {
    id: "approval_status",
    sortable: true,
    align: "center",
    label: "Approval Status",
  },
  {
    id: "store_drops",
    sortable: true,
    align: "center",
    label: "Store Drops",
  },
  {
    id: "vehicle_num",
    sortable: true,
    align: "center",
    label: "Vehicle Number",
  },
  {
    id: "trip_dist_fms_est",
    sortable: true,
    align: "center",
    label: "Trip Distance (FMS)",
  },
  {
    id: "trip_dist_dc_input",
    sortable: true,
    align: "center",
    label: "Trip Distance (DC)",
  },
  {
    id: "Locations_Serviced",
    sortable: false,
    align: "center",
    label: "Locations Serviced",
  },
  {
    id: "bol_value",
    sortable: true,
    align: "center",
    label: "BOL Value",
  },
  {
    id: "vendor_name",
    sortable: true,
    align: "center",
    label: "Vendor",
  },

  {
    id: "contract_type",
    sortable: true,
    align: "center",
    label: "Contract Type",
  },
  {
    id: "trip_wt",
    sortable: true,
    align: "center",
    label: "Trip Weight (Kgs)",
  },
  {
    id: "payload_in_mt",
    sortable: true,
    align: "center",
    label: "Payload",
  },
  {
    id: "freight_cost_fms",
    sortable: true,
    align: "center",
    label: "Trip Cost (FMS estimate)",
  },

  {
    id: "freight_cost_dc_input",
    sortable: true,
    align: "center",
    label: "Trip Cost (DC Input)",
  },
  {
    id: "ntm_trip_cost",
    sortable: false,
    align: "center",
    label: "NTM Trip Cost",
  },
  {
    id: "toll_charges",
    sortable: false,
    align: "center",
    label: "Toll Charges",
  },
  {
    id: "halting_charges",
    sortable: false,
    align: "center",
    label: "Halting Charges",
  },
  {
    id: "other_charges",
    sortable: false,
    align: "center",
    label: "Other Charges",
  },
  {
    id: "damage_charges",
    sortable: false,
    align: "center",
    label: "Other Charges",
  },
  {
    id: "total_number_of_crates_dc_input",
    sortable: false,
    align: "center",
    label: "Lpn Count",
  },
];

const useStyles = makeStyles(TripCreationStyle);

export function CostingHeader(props) {
  const { downloadReport,handleStartDate,handleEndDate,handleConfirm,startDate,endDate} = props;
 
  const classes = useStyles();


 
  return (
    <React.Fragment>
      <div className="flex justify-between pa3">
        <Typography variant="body2">Trip Summary</Typography>
        <div className="flex items-center">
          <span className={clsx(classes.fs12, "mr3")}>Start Date:</span>
          <Input
            type="date"
            label="Start Date"
            defaultValue=""
            className={clsx(classes.bgInputGray, classes.underline)}
            onChange={handleStartDate}
          />
        </div>
        <div className="flex items-center">
          <span className={clsx(classes.fs12, "mr3")}>End Date:</span>
          <Input
            type="date"
            label="End Date"
            defaultValue=""
            className={clsx(classes.bgInputGray, classes.underline)}
            onChange={handleEndDate}
          />
        </div>
          <div>
          <MoreButton
            variant="contained"
            disableElevation
            onClick={() => handleConfirm(startDate, endDate)}
          >
            <Typography className={clsx(classes.fs12, "ttn")}>
              Generate Trip Summary
            </Typography>
          </MoreButton>
        </div>
        <div>
          <MoreButton
            variant="contained"
            disableElevation
            className={classes.button}
            startIcon={<GetAppRoundedIcon fontSize="small" />}
            onClick={downloadReport}
          >
            <Typography className={clsx(classes.fs12, "ttn")}>
              Download
            </Typography>
          </MoreButton>
        </div>
      </div>
    </React.Fragment>
  );
}

export default function TripSummaryTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("trip_id");
  const [tableData, setTableData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [storeDetails, setStoreDetails] = React.useState([]);
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const [isSummaryGenerated, setIsSummaryGenerated] = React.useState(false);
  const [severity, setSeverity] = React.useState("");
  const [content, setContent] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);
  const [storeData,setStoreData] = React.useState([])
  const [bolData,setBolData] = React.useState([])
  const handleClose = () => {
    setOpensb(false);
  };

 const handleStartDate = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDate = (event) => {
    setEndDate(event.target.value);
  };

  const formatDate = (date) => {
    var d = new Date(date);
    var month = "" + (d.getMonth() + 1);
    var day = "" + d.getDate();
    var year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const handleConfirm = (startDate, endDate) => {
    if (startDate && endDate) {
      endDate = new Date(endDate);
      endDate.setDate(endDate.getDate() + 1);
      endDate = formatDate(endDate);
      getCostingData(startDate,endDate)
      // getDateLevelSummary(startDate, endDate);
      // getDateVendorLevelSummary(startDate, endDate);
      setIsSummaryGenerated(true);
    } else {
      handleSnackBar("Select Start & End date", "error");
    }
  };

  const DialogOpen = (row) => {
    setStoreDetails(row.stores_list);
    setOpen(true);
  };

  const DialogClose = () => {
    setOpen(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const downloadReport = () => {
    let csvData = [];
    let csvStoreData=[];
    let csvBolData=[];
    let titleStore=[
        "actual_arr_time",
        "actual_dep_time",
        "created_date",
        "delivery_seq",
        "origin_id",
        "pod_1" ,
        "pod_2",
        "store_eta",
        "store_etd",
        "store_id",
        "store_name",
        "trip_id",
        "vehicle_num",
        "vendor_name",
        "market_vehicle_num",
        "trip_store_details_id",
        "updated_date",
    ]
      let titleBol=[
      "bol_no",
      "bol_value",
      "bol_volume",
      "bol_weight",
      "created_date",
      "einvoice_no",
      "ewaybill_file",
      "ewaybill_no",
      "fms_location_id",
      "from_loc",
      "origin_type",
      "ship_date",
      "shipment",
      "to_loc",
      "trip_id",
      "vehicle_num",
      "vendor_name",
      "market_vehicle_num",
      "updated_date"
    ]
    let title = [
      "fms_location_name",
      "trip_created_time",
      "trip_id",
      "trip_status",
      "approval_status",
      "store_drops",
      "vehicle_num",
      "trip_dist_fms_est",
      "trip_dist_dc_input",
      "bol_value",
      "vendor_name",
      "contract_type",
      "trip_wt",
      "payload_in_mt",
      "toll_charges",
      "halting_charges",
      "other_charges",
      "damage_charges",
      "total_number_of_crates_dc_input",
      "freight_cost_fms",
      "freight_cost_dc_input",
      "ntm_trip_cost",
      "is_combi_trip",
    ];
    let rows = tableData.map((x) => [
      x[title[0]],
      formatDate(x[title[1]]),
      x[title[2]],
      x[title[3]],
      x[title[4]],
      x[title[5]],
      x[title[6]],
      x[title[7]],
      x[title[8]],
      x[title[9]],
      x[title[10]],
      x[title[11]],
      x[title[12]],
      x[title[13]],
      x[title[14]],
      x[title[15]],
      x[title[16]],
      x[title[17]],
      x[title[18]],
      x[title[19]],
      x[title[20]],
      x[title[21]],
      x[title[22]] === null ? false : x[title[22]],
    ]);

    let rowsstore = storeData.map((x) => [
      x[titleStore[0]],
      x[titleStore[1]],
      x[titleStore[2]],
      x[titleStore[3]],
      x[titleStore[4]],
      x[titleStore[5]],
      x[titleStore[6]],
      x[titleStore[7]],
      x[titleStore[8]],
      x[titleStore[9]],
      x[titleStore[10]],
      x[titleStore[11]],
      x[titleStore[12]],
      x[titleStore[13]],
      x[titleStore[14]],
      x[titleStore[15]],
      x[titleStore[16]],

   
    ]);

    let rowsbols =bolData.map((x)=>[
      x[titleBol[0]],
      x[titleBol[1]],
      x[titleBol[2]],
      x[titleBol[3]],
      x[titleBol[4]],
      x[titleBol[5]],
      x[titleBol[6]],
      x[titleBol[7]],
      x[titleBol[8]],
      x[titleBol[9]],
      x[titleBol[10]],
      x[titleBol[11]],
      x[titleBol[12]],
      x[titleBol[13]],
      x[titleBol[14]],
      x[titleBol[15]],
      x[titleBol[16]],
      x[titleBol[17]],
      x[titleBol[18]],
    ]);
    csvData = [title, ...rows];
    csvStoreData=[titleStore, ...rowsstore];
    csvBolData=[titleBol, ...rowsbols];
    exportToCsv("Trip Summary Report on trip id level" + new Date() + ".csv", csvData);
    exportToCsv("Trip Summary Report on tripstore id level"+new Date()+".csv",csvStoreData);
    exportToCsv("Trip Summary Report on bol level"+new Date()+".csv",csvBolData);
    handleSnackBar("Downloaded Successfully", "success");
  };

  // const getCostingData = async (startDate,endDate) => {
  //   await axiosRequest({
  //     url: "costing/pendingNTMApproval",
  //     method: "GET",
  //     data:{
  //       startDate
  //     }
  //   }).then((data) => {
  //     if (data.status === 200) {
  //       setTableData(data.data);
  //     } else {
  //       handleSnackBar(data, "error");
  //     }
  //   });
  // };

  const getCostingData = async (startDate, endDate) => {
    let fmsLocationId =getLocationId();
    await axiosRequest({
      url: "costing/pendingNTMApproval",
      method: "POST",
      data: {
        startDate: startDate,
        endDate: endDate,
        fmslocationid:fmsLocationId,
      },
    }).then((data) => {
      if (data.status === 200) {
        setTableData(data.data["data"]);
        setStoreData(data.data["store"])
        setBolData(data.data["bol"])
      } else {
        handleSnackBar(data, "error");
      }
    });
  };


  // React.useEffect(() => {
  //   getCostingData();
  // }, []);

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };
  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <CostingHeader downloadReport={downloadReport} handleConfirm={handleConfirm} handleStartDate={handleStartDate} handleEndDate={handleEndDate}  startDate={startDate} endDate={endDate}/>
        <TableContainer className={classes.costingContainer}>
          <Table stickyHeader>
            <TableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={tableData.length}
              headers={headers}
              isCheckboxEnabled={false}
            />
            <TableBody>
              {sortArray(tableData, getComparator(order, orderBy)).map(
                (row, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <TableCell align="center" className={classes.fs12}>
                        {row.fms_location_name}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_created_time.substr(0, 10)}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_id}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_status}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.approval_status}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.store_drops}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.vehicle_num}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_dist_fms_est}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_dist_dc_input}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        <Link
                          component="button"
                          variant="body2"
                          className={clsx(classes.fs12, classes.textColor)}
                          onClick={() => DialogOpen(row)}
                        >
                          {"View Stores"}
                        </Link>
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        &#8377; {Number(row.bol_value).toFixed(2)}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.vendor_name}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.contract_type}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_wt}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.payload_in_mt}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        &#8377; {Number(row.freight_cost_fms).toFixed(2)}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        &#8377; {Number(row.freight_cost_dc_input).toFixed(2)}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        &#8377; {Number(row.ntm_trip_cost).toFixed(2)}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.toll_charges}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.halting_charges}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.other_charges}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.damage_charges}
                      </TableCell>
                       <TableCell align="center" className={classes.fs12}>
                        {row.total_number_of_crates_dc_input}
                      </TableCell>
                    </StyledTableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <TripStoreDetailDialog
        DialogClose={DialogClose}
        open={open}
        storeDetails={storeDetails}
      />
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </Grid>
  );
}
