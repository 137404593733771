import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { TableHeader, StyledTableRow } from "../../Base/TableHeader";
import { getComparator, sortArray } from "../../../helpers/sorting";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { SuccessButton } from "../../Button/Button";
import TripCreationStyle from "../../../assets/css/TripCreationStyle";
import axiosRequest from "../../../helpers/axios";
import SnackBarComponent from "../../../helpers/SnackBarComponent";



const headers = [
  {
    id: "ptl_id",
    sortable: true,
    align: "center",
    label: "FMS PTL ID",
  },
  {
    id: "consignment_number",
    sortable: true,
    align: "center",
    label: "Consignment number",
  },
  {
    id: "consignment_date",
    sortable: true,
    align: "center",
    label: "Consignment date",
  },
  {
    id: "service_provider",
    sortable: true,
    align: "center",
    label: "Service provider",
  },
  {
    id: "from_location",
    sortable: true,
    align: "center",
    label: "From Location",
  },

  {
    id: "to_location",
    sortable: true,
    align: "center",
    label: "To Location",
  },
  {
    id: "weight",
    sortable: true,
    align: "center",
    label: "Weight (Kgs)",
  },
  {
    id: "number_of_pieces",
    sortable: true,
    align: "center",
    label: "Number of Pieces",
  },

  {
    id: "total_freight_cost",
    sortable: true,
    align: "center",
    label: "Total Freight Cost",
  },
];

const useStyles = makeStyles(TripCreationStyle);

export default function ViewPTL() {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("ptl_id");
  const [tableData,setTableData] = React.useState([])
  const [content, setContent] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);

  const handleClose = () => {
    setOpensb(false);
  };

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getPtlData = async () => {
    await axiosRequest({
      url: "ptl/get_ptl/",
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        setTableData(data.data)
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  React.useEffect(()=>{
    getPtlData()
  },[])

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TableContainer className={classes.PTLContainer}>
          <Table stickyHeader>
            <TableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={tableData.length}
              headers={headers}
              isCheckboxEnabled={false}
            />
            <TableBody>
              {sortArray(tableData, getComparator(order, orderBy)).map(
                (row, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <TableCell align="center" className={classes.fs12}>
                        {row.ptl_id}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.consignment_number}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.consignment_date}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.service_provider}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.from_location}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.to_location}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.weight}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.number_of_pieces}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        &#8377; {row.total_freight_cost.toFixed(2)}
                      </TableCell>
                    </StyledTableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="flex justify-center pa3">
          <SuccessButton variant="contained" disableElevation>
            <Typography className={clsx(classes.fs12, "ttn")}>
              View PTLs
            </Typography>
          </SuccessButton>
        </div>
      </Grid>
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </Grid>
  );
}
