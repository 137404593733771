import React from "react";
import Filter from "./Filter";
import Results from "./Results";
import axiosRequest from "../../../helpers/axios";
import SnackBarComponent from "../../../helpers/SnackBarComponent";

export default function Daily() {
  const [isSummaryGenerated, setIsSummaryGenerated] = React.useState(false);
  const [dateLevelSummary, setDateLevelSummary] = React.useState([]);
  const [dateVendorLevelSummary, setDateVendorLevelSummary] = React.useState(
    []
  );
  const [severity, setSeverity] = React.useState("");
  const [content, setContent] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);
  const [fmsLocationid, setFmsLocationid] = React.useState([]);
  const [LocationOptions,setLocationOptions]=React.useState([]);
  let fmsLocationID = [];
  const handleClose = () => {
    setOpensb(false);
  };

  const formatDate = (date) => {
    var d = new Date(date);
    var month = "" + (d.getMonth() + 1);
    var day = "" + d.getDate();
    var year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const handleConfirm = (startDate, endDate, fmsLocations) => {
    if (startDate && endDate) {
      endDate = new Date(endDate);
      endDate.setDate(endDate.getDate() + 1);
      endDate = formatDate(endDate);
      getDateLevelSummary(startDate, endDate, fmsLocations);
      getDateVendorLevelSummary(startDate, endDate, fmsLocations);
      setIsSummaryGenerated(true);
    } else {
      handleSnackBar("Select Start & End date", "error");
    }
  };

  const getDateLevelSummary = async (startDate, endDate, fmsLocations) => {
    await axiosRequest({
      url: "tripCommercials/dailyLevelSummary",
      method: "POST",
      data: {
        startDate: startDate,
        endDate: endDate,
        fmsLocationId: fmsLocations,
      },
    }).then((data) => {
      if (data.status === 200) {
        setDateLevelSummary(data.data);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const getDateVendorLevelSummary = async (
    startDate,
    endDate,
    fmsLocations
  ) => {
    await axiosRequest({
      url: "tripCommercials/dailyVendorLevelSummary",
      method: "POST",
      data: {
        startDate: startDate,
        endDate: endDate,
        fmsLocationId: fmsLocations,
      },
    }).then((data) => {
      if (data.status === 200) {
        setDateVendorLevelSummary(data.data);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const getLocationData = async () => {
    await axiosRequest({
      url: "master/get_origin/1/",
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        setLocationOptions(data.data);
        console.log(LocationOptions)
        for (let i in data.data) {
          fmsLocationID.push(data.data[i]["fms_location_id"]);
        }
        console.log(fmsLocationID);
        setFmsLocationid(fmsLocationID);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  React.useEffect(()=>{
    getLocationData()
  },[isSummaryGenerated])

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };
  return (
    <React.Fragment>
      <Filter handleConfirm={handleConfirm} LocationOptions={LocationOptions} fmsLocations={fmsLocationid} setFmsLocations={setFmsLocationid}/>
      {isSummaryGenerated ? (
        <Results
          dateLevelSummary={dateLevelSummary}
          dateVendorLevelSummary={dateVendorLevelSummary}
        />
      ) : (
        " "
      )}
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </React.Fragment>
  );
}
