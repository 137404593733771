import * as React from "react";

function SvgPtl(props) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      {...props}
    >
      <path d="M16 37.93a1.14 1.14 0 000-.87 1.12 1.12 0 00-.61-.62l-5.5-2.36a1.12 1.12 0 00-.44-.08 1.2 1.2 0 00-.42.08 1.14 1.14 0 00-.63.61 1.17 1.17 0 000 .88 1.11 1.11 0 00.61.61l5.55 2.36a1.16 1.16 0 001.44-.61z" />
      <path d="M46.32 12.7a1 1 0 00-.43-.33h-.08L23.68 4a1 1 0 00-.77 0L3.43 12.41a1 1 0 00-.33.3l-.1.11-.06.1a.9.9 0 00-.09.4v25a1.05 1.05 0 00.63 1l19.36 8.29h.06a1.05 1.05 0 00.78 0l22.14-8.3a1 1 0 00.67-1v-25a.91.91 0 00-.05-.31l-.1-.15.21-.32zM22.28 45L4.94 37.61V14.9l17.34 7.43zm1.06-24.51l-.13-.06-16.68-7.11 16.81-7.2h.13l19.09 7.15-5.35 2-7.92-3.55a1 1 0 00-.9 0l-5.48 2.32a1.07 1.07 0 00-.63 1 1 1 0 00.64.95l5.87 2.43zm9.34-1.29l3.47-1.3v7.51l-3.47 1.3zm-1-1.82L26 15.09l2.87-1.22 5.58 2.49zm12.8 20.19l-.23.09-19.89 7.45V22.36L30.6 20v8.23a1.06 1.06 0 00.45.85 1 1 0 001 .12l5.55-2.08a1.05 1.05 0 00.67-1v-9l6.25-2.34z" />
    </svg>
  );
}

export default SvgPtl;
