import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { TableHeader, StyledTableRow } from "../Base/TableHeader";
import { getComparator, sortArray } from "../../helpers/sorting";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import PublishRoundedIcon from "@material-ui/icons/PublishRounded";
import { MoreButton, SuccessButton } from "../Button/Button";
import TripCreationStyle from "../../assets/css/TripCreationStyle";
import axiosRequest from "../../helpers/axios";
import Link from "@material-ui/core/Link";
import TripStoreDetailDialog from "../Base/TripStoreDetailDialogue";
import SnackBarComponent from "../../helpers/SnackBarComponent";
import StoreDetailTable from "../Base/StoreDetailTable";
import exportToCsv from "../../helpers/exportToCsv";
import { Input } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import DownloadIcon from "../../assets/icons/download.svg";

const headers = [
  {
    id: "fms_location_name",
    sortable: true,
    align: "center",
    label: "FMS Location",
  },
  {
    id: "trip_created_time",
    sortable: true,
    align: "center",
    label: "Trip Date",
  },
  {
    id: "trip_id",
    sortable: true,
    search: true,
    align: "center",
    label: "Trip ID",
  },
  {
    id: "approval_status",
    sortable: true,
    align: "center",
    label: "Status",
  },
  {
    id: "Locations_Serviced",
    sortable: false,
    align: "center",
    label: "Locations Serviced",
  },
  {
    id: "bol_value",
    sortable: true,
    align: "center",
    label: "BOL Value",
  },
  {
    id: "vendor_name",
    sortable: true,
    align: "center",
    label: "Vendor",
  },

  {
    id: "contract_type",
    sortable: true,
    align: "center",
    label: "Contract Type",
  },
  {
    id: "trip_dist_fms_est",
    sortable: true,
    align: "center",
    label: "Trip Dist (FMS Estimate)",
  },
  {
    id: "trip_dist_dc_input",
    sortable: true,
    align: "center",
    label: "Trip Dist (DC Input)",
  },
  {
    id: "payload_in_mt",
    sortable: true,
    align: "center",
    label: "Payload",
  },
  {
    id: "freight_cost_fms",
    sortable: true,
    align: "center",
    label: "Trip Cost (FMS estimate)",
  },

  {
    id: "freight_cost_dc_input",
    sortable: true,
    align: "center",
    label: "Trip Cost (DC Input)",
  },
  {
    id: "revised_cost",
    sortable: true,
    align: "center",
    label: "Trip Cost (Vendor Input)",
  },
  {
    id: "rejection_doc_url",
    sortable: true,
    align: "center",
    label: "Vendor Docs",
  },
  {
    id: "ntm_trip_cost",
    sortable: false,
    align: "center",
    label: "NTM Trip Cost",
  },
];

const useStyles = makeStyles(TripCreationStyle);
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      width: 300,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

const names = ["Pending NTM", "NTM Approved", "Vendor Rejected","Vendor Accepted"];
export function CostingHeader(props) {
  const {
    costBasedOnProperty,
    downloadReport,
    handleStartDate,
    handleEndDate,
    handleConfirm,
    startDate,
    endDate,
    LocationOptions,
    fmsLocations,
    setFmsLocations,
    vendorOptions,
    vendor,
    setVendor,
    status,
    setStatus,
  } = props;
  const classes = useStyles();

  const isAllSelectedVendor =
    vendorOptions.length > 0 && vendor.length === vendorOptions.length;

  const handleChangeVendor = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      var vendorId = vendorOptions.map((vendors) => vendors.vendor_id);
      setVendor(vendor.length === vendorOptions.length ? [] : vendorId);
    } else {
      setVendor(value);
    }
  };

  const isAllSelected =
    LocationOptions.length > 0 &&
    fmsLocations.length === LocationOptions.length;

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      var fmsLocationsId = LocationOptions.map(
        (fmsLocation) => fmsLocation.fms_location_id
      );
      setFmsLocations(
        fmsLocations.length === LocationOptions.length ? [] : fmsLocationsId
      );
    } else {
      setFmsLocations(value);
    }
  };

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };
  return (
    <React.Fragment>
      <div className="flex flex-column justify-between pa3">
        <Typography variant="body2">Costing</Typography>
        <div className="flex pb3">
          {/* <div className="flex flex-column"> */}
          <span className={clsx(classes.fs12, "mr3")}>Start Date:</span>
          <Input
            type="date"
            label="Start Date"
            defaultValue=""
            className={clsx(classes.bgInputGray, classes.underline, "mr3")}
            onChange={handleStartDate}
          />
          <span className={clsx(classes.fs12, "mr3")}>End Date:</span>
          <Input
            type="date"
            label="End Date"
            defaultValue=""
            className={clsx(classes.bgInputGray, classes.underline, "mr3")}
            onChange={handleEndDate}
          />
          <span className={clsx(classes.fs12, "mr3")}>
            Selection of locations
          </span>
          <Select
            multiple
            value={fmsLocations}
            onChange={handleChange}
            className={clsx(
              classes.inputDailySelect,
              classes.underline,
              classes.fs12,
              classes.bgInputGray
            )}
            renderValue={(fmsLocations) =>
              fmsLocations.length + " Locations selected"
            }
            MenuProps={MenuProps}
          >
            <MenuItem
              value="all"
              classes={{
                root: isAllSelected ? classes.selectedAll : "",
              }}
            >
              <ListItemIcon>
                <Checkbox
                  classes={{ indeterminate: classes.indeterminateColor }}
                  checked={isAllSelected}
                  indeterminate={
                    fmsLocations.length > 0 &&
                    fmsLocations.length < LocationOptions.length
                  }
                />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.selectAllText }}
                primary="Select All Locations"
              />
            </MenuItem>
            {LocationOptions.map((fmsLocation, index) => (
              <MenuItem key={index} value={fmsLocation.fms_location_id}>
                <ListItemIcon>
                  <Checkbox
                    checked={
                      fmsLocations.indexOf(fmsLocation.fms_location_id) > -1
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primary={fmsLocation.fms_location_name}
                  classes={{ primary: classes.selectAllText }}
                />
              </MenuItem>
            ))}
          </Select>
          <MoreButton
            variant="contained"
            disableElevation
            className={clsx(classes.button,"pl4")}
            startIcon={<GetAppRoundedIcon fontSize="small" />}
            onClick={downloadReport}
          >
            <Typography className={clsx(classes.fs12, "ttn")}>
              Download
            </Typography>
          </MoreButton>
        </div>
        <div className="flex pb2">
          <span className={clsx(classes.fs12, "mr3")}>
            Selection of vendors
          </span>
          <Select
            multiple
            value={vendor}
            onChange={handleChangeVendor}
            className={clsx(
              classes.inputDailySelect,
              classes.underline,
              classes.fs12,
              classes.bgInputGray
            )}
            renderValue={(vendor) => vendor.length + " Vendors selected"}
            MenuProps={MenuProps}
          >
            <MenuItem
              value="all"
              classes={{
                root: isAllSelectedVendor ? classes.selectedAll : "",
              }}
            >
              <ListItemIcon>
                <Checkbox
                  classes={{ indeterminate: classes.indeterminateColor }}
                  checked={isAllSelectedVendor}
                  indeterminate={
                    vendor.length > 0 && vendor.length < vendorOptions.length
                  }
                />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.selectAllText }}
                primary="Select All Vendors"
              />
            </MenuItem>
            {vendorOptions.map((vendors, index) => (
              <MenuItem key={index} value={vendors.vendor_id}>
                <ListItemIcon>
                  <Checkbox checked={vendor.indexOf(vendors.vendor_id) > -1} />
                </ListItemIcon>
                <ListItemText
                  primary={vendors.vendor_name}
                  classes={{ primary: classes.selectAllText }}
                />
              </MenuItem>
            ))}
          </Select>
          <span className={clsx(classes.fs12, "pl3 mr3")}>Trip status</span>
          <div className="flex pl2 pr4">
            <Select
              labelId="demo-mutiple-checkbox-label"
              id="demo-mutiple-checkbox"
              multiple
              value={status}
              name="first"
              onChange={handleChangeStatus}
              className={clsx(
                classes.inputDailySelect,
                classes.underline,
                classes.fs12,
                classes.bgInputGray
              )}
              input={<Input />}
              renderValue={(selected) => selected.join(", ")}
            >
              {names.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={status.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </div>
          <MoreButton
            variant="contained"
            disableElevation
            onClick={() =>
              handleConfirm(startDate, endDate, fmsLocations, vendor, status)
            }
            className="ml3"
          >
            <Typography className={clsx(classes.fs12, "ttn")}>
              Generate Costing Summary
            </Typography>
          </MoreButton>
          {/* <Button
            variant="contained"
            disableElevation
            className={classes.button}
            onClick={() => costBasedOnProperty("freight_cost_fms")}
          >
            <Typography className={clsx(classes.fs12, "ttn")}>
              I/P Cost Based on FMS Estimate
            </Typography>
          </Button>
          <Button
            variant="contained"
            disableElevation
            className={classes.button}
            onClick={() => costBasedOnProperty("freight_cost_dc_input")}
          >
            <Typography className={clsx(classes.fs12, "ttn")}>
              I/P Cost Based on DC Input
            </Typography>
          </Button> */}
        </div>
      </div>
    </React.Fragment>
  );
}

export default function CostingTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("trip_id");
  const [tableData, setTableData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [storeDetails, setStoreDetails] = React.useState([]);
  const [severity, setSeverity] = React.useState("");
  const [content, setContent] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [isSummaryGenerated, setIsSummaryGenerated] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [fmsLocationid, setFmsLocationid] = React.useState([]);
  const [LocationOptions, setLocationOptions] = React.useState([]);
  const [vendor, setVendor] = React.useState([]);
  const [vendorOptions, setVendorOptions] = React.useState([]);
  const [status, setStatus] = React.useState([]);
  let fmsLocationID = [];
  let vendorId = [];
  const handleClose = () => {
    setOpensb(false);
  };

  const handleStartDate = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDate = (event) => {
    setEndDate(event.target.value);
  };

  const handleConfirm = (startDate, endDate) => {
    if (startDate && endDate) {
      endDate = new Date(endDate);
      endDate.setDate(endDate.getDate() + 1);
      endDate = formatDate(endDate);
      getCostingData(startDate, endDate);
      // getDateLevelSummary(startDate, endDate);
      // getDateVendorLevelSummary(startDate, endDate);
      setIsSummaryGenerated(true);
    } else {
      handleSnackBar("Select Start & End date", "error");
    }
  };

  const DialogOpen = (row) => {
    setStoreDetails(row.stores_list);
    setOpen(true);
  };

  const formatDate = (date) => {
    var d = new Date(date);
    var month = "" + (d.getMonth() + 1);
    var day = "" + d.getDate();
    var year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const DialogClose = () => {
    setOpen(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleNTMTripCost = (row, event, index) => {
    row["ntm_trip_cost_updated"] = event.target.value;
    var tempTableData = [...tableData];
    tempTableData[index] = row;
    setTableData(tempTableData);
  };

  const costBasedOnProperty = (property) => {
    var tempTableData = [...tableData];
    tempTableData.map((row) =>
      row["approval_status"] == "Pending NTM"
        ? (row["ntm_trip_cost_updated"] = row[property])
        : ""
    );
    setTableData(tempTableData);
  };

  const downloadReport = () => {
    let csvData = [];
    let title = [
      "fms_location_name",
      "trip_id",
      "approval_status",
      "bol_value",
      "vendor_name",
      "contract_type",
      "payload_in_mt",
      "freight_cost_fms",
      "freight_cost_dc_input",
      "ntm_trip_cost",
      "revised_cost",
      "trip_created_time",
      "trip_dist_fms_est",
      "trip_dist_dc_input",
    ];
    let rows = tableData.map((x) => [
      x[title[0]],
      x[title[1]],
      x[title[2]],
      x[title[3]],
      x[title[4]],
      x[title[5]],
      x[title[6]],
      x[title[7]],
      x[title[8]],
      x[title[9]],
      x.vendor_reject[title[10]] ? x.vendor_reject[title[10]] : 0,
      x[title[11]],
      x[title[12]],
      x[title[13]],
    ]);
    csvData = [title, ...rows];
    exportToCsv("NTM Report on " + new Date() + ".csv", csvData);
    handleSnackBar("Downloaded Successfully", "success");
  };

  const getCostingData = async () => {
    await axiosRequest({
      url: "costing/NTMdata",
      method: "POST",
      data: {
        startDate: startDate,
        endDate: endDate,
        fmslocationid: fmsLocationid,
        vendorid: vendor,
        status: status,
      },
    }).then((data) => {
      if (data.status === 200) {
        const formattedTableData = data?.data
          ?.map((item) => {
            return {
              ...item,
              ntm_trip_cost_updated:
                (status[0] === "Pending NTM"
                  ? item.freight_cost_dc_input
                  : item.ntm_trip_cost) || 0,
            };
          })
          .filter((item) => item);
        setTableData(formattedTableData || []);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };
  const getLocationData = async () => {
    await axiosRequest({
      url: "master/getlocations/",
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        setLocationOptions(data.data);
        console.log(LocationOptions);
        for (let i in data.data) {
          fmsLocationID.push(data.data[i]["fms_location_id"]);
        }
        console.log(fmsLocationID);
        setFmsLocationid(fmsLocationID);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };
  const getVendorData = async () => {
    await axiosRequest({
      url: "master/vendor/",
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        setVendorOptions(data.data);
        console.log(vendorOptions);
        for (let i in data.data) {
          vendorId.push(data.data[i]["vendor_id"]);
        }
        console.log(vendorId);
        setVendor(vendorId);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };
  React.useEffect(() => {
    setRows(tableData);
  }, [tableData]);

  React.useEffect(() => {
    getLocationData();
    getVendorData();
  }, []);

  const updateCostingData = async () => {
    await axiosRequest({
      url: "costing/updateNTMCosting",
      method: "PUT",
      data: {
        tripData: tableData.filter(
          (trip) =>
            (trip.approval_status === "Pending NTM" &&
              Number(trip.ntm_trip_cost_updated) > 0) ||
            (trip.approval_status === "NTM Approved" &&
              Number(trip.ntm_trip_cost_updated) > 0 &&
              Number(trip.ntm_trip_cost_updated) !=
                Number(trip.ntm_trip_cost)) ||
            (trip.approval_status === "Vendor Rejected" &&
              Number(trip.ntm_trip_cost_updated) > 0) ||
              (trip.approval_status === "Vendor Accepted" &&
              Number(trip.ntm_trip_cost_updated) > 0)
        ),
      },
    }).then((data) => {
      if (data.status === 200) {
        getCostingData(data.data);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };
  const handleAllSearch = (event, property) => {
    const searchResult = tableData.filter((row) => {
      return row[property]
        .toString()
        .toLowerCase()
        .includes(event.target.value.toLowerCase());
    });
    setRows(searchResult);
  };
  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };

  const downloadVendorDocs = async (fileName, tripId) => {
    await axiosRequest({
      url: "costing/vendor_rejection_doc",
      method: "POST",
      data: {
        fileName: fileName,
        tripId: tripId,
      },
    }).then((data) => {
      if (data.status === 200) {
        window.open(data.data);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <CostingHeader
          costBasedOnProperty={costBasedOnProperty}
          downloadReport={downloadReport}
          handleStartDate={handleStartDate}
          handleEndDate={handleEndDate}
          handleConfirm={handleConfirm}
          startDate={startDate}
          endDate={endDate}
          LocationOptions={LocationOptions}
          fmsLocations={fmsLocationid}
          setFmsLocations={setFmsLocationid}
          vendorOptions={vendorOptions}
          vendor={vendor}
          setVendor={setVendor}
          status={status}
          setStatus={setStatus}
        />
        <TableContainer className={classes.costingContainer}>
          <Table stickyHeader>
            <TableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={tableData.length}
              headers={headers}
              handleSearch={handleAllSearch}
              isCheckboxEnabled={false}
            />
            <TableBody>
              {sortArray(rows, getComparator(order, orderBy)).map(
                (row, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <TableCell align="center" className={classes.fs12}>
                        {row.fms_location_name}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_created_time.substr(0, 10)}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_id}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.approval_status}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        <Link
                          component="button"
                          variant="body2"
                          className={clsx(classes.fs12, classes.textColor)}
                          onClick={() => DialogOpen(row)}
                        >
                          {"View Stores"}
                        </Link>
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        <div className="d-flex align-items-center">
                          <div>&#8377;</div>
                          {Number(row.bol_value).toFixed(2)}{" "}
                        </div>
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.vendor_name}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.contract_type}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                      {row.trip_dist_fms_est}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_dist_dc_input}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.payload_in_mt}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        <div className="d-flex align-items-center">
                          <div>&#8377;</div>
                          {Number(row.freight_cost_fms).toFixed(2)}
                        </div>
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        <div className="d-flex align-items-center">
                          <div>&#8377;</div>
                          {Number(row.freight_cost_dc_input).toFixed(2)}
                        </div>
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        <div className="d-flex align-items-center">
                          <div>&#8377;</div>
                          {Number(row?.vendor_reject?.revised_cost).toFixed(2)}
                        </div>
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.fs12}
                        onClick={() => {
                          if (row?.vendor_reject?.rejection_doc_url) {
                            downloadVendorDocs(
                              row?.vendor_reject?.rejection_doc_url,
                              row.trip_id
                            );
                          }
                        }}
                      >
                        {row?.vendor_reject?.rejection_doc_url ? (
                          <img
                            style={{
                              width: "30px",
                              height: "30px",
                              cursor: "pointer",
                            }}
                            src={DownloadIcon}
                            alt="download"
                          />
                        ) : (
                          "NA"
                        )}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        <div className={"mb3"}>
                          <TextField
                            variant="outlined"
                            value={Number(row.ntm_trip_cost_updated)}
                            InputProps={{
                              inputProps: {
                                min: 0,
                              },
                            }}
                            className={clsx(classes.inputNumber)}
                            type="number"
                            onChange={(e) => handleNTMTripCost(row, e, index)}
                          />
                        </div>
                      </TableCell>
                    </StyledTableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="flex justify-center pa3">
          <SuccessButton
            variant="contained"
            disableElevation
            onClick={updateCostingData}
          >
            <Typography className={clsx(classes.fs12, "ttn")}>Save</Typography>
          </SuccessButton>
        </div>
      </Grid>
      <TripStoreDetailDialog
        DialogClose={DialogClose}
        open={open}
        storeDetails={storeDetails}
      />
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </Grid>
  );
}
