import React,{useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Normal from "../../Typography/Normal";
import CloseIcon from "@material-ui/icons/Close";
import TripCreationStyle from "../../../assets/css/TripCreationStyle";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { DialogActions } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { SuccessButton } from "../../Button/Button";
import Input from "@material-ui/core/Input";
import axiosRequest from "../../../helpers/axios";
import SnackBarComponent from "../../../helpers/SnackBarComponent";

const useStyles = makeStyles(TripCreationStyle);
const useStyles1 = makeStyles((theme) => ({
  root: {
    "& .MuiFormLabel-root": {
      fontSize: "12px",
      lineHeight: 0,
    },
  },
}));
export default function EditDialog(props) {
  const classes = useStyles();
  const classes1 = useStyles1();

  const { open, DialogClose, vendorid, contractid, vendorName, contract, payload, contractstartdate, contractenddate, contractdetailsid } = props;

  const [variablerate, setVariableRate] = React.useState("0.0");
  const [fixedcost, setFixedCost] = React.useState("0.0");
  const [minagreedkm, setMinAgreedKm] = React.useState("0");
  const [fixedrate, setFixedRate] = React.useState("0.0");
  const [routeCost, setRouteCost] = React.useState("0.0");
  const [fromkm, setFromKm] = React.useState("");
  const [tokm, setToKm] = React.useState("");
  const [triprate1, setTripRate1] = React.useState("");
  const [triprate2, setTripRate2] = React.useState("");
  const [fromkm1, setFromKm1] = React.useState("");
  const [tokm1, setToKm1] = React.useState("");
  const [triprate11, setTripRate11] = React.useState("");
  const [triprate21, setTripRate21] = React.useState("");
  const [totalvehicle, setTotalVehicle] = React.useState("");
  const [content, setContent] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);
  const handleClose = () => {
    setOpensb(false);
  };

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };

  const handleVariableRate = (event) => {
    setVariableRate(event.target.value);
  };
  const handleFixedCost = (event) => {
    setFixedCost(event.target.value);
  };
  const handleMinAgreedKm = (event) => {
    setMinAgreedKm(event.target.value);
  };
  const handleFixedRate = (event) => {
    setFixedRate(event.target.value);
  };
  const handleRouteCost = (event) => {
    setRouteCost(event.target.value);
  };
  const handleFromKm = (event) => {
    setFromKm(event.target.value);
  };
  const handleToKm = (event) => {
    setToKm(event.target.value);
  };
  const handleTrip1Rate = (event) => {
    setTripRate1(event.target.value);
  };
  const handleTrip2Rate = (event) => {
    setTripRate2(event.target.value);
  };

  const handleFromKm1 = (event) => {
    setFromKm1(event.target.value);
  };
  const handleToKm1 = (event) => {
    setToKm1(event.target.value);
  };
  const handleTrip1Rate1 = (event) => {
    setTripRate11(event.target.value);
  };
  const handleTrip2Rate1 = (event) => {
    setTripRate21(event.target.value);
  };
  const handleTotalVehicles = (event) => {
    setTotalVehicle(event.target.value);
  };
  useEffect(() => {
    if (open) {
      setVariableRate("0.0");
      setFixedCost("0.0");
      setMinAgreedKm("0");
      setFixedRate("0.0");
      setRouteCost("0.0");
    }
  }, [open]);
  const editContractMasterData = async () => {
    await axiosRequest({
      url: "master/renewal_edit_contractdetails/",
      method: "POST",
      data: {
        vendor_id: Number(vendorid),
        per_trip: contract,
        payload: payload,
        total_vehicles: Number(totalvehicle),
        contract_start_date: String("26/" + contractstartdate.split("-")[1] + "/" + contractstartdate.split("-")[0]),
        contract_end_date: String("25/" + contractenddate.split("-")[1] + "/" + contractenddate.split("-")[0]),
        variable_rate: variablerate,
        fixed_cost: fixedcost,
        fixed_rate: fixedrate,
        min_agreed_km: minagreedkm,
        slab1_from: Number(fromkm),
        slab1_to: Number(tokm),
        slab2_from: Number(fromkm1),
        slab2_to: Number(tokm1),
        slab1_trip1_rate: triprate1 == "" ? "" : Number(triprate1),
        slab1_trip2_rate: triprate2 == "" ? "" : Number(triprate2),
        slab2_trip1_rate: triprate11 == "" ? "" : Number(triprate11),
        slab2_trip2_rate: triprate21 == "" ? "" : Number(triprate21),
        route_cost: routeCost,
        event: "edit",                  //renewal
        contract_details_id: contractid
      },
    }).then((data) => {
      if (data.status === 200) {
        DialogClose();
      } else {
        handleSnackBar(data, "error");
      }
    });
  };
  const cleanString = (str) => str.replace(/[\[\]()']+/g, '');


  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      aria-labelledby="confirmation-dialog-title"
      classes={{ paperWidthSm: classes.contractMasterDialog }}
    >
      <DialogTitle id="confirmation-dialog-title">
        <div className="flex justify-between items-center">
          <Normal>
            <h3 className={classes.textColor}>Edit Contract Rate</h3>
          </Normal>
          <IconButton>
            <CloseIcon onClick={DialogClose} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <div className="flex flex-row justify-between mt3">
          <TextField
            id="outlined-basic"
            label="Vendor Name"
            className={clsx(classes.inputmargin, classes1.root)}
            variant="outlined"
            value={vendorName}
          />
          <TextField
            id="outlined-basic"
            label="Vendor ID"
            className={clsx(classes.inputmargin, classes1.root)}
            variant="outlined"
            value={vendorid}
          />
          <TextField
            id="outlined-basic"
            label="Contract Type"
            variant="outlined"
            className={clsx(classes.inputmargin, classes1.root)}
            value={contract}
          />
          <TextField
            id="outlined-basic"
            label="Payload"
            variant="outlined"
            className={clsx(classes.inputmargin, classes1.root)}
            value={payload}
          />
          <TextField
            id="outlined-basic"
            label="Contract ID"
            variant="outlined"
            className={clsx(classes.inputmargin, classes1.root)}
            value={contractid}
          />
          {/* {(contract == "Fixed+Variable (with min km guarantee)" || contract == "Fixed+Variable") ? (<React.Fragment>
            <TextField
              id="outlined-basic"
              label="Total Vehicles"
              variant="outlined"
              className={clsx(classes.inputmargin, classes1.root)}
              onChange={handleTotalVehicles}
            />
          </React.Fragment>) : ("")} */}
        </div>

        <div className="flex flex-row justify-between mt3">
          <div className="flex">
            <label>
              Contract_Start_Date
              <div className={clsx(classes.bgInputGray, "mr3")}>
                <Input
                  type="date"
                  value={contractstartdate}
                  className={clsx(classes.underline, classes.fs12)}
                />
              </div>
            </label>
          </div>
          <div className="flex">
            <label>
              Contract_End_Date
              <div className={classes.bgInputGray}>
                <Input
                  type="date"
                  value={contractenddate}
                  className={clsx(classes.underline, classes.fs12)}
                />
              </div>
            </label>
          </div>
        </div>

        <div className="flex flex-row justify-between mt3">
          {(contract.toLowerCase() === "fixed+variable") ? (<React.Fragment>
            <TextField
              id="outlined-basic"
              label="Variable Rate"
              variant="outlined"
              className={clsx(classes.inputmargin, classes1.root)}
              onChange={handleVariableRate}
            />
            <TextField
              id="outlined-basic"
              label="Fixed Cost"
              variant="outlined"
              className={clsx(classes.inputmargin, classes1.root)}
              onChange={handleFixedCost}
            />
          </React.Fragment>) : ("")}
          {(contract.toLowerCase() === "per km") ? (<React.Fragment><TextField
            id="outlined-basic"
            label="Variable Rate"
            variant="outlined"
            className={clsx(classes.inputmargin, classes1.root)}
            onChange={handleVariableRate}
          />
          </React.Fragment>) : ("")}
          {(cleanString(contract).toLowerCase() === "fixed+variable with min km guarantee") ? (
            <React.Fragment>
              <TextField
                id="outlined-basic"
                label="Variable Rate"
                variant="outlined"
                className={clsx(classes.inputmargin, classes1.root)}
                onChange={handleVariableRate}
              />
              <TextField
                id="outlined-basic"
                label="Fixed Cost"
                variant="outlined"
                className={clsx(classes.inputmargin, classes1.root)}
                onChange={handleFixedCost}
              />
              <TextField
                id="outlined-basic"
                label="Min.agreed KM "
                variant="outlined"
                className={clsx(classes.inputmargin, classes1.root)}
                onChange={handleMinAgreedKm}
              />
              <TextField
                id="outlined-basic"
                label="Fixed Rate"
                variant="outlined"
                className={clsx(classes.inputmargin, classes1.root)}
                onChange={handleFixedRate}
              />
            </React.Fragment>) : ("")}

          {(contract.toLowerCase() !== "fixed+variable (with min km guarantee)" &&
            contract.toLowerCase() !== "fixed+variable" &&
            contract.toLowerCase() !== "per km" &&
            contract.toLowerCase() !== "per trip" &&
            cleanString(contract).toLowerCase() !== "fixed+variable with min km guarantee" &&
            cleanString(contract).toLowerCase() !== "per km") ? (
            <TextField
              id="outlined-basic"
              label="Route Cost"
              variant="outlined"
              className={clsx(classes.inputmargin, classes1.root)}
              onChange={handleRouteCost}
            />
          ) : null}
        </div>
        <div className="flex flex-row justify-between mt4">
          {(contract.toLowerCase() === "per trip") ? (<React.Fragment>
            <label> Trip Rate </label>
            <TextField
              disabled
              id="filled-disabled"
              defaultValue="Slab1"
              className={clsx(classes.inputmargin, classes1.root)}
              variant="filled"
            />
            <TextField
              id="outlined-basic"
              label="From (KM)"
              variant="outlined"
              className={clsx(classes.inputmargin, classes1.root)}
              onChange={handleFromKm}
            />
            <TextField
              id="outlined-basic"
              label="To (KM)"
              variant="outlined"
              className={clsx(classes.inputmargin, classes1.root)}
              onChange={handleToKm}
            />
            <TextField
              id="outlined-basic"
              label="Trip 1 Rate "
              variant="outlined"
              className={clsx(classes.inputmargin, classes1.root)}
              onChange={handleTrip1Rate}
            />
            <TextField
              id="outlined-basic"
              label="Trip 2 Rate"
              variant="outlined"
              className={clsx(classes.inputmargin, classes1.root)}
              onChange={handleTrip2Rate}
            />
          </React.Fragment>) : ("")}

        </div>
        <div className="flex flex-row justify-between mt3">
          {contract == "Per Trip" ? (<React.Fragment>
            <TextField
              disabled
              id="filled-disabled"
              defaultValue="Slab2"
              className={clsx(classes.inputmargin, classes1.root)}
              variant="filled"
            />
            <TextField
              id="outlined-basic"
              label="From (KM)"
              variant="outlined"
              className={clsx(classes.inputmargin, classes1.root)}
              onChange={handleFromKm1}
            />
            <TextField
              id="outlined-basic"
              label="To (KM)"
              variant="outlined"
              className={clsx(classes.inputmargin, classes1.root)}
              onChange={handleToKm1}
            />
            <TextField
              id="outlined-basic"
              label="Trip 1 Rate "
              variant="outlined"
              className={clsx(classes.inputmargin, classes1.root)}
              onChange={handleTrip1Rate1}
            />
            <TextField
              id="outlined-basic"
              label="Trip 2 Rate"
              variant="outlined"
              className={clsx(classes.inputmargin, classes1.root)}
              onChange={handleTrip2Rate1}
            />
          </React.Fragment>) : ("")}
        </div>
      </DialogContent>
      <DialogActions>
        <div>
          <p>*New rates will be applicable only from the next billing period</p>
        </div>
        <div className="flex justify-center">
          <SuccessButton onClick={editContractMasterData} variant="contained" disableElevation>
            <Typography className={clsx(classes.fs12, "ttn")}>
              Save Contract
            </Typography>
          </SuccessButton>
        </div>
      </DialogActions>
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </Dialog>
  );
}
