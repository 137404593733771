import React from "react";
import Filter from "./Filter";
import Results from "./Results";
import axiosRequest from "../../../helpers/axios";
import SnackBarComponent from "../../../helpers/SnackBarComponent";

export default function Monthly() {
  const [isSummaryGenerated, setIsSummaryGenerated] = React.useState(false);
  const [monthLevelSummary, setMonthLevelSummary] = React.useState([]);
  const [monthVendorLevelSummary, setMonthVendorLevelSummary] = React.useState(
    []
  );
  const [tripStoreLevelSummary, setTripStoreLevelSummary] = React.useState([]);
  const [tripBolLevelSummary, setTripBolLevelSummary] = React.useState([]);
  const [tripLevelSummary, setTripLevelSummary] = React.useState([]);

  const [startMonth, setStartMonth] = React.useState("");
  const [endMonth, setEndMonth] = React.useState("");
  const [fmsLocations, setFmsLocations] = React.useState([]);
  const [severity, setSeverity] = React.useState("");
  const [content, setContent] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);
  const [fmsLocationid, setFmsLocationid] = React.useState([]);
  const [LocationOptions,setLocationOptions]=React.useState([]);
  let fmsLocationID = [];
  const handleClose = () => {
    setOpensb(false);
  };

  const handleConfirm = (startMonth, endMonth, fmsLocations) => {
    if (startMonth && endMonth) {
      getMonthLevelSummary(startMonth, endMonth, fmsLocations);
      setStartMonth(startMonth);
      setEndMonth(endMonth);
      setFmsLocations(fmsLocations);
      setIsSummaryGenerated(true);
    } else {
      handleSnackBar("Select Start & End Month", "error");
    }
  };

  const handleDiscount = (row, event, index) => {
    row.monthly_discount_entered = event.target.value;
    var tempMonthVendorData = [...monthVendorLevelSummary];
    tempMonthVendorData[index] = row;
    setMonthVendorLevelSummary(tempMonthVendorData);
  };

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const getMonthRange = (startDate, endDate) => {
    var start = startDate.split("-");
    var end = endDate.split("-");
    var startYear = parseInt(start[0]);
    var endYear = parseInt(end[0]);
    var months = [];

    for (var i = startYear; i <= endYear; i++) {
      var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
      var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
      for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        var reqMonth = {};
        var month = j;
        if (month === 0) {
          reqMonth.startDate = 12;
          reqMonth.month = monthNames[month];
          reqMonth.startDate = [i - 1, reqMonth.startDate, "26"].join("-");
        } else {
          reqMonth.startDate = month < 10 ? "0" + month : month;
          reqMonth.month = monthNames[month];
          reqMonth.startDate = [i, reqMonth.startDate, "26"].join("-");
        }
        reqMonth.endDate = month + 1 < 10 ? "0" + (month + 1) : month + 1;
        reqMonth.endDate = [i, reqMonth.endDate, "26"].join("-");
        reqMonth.month = reqMonth.month + " - " + i;
        months.push(reqMonth);
      }
    }
    return months;
  };

  const getMonthLevelSummary = async (startMonth, endMonth, fmsLocations) => {
    var months = getMonthRange(startMonth, endMonth);
    await axiosRequest({
      url: "tripCommercials/monthLevelSummary",
      method: "POST",
      data: {
        month: months,
        fmsLocationId: fmsLocations,
      },
    }).then((data) => {
      if (data.status === 200) {
        setMonthLevelSummary(data.data["fmsLocationLevelSummary"]);
        setMonthVendorLevelSummary(data.data["vendorLevelSummary"]);
        setTripStoreLevelSummary(data.data["tripStoreLevelSummary"])
        setTripBolLevelSummary(data.data["tripBolLevelSummary"])
        setTripLevelSummary(data.data["tripLevelSummary"])
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const getLocationData = async () => {
    await axiosRequest({
      url: "master/get_origin/1/",
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        setLocationOptions(data.data);
        console.log(LocationOptions)
        for (let i in data.data) {
          fmsLocationID.push(data.data[i]["fms_location_id"]);
        }
        console.log(fmsLocationID);
        setFmsLocationid(fmsLocationID);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };

  React.useEffect(()=>{
    getLocationData()
  },[isSummaryGenerated])
  return (
    <React.Fragment>
      <Filter handleConfirm={handleConfirm} LocationOptions={LocationOptions} fmsLocations={fmsLocationid} setFmsLocations={setFmsLocationid}/>
      {isSummaryGenerated ? (
        <Results
          monthLevelSummary={monthLevelSummary}
          monthVendorLevelSummary={monthVendorLevelSummary}
          tripBolLevelSummary={tripBolLevelSummary}
          tripStoreLevelSummary={tripStoreLevelSummary}
          tripLevelSummary={tripLevelSummary}
          handleDiscount={handleDiscount}
          handleConfirm={handleConfirm}
          startMonth={startMonth}
          endMonth={endMonth}
          fmsLocations={fmsLocations}
        />
      ) : (
        " "
      )}
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </React.Fragment>
  );
}
