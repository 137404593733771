import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { moreColor } from "../../assets/css/BaseStyles";
import Daily from "./Daily/Daily";
import Monthly from "./Monthly/Monthly";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Grid from "@material-ui/core/Grid";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel">
      {value === index && (
        <Paper elevation={0} square>
          {children}
        </Paper>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  fs12: {
    fontSize: "12px",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  selectedTab: {
    color: moreColor[0],
  },
  tab: {
    textTransform: "none",
    fontSize: "12px",
  },
}));

export default function NTMSummaryStepper() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={clsx(classes.root, "mt4")}>
      <Grid container justify="space-between">
        <Grid item>
          <Tabs
            value={value}
            onChange={handleChange}
            classes={{ root: classes.ttn }}
          >
            <Tab
              label="Daily summary"
              classes={{ selected: classes.selectedTab, root: classes.tab }}
            />
            <Tab
              label="Monthly summary"
              classes={{ selected: classes.selectedTab, root: classes.tab }}
            />
          </Tabs>
        </Grid>
      </Grid>
      <TabPanel value={value} index={0}>
        <Daily />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Monthly />
      </TabPanel>
    </div>
  );
}
