import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { TableHeader, StyledTableRow } from "../../../Base/TableHeader";
import { getComparator, sortArray } from "../../../../helpers/sorting";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { SuccessButton, MoreIconButton } from "../../../Button/Button";
import TripCreationStyle from "../../../../assets/css/TripCreationStyle";
import axiosRequest from "../../../../helpers/axios";
import Link from "@material-ui/core/Link";
import TripStoreDetailDialog from "../../../Base/TripStoreDetailDialogue";
import SnackBarComponent from "../../../../helpers/SnackBarComponent";
import { getLocationId } from "../../../../helpers/RouteValidation";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocalShippingRoundedIcon from "@material-ui/icons/LocalShippingRounded";
import MarketVehicle from "./MarketVehicleDialog";
import PrimaryTransport from "./PrimaryTransportDialog";
import {checkUserDetails} from "../../../../helpers/RouteValidation";
import VirtualTransfer from "./VirtualTransfer";
import Moment from 'react-moment';
import 'moment-timezone';
import CircularProgress from '@material-ui/core/CircularProgress';


const headers = [
  {
    id: "trip_id",
    sortable: true,
    align: "center",
    label: "Temp Trip ID",
  },
  {
    id: "trip_initiated_time",
    align: "center",
    sortable: true,
    label: "Trip Initiated Time",
  },
  {
    id: "Store_details",
    align: "center",
    label: "Store Details",
  },
  {
    id: "trip_wt",
    sortable: true,
    align: "center",
    label: "Total Weight (Kg)",
  },
  {
    id: "trip_vol",
    sortable: true,
    align: "center",
    label: "Total Volume (Cft)",
  },
  {
    id: "trip_dist_fms_est",
    sortable: true,
    align: "center",
    label: "Distance Travelled",
  },
  {
    id: "vehicle_num",
    sortable: true,
    align: "center",
    label: "Vehicle Number",
  },
  {
    id: "contract_type",
    sortable: true,
    align: "center",
    label: "Contract Type",
  },
  {
    id: "vendor_name",
    sortable: true,
    align: "center",
    label: "Vendor Name",
  },
  {
    id: "vehicle_wt_capacity",
    sortable: true,
    align: "center",
    label: "Vehicle Weight Capacity",
  },
  {
    id: "vehicle_vol_capacity",
    sortable: true,
    align: "center",
    label: "Vehicle Volume Capacity",
  },
  {
    id: "vehicle_wt_util",
    sortable: true,
    align: "center",
    label: "Vehicle Weight Utilization",
  },
  {
    id: "vehicle_vol_util",
    sortable: true,
    align: "center",
    label: "Vehicle Volume Utilization",
  },
  {
    id:"Movement_type",
    align:"center",
    label:"Movement Type"
  }
];

const useStyles = makeStyles(TripCreationStyle);

const useStyles1 = makeStyles((theme) => ({
  root: {
    "& .MuiFormLabel-root": {
      fontSize: "12px",
      lineHeight: 0,
    },
  },
}));

export default function TripCreation(props) {
  const classes = useStyles();
  const classes1 = useStyles1();
  const { handleNext, proceedToeWayBillPartB, tripData } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("trip_id");
  const [tableData, setTableData] = React.useState([]);
  const [vehicleData, setVehicleData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [storeDetails, setStoreDetails] = React.useState([]);
  const [severity, setSeverity] = React.useState("");
  const [content, setContent] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);
  const [movement,setMovement]=React.useState("")
  const [marketVehicleOpen, setMarketVehicleOpen] = React.useState(false);
  const [marketVehicleIndex, setMarketVehicleIndex] = React.useState("");
  const [selectedVehicles, setSelectedVehicles] = React.useState([]);
  const [primaryTransportOpen, setPrimaryTransportOpen] = React.useState(false);
  const [primaryTransportIndex, setPrimaryTransportIndex] = React.useState("");
  const [virtualTransferOpen, setVirtualTransferOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [virtualTransferIndex, setVirtualTransferIndex] = React.useState("");
  const [currentselectedVehicle, setCurrentSelectedVehicle] =
    React.useState("");
  let userrole=checkUserDetails()
  const handleClose = () => {
    setOpensb(false);
  };

  const DialogOpen = (row) => {
    setStoreDetails(row.stores_list);
    setOpen(true);
  };

  const DialogClose = () => {
    setOpen(false);
  };

  const marketVehicleDialogOpen = (index) => {
    setMarketVehicleIndex(index);
    setMarketVehicleOpen(true);
  };

  const marketVehicleDialogClose = () => {
    setMarketVehicleOpen(false);
  };

  const primaryTransportDialogOpen = (index) => {
    setPrimaryTransportIndex(index);
    setPrimaryTransportOpen(true);
  };

  const primaryTransportDialogClose = () => {
    setPrimaryTransportOpen(false);
  };
  const virtualTransferDialogOpen = (index) => {
    setVirtualTransferIndex(index);
    setVirtualTransferOpen(true);
  };

  const virtualTransferDialogClose = () => {
    setVirtualTransferOpen(false);
  };

  const primaryTransportDialogSuccess = (primaryTransport) => {
    var tempTableData = [...tableData];
    var row = tempTableData[primaryTransportIndex];
    row["vendor_id"] = primaryTransport["vendor_id"];
    row["vendor_name"] = primaryTransport["vendor_name"];
    row["vehicle_wt_capacity"] = primaryTransport["payloadMT"] * 1000;
    row["vehicle_vol_capacity"] = primaryTransport["volCapacity"];
    row["vehicle_wt_util"] = Number(
      (row["trip_wt"] / (primaryTransport["payloadMT"] * 1000)) * 100
    ).toFixed(0);
    row["vehicle_vol_util"] = Number(
      (row["trip_vol"] / row["vehicle_vol_capacity"]) * 100
    ).toFixed(0);
    row["contract_type"] = primaryTransport["contract_type"];
    row["contract_id"] = primaryTransport["contract_id"];
    row["market_vehicle_number"] =
      primaryTransport["vehicleNumber"].toUpperCase();
    tempTableData[primaryTransportIndex] = row;

    setTableData(tempTableData);
  };

  const marketVehicleDialogSuccess = (marketVehicle) => {
    var tempTableData = [...tableData];
    var row = tempTableData[marketVehicleIndex];
    row["vendor_id"] = marketVehicle["vendor_id"];
    row["vendor_name"] = marketVehicle["vendor_name"];
    row["vehicle_wt_capacity"] = marketVehicle["payloadMT"] * 1000;
    row["vehicle_vol_capacity"] = marketVehicle["volCapacity"];
    row["vehicle_wt_util"] = Number(
      (row["trip_wt"] / (marketVehicle["payloadMT"] * 1000)) * 100
    ).toFixed(0);
    row["vehicle_vol_util"] = Number(
      (row["trip_vol"] / row["vehicle_vol_capacity"]) * 100
    ).toFixed(0);
    row["contract_type"] = "Market Vehicle";
    row["market_vehicle_number"] = marketVehicle["vehicleNumber"].toUpperCase();
    row["freight_cost_fms"] = marketVehicle["frieghtCost"];
    row["freight_cost_dc_input"] = marketVehicle["frieghtCost"];
    tempTableData[marketVehicleIndex] = row;
    setTableData(tempTableData);
  };

  const marketVehicleDialogSuccessVirtual = (marketVehicle) => {
    var tempTableData = [...tableData];
    var row = tempTableData[virtualTransferIndex];
    row["vendor_id"] = marketVehicle["vendor_id"];
    row["vendor_name"] =marketVehicle["vendor_name"] ;
    row["vehicle_wt_capacity"] = marketVehicle["payloadMT"] * 1000;
    row["vehicle_vol_capacity"] = marketVehicle["volCapacity"];
    row["vehicle_wt_util"] = 0
    row["vehicle_vol_util"] = 0
    row["contract_type"] = "Virtual Transfer";
    row["market_vehicle_number"] = marketVehicle["vehicleNumber"]
    row["freight_cost_fms"] = 0;
    row["freight_cost_dc_input"] = 0;
    tempTableData[virtualTransferIndex] = row;
    setTableData(tempTableData);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectedVehicles = () => {
    var vehicleMasterId = [];
    var tempTableData = [...tableData];
    for (var i = 0; i < tempTableData.length; i++) {
      if (tempTableData[i]["vehicle_master_id"] !== null) {
        vehicleMasterId.push(tempTableData[i]["vehicle_master_id"]);
      }
    }
    setSelectedVehicles(vehicleMasterId);
  };

  const handleChange = async (row, value, property, index) => {
    if (property === "vehicle_master_id" && value !== null) {
      row[property] = value["fms_vehicle_master_id"];
      row["vehicle"] = value;
    } else if (property === "contract_id" && value !== null) {
      row[property] = value["contract_id"];
      row["contract_type"] = value["contract_type"];
      row["contract"] = value;
    }
    var tempTableData = [...tableData];
    if (value === null) {
      if (property === "vehicle_master_id") {
        delete row["vendor_id"];
        delete row["vendor_name"];
        delete row["vehicle_wt_capacity"];
        delete row["vehicle_vol_capacity"];
        delete row["vehicle_wt_util"];
        delete row["vehicle_vol_util"];
        delete row["contract_types"];
        delete row["vehicle"];
        row["vehicle_master_id"] = null;
      }
      delete row["contract_id"];
      delete row["contract_type"];
      delete row["contract"];
      tempTableData[index] = row;
    }
    if (property === "vehicle_master_id") {
      if (value !== null) {
        row["vendor_id"] = value["vendor_id"]["vendor_id"];
        row["vendor_name"] = value["vendor_id"]["vendor_name"];
        row["vehicle_wt_capacity"] = value["payload_in_mt"] * 1000;
        row["vehicle_vol_capacity"] = value["volume"];
        row["vehicle_wt_util"] = Number(
          (row["trip_wt"] / (value["payload_in_mt"] * 1000)) * 100
        ).toFixed(0);
        row["vehicle_vol_util"] = Number(
          (row["trip_vol"] / row["vehicle_vol_capacity"]) * 100
        ).toFixed(0);
        delete row["contract_id"];
        delete row["contract_type"];
        delete row["contract"];
        await getContractDetails(
          value["vendor_id"]["vendor_id"],
          value["payload_in_mt"],
          tempTableData,
          index,
          row
        );
      }
    }
    setTableData(tempTableData);
    handleSelectedVehicles();
  };

  const getVehicleList = async () => {
    let fmsLocationId = getLocationId();
    await axiosRequest({
      url: "tripCreation/getVehicles/" + fmsLocationId,
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        setVehicleData(data.data);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  React.useEffect(() => {
    getVehicleList();
  }, []);

  React.useEffect(() => {
    setTableData(tripData);
    setSelectedVehicles([]);
  }, [tripData]);

  const getContractDetails = async (
    vehicleVendorId,
    payload,
    tempTableData,
    index,
    row
  ) => {
    await axiosRequest({
      url: "tripCreation/getContractDetails",
      method: "POST",
      data: {
        vehicleVendorId: vehicleVendorId,
        payload_in_mt: payload,
      },
    }).then((data) => {
      if (data.status === 200) {
        row["contract_types"] = data.data;
        tempTableData[index] = row;
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const updateConfirmedTrips = async () => {
    setIsLoading(true)
    await axiosRequest({
      url: "tripCreation/confirmTripCreation",
      method: "PUT",
      data: {
        tripData: tableData.filter(
          (trip) =>
            (trip.contract_id && trip.contract_id !== null) ||
            trip.contract_type === "Market Vehicle" || trip.contract_type === "Virtual Transfer"
        ),
      },
    }).then((data) => {
      if (data.status === 200) {
        proceedToeWayBillPartB();
      } else {
        handleSnackBar(data, "error");
      }
      setIsLoading(false)
    });
  };

  const updateNewConfirmedTrips = async () => {
    setIsLoading(true)
    await axiosRequest({
      url: "tripCreation/confirmNewTripCreation",
      method: "PUT",
      data: {
        tripData: tableData.filter(
          (trip) =>
            (trip.contract_id && trip.contract_id !== null) ||
            trip.contract_type === "Market Vehicle" || trip.contract_type === "Virtual Transfer"
        ),
      },
    }).then((data) => {
      if (data.status === 200) {
        proceedToeWayBillPartB();
      } else {
        handleSnackBar(data, "error");
      }
      setIsLoading(false)
    });
  };

  const handleMovementType = (event,index) => {
    console.log(event.target.value)
    setMovement(event.target.value);
    if(event.target.value=="market"){
       marketVehicleDialogOpen(index)
    }
    else if(event.target.value=="primary"){
      primaryTransportDialogOpen(index)
    }else if(event.target.value=="virtual"){
      virtualTransferDialogOpen(index)
    }
    else{
      alert("Select between the two options")
    }
  };

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };
  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TableContainer className={classes.container}>
          <Table stickyHeader>
            <TableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={tableData.length}
              headers={headers}
              isCheckboxEnabled={false}
            />
            <TableBody>
              {sortArray(tableData, getComparator(order, orderBy)).map(
                (row, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_id}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                      <Moment format="YYYY/MM/DD hh:mm">
                        {row.trip_initiated_time}
                      </Moment>
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        <Link
                          component="button"
                          variant="body2"
                          className={clsx(classes.fs12, classes.textColor)}
                          onClick={() => DialogOpen(row)}
                        >
                        {row.store_drops}
                        </Link>
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_wt}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_vol}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_dist_fms_est}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.market_vehicle_number === undefined ? (
                          <Autocomplete
                            className={clsx(classes.w200p, classes1.root)}
                            autoComplete
                            options={vehicleData}
                            getOptionLabel={(option) => option.vehicle_num}
                            getOptionSelected={(option, value) =>
                              option.fms_vehicle_master_id ===
                              value.fms_vehicle_master_id
                            }
                            getOptionDisabled={(option) =>
                              selectedVehicles.indexOf(
                                option.fms_vehicle_master_id
                              ) !== -1
                                ? true
                                : false
                            }
                            onChange={(e, value) =>
                              handleChange(
                                row,
                                value,
                                "vehicle_master_id",
                                index
                              )
                            }
                            value={row.vehicle !== undefined ? row.vehicle : {}}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Vehicle Number"
                                variant="outlined"
                                key={index}
                              />
                            )}
                          />
                        ) : (
                          row.market_vehicle_number
                        )}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.market_vehicle_number === undefined ? (
                          <Autocomplete
                            className={clsx(classes.w200p, classes1.root)}
                            autoComplete
                            options={
                              row.contract_types &&
                              row.contract_types.length > 0
                                ? row.contract_types
                                : []
                            }
                            getOptionLabel={(option) => option.contract_type}
                            onChange={(e, value) =>
                              handleChange(row, value, "contract_id", index)
                            }
                            value={
                              row.contract !== undefined ? row.contract : {}
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Contract Type"
                                variant="outlined"
                                key={index}
                              />
                            )}
                          />
                        ) : (
                          row.contract_type
                        )}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        <span
                          className={
                            (row.contract_id && row.contract_id !== null) ||
                            row.contract_type === "Market Vehicle" || row.contract_type === "Virtual Transfer"
                              ? ""
                              : "dn"
                          }
                        >
                          {row.vendor_name}
                        </span>
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        <span
                          className={
                            (row.contract_id && row.contract_id !== null) ||
                            row.contract_type === "Market Vehicle" || row.contract_type === "Virtual Transfer"
                              ? ""
                              : "dn"
                          }
                        >
                          {row.vehicle_wt_capacity}
                        </span>
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        <span
                          className={
                            (row.contract_id && row.contract_id !== null) ||
                            row.contract_type === "Market Vehicle" || row.contract_type === "Virtual Transfer"
                              ? ""
                              : "dn"
                          }
                        >
                          {row.vehicle_vol_capacity}
                        </span>
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        <span
                          className={
                            (row.contract_id && row.contract_id !== null) ||
                            row.contract_type === "Market Vehicle" || row.contract_type === "Virtual Transfer"
                              ? ""
                              : "dn"
                          }
                        >
                          {row.vehicle_wt_util}%
                        </span>
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        <span
                          className={
                            (row.contract_id && row.contract_id !== null) ||
                            row.contract_type === "Market Vehicle" || row.contract_type === "Virtual Transfer"
                              ? ""
                              : "dn"
                          }
                        >
                          {row.vehicle_vol_util}%
                        </span>
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                      <select
                         disabled={row.vehicle ? true : false}
                        onChange={(event)=>handleMovementType(event,index)}
                        className={clsx(classes.fs12, classes.SelectStyleTripCreation, "mr2 mb4")}
                        >
                      <option
                      value={""}
                      key={-1}
                      className={clsx(classes.fs12, classes.underline)}
                      >
                      Movement Type
                    </option>
                    <option value="market" className={classes.fs12}>
                        Market Vehicle
                    </option>
                    <option value="primary" className={classes.fs12}>
                        Primary Transport
                    </option>
                    <option value="virtual" className={classes.fs12}>
                        Virtual Transfer
                    </option>
                    </select>
                        {/* <MoreIconButton
                          disabled={row.vehicle ? true : false}
                          onClick={() => marketVehicleDialogOpen(index)}
                        >
                          <LocalShippingRoundedIcon />
                        </MoreIconButton>
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        <MoreIconButton
                          disabled={row.vehicle ? true : false}
                          onClick={() => primaryTransportDialogOpen(index)}
                        >
                          <LocalShippingRoundedIcon />
                        </MoreIconButton> */}
                      </TableCell>
                    </StyledTableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="flex justify-center pa3">
          {userrole.role=="logisticsirn"?(
            <SuccessButton
            variant="contained"
            disableElevation
            onClick={updateNewConfirmedTrips}
            disabled={isLoading}
          >
            <Typography className={clsx(classes.fs12, "ttn",classes.mr5)}>
              Confirm Trip Creation
            </Typography>
            {isLoading && <CircularProgress color="inherit" size={12} />}
          </SuccessButton>
          ):(<SuccessButton
            variant="contained"
            disableElevation
            onClick={updateConfirmedTrips}
            disabled={isLoading}
          >
            <Typography className={clsx(classes.fs12, "ttn",classes.mr5)}>
              Confirm Trip Creation
            </Typography>
            {isLoading && <CircularProgress color="inherit" size={12} />}
          </SuccessButton>)}
        </div>
      </Grid>
      <TripStoreDetailDialog
        open={open}
        DialogClose={DialogClose}
        storeDetails={storeDetails}
      />
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
      <MarketVehicle
        open={marketVehicleOpen}
        dialogClose={marketVehicleDialogClose}
        dialogSuccess={marketVehicleDialogSuccess}
      ></MarketVehicle>
      <PrimaryTransport
        open={primaryTransportOpen}
        dialogClose={primaryTransportDialogClose}
        dialogSuccess={primaryTransportDialogSuccess}
      ></PrimaryTransport>
       <VirtualTransfer
      open={virtualTransferOpen}
      dialogClose={virtualTransferDialogClose}
      dialogSuccess={marketVehicleDialogSuccessVirtual}
      ></VirtualTransfer>
    </Grid>
  );
}
