import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import TripCreationStyle from "../../../../assets/css/TripCreationStyle";
import TextField from "@material-ui/core/TextField";
import { SuccessButton } from "../../../Button/Button";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { Button } from "@material-ui/core";
import SnackBarComponent from "../../../../helpers/SnackBarComponent";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axiosRequest from "../../../../helpers/axios";
import { getLocationId } from "../../../../helpers/RouteValidation";
import { InputAdornment } from "@material-ui/core";

const useStyles = makeStyles(TripCreationStyle);

const useStyles1 = makeStyles((theme) => ({
  root: {
    "& .MuiFormLabel-root": {
      fontSize: "12px",
      lineHeight: 0,
    },
  },
}));

export default function MarketVehicle(props) {
  const classes = useStyles();
  const classes1 = useStyles1();

  const { open, dialogClose, dialogSuccess } = props;

  const [marketVehicle, setMarketVehicle] = React.useState({});
  const [severity, setSeverity] = React.useState("error");
  const [content, setContent] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);
  const [vendorDetails, setVendorDetails] = React.useState([]);

  const handleClose = () => {
    setOpensb(false);
  };

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };

  const handleChange = (event, property) => {
    let temp = marketVehicle;
    if (event.target.value === "") {
      delete temp[property];
    } else {
      temp[property] = event.target.value;
    }
    setMarketVehicle({ ...temp });
  };

  const handleVendorChange = (value, property) => {
    let temp = marketVehicle;
    if (value) {
      temp[property] = value[property];
      temp["vendor_name"] = value["vendor_name"];
    } else {
      delete temp[property];
      delete temp["vendor_name"];
    }
    setMarketVehicle({ ...temp });
  };

  const updateMarketVehicle = () => {
    if (Object.keys(marketVehicle).length === 6) {
      dialogSuccess(marketVehicle);
      resetMarketVehicle();
    } else {
      handleSnackBar("Please fill all the fields", "error");
    }
  };

  const resetMarketVehicle = () => {
    setMarketVehicle({});
    dialogClose();
  };

  const getVendorDetails = async () => {
    let fmsLocationId = getLocationId();
    await axiosRequest({
      url: "tripCreation/getvendordata/",
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        setVendorDetails(data.data);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  React.useEffect(() => {
    getVendorDetails();
  }, []);

  return (
    <Dialog disableBackdropClick disableEscapeKeyDown open={open}>
      <DialogTitle>
        <div className="flex justify-center items-center">
          <span className={clsx(classes.textColor, classes.fs12)}>
            Market Vehicle
          </span>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <div className="mb3">
          <Autocomplete
            autoComplete
            options={vendorDetails}
            getOptionLabel={(option) => option.vendor_name}
            className={classes1.root}
            onChange={(e, value) => handleVendorChange(value, "vendor_id")}
            value={marketVehicle.vendor_id}
            renderInput={(params) => (
              <TextField {...params} label="Vendor Name" variant="outlined" />
            )}
          />
        </div>
        <div className="mb3">
          <TextField
            label="Vehicle Number"
            variant="outlined"
            className={clsx(classes.w233p, classes1.root)}
            value={marketVehicle.vehicleNumber}
            onChange={(e) => handleChange(e, "vehicleNumber")}
          />
          <TextField
            label="Payload Capacity"
            variant="outlined"
            className={clsx(classes.ml20p, classes1.root)}
            value={marketVehicle.payloadMT}
            onChange={(e) => handleChange(e, "payloadMT")}
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">MT</InputAdornment>,
              inputProps: { min: 1 },
            }}
          />
        </div>
        <div>
          <TextField
            label="Volume Capacity"
            variant="outlined"
            className={clsx(classes1.root)}
            value={marketVehicle.volCapacity}
            onChange={(e) => handleChange(e, "volCapacity")}
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">CFT</InputAdornment>,
              inputProps: { min: 1 },
            }}
          />
          <TextField
            label="Freight Cost"
            variant="outlined"
            className={clsx(classes.ml20p, classes.w225p, classes1.root)}
            value={marketVehicle.freightCost}
            onChange={(e) => handleChange(e, "frieghtCost")}
            type="number"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" className={classes.fs12}>
                  Rs
                </InputAdornment>
              ),
              inputProps: { min: 1 },
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disableElevation
          onClick={resetMarketVehicle}
        >
          <Typography className={clsx(classes.fs12, "ttn")}>Cancel</Typography>
        </Button>
        <SuccessButton
          variant="contained"
          disableElevation
          onClick={updateMarketVehicle}
        >
          <Typography className={clsx(classes.fs12, "ttn")}>Save</Typography>
        </SuccessButton>
      </DialogActions>
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </Dialog>
  );
}
