import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { TableHeader, StyledTableRow } from "../../../Base/TableHeader";
import Grid from "@material-ui/core/Grid";
import { getComparator, sortArray } from "../../../../helpers/sorting";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { SuccessButton } from "../../../Button/Button";
import TripCreationStyle from "../../../../assets/css/TripCreationStyle";
import axiosRequest from "../../../../helpers/axios";
import SnackBarComponent from "../../../../helpers/SnackBarComponent";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(TripCreationStyle);

export default function BOLVerificationTable(props) {
  const classes = useStyles();
  const { selectedBOL, proceedToTripInitiation, headCells } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [severity, setSeverity] = React.useState("");
  const [content, setContent] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [rows, setRows] = React.useState([]);

  const handleClose = () => {
    setOpensb(false);
  };

  const handleSelectedRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleTripInitiation = async () => {
    let bol_list = [];

    for (let bol of selectedBOL) {
      bol_list.push(bol.bol_no);
    }
    setLoader(true);
    await axiosRequest({
      url: "bolNoVerification/insertBolHeader",
      method: "POST",
      data: {
        bol_list: bol_list,
      },
    }).then((data) => {
      if (data.status === 200) {
        proceedToTripInitiation();
      } else {
        handleSnackBar(data, "error");
      }
    });
    setLoader(false);
  };

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };

  React.useEffect(() => {
    setRows(selectedBOL);
  }, [selectedBOL]);

  const handleSearchBar = (event, property) => {
    const searchResult = selectedBOL.filter((row) => {
      return row[property]
        .toString()
        .toLowerCase()
        .includes(event.target.value.toLowerCase());
    });
    setRows(searchResult);
  };

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TableContainer className={classes.container}>
          <Table stickyHeader>
            <TableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleSelectedRequestSort}
              rowCount={selectedBOL.length}
              headers={headCells}
              isCheckboxEnabled={false}
              handleSearch={handleSearchBar}
            />
            <TableBody>
              {sortArray(rows, getComparator(order, orderBy)).map(
                (row, index) => {
                  return (
                    <StyledTableRow tabIndex={-1} key={index}>
                      <TableCell className={classes.fs12} align="center">
                        {row.ship_date}
                      </TableCell>
                      <TableCell className={classes.fs12} align="center">
                        {row.fms_location_name}
                      </TableCell>
                      <TableCell className={classes.fs12} align="center">
                        {row.store_id}
                      </TableCell>
                      <TableCell className={classes.fs12} align="center">
                        {row.store_name}
                      </TableCell>
                      <TableCell className={classes.fs12} align="center">
                        {row.bol_no}
                      </TableCell>
                      <TableCell className={classes.fs12} align="center">
                        {row.bol_value}
                      </TableCell>
                    </StyledTableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="flex justify-center pa3">
          {loader === true ? (
            <Grid className={classes.loader}>
              <CircularProgress disableShrink status="loading" />
            </Grid>
          ) : (
            <SuccessButton
              onClick={handleTripInitiation}
              className={classes.button}
              variant="contained"
              disableElevation
            >
              <Typography className={clsx(classes.fs12, "ttn")}>
                Proceed to Trip Initiation
              </Typography>
            </SuccessButton>
          )}
        </div>
      </Grid>
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </Grid>
  );
}
