import cookie from "react-cookies";

const checkUserDetails = () => {
  var user = cookie.load("user");
  if (user) {
    return user;
  } else {
    return false;
  }
};

const getLocationId = () => {
  var user = cookie.load("user");
  if (user) {
    return user.locations.location;
  } else {
    return false;
  }
};

const getLocationName = () => {
  var user = cookie.load("user");
  if (user) {
    return user.locationName;
  } else {
    return false;
  }
};

const getUserName = () => {
  var user = cookie.load("user");
  if (user) {
    return user.first_name + " " + user.last_name;
  }
};

const downloadFile = (url) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "download");
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export {
  checkUserDetails,
  getLocationId,
  getUserName,
  downloadFile,
  getLocationName,
};
