import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { QontoConnector, QontoStepIcon } from "../../Base/StepperStyle";
import Button from "@material-ui/core/Button";
import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";
import TripEnd from "./TripEnd/TripEnd";
import Costing from "./Costing/Costing";
import TripCreationStyle from "../../../assets/css/TripCreationStyle";

const useStyles = makeStyles(TripCreationStyle);

function getSteps() {
  return ["Trip End", "Costing"];
}

function StepperHeader(props) {
  const classes = useStyles();
  const { activeStep, steps } = props;
  return (
    <Stepper activeStep={activeStep} connector={<QontoConnector />}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel
            StepIconComponent={QontoStepIcon}
            classes={{
              completed: classes.completedStepLabel,
            }}
          >
            <Typography className={classes.fs12}>{label}</Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

function StepperButton(props) {
  const classes = useStyles();
  const { handleBack, activeStep } = props;
  return (
    <React.Fragment>
      {activeStep > 0 ? (
        <Button
          onClick={handleBack}
          className={classes.button}
          variant="contained"
          disableElevation
          startIcon={<ChevronLeftRoundedIcon fontSize="small" />}
        >
          <Typography className={clsx(classes.fs12, "ttn")}>Back</Typography>
        </Button>
      ) : (
        ""
      )}
    </React.Fragment>
  );
}

export default function TripEndStepper() {
  // Trip Initiation Stepper
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <TripEnd handleNext={handleNext} />;
      case 1:
        return <Costing />;
      default:
        return "";
    }
  };

  return (
    <div>
      <Paper elevation={0} square>
        <Grid container justify="space-between">
          <Grid item>
            <StepperHeader activeStep={activeStep} steps={steps} />
          </Grid>
          <Grid item className="self-center">
            <StepperButton handleBack={handleBack} activeStep={activeStep} />
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={0} square>
        <Divider />
        <React.Fragment>{getStepContent(activeStep)}</React.Fragment>
      </Paper>
    </div>
  );
}
