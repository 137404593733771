import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { QontoConnector, QontoStepIcon } from "../../Base/StepperStyle";
import Button from "@material-ui/core/Button";
import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import TripCreation from "./TripCreation/TripCreation";
import { MoreIconButton } from "../../Button/Button";
import TripCreationStyle from "../../../assets/css/TripCreationStyle";
import exportToCsv from "../../../helpers/exportToCsv";
import RefreshRoundedIcon from "@material-ui/icons/RefreshRounded";
import axiosRequest from "../../../helpers/axios";
import SnackBarComponent from "../../../helpers/SnackBarComponent";
import { getLocationId } from "../../../helpers/RouteValidation";

const useStyles = makeStyles(TripCreationStyle);

function getSteps() {
  return ["Trip Creation"];
}

function StepperHeader(props) {
  const classes = useStyles();
  const { activeStep, steps } = props;
  return (
    <Stepper activeStep={activeStep} connector={<QontoConnector />}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel
            StepIconComponent={QontoStepIcon}
            classes={{
              completed: classes.completedStepLabel,
            }}
          >
            <Typography className={classes.fs12}>{label}</Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

function StepperButton(props) {
  const classes = useStyles();
  const { handleBack, downloadReport, print, activeStep, getInitiatedTrips } =
    props;

  return (
    <React.Fragment>
      {activeStep > 0 ? (
        <Button
          onClick={handleBack}
          className={classes.button}
          variant="contained"
          disableElevation
          startIcon={<ChevronLeftRoundedIcon fontSize="small" />}
        >
          <Typography className={clsx(classes.fs12, "ttn")}>Back</Typography>
        </Button>
      ) : (
        <MoreIconButton color="primary" onClick={getInitiatedTrips}>
          <RefreshRoundedIcon />
        </MoreIconButton>
      )}
      {/* <MoreButton
        className={classes.button}
        variant="contained"
        disableElevation
        // onClick={downloadReport}
        startIcon={<GetAppRoundedIcon fontSize="small" />}
      >
        <CSVLink
          data={downloadData}
          filename={"my-file.csv"}
          className="btn btn-primary"
          target="_blank"
        >
        <Typography className={clsx(classes.fs12, "ttn")}>
          Download Report 
        </Typography>
      </CSVLink>
        
      </MoreButton> */}
      {/* <Button
        className={classes.button}
        variant="outlined"
        disableElevation
        onClick={print}
        startIcon={<PrintOutlinedIcon fontSize="small" />}
      >
        <Typography className={clsx(classes.fs12, "ttn")}>Print</Typography>
      </Button> */}
    </React.Fragment>
  );
}

export default function TripCreationStepper(props) {
  const { proceedToeWayBillPartB } = props;

  // Trip Creation Stepper
  const [activeStep, setActiveStep] = React.useState(0);
  const [downloadData, setDownloadData] = React.useState([]);
  const [tripData, setTripData] = React.useState([]);
  const [severity, setSeverity] = React.useState("");
  const [content, setContent] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);
  const steps = getSteps();

  const handleClose = () => {
    setOpensb(false);
  };

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getInitiatedTrips = async () => {
    let fmsLocationId = getLocationId();
    await axiosRequest({
      url: "tripCreation/getInitiatedTrips/" + fmsLocationId,
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        setTripData(data.data);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  React.useEffect(() => {
    getInitiatedTrips();
  }, []);

  // const downloadReport = () => {
  //   console.log(downloadData,"data")
  //   if (activeStep === 0) {
  //     alert("Download Trip Creation");
  //     exportToCsv("temp_temp.csv",downloadData);
  //   } else {
  //     alert("Download Trip Summary");
  //   }
  // };

  const print = () => {
    if (activeStep === 0) {
      alert("Print Trip Creation");
    } else {
      alert("Print Trip Summary");
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <TripCreation
            handleNext={handleNext}
            // setDownloadData ={setDownloadData}
            proceedToeWayBillPartB={proceedToeWayBillPartB}
            tripData={tripData}
          />
        );
      default:
        return "";
    }
  };

  return (
    <div>
      <Paper elevation={0} square>
        <Grid container justify="space-between">
          <Grid item>
            <StepperHeader activeStep={activeStep} steps={steps} />
          </Grid>
          <Grid item className="self-center">
            <StepperButton
              handleBack={handleBack}
              print={print}
              activeStep={activeStep}
              getInitiatedTrips={getInitiatedTrips}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={0} square>
        <Divider />
        <React.Fragment>{getStepContent(activeStep)}</React.Fragment>
      </Paper>
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </div>
  );
}
