import React from "react";
import Paper from "@material-ui/core/Paper";
import { Redirect } from "react-router";
import { checkUserDetails } from "../../helpers/RouteValidation";
import BulkUpdate from "../../components/BulkUpdate/BulkUpdate";
export default function BulkUpload() {
  const [isAuthenticated, setIsAuthenticated] = React.useState(true);
  React.useEffect(() => {
    var user = checkUserDetails();
    if (user) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);
  return (
    <React.Fragment>
      {isAuthenticated ? (
        <Paper elevation={0} square className="mt4">
          <BulkUpdate/>
        </Paper>
      ) : (
        <Redirect to="/login" />
      )}
    </React.Fragment>
  );
}