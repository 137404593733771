import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Normal from "../Typography/Normal";
import CloseIcon from "@material-ui/icons/Close";
import TripCreationStyle from "../../assets/css/TripCreationStyle";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { Button, DialogActions } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { SuccessButton } from "../Button/Button";
import axiosRequest from "../../helpers/axios";
import SnackBarComponent from "../../helpers/SnackBarComponent";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles(TripCreationStyle);

const useStyles1 = makeStyles((theme) => ({
  root: {
    "& .MuiFormLabel-root": {
      fontSize: "12px",
      lineHeight: 0,
    },
  },
}));


export default function AddVehicleMasterDialog(props) {
  const classes = useStyles();
  const classes1=useStyles1();
  const { open, DialogClose, addData, vendorData, vendor, originData,getVehicleMasterData } = props;
  const [originid, setOriginId] = React.useState("");
  const [originname,setOriginName] = React.useState("")
  const [vendorid, setVendorId] = React.useState("");
  const [vendorName, setVendorName] = React.useState("");
  const [vehicleno, setVehicleNo] = React.useState("");
  const [contract, setContract] = React.useState([]);
  const [payload, setPayload] = React.useState("");
  const [rcbook, setRcbook] = React.useState("");
  const [length, setLength] = React.useState("");
  const [width, setWidth] = React.useState("");
  const [height, setHeight] = React.useState("");
  const [volume, setVolume] = React.useState("");
  const [make, setMake] = React.useState("");
  const [manufactureyr, setManufactureyr] = React.useState("");
  const [content, setContent] = React.useState("");
  const [severity,setSeverity]=React.useState("")
  const [opensb, setOpensb] = React.useState(false);
  const handleClose = () => {
    setOpensb(false);
  };
  let payloadlist = [];
  let vendorname;
  let originName
  let year = [
    1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006,
    2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018,
    2019, 2020, 2021, 2022,
  ];
  const handleOriginid = (event,value) => {
    setOriginId(value);
    console.log(originid)
    if(value == ""){
      setOriginName("")
    }else{
      for(let i in originData){
        if(originData[i]["origin_id"]==value){
          originName=originData[i]["origin_name"]
          console.log(originName)
        }
        setOriginName(originName)
      }
    }
  };

  const handleVendorId = (event,value) => {
    setVendorId(value)
    console.log(vendorid)
    if (originid !== "") {
      if (value == "") {
        setVendorName("");
        setContract("");
      } else {
        for (let i in vendorData) {
          if (vendorData[i]["vendor_id"] ==value) {
            console.log(i["vendor_name"], "vn");
            vendorname = vendorData[i]["vendor_name"];
            for (let payload_mt in vendorData[i]["contract_payload_in_mt"]) {
              payloadlist.push(
                vendorData[i]["contract_payload_in_mt"][payload_mt][
                  "payload_in_mt"
                ]
              );
              console.log(payload);
            }
          
          }
            setVendorName(vendorname);
            setContract(payloadlist);
        }
      }
    }
  };
  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };

  const handleVehicleno = (event) => {
    var letters=/^[A-Z0-9]+$/;
    if(event.target.value.match(letters)){
       setVehicleNo(event.target.value);
    }else{
      alert("please enter a Vehicle No in UPPERCASE without spaces,special characters and lowercase")
    }
  };
  const handleContractPayload = (event) => {
    setPayload(event.target.value);
  };
  const handleRcbookPayload = (event) => {
    setRcbook(event.target.value);
  };

  const handleLength = (event) => {
    setLength(event.target.value);
  };
  const handleWidth = (event) => {
    setWidth(event.target.value);
  };

  const handleHeight = (event) => {
    setHeight(event.target.value);
  };
  const handleVolume = (event) => {
    setVolume(event.target.value);
  };

  const handleMake = (event) => {
    setMake(event.target.value);
  };

  const handleVehicleManufactureyr = (event) => {
    setManufactureyr(event.target.value);
  };

  const saveVehicleDetails = async () => {
    await axiosRequest({
      url: "master/add_vehicle/",
      method: "POST",
      data: {
        origin_id: Number(originid),
        vendor_id:Number(vendorid),
        vehicle_no: vehicleno,
        contract_payload_in_mt: payload,
        rcbook_payload_in_mt: rcbook,
        length_in_mt: length,
        width_in_mt: width,
        height_in_mt: height,
        volume_in_cft: volume,
        usage_zone: "ALL",
        make: make,
        manufacture_year: manufactureyr,
      },
    }).then((data) => {
      if (data.status === 200) {
        DialogClose()
        getVehicleMasterData()
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      aria-labelledby="confirmation-dialog-title"
      classes={{ paperWidthSm: classes.vehicleMasterDialog }}
    >
      <DialogTitle id="confirmation-dialog-title">
        <div className="flex justify-between items-center">
          <Normal>
            <h3 className={classes.textColor}>Add Vehicle</h3>
          </Normal>
          <IconButton>
            <CloseIcon onClick={DialogClose} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <div className="flex flex-row justify-between">
          <Autocomplete
            className={clsx(classes.w200p, classes1.root,"mr2 mb4")}
            autoComplete
            options={addData}
            getOptionLabel={option =>option?String(option):""}
            getOptionSelected={(option, value) =>
                              option === value
                            }
            onChange={(event,value)=>handleOriginid(event,value)}
            value={originid}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Origin ID"
                variant="outlined"
                key={params}
                value={params}

              />
            )}
          />
          
          <TextField
            id="outlined-basic"
            label="Origin Name"
            className={clsx(classes.inputmargin,classes1.root)}
            variant="outlined"
            value={originname !== undefined ? originname : ""}
          />
            <Autocomplete
            className={clsx(classes.w200p, classes1.root,"mr2 mb4")}
            autoComplete
            options={vendor}
            getOptionLabel={option =>option?String(option):""}
            getOptionSelected={(option, value) =>
                              option === value
                            }
            onChange={(event,value)=>handleVendorId(event,value)}
            value={vendorid}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Vendor ID"
                variant="outlined"
                key={params}
                value={params}

              />
            )}
          />
          <TextField
            id="outlined-basic"
            label="Vendor Name"
            className={clsx(classes.inputmargin,classes1.root)}
            variant="outlined"
            value={vendorName !== undefined ? vendorName : ""}
          />
          <TextField
            id="outlined-basic"
            label="Vehicle No"
            className={clsx(classes.inputmargin,classes1.root)}
            variant="outlined"
            value={vehicleno}
            onChange={handleVehicleno}
          />
        </div>
        <div className="flex flex-row justify-between">
          <select
            onChange={handleContractPayload}
            value={payload}
            className={clsx(classes.fs12, classes.SelectStyleVehicleMaster, "mr2 mb4")}
          >
            <option value="" className={classes.fs12}>
              Contract payload in mt
            </option>
            {contract != undefined
              ? contract.map((payload) => {
                  return (
                    <option
                      value={payload}
                      className={clsx(classes.fs12, classes.underline)}
                    >
                      {payload}
                    </option>
                  );
                })
              : ""}
          </select>
          <TextField
            id="outlined-basic"
            label="rcbook payload in MT"
            variant="outlined"
            type="number"
            className={clsx(classes.inputmargin,classes1.root)}
            onChange={handleRcbookPayload}
          />
          <TextField
            id="outlined-basic"
            label="length in ft"
            variant="outlined"
            className={clsx(classes.inputmargin,classes1.root)}
            onChange={handleLength}
          />
          <TextField
            id="outlined-basic"
            label="Width in ft"
            variant="outlined"
            className={clsx(classes.inputmargin,classes1.root)}
            onChange={handleWidth}
          />
        </div>
        <div className="flex flex-row justify-between">
          <TextField
            id="outlined-basic"
            label="height in ft"
            variant="outlined"
            className={clsx(classes.inputmargin,classes1.root)}
            onChange={handleHeight}
          />
          <TextField
            id="outlined-basic"
            label="volume in cft"
            className={clsx(classes.inputmargin,classes1.root)}
            variant="outlined"
            onChange={handleVolume}
          />
          <TextField
            id="outlined-basic"
            label="make"
            className={clsx(classes.inputmargin,classes1.root)}
            variant="outlined"
            onChange={handleMake}
          />
          <select
            onChange={handleVehicleManufactureyr}
            className={clsx(classes.fs12, classes.SelectStyle, "mr2 mb4")}
          >
            <option
              value={""}
              key={-1}
              className={clsx(classes.fs12, classes.underline)}
            >
              Manufacture year
            </option>
            {year !== undefined
              ? year.map((year) => {
                  return (
                    <option
                      value={year}
                      className={clsx(classes.fs12, classes.underline)}
                    >
                      {year}
                    </option>
                  );
                })
              : ""}
          </select>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="flex justify-center">
          <SuccessButton
            variant="contained"
            onClick={saveVehicleDetails}
            disableElevation
          >
            <Typography className={clsx(classes.fs12, "ttn")}>Save</Typography>
          </SuccessButton>
        </div>
      </DialogActions>
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </Dialog>
  );
}
