import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Normal from "../Typography/Normal";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { MoreButton } from "../Button/Button";
import { FormControl } from "@material-ui/core";
import { TextareaAutosize } from "@material-ui/core";
import TripCreationStyle from "../../assets/css/TripCreationStyle";

const useStyles = makeStyles(TripCreationStyle);

export default function CommentDialog(props) {
  const classes = useStyles();
  const { closeDialog, open, tripId,updateVerifiedComments} = props;
  const [charges, setCharges] = React.useState("");
  const [comments, setComments] = React.useState("");

  const handleCharges = (event) => {
    setCharges(event.target.value);
  };

  const handleInput = (event) => {
    setComments(event.target.value);
  };

  const handleCancel = () => {
    setCharges("");
    closeDialog();
  };

  const handleOk = () => {
    if (charges.length > 0 && comments.length > 0) {
      updateVerifiedComments(comments)
      closeDialog();
    } else {
      alert("Select Charge & Type Comments");
    }
  };

  return (
    <Dialog disableBackdropClick disableEscapeKeyDown open={open}>
      <DialogTitle id="confirmation-dialog-title">
        <div className="flex justify-between items-center">
          <Normal>
            <h3 className={clsx(classes.textColor, "ma0")}>
              Trip ID: {tripId}
            </h3>
          </Normal>
          <IconButton>
            <CloseIcon onClick={handleCancel} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <div className="flex flex-column justify-between">
          <select
            onChange={handleCharges}
            className={clsx(classes.fs12, classes.commentStyle)}
          >
            <option value="" className={classes.fs12}>
              select a charge to comment
            </option>
            <option value="Toll charges" className={classes.fs12}>
              Toll charges
            </option>
            <option value="Halting charges" className={classes.fs12}>
              Halting charges
            </option>
            <option value="damage charges" className={classes.fs12}>
              damage charges
            </option>
            <option value="Other charges" className={classes.fs12}>
              Other charges
            </option>
          </select>
          <div className={classes.pdT20}>
            <TextareaAutosize
              aria-label="minimum height"
              className={classes.commentStyle}
              rowsMin={4}
              placeholder="leave your comment"
              onChange={handleInput}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <MoreButton variant="contained" disableElevation onClick={handleOk}>
          <Typography className={clsx(classes.fs12, "ttn")}>
            Place Comment
          </Typography>
        </MoreButton>
      </DialogActions>
    </Dialog>
  );
}
