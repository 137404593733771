import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { TableHeader, StyledTableRow } from "../../Base/TableHeader";
import { getComparator, sortArray } from "../../../helpers/sorting";
import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import clsx from "clsx";
import TripCreationStyle from "../../../assets/css/TripCreationStyle";
import Grid from "@material-ui/core/Grid";
import { SuccessButton } from "../../Button/Button";
import ModeCommentOutlinedIcon from "@material-ui/icons/ModeCommentOutlined";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import TripStoreDetailDialog from "../../Base/TripStoreDetailDialogue";

const headers = [
  {
    id: "fms_location_name",
    sortable: true,
    align: "center",
    label: "FMS Location",
  },
  {
    id: "trip_created_time",
    sortable: true,
    align: "center",
    label: "Trip Date",
  },
  {
    id: "trip_id",
    sortable: true,
    align: "center",
    label: "Trip ID",
  },
  {
    id: "Store",
    sortable: true,
    align: "center",
    label: "Store",
  },
  {
    id: "store_drops",
    sortable: true,
    align: "center",
    label: "Store Drops",
  },
  {
    id: "trip_dist_fms_est",
    sortable: true,
    align: "center",
    label: "Distance Travelled",
  },
  {
    id: "freight_cost_fms",
    sortable: true,
    align: "center",
    label: "Freight Cost (FMS)",
  },
  {
    id: "vehicle_num",
    sortable: true,
    align: "center",
    label: "Vehicle Number",
  },
  {
    id: "vendor_name",
    sortable: true,
    align: "center",
    label: "Vendor Name",
  },
  {
    id: "contract_type",
    sortable: true,
    align: "center",
    label: "Contract Type",
  },
  {
    id: "trip_dist_dc_input",
    sortable: false,
    align: "center",
    label: "Actual Distance (Km)",
  },
  {
    id: "toll_charges",
    sortable: false,
    align: "center",
    label: "Toll Charges",
  },
  {
    id: "halting_charges",
    sortable: false,
    align: "center",
    label: "Halting Charges",
  },
  {
    id: "damage_charges",
    sortable: false,
    align: "center",
    label: "Damage Charges",
  },
  {
    id: "other_charges",
    sortable: false,
    align: "center",
    label: "Other Charges",
  },
  {
    id: "total_other_charges",
    sortable: false,
    align: "center",
    label: "Total Other Charges",
  },
  {
    id: "dcm_comments",
    sortable: false,
    align: "center",
    label: "Comments",
  },
];

const useStyles = makeStyles(TripCreationStyle);

export default function Verification(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("FMS_Location");

  const {
    handleNext,
    tripData,
    handleTripData,
    handleComment,
    DialogOpen,
    DialogClose,
    open,
    storeDetails,
  } = props;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleCharges = (row, event, property, index) => {
    row[property] = event.target.value;
    row["total_other_charges"] =
      Number(row["toll_charges"]) +
      Number(row["halting_charges"]) +
      Number(row["other_charges"]) -
      Number(row["damage_charges"]);
    var tempTableData = [...tripData];
    tempTableData[index] = row;
    handleTripData(tempTableData);
  };

  const handleDistance = (row, event, index) => {
    row["trip_dist_dc_input"] = event.target.value;
    var tempTableData = [...tripData];
    tempTableData[index] = row;
    handleTripData(tempTableData);
  };

  const ShowCommentDialog = (trip) => {
    handleComment(trip);
  };

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TableContainer className={classes.TripConfirmationContainer}>
          <Table stickyHeader>
            <TableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={tripData.length}
              headers={headers}
              isCheckboxEnabled={false}
            />
            <TableBody>
              {sortArray(tripData, getComparator(order, orderBy)).map(
                (row, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <TableCell align="center" className={classes.fs12}>
                        {row.fms_location_name}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_created_time.substr(0, 10)}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_id}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        <Link
                          component="button"
                          variant="body2"
                          className={clsx(classes.fs12, classes.textColor)}
                          onClick={() => DialogOpen(row)}
                        >
                          {"View Stores"}
                        </Link>
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.store_drops}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_dist_fms_est}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        &#8377; {Number(row.freight_cost_fms).toFixed(2)}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.vehicle_num}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.vendor_name}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.contract_type}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        <div className={classes.bgInput1}>
                          <TextField
                            variant="outlined"
                            value={row.trip_dist_dc_input}
                            InputProps={{
                              inputProps: {
                                min: 0,
                              },
                            }}
                            className={clsx(classes.inputNumber)}
                            type="number"
                            onChange={(e) => handleDistance(row, e, index)}
                          />
                        </div>
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        <div className={classes.bgInput1}>
                          <TextField
                            variant="outlined"
                            value={Number(row.toll_charges)}
                            className={clsx(classes.inputNumber)}
                            InputProps={{
                              inputProps: {
                                min: 0,
                              },
                            }}
                            type="number"
                            onChange={(e) =>
                              handleCharges(row, e, "toll_charges", index)
                            }
                          />
                        </div>
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        <div className={classes.bgInput1}>
                          <TextField
                            variant="outlined"
                            value={Number(row.halting_charges)}
                            className={clsx(classes.inputNumber)}
                            InputProps={{
                              inputProps: {
                                min: 0,
                              },
                            }}
                            type="number"
                            onChange={(e) =>
                              handleCharges(row, e, "halting_charges", index)
                            }
                          />
                        </div>
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        <div className={classes.bgInput1}>
                          <TextField
                            variant="outlined"
                            value={Number(row.damage_charges)}
                            className={clsx(classes.inputNumber)}
                            InputProps={{
                              inputProps: {
                                min: 0,
                              },
                            }}
                            type="number"
                            onChange={(e) =>
                              handleCharges(row, e, "damage_charges", index)
                            }
                          />
                        </div>
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        <div className={classes.bgInput1}>
                          <TextField
                            variant="outlined"
                            value={Number(row.other_charges)}
                            className={clsx(classes.inputNumber)}
                            type="number"
                            onChange={(e) =>
                              handleCharges(row, e, "other_charges", index)
                            }
                          />
                        </div>
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        &#8377; {row.total_other_charges.toFixed(2)}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        <IconButton>
                          <ModeCommentOutlinedIcon
                            onClick={() => ShowCommentDialog(row)}
                            fontSize="small"
                          />
                        </IconButton>
                      </TableCell>
                    </StyledTableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <div className="flex justify-center pa3">
        <SuccessButton
          variant="contained"
          disableElevation
          onClick={handleNext}
        >
          <Typography className={clsx(classes.fs12, "ttn")}>
            Proceed to Confirmation
          </Typography>
        </SuccessButton>
      </div>
      <TripStoreDetailDialog
        DialogClose={DialogClose}
        open={open}
        storeDetails={storeDetails}
      />
    </Grid>
  );
}
