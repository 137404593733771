import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Normal from "../../Typography/Normal";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { FormControl } from "@material-ui/core";
import { TextareaAutosize } from "@material-ui/core";
import TripCreationStyle from "../../../assets/css/TripCreationStyle";
import { SuccessButton } from "../../Button/Button";
import { TextField } from "@material-ui/core";
import axiosRequest from "../../../helpers/axios";

const useStyles = makeStyles(TripCreationStyle);

export default function FandVCrateDialog(props) {
  const classes = useStyles();
  const { CloseDialog, open, tripId } = props;
  const [cratebig, setCrateBig] = React.useState("");
  const [cratemedium, setCrateMedium] = React.useState("");
  const [cratesmall, setCrateSmall] = React.useState("");
  const [shipperbox, setShipperBox] = React.useState("");
  const [others1, setOthers1] = React.useState("");
  const [others2, setOthers2] = React.useState("");

  const handleCrateBig = (event) => {
    setCrateBig(event.target.value);
  };
  const handleCrateMedium = (event) => {
    setCrateMedium(event.target.value);
  };
  const hanldeCrateSmall = (event) => {
    setCrateSmall(event.target.value);
  };
  const hanldeShipperBox = (event) => {
    setShipperBox(event.target.value);
  };
  const handleOthers1 = (event) => {
    setOthers1(event.target.value);
  };
  const handleOthers2 = (event) => {
    setOthers2(event.target.value);
  };

  const generateTripSheet = async () => {
    await axiosRequest({
      url: "eWayBillPartB/tripsheet/"+tripId,
      method: "GET",
      // params:{
      //   carte_big:cratebig,
      //   crate_medium:cratemedium,
      //   crate_small:cratesmall,
      //   shipper_box:shipperbox,
      //   others_1:others1,
      //   others_2:others2
      // }
    }).then((data) =>console.log(data));
  };
  const handleOk = () => {
    //   updateVerifiedComments(comments)
    generateTripSheet()
    CloseDialog();
  };

  return (
      <Dialog disableBackdropClick disableEscapeKeyDown open={open} 
      classes={{ paperWidthSm: classes.TripSheetDialog}}
      >
      <DialogTitle id="confirmation-dialog-title">
        <div className="flex justify-between items-center">
          <Normal>
            <h3 className={clsx(classes.textColor, "ma0")}>
              Trip ID: {tripId}
            </h3>
          </Normal>
          <IconButton>
            <CloseIcon onClick={CloseDialog} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <div className="flex flex-row justify-between mb3">
          <TextField
            id="outlined-basic"
            label="crate big"
            variant="outlined"
            className={classes.inputmargin}
            onChange={handleCrateBig}
          />
          <TextField
            id="outlined-basic"
            label="crate medium"
            variant="outlined"
            className={classes.inputmargin}
            onChange={handleCrateMedium}
          />
          <TextField
            id="outlined-basic"
            label="crate small"
            variant="outlined"
            className={classes.inputmargin}
            onChange={hanldeCrateSmall}
          />
          </div>
          <div className="flex flex-row justify-between mb3">
          <TextField
            id="outlined-basic"
            label="shipper box"
            variant="outlined"
            className={classes.inputmargin}
            onChange={hanldeShipperBox}
          />
          <TextField
            id="outlined-basic"
            label="others1"
            variant="outlined"
            className={classes.inputmargin}
            onChange={handleOthers1}
          />
          <TextField
            id="outlined-basic"
            label="others2"
            variant="outlined"
            className={classes.inputmargin}
            onChange={handleOthers2}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <SuccessButton variant="contained" disableElevation onClick={handleOk}>
          <Typography className={clsx(classes.fs12, "ttn")}>Save</Typography>
        </SuccessButton>
      </DialogActions>
    </Dialog>
  );
}
