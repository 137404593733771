import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { QontoConnector, QontoStepIcon } from "../Base/StepperStyle";
import Button from "@material-ui/core/Button";
import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";
import TripEndConfirmation from "./TripEndConfirmation/TripEndConfirmation";
import Verification from "./Verification/Verification";
import TripCreationStyle from "../../assets/css/TripCreationStyle";
import CommentDialog from "./CommentDialog";
import axiosRequest from "../../helpers/axios";
import SnackBarComponent from "../../helpers/SnackBarComponent";
import { getLocationId } from "../../helpers/RouteValidation";
import { Input } from "@material-ui/core";
import {MoreButton} from "../Button/Button";

const useStyles = makeStyles(TripCreationStyle);

function getSteps() {
  return ["Verification", "Trip End Confirmation"];
}

function StepperHeader(props) {
  const classes = useStyles();
  const { activeStep, steps } = props;
  return (
    <Stepper activeStep={activeStep} connector={<QontoConnector />}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel
            StepIconComponent={QontoStepIcon}
            classes={{
              completed: classes.completedStepLabel,
            }}
          >
            <Typography className={classes.fs12}>{label}</Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

function StepperButton(props) {
  const classes = useStyles();
  const { handleBack, activeStep,handleStartDate,handleEndDate,handleConfirm,startDate,endDate } = props;
  return (
    <React.Fragment>
      {activeStep > 0 ? (
        <Button
          onClick={handleBack}
          className={classes.button}
          variant="contained"
          disableElevation
          startIcon={<ChevronLeftRoundedIcon fontSize="small" />}
        >
          <Typography className={clsx(classes.fs12, "ttn")}>Back</Typography>
        </Button>
      ) : (
        <React.Fragment>
        <div className="flex flex-row justify-between items-center">
        <span className={clsx(classes.fs12, "mr2")}>Start Date:</span>
          <Input
            type="date"
            label="Start Date"
            defaultValue=""
            className={clsx(classes.bgInputGray, classes.underline,"mr2")}
            onChange={handleStartDate}
          />
          <span className={clsx(classes.fs12, "mr2")}>End Date:</span>
          <Input
            type="date"
            label="End Date"
            defaultValue=""
            className={clsx(classes.bgInputGray, classes.underline,"mr2")}
            onChange={handleEndDate}
          />
          <MoreButton
            variant="contained"
            disableElevation
            onClick={() => handleConfirm(startDate, endDate)}
            className={classes.button}
          >
            <Typography className={clsx(classes.fs12, "ttn")}>
              Generate Trip data
            </Typography>
          </MoreButton>
        </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default function TripConfirmationStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [tripData, setTripData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [tripId, setTripId] = React.useState();
  const [verifiedTripData, setVerifiedTripData] = React.useState([]);
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const [isSummaryGenerated, setIsSummaryGenerated] = React.useState(false);
  const [openStore, setOpenStore] = React.useState(false);
  const [storeDetails, setStoreDetails] = React.useState([]);
  const [severity, setSeverity] = React.useState("");
  const [content, setContent] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);
  const handleClose = () => {
    setOpensb(false);
  };

  const DialogOpen = (row) => {
    setStoreDetails(row.stores_list);
    setOpenStore(true);
  };

  const handleStartDate = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDate = (event) => {
    setEndDate(event.target.value);
  };

  const formatDate = (date) => {
    var d = new Date(date);
    var month = "" + (d.getMonth() + 1);
    var day = "" + d.getDate();
    var year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const handleConfirm = (startDate, endDate) => {
    if (startDate && endDate) {
      endDate = new Date(endDate);
      endDate.setDate(endDate.getDate() + 1);
      endDate = formatDate(endDate);
      pendingForDCApproval(startDate,endDate)
      // getDateLevelSummary(startDate, endDate);
      // getDateVendorLevelSummary(startDate, endDate);
      setIsSummaryGenerated(true);
    } else {
      handleSnackBar("Select Start & End date", "error");
    }
  };

  const DialogClose = () => {
    setOpenStore(false);
  };

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const handleComment = (trip) => {
    setTripId(trip.trip_id);
    openDialog();
  };


  const pendingForDCApproval  = async (startDate, endDate) => {
    let fmsLocationId =getLocationId();
    await axiosRequest({
      url: "tripConfirmation/getEndedTrips/",
      method: "POST",
      data: {
        startDate: startDate,
        endDate: endDate,
        fmslocationid:fmsLocationId,
      },
    }).then((data) => {
      if (data.status === 200) {
        setTripData(data.data);
      
      } else {
        handleSnackBar(data, "error");
      }
    });
  };


  const updateVerifiedTrips = async () => {
    await axiosRequest({
      url: "tripConfirmation/updateDCCosting",
      method: "PUT",
      data: {
        tripData: verifiedTripData,
      },
    }).then((data) => {
      if (data.status === 200) {
        // pendingForDCApproval(data.data);
        handleSnackBar("Updated to PENDING NTM successfully","success")
      } else {
        handleSnackBar(data, "error");
      }
    });
    handleBack();
  };

  const updateVerifiedComments = async (comments) => {
    await axiosRequest({
      url: "tripConfirmation/comment",
      method: "PUT",
      data: {
        trip_id: tripData[0].trip_id,
        comment: comments,
      },
    }).then((data) => {
      if (data.status === 200) {
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const steps = getSteps();

  const handleNext = () => {
    setVerifiedTripData(tripData.filter((trip) => trip.trip_dist_dc_input > 0));
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleTripData = (tripData) => {
    setTripData(tripData);
  };
  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Verification
            tripData={tripData}
            handleTripData={handleTripData}
            handleNext={handleNext}
            handleComment={handleComment}
            open={openStore}
            storeDetails={storeDetails}
            DialogOpen={DialogOpen}
            DialogClose={DialogClose}
          />
        );
      case 1:
        return (
          <TripEndConfirmation
            tripData={verifiedTripData}
            updateVerifiedTrips={updateVerifiedTrips}
            open={openStore}
            storeDetails={storeDetails}
            DialogOpen={DialogOpen}
            DialogClose={DialogClose}
          />
        );
      default:
        return "";
    }
  };

  return (
    <React.Fragment>
      <Paper elevation={0} square>
        <Grid container justify="space-between">
          <Grid item>
            <StepperHeader activeStep={activeStep} steps={steps} />
          </Grid>
          <Grid item className="self-center">
            <StepperButton handleBack={handleBack} activeStep={activeStep} handleConfirm={handleConfirm} handleStartDate={handleStartDate} handleEndDate={handleEndDate}  startDate={startDate} endDate={endDate} />
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={0} square>
        <Divider />
        <React.Fragment>{getStepContent(activeStep)}</React.Fragment>
      </Paper>
      <CommentDialog
        open={open}
        closeDialog={closeDialog}
        tripId={tripId}
        updateVerifiedComments={updateVerifiedComments}
      />
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </React.Fragment>
  );
}
