// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";

// Components / Views
import Home from "./pages/Dashboard/Dashboard";
import Login from "./pages/Login/Login";
import TripCreation from "./pages/TripCreation/TripCreation";
import Costing from "./pages/Costing/Costing";
import PTL from "./pages/PTL/PTL";
import TripConfirmation from "./pages/TripConfirmation/TripConfirmation";
import { ReactComponent as DashboardInactive } from "./assets/icons/Left Menu/dashboard_inactive1.svg";
import { ReactComponent as DashboardActive } from "./assets/icons/Left Menu/dashboard_active1.svg";
import { ReactComponent as TripCreationActive } from "./assets/icons/Left Menu/tripcreation_active.svg";
import { ReactComponent as TripCreationInactive } from "./assets/icons/Left Menu/tripcreation_inactive.svg";
import { ReactComponent as PTLInactive } from "./assets/icons/Left Menu/PTL_inactive.svg";
import { ReactComponent as PTLActive } from "./assets/icons/Left Menu/PTL_active.svg";
import NTM from "./pages/Summary/NTM";
import Finance from "./pages/Finance/Finance";
import Master from "./pages/Master/Master";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Home,
    layout: "/fms",
    activeIcon: DashboardActive,
    inactiveIcon: DashboardInactive,
  },
  {
    path: "/Finance",
    name: "Finance",
    component: Finance,
    layout: "/fms",
    activeIcon: DashboardActive,
    inactiveIcon: DashboardInactive,
},
{
  path: "/tripConfirmation",
  name: "Trip Confirmation",
  component: TripConfirmation,
  layout: "/fms",
  activeIcon: TripCreationActive,
  inactiveIcon: TripCreationInactive,
},
];

export default dashboardRoutes;
