import { moreColor, successColor } from "./BaseStyles";
import { green } from "@material-ui/core/colors";
const TripCreationStyle = (theme) => ({
    container: {
        minHeight: "calc(100vh - 365px)",
        maxHeight: "calc(100vh - 365px)",
    },
    fs12: {
        fontSize: "12px",
    },
    mr5: {
        marginRight: "5px",
    },
    BgDeleteIcon: {
        width: "36px",
        height: "30px",
        backgroundColor: "#e6e6e6",
        borderRadius: "8px",
    },
    DeleteIcon: {
        width: "20px",
        height: "20px",
        marginLeft: "0px",
        marginTop: "5px",
        cursor: "pointer",
    },
    block: {
        minHeight: "calc(100vh - 310px)",
        maxHeight: "calc(100vh - 310px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    imageWidth: {
        width: "80px",
    },
    imageText: {
        fontSize: "12px",
        textAlign: "center",
        margin: "0 0 10px",
    },
    button: {
        marginRight: theme.spacing(1),
    },
    w20p: {
        width: "20px",
    },
    completedStepLabel: {
        color: `${moreColor[0]} !important`,
    },
    tripendbutton: {
        fontSize: "11px",
        height: "40px",
        width: "20px",
        textTransform: "capitalize",
    },
    tripsheetbutton: {
        fontSize: "12px",
        height: "50px",
        width: "120px",
        textAlign: "center",
        textTransform: "capitalize",
    },
    deliverychallanbutton: {
        fontSize: "12px",
        textAlign: "center",
        textTransform: "capitalize",
    },
    ntmcostWidth: {
        height: "40px",
        width: "60px",
    },
    eWayContainer: {
        minHeight: "calc(100vh - 235px)",
        maxHeight: "calc(100vh - 235px)",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
    },
    costingContainer: {
        minHeight: "calc(100vh - 275px)",
        maxHeight: "calc(100vh - 275px)",
    },
    PTLContainer: {
        minHeight: "calc(100vh - 270px)",
        maxHeight: "calc(100vh - 270px)",
    },
    TripConfirmationContainer: {
        minHeight: "calc(100vh - 285px)",
        maxHeight: "calc(100vh - 285px)",
    },
    StartCircle: {
        width: 8,
        height: 8,
        borderRadius: "50%",
        backgroundColor: `${successColor[0]}`,
    },
    EndCircle: {
        width: 8,
        height: 8,
        borderRadius: "50%",
        backgroundColor: `${moreColor[0]}`,
    },
    dn: {
        display: "none !important",
    },
    AddTripContainer: {
        minHeight: "calc(100vh - 445px)",
        maxHeight: "calc(100vh - 445px)",
    },
    ml220p: {
        marginLeft: "220px",
    },
    underline: {
        "&&&:before": {
            borderBottom: "none",
        },
        "&&:after": {
            borderBottom: "none",
        },
    },
    bgInput: {
        backgroundColor: "#fff",
        borderRadius: "8px",
        padding: "8px",
    },
    loader: {
        textAlign: "center",
    },
    bgInput1: {
        marginBottom: "15px",
    },
    inputNumber: {
        width: "150px",
        height: "20px",
        marginLeft: "0px",
        marginTop: "5px",
    },
    bgInputGray: {
        backgroundColor: "#e6e6e6",
        borderRadius: "8px",
        height: "30px",
        paddingLeft: "8px",
    },
    inputSelect: {
        width: "100px",
        height: "30px",
        borderRadius: "8px",
        borderColor: "#e6e6e6",
    },
    tempViewTripcontainer: {
        minHeight: "calc(100vh - 410px)",
        maxHeight: "calc(100vh - 410px)",
    },
    textColor: {
        color: `${moreColor[0]} !important`,
    },
    storeTableDialog: {
        minWidth: 800,
        maxWidth: 800,
    },
    downloadDialog: {
        minWidth: 300,
        maxWidth: 300,
        minHeight: 400,
        maxHeight: 400,
    },
    vehicleMasterDialog: {
        minWidth: 800,
        maxWidth: 800,
        minHeight: 400,
        maxHeight: 400,
    },
    OriginDestinationDialog: {
        minWidth: 1000,
        maxWidth: 1000,
        minHeight: 500,
        maxHeight: 500,
    },
    contractMasterDialog: {
        minWidth: 1000,
        maxWidth: 1000,
        minHeight: 500,
        maxHeight: 500,
    },
    BOLTableDialog: {
        minWidth: 800,
        maxWidth: 800,
    },
    TripSheetDialog: {
        minWidth: 600,
        maxWidth: 600,
        minHeight: 250,
        maxHeight: 250,
    },
    searchBarStyle: {
        position: "sticky",
        borderTop: "0px",
        borderLeft: "0px",
        borderRadius: "7px",
        backgroundColor: "#e6e6e6",
        borderColor: "#e6e6e6",
        paddingTop: "3px",
        paddingLeft: "3px",
    },
    icon: {
        fontSize: "1.0rem",
        minWidth: 10,
    },
    commentDialog: {
        minWidth: 300,
        maxWidth: 300,
        maxHeight: 150,
        minHeight: 150,
    },
    DocumentDialog: {
      minWidth: 350,
      maxWidth: 350,
      maxHeight: 400,
      minHeight: 400,
  },
    commentStyle: {
        width: "300px",
        height: "30px",
        borderRadius: "8px",
        backgroundColor: "#e6e6e6",
        borderColor: "#e6e6e6",
    },
    SelectStyle: {
        width: "250px",
        height: "36px",
        paddingLeft: "2px",
        fontSize: "12px",
        borderRadius: "8px",
        backgroundColor: "#e6e6e6",
        borderColor: "#e6e6e6",
    },
    SelectStyleTripCreation: {
        width: "200px",
        height: "36px",
        paddingLeft: "2px",
        fontSize: "12px",
        borderRadius: "8px",
        backgroundColor: "#e6e6e6",
        borderColor: "#e6e6e6",
    },
    SelectStyleVehicleMaster: {
        width: "200px",
        height: "36px",
        paddingLeft: "2px",
        fontSize: "12px",
        borderRadius: "8px",
        backgroundColor: "#e6e6e6",
        borderColor: "#e6e6e6",
    },
    pdT20: {
        paddingTop: "20px",
    },
    dailyContainer: {
        minHeight: "calc(100vh - 235px)",
        maxHeight: "calc(100vh - 235px)",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "space-between",
    },
    formControl: {
        margin: theme.spacing(1),
        width: 30,
    },
    indeterminateColor: {
        color: `${moreColor[0]} !important`,
    },
    selectAllText: {
        fontSize: "12px",
    },
    selectedAll: {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.08)",
        },
    },
    inputDailySelect: {
        width: "200px",
        height: "30px",
        borderRadius: "8px",
        borderColor: "#e6e6e6",
    },
    align: {
        alignItems: "baseline",
    },
    pl48: {
        paddingLeft: "48rem",
    },
    input: {
        display: "none",
    },
    StoreTableDialog: {
        minWidth: 600,
        maxWidth: 600,
    },
    inputmargin: {
        marginRight: "20px",
        fontSize: "12px",
        height: "30px",
        textAlign: "center",
    },
    inputmarginorigindest: {
        marginRight: "2rem",
        fontSize: "12px",
        height: "30px",
        textAlign: "center",
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    w200p: {
        width: "200px",
    },
    ml20p: {
        marginLeft: "20px",
    },
    w233p: {
        width: "233px",
    },
    w225p: {
        width: "225px",
    },
});

export default TripCreationStyle;