import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import clsx from "clsx";
import { Typography } from "@material-ui/core";
import MonthlyLevelSummary from "./MonthlyLevelSummary";
import MonthlyVendorSummary from "./MonthlyVendorSummary";
import Grid from "@material-ui/core/Grid";
import { moreColor } from "../../../assets/css/BaseStyles";
import { MoreButton } from "../../Button/Button";
import Divider from "@material-ui/core/Divider";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import exportToCsv from "../../../helpers/exportToCsv";
import SnackBarComponent from "../../../helpers/SnackBarComponent";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel">
      {value === index && (
        <Paper elevation={0} square>
          {children}
        </Paper>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  fs12: {
    fontSize: "12px",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  selectedTab: {
    color: moreColor[0],
  },
  tab: {
    textTransform: "none",
    fontSize: "12px",
  },
}));

export default function Results(props) {
  const classes = useStyles();
  const {
    monthLevelSummary,
    monthVendorLevelSummary,
    tripBolLevelSummary,
    tripStoreLevelSummary,
    tripLevelSummary,
    handleDiscount,
    handleConfirm,
    startMonth,
    endMonth,
    fmsLocations,
  } = props;

  const [value, setValue] = React.useState(0);
  const [severity, setSeverity] = React.useState("");
  const [content, setContent] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);

  const handleClose = () => {
    setOpensb(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };

  const download = () => {
    if (value === 0) {
      let csvData = [];
      let csvTripData=[];
      let csvStoreData=[];
      let csvBolData=[];
      let titleStore=[
          "actual_arr_time",
          "actual_dep_time",
          "created_date",
          "delivery_seq",
          "origin_id",
          "pod_1" ,
          "pod_2",
          "store_eta",
          "store_etd",
          "store_id",
          "store_name",
          "trip_id_id",
          "trip_store_details_id",
          "updated_date",
      ]
        let titleBol=[
        "bol_no",
        "bol_value",
        "bol_volume",
        "bol_weight",
        "created_date",
        "einvoice_no",
        "ewaybill_file",
        "ewaybill_no",
        "fms_location_id",
        "from_loc",
        "origin_type",
        "ship_date",
        "shipment",
        "to_loc",
        "trip_id",
        "updated_date"
      ]
  
      let title = [
        "month_name",
        "fms_location_name",
        "trip_cost_fms",
        "trip_cost_ntm",
        "other_charges",
        "damage_charges",
        "total_freight_cost",
        "fixed_cost",
        "monthly_discount",
        "invoice_amount",
      ];
      let titleTrip=[
        "actual_trip_end_time",
        "approval_status",
        "contract_id_id",
        "contract_type",
        "created_date",
        "damage_charges",
        "dcm_comments",
        "dest_id",
        "dest_type",
        "end_km",
        "fms_location_id",
        "freight_cost_dc_input",
        "freight_cost_fms",
        "halting_charges",
        "is_combi_trip",
        "market_vehicle_num",
        "ntm_trip_cost",
        "other_charges",
        "plan_dispatch",
        "start_km",
        "store_drops",
        "toll_charges",
        "total_freight_cost",
        "total_number_of_crates_dc_input",
        "total_other_charges",
        "trip_created_time",
        "trip_dist_dc_input",
        "trip_dist_fms_est",
        "trip_id",
        "trip_sheet_path",
        "trip_status",
        "trip_time_fms_est",
        "trip_vol",
        "trip_wt",
        "truck_img",
        "updated_date",
        "vehicle_master_id_id",
        "vehicle_vol_util",
        "vehicle_wt_util",
        "vendor_id_id",
      ]

      let rows = monthLevelSummary.map((x) => [
        x[title[0]],
        x[title[1]],
        x[title[2]],
        x[title[3]],
        x[title[4]],
        x[title[5]],
        x[title[6]],
        x[title[7]],
        x[title[8]],
        x[title[9]],
      ]);
      let rowsstore = tripStoreLevelSummary.map((x) => [
        x[titleStore[0]],
        x[titleStore[1]],
        x[titleStore[2]],
        x[titleStore[3]],
        x[titleStore[4]],
        x[titleStore[5]],
        x[titleStore[6]],
        x[titleStore[7]],
        x[titleStore[8]],
        x[titleStore[9]],
        x[titleStore[10]],
        x[titleStore[11]],
        x[titleStore[12]],
        x[titleStore[13]],
     
      ]);
  
      let rowsbols =tripBolLevelSummary.map((x)=>[
        x[titleBol[0]],
        x[titleBol[1]],
        x[titleBol[2]],
        x[titleBol[3]],
        x[titleBol[4]],
        x[titleBol[5]],
        x[titleBol[6]],
        x[titleBol[7]],
        x[titleBol[8]],
        x[titleBol[9]],
        x[titleBol[10]],
        x[titleBol[11]],
        x[titleBol[12]],
        x[titleBol[13]],
        x[titleBol[12]],
        x[titleBol[13]],
      ]);
  
      let rowstrip=tripLevelSummary.map((x)=>[
        x[titleTrip[0]],
        x[titleTrip[1]],
        x[titleTrip[2]],
        x[titleTrip[3]],
        x[titleTrip[4]],
        x[titleTrip[5]],
        x[titleTrip[6]],
        x[titleTrip[7]],
        x[titleTrip[8]],
        x[titleTrip[9]],
        x[titleTrip[10]],
        x[titleTrip[11]],
        x[titleTrip[12]],
        x[titleTrip[13]],
        x[titleTrip[14]],
        x[titleTrip[15]],
        x[titleTrip[16]],
        x[titleTrip[17]],
        x[titleTrip[18]],
        x[titleTrip[19]],
        x[titleTrip[20]],
        x[titleTrip[21]],
        x[titleTrip[22]],
        x[titleTrip[23]],
        x[titleTrip[24]],
        x[titleTrip[25]],
        x[titleTrip[26]],
        x[titleTrip[27]],
        x[titleTrip[28]],
        x[titleTrip[29]],
        x[titleTrip[30]],
        x[titleTrip[31]],
        x[titleTrip[32]],
        x[titleTrip[33]],
        x[titleTrip[34]],
        x[titleTrip[35]],
        x[titleTrip[36]],
        x[titleTrip[37]],
        x[titleTrip[38]],
        x[titleTrip[39]],
      ]);
        csvTripData=[titleTrip, ...rowstrip]
        csvStoreData=[titleStore, ...rowsstore];
        csvBolData=[titleBol, ...rowsbols];
        csvData = [title, ...rows];
        exportToCsv("Month Summary Report on " + new Date() + ".csv", csvData);
        exportToCsv("Month Summary Report on trip id level"+new Date()+".csv",csvTripData);
        exportToCsv("Month Summary Report on tripstore id level"+new Date()+".csv",csvStoreData);
        exportToCsv("Month Summary Report on bol level"+new Date()+".csv",csvBolData);
        handleSnackBar("Downloaded Successfully", "success");
    } else {
      let csvData = [];
      let csvTripData=[];
      let csvStoreData=[];
      let csvBolData=[];
       let titleTrip=[
        "actual_trip_end_time",
        "approval_status",
        "contract_id_id",
        "contract_type",
        "created_date",
        "damage_charges",
        "dcm_comments",
        "dest_id",
        "dest_type",
        "end_km",
        "fms_location_id",
        "freight_cost_dc_input",
        "freight_cost_fms",
        "halting_charges",
        "is_combi_trip",
        "market_vehicle_num",
        "ntm_trip_cost",
        "other_charges",
        "plan_dispatch",
        "start_km",
        "store_drops",
        "toll_charges",
        "total_freight_cost",
        "total_number_of_crates_dc_input",
        "total_other_charges",
        "trip_created_time",
        "trip_dist_dc_input",
        "trip_dist_fms_est",
        "trip_id",
        "trip_sheet_path",
        "trip_status",
        "trip_time_fms_est",
        "trip_vol",
        "trip_wt",
        "truck_img",
        "updated_date",
        "vehicle_master_id_id",
        "vehicle_vol_util",
        "vehicle_wt_util",
        "vendor_id_id",
      ]
    let titleStore=[
        "actual_arr_time",
        "actual_dep_time",
        "created_date",
        "delivery_seq",
        "origin_id",
        "pod_1" ,
        "pod_2",
        "store_eta",
        "store_etd",
        "store_id",
        "store_name",
        "trip_id_id",
        "trip_store_details_id",
        "updated_date",
    ]
      let titleBol=[
      "bol_no",
      "bol_value",
      "bol_volume",
      "bol_weight",
      "created_date",
      "einvoice_no",
      "ewaybill_file",
      "ewaybill_no",
      "fms_location_id",
      "from_loc",
      "origin_type",
      "ship_date",
      "shipment",
      "to_loc",
      "trip_id",
      "updated_date"
    ]

      let title = [

        "month_name",
        "fms_location_name",
        "vendor_name",
        "trip_cost_fms",
        "trip_cost_ntm",
        "other_charges",
        "damage_charges",
        "total_freight_cost",
        "fixed_cost",
        "monthly_discount",
        "invoice_amount",
      ];
      let rows = monthVendorLevelSummary.map((x) => [
        x[title[0]],
        x[title[1]],
        x[title[2]],
        x[title[3]],
        x[title[4]],
        x[title[5]],
        x[title[6]],
        x[title[7]],
        x[title[8]],
        x[title[9]],
        x[title[10]],
      ]);
      let rowsstore = tripStoreLevelSummary.map((x) => [
        x[titleStore[0]],
        x[titleStore[1]],
        x[titleStore[2]],
        x[titleStore[3]],
        x[titleStore[4]],
        x[titleStore[5]],
        x[titleStore[6]],
        x[titleStore[7]],
        x[titleStore[8]],
        x[titleStore[9]],
        x[titleStore[10]],
        x[titleStore[11]],
        x[titleStore[12]],
        x[titleStore[13]],
      ]);
  
      let rowsbols =tripBolLevelSummary.map((x)=>[
        x[titleBol[0]],
        x[titleBol[1]],
        x[titleBol[2]],
        x[titleBol[3]],
        x[titleBol[4]],
        x[titleBol[5]],
        x[titleBol[6]],
        x[titleBol[7]],
        x[titleBol[8]],
        x[titleBol[9]],
        x[titleBol[10]],
        x[titleBol[11]],
        x[titleBol[12]],
        x[titleBol[13]],
        x[titleBol[12]],
        x[titleBol[13]],
      ]);
        let rowstrip=tripLevelSummary.map((x)=>[
        x[titleTrip[0]],
        x[titleTrip[1]],
        x[titleTrip[2]],
        x[titleTrip[3]],
        x[titleTrip[4]],
        x[titleTrip[5]],
        x[titleTrip[6]],
        x[titleTrip[7]],
        x[titleTrip[8]],
        x[titleTrip[9]],
        x[titleTrip[10]],
        x[titleTrip[11]],
        x[titleTrip[12]],
        x[titleTrip[13]],
        x[titleTrip[14]],
        x[titleTrip[15]],
        x[titleTrip[16]],
        x[titleTrip[17]],
        x[titleTrip[18]],
        x[titleTrip[19]],
        x[titleTrip[20]],
        x[titleTrip[21]],
        x[titleTrip[22]],
        x[titleTrip[23]],
        x[titleTrip[24]],
        x[titleTrip[25]],
        x[titleTrip[26]],
        x[titleTrip[27]],
        x[titleTrip[28]],
        x[titleTrip[29]],
        x[titleTrip[30]],
        x[titleTrip[31]],
        x[titleTrip[32]],
        x[titleTrip[33]],
        x[titleTrip[34]],
        x[titleTrip[35]],
        x[titleTrip[36]],
        x[titleTrip[37]],
        x[titleTrip[38]],
        x[titleTrip[39]],
      ]);
        csvTripData=[titleTrip, ...rowstrip]
        csvStoreData=[titleStore, ...rowsstore];
        csvBolData=[titleBol, ...rowsbols];
      csvData = [title, ...rows];
      exportToCsv(
        "Month Vendor Level Summary Report on " + new Date() + ".csv",
        csvData
      );
      exportToCsv("Month Summary Report on tripstore id level"+new Date()+".csv",csvStoreData);
      exportToCsv("Month Summary Report on trip id level"+new Date()+".csv",csvTripData);
      exportToCsv("Month Summary Report on bol level"+new Date()+".csv",csvBolData);

      handleSnackBar("Downloaded Successfully", "success");
    }
  };

  return (
    <Paper elevation={0} square>
      <Divider />
      <Grid container justify="space-between">
        <Grid item>
          <Tabs
            value={value}
            onChange={handleChange}
            classes={{ root: classes.ttn }}
          >
            <Tab
              classes={{ selected: classes.selectedTab, root: classes.tab }}
              label="Month Level Summary"
            />
            <Tab
              classes={{ selected: classes.selectedTab, root: classes.tab }}
              label="Month Vendor level summary"
            />
          </Tabs>
        </Grid>
        <Grid item className="self-center">
          <MoreButton
            className={clsx(classes.button, "mr3")}
            variant="contained"
            disableElevation
            onClick={download}
            startIcon={<GetAppRoundedIcon fontSize="small" />}
          >
            <Typography className={clsx(classes.fs12, "ttn")}>
              Download
            </Typography>
          </MoreButton>
        </Grid>
      </Grid>
      <TabPanel value={value} index={0}>
        <MonthlyLevelSummary MonthLevel={monthLevelSummary} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MonthlyVendorSummary
          MonthVendor={monthVendorLevelSummary}
          handleDiscount={handleDiscount}
          handleConfirm={handleConfirm}
          startMonth={startMonth}
          endMonth={endMonth}
          fmsLocations={fmsLocations}
        />
      </TabPanel>
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </Paper>
  );
}
