import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { TableHeader, StyledTableRow } from "../Base/TableHeader";
import { getComparator, sortArray } from "../../helpers/sorting";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { MoreButton, SuccessButton } from "../Button/Button";
import TripCreationStyle from "../../assets/css/TripCreationStyle";
import { Paper } from "@material-ui/core";
import axiosRequest from "../../helpers/axios";
import AddVehicleMasterDialog from "./AddVehicleMasterDialog";
import SnackBarComponent from "../../helpers/SnackBarComponent";
import { getLocationId,checkUserDetails } from "../../helpers/RouteValidation";
import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";
import exportToCsv from "../../helpers/exportToCsv";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";

const headers = [
  {
    id: "origin_id",
    sortable: true,
    align: "center",
    label: "Origin_ID",
  },
  {
    id: "vendor_id",
    sortable: true,
    align: "center",
    label: "Vendor_ID",
  },
  {
    id: "vendor_name",
    sortable: true,
    align: "center",
    label: "Vendor_name",
  },
  {
    id: "vehicle_num",
    sortable: true,
    align: "center",
    label: "Vehicle_number",
  },
  {
    id: "payload_in_mt",
    sortable: true,
    align: "center",
    label: "ContractPayload in MT",
  },

  {
    id: "rcbook_payload_in_mt",
    sortable: true,
    align: "center",
    label: "RCBook Payload in MT",
  },
  {
    id: "length",
    sortable: true,
    align: "center",
    label: "Length in FT",
  },
  {
    id: "width",
    sortable: true,
    align: "center",
    label: "Width in FT ",
  },

  {
    id: "height",
    sortable: true,
    align: "center",
    label: "Height in FT",
  },
  {
    id: "volume",
    sortable: true,
    align: "center",
    label: "Volume in CFT",
  },
  {
    id: "field_vehicle_manufacture_year",
    sortable: true,
    align: "center",
    label: "Vehicle Manufacture Year",
  },
  {
    id: "usage_zone",
    sortable: true,
    align: "center",
    label: "Usage Zone",
  },
  {
    id: "documents",
    sortable: false,
    align: "center",
    label: "documents",
  },
  {
    id: "Actions",
    sortable: false,
    align: "center",
    label: "Actions",
  },
];

const useStyles = makeStyles(TripCreationStyle);

export function VehicleHeader(props) {
  const classes = useStyles();
  const { addData, vendorData, vendor, getAddMasterData,originData,getVehicleMasterData,handleBack,downloadReport,GenerateData} = props;
  const [open, setOpen] = React.useState(false);
  function VehicleMasterDialogOpen() {
    setOpen(true);
    getAddMasterData();
  }
  function DialogClose() {
    setOpen(false);
  }
  return (
    <React.Fragment>
      <div className="flex justify-between pa3">
        <Typography variant="body2">Vehicle Master</Typography>
        <div>
        <Button
            variant="contained"
            disableElevation
            className={classes.button}
            onClick={handleBack}
            startIcon={<ChevronLeftRoundedIcon fontSize="small" />}

          >
            <Typography className={clsx(classes.fs12, "ttn")}>Back</Typography>
          </Button>
          <MoreButton
          variant="contained"
          disableElevation
          onClick={GenerateData}
          className={classes.button}
        >
          <Typography className={clsx(classes.fs12, "ttn")}>
            Generate Vehicle data
          </Typography>
        </MoreButton> 
        <MoreButton
          variant="contained"
          disableElevation
          onClick={VehicleMasterDialogOpen}
          className={classes.button}
        >
          <Typography className={clsx(classes.fs12, "ttn")}>
            + Add Vehicle
          </Typography>
        </MoreButton>
        <MoreButton
          variant="contained"
          disableElevation
          onClick={downloadReport}
          className={classes.button}
          startIcon={<GetAppRoundedIcon fontSize="small" />}
        >
          <Typography className={clsx(classes.fs12, "ttn")}>
           Download vehicle master
          </Typography>
        </MoreButton>
        </div>
      </div>
      <AddVehicleMasterDialog
        open={open}
        DialogClose={DialogClose}
        addData={addData}
        vendorData={vendorData}
        vendor={vendor}
        originData={originData}
        getVehicleMasterData={getVehicleMasterData}

      />
    </React.Fragment>
  );
}

export default function VehicleMaster(props) {
  const classes = useStyles();
  const { state,handleBack } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("origin_id");
  const [tableData, setTableData] = React.useState([]);
  const [addData, setAddData] = React.useState([]);
  const [originData, setOriginData] = React.useState([]);
  const [vendor, setVendor] = React.useState([]);
  const [vendorData,setVendorData]=React.useState([]);
  const [severity, setSeverity] = React.useState("");
  const [content, setContent] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);
  let userrole=checkUserDetails()
  const handleClose = () => {
    setOpensb(false);
  };
  let vendorID=[];
  let originID=[]
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getVehicleMasterData = async () => {
    let fmsLocationId =userrole.role=="NTM"?0:getLocationId();
    await axiosRequest({
      url: "master/vehicle/"+fmsLocationId,
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        setTableData(data.data);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const getAddMasterData = async () => {
    await axiosRequest({
      url: "master/vehicle_input/",
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        console.log(data.data, "data");
        for(let i in data.data['origin_id']){
          originID.push(data.data['origin_id'][i]['origin_id']);
        }
        setOriginData(data.data["origin_id"])
        // setOriginId(tableData["origin_id"]);
        // vendorinfo.push(addData["vendor_info"]);
        setVendorData(data.data['vendor_info'])

        console.log(vendorData, "vendorData");
        // console.log(vendor['vendor_id'])
        // setVendor(vendor['vendor_id'])
        for (let i in data.data['vendor_info']) {
          vendorID.push(data.data['vendor_info'][i]['vendor_id']);
        }
        setAddData(originID)
        console.log(addData,"----")
        setVendor(vendorID)
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };

  const downloadReport = () => {
    let csvData = [];
    let title = [
      "origin_id",
      "vehicle_num",
      "vendor_id",
      "vendor_name",
      "field_vehicle_manufacture_year",
      "height",
      "length",
      "payload_in_mt",
      "rcbook_payload_in_mt",
      "usage_zone",
      "volume",
      "width",
    ];
    let rows = tableData.map((x) => [
      x[title[0]],
      x[title[1]],
      x[title[2]],
      x[title[3]],
      x[title[4]],
      x[title[5]],
      x[title[6]],
      x[title[7]],
      x[title[8]],
      x[title[9]],
      x[title[10]],
      x[title[11]],
    ]);
    csvData = [title, ...rows];
    exportToCsv("vehicle master data" + new Date() + ".csv", csvData);
    handleSnackBar("Downloaded Successfully", "success");
  };

  // React.useEffect(() => {
  //   getVehicleMasterData();
  // }, []);
  return (
    <div className="pa1">
      <Paper elevation={0} square>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <VehicleHeader
              addData={addData}
              vendorData={vendorData}
              vendor={vendor}
              getAddMasterData={getAddMasterData}
              originData={originData}
              getVehicleMasterData={getVehicleMasterData}
              handleBack={handleBack}
              downloadReport={downloadReport}
              GenerateData={getVehicleMasterData}
            />
            <TableContainer className={classes.costingContainer}>
              <Table stickyHeader>
                <TableHeader
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={tableData.length}
                  headers={headers}
                  isCheckboxEnabled={false}
                />
                <TableBody>
                  {sortArray(tableData, getComparator(order, orderBy)).map(
                    (row, index) => {
                      return (
                        <StyledTableRow key={index}>
                          <TableCell align="center" className={classes.fs12}>
                            {row.origin_id}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.vendor_id}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.vendor_name}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.vehicle_num}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.payload_in_mt}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.rcbook_payload_in_mt}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.length}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.width}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.height}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.volume}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.field_vehicle_manufacture_year}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.usage_zone}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.documents}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.Actions}
                          </TableCell>
                        </StyledTableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="flex justify-center pa3">
              <SuccessButton variant="contained" disableElevation>
                <Typography className={clsx(classes.fs12, "ttn")}>
                  Save
                </Typography>
              </SuccessButton>
            </div>
          </Grid>
          <SnackBarComponent
            opensb={opensb}
            handleClose={handleClose}
            severity={severity}
            content={content}
          />
        </Grid>
      </Paper>
    </div>
  );
}
