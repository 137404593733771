import React from "react";
import Finance from "../../components/Finance/FinanceStepper";
import { Redirect } from "react-router";
import { checkUserDetails } from "../../helpers/RouteValidation";

export default function TripConfirmation() {
  const [isAuthenticated, setIsAuthenticated] = React.useState(true);

  React.useEffect(() => {
    var user = checkUserDetails();
    if (user) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);
  
  return (
    <React.Fragment>
      {isAuthenticated ? (
        <div className="mt4">
          <Finance/>
        </div>
      ) : (
        <Redirect to="/login" />
      )}
    </React.Fragment>
  );
}
