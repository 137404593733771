import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

// Core components
import Sidebar from "../components/Sidebar/Sidebar";
import Header from "../components/Base/Header";

// Routes
import adminRoutes from "../adminRoutes";
import styles from "../assets/css/AdminStyle";
import MoreLogo from "../assets/images/MoreLogo.png";
import ntmRoutes from "../ntmRoutes";
import financeRoutes from "../financeRoutes";
import logisticsUserRoutes from "../logisticsUserRoutes";
import locationManagerRoutes from "../locationManagerRoutes";
import newRoutes from "../newRoutes";
import newRoutesIrn from "../newRoutesIrn";
import { checkUserDetails } from "../helpers/RouteValidation";
import Button from "@material-ui/core/Button";
import MenuIcon from "@material-ui/icons/Menu";

function SwitchRoutes(props) {
  const { routes } = props;
  return (
    <Switch>
      {routes.map((prop, key) => {
        if (prop.layout === "/fms") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
        return null;
      })}
      <Redirect from="/fms" to="/fms/dashboard" />
    </Switch>
  );
}

const useStyles = makeStyles(styles);

export default function Base() {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [routes, setRoutes] = React.useState([]);
  const [userLogged, setUserLogged] = React.useState(true);

  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth < 960) {
        setMobileOpen(true);
      } else {
        setMobileOpen(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth]);

  const findScreenWidth = () => {
    if (window.innerWidth < 960) {
      setMobileOpen(true);
    } else {
      setMobileOpen(false);
    }
  };

  // initialize and destroy the PerfectScrollbar plugin
  // React.useEffect(() => {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps = new PerfectScrollbar(mainPanel.current, {
  //       suppressScrollX: true,
  //       suppressScrollY: false,
  //     });
  //     document.body.style.overflow = "hidden";
  //   }
  //   window.addEventListener("resize", resizeFunction);
  //   // Specify how to clean up after this effect:
  //   return function cleanup() {
  //     if (navigator.platform.indexOf("Win") > -1) {
  //       ps.destroy();
  //     }
  //     window.removeEventListener("resize", resizeFunction);
  //   };
  // }, [mainPanel]);

  React.useEffect(() => {
    var user = checkUserDetails();
    if (user) {
      setUserLogged(true);
      if (user && user.role === "Admin") {
        setRoutes(adminRoutes);
      } else if (user && user.role === "Finance") {
        setRoutes(financeRoutes);
      } else if (user && user.role === "NTM") {
        setRoutes(ntmRoutes);
      } else if (user && user.role === "location_manager") {
        setRoutes(locationManagerRoutes);
      } else if (user && user.role === "logistics_user") {
        setRoutes(logisticsUserRoutes);
      }else if (user && user.role === "logistics") {
        setRoutes(newRoutes);
      }else if (user && user.role === "logisticsirn") {
        setRoutes(newRoutes);
      }
    } else {
      setUserLogged(false)
    }
    findScreenWidth();
  }, []);

  if (!userLogged) {
    return <Redirect to="/login" />;
  }

  return (
    <div className={classes.wrapper}>
    <Button variant="text" onClick={() => setMobileOpen(true)}>
        <MenuIcon />
        </Button>
      <Sidebar
        routes={routes}
        logo={MoreLogo}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color="red"
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Header />
        <SwitchRoutes routes={routes} />
      </div>
    </div>
  );
}
