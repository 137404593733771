// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";

// Components / Views
import Home from "./pages/Dashboard/Dashboard";
import Login from "./pages/Login/Login";
import TripCreation from "./pages/TripCreation/TripCreation";
import Costing from "./pages/Costing/Costing";
import Finance from "./pages/Finance/Finance";

import PTL from "./pages/PTL/PTL";
import TripConfirmation from "./pages/TripConfirmation/TripConfirmation";
import { ReactComponent as DashboardInactive } from "./assets/icons/Left Menu/dashboard_inactive1.svg";
import { ReactComponent as DashboardActive } from "./assets/icons/Left Menu/dashboard_active1.svg";
import { ReactComponent as TripCreationActive } from "./assets/icons/Left Menu/tripcreation_active.svg";
import { ReactComponent as TripCreationInactive } from "./assets/icons/Left Menu/tripcreation_inactive.svg";
import { ReactComponent as PTLInactive } from "./assets/icons/Left Menu/PTL_inactive.svg";
import { ReactComponent as PTLActive } from "./assets/icons/Left Menu/PTL_active.svg";
import NTM from "./pages/Summary/NTM";
import Master from "./pages/Master/Master";
import Report from "./pages/Reports/Reports";
import CostUpdation from "./components/Cost_Updation/CostUpdation";
import BulkUpload from "./pages/BulkUpload/BulkUpload";


const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Home,
    layout: "/fms",
    activeIcon: DashboardActive,
    inactiveIcon: DashboardInactive,
  },
  {
    path: "/costing",
    name: "Costing",
    component: Costing,
    layout: "/fms",
    activeIcon: TripCreationActive,
    inactiveIcon: TripCreationInactive,
  },
  {
    path: "/master",
    name: "Master",
    component: Master,
    layout: "/fms",
    activeIcon: PTLActive,
    inactiveIcon: PTLInactive,
  },
  {
    path: "/Finance",
    name: "Finance",
    component: Finance,
    layout: "/fms",
    activeIcon: DashboardActive,
    inactiveIcon: DashboardInactive,
},
{
  path: "/transporationCommercials",
  name: "Transportation Commercials",
  component: NTM,
  layout: "/fms",
  activeIcon: DashboardActive,
  inactiveIcon: DashboardInactive,
},
{
        path: "/reports",
        name: "Reports",
        component: Report,
        layout: "/fms",
        activeIcon: PTLActive,
        inactiveIcon: PTLInactive,
},
    {
        path: "/costUpdate",
        name: "Cost Update",
        component: CostUpdation,
        layout: "/fms",
        activeIcon: DashboardActive,
        inactiveIcon: DashboardInactive,
    },
    {
        path: "/BulkUpdate",
        name: "Bulk Contract Update",
        component: BulkUpload,
        layout: "/fms",
        activeIcon: PTLActive,
        inactiveIcon: PTLInactive,
    },

];

export default dashboardRoutes;
