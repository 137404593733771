import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Normal from "../../Typography/Normal";
import CloseIcon from "@material-ui/icons/Close";
import TripCreationStyle from "../../../assets/css/TripCreationStyle";
import TripRateTable from "./TripRateTable";


const useStyles = makeStyles(TripCreationStyle);

export default function TripRateDialog(props) {
  const classes = useStyles();
  
  const {open , DialogClose,tripratedetails} = props;



  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      aria-labelledby="confirmation-dialog-title"
      classes={{ paperWidthSm: classes. StoreTableDialog}}
    >
      <DialogTitle id="confirmation-dialog-title">
      <div className="flex justify-between items-center">
          <Normal>
            <h3 className={classes.textColor}>Trip Rates:</h3>
          </Normal>
          <IconButton>
            <CloseIcon onClick={DialogClose} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>
          <TripRateTable tripratedetails={tripratedetails}/>
      </DialogContent>
    </Dialog>
  );
}
