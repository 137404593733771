import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Normal from "../../Typography/Normal";
import CloseIcon from "@material-ui/icons/Close";
import TripCreationStyle from "../../../assets/css/TripCreationStyle";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { Button, DialogActions } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { SuccessButton } from "../../Button/Button";
import axiosRequest from "../../../helpers/axios";
import SnackBarComponent from "../../../helpers/SnackBarComponent";

const useStyles = makeStyles(TripCreationStyle);

export default function LocationMasterDialog(props) {
  const classes = useStyles();

  const { open, DialogClose,fmslocationid,reteklocationid} = props;
  const [fmslocationname, setFmsLocationName] = React.useState("");
  const [content, setContent] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);
  const handleClose = () => {
    setOpensb(false);
  };

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };

  const postLocationMasterData = async () => {
    await axiosRequest({
      url: "master/post_location_master/",
      method: "POST",
      data:{
        retek_location_id:Number(reteklocationid),
        fms_location_id:Number(fmslocationid),
        fms_location_name:fmslocationname
      }
    }).then((data) => {
      if (data.status === 200) {
        DialogClose()
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const handleFmsLocationName= (event) => {
    setFmsLocationName(event.target.value);
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      aria-labelledby="confirmation-dialog-title"
      classes={{ paperWidthSm: classes.LocationMasterDialog }}
    >
      <DialogTitle id="confirmation-dialog-title">
        <div className="flex justify-between items-center">
          <Normal>
            <h3 className={classes.textColor}>Add new fms location id</h3>
          </Normal>
          <IconButton>
            <CloseIcon onClick={DialogClose} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <div className="flex flex-row justify-between">
      
          <TextField
            id="outlined-basic"
            label="fms location id"
            className={classes.inputmargin}
            variant="outlined"
            value={fmslocationid}
          />
          <TextField
            id="outlined-basic"
            label="fms location name"
            className={classes.inputmargin}
            variant="outlined"
            onChange={handleFmsLocationName}
          />
          {/* <option value="" className={classes.fs12}>
              Origin ID
            </option> */}
        </div>
      </DialogContent>
      <DialogActions>
        <div className="flex justify-center">
          <SuccessButton onClick={postLocationMasterData} variant="contained" disableElevation>
            <Typography className={clsx(classes.fs12, "ttn")}>Add</Typography>
          </SuccessButton>
        </div>
      </DialogActions>
      <SnackBarComponent
                opensb={opensb}
                handleClose={handleClose}
                severity={severity}
                content={content}
              />
    </Dialog>
  );
}
