import { useEffect } from "react";
import { useState } from "react";
import MapContent from "../Maps/Maps";
import axiosRequest from "../../helpers/axios";
import { getLocationId } from "../../helpers/RouteValidation";
import GpsIcon from "../../assets/icons/gps.svg";
import "./Trips.css";
import moment from "moment";
const Trips = () => {
  const [routeList, setRouteList] = useState([]);
  const [trips, setTrips] = useState([]);
  const [vehicleDetails, setVehicleDetails] = useState({
    number: "",
    trip_id: "",
  });
  const [markers, setMarkers] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [vehicleCoordinates, setVehicleCoordinates] = useState({
    latitude: "",
    longitude: "",
  });
  const [isMapLoading, setIsMapLoading] = useState(false);
  useEffect(() => {
    getTrips();
  }, []);
  const getTrips = async () => {
    try {
      let fmsLocationId = getLocationId();
      const trips = await axiosRequest({
        url: "/bolNoVerification/tripStatus/" + fmsLocationId,
        method: "GET",
      });
      if (trips?.data?.length) {
        setTrips(trips?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getTripData = async (id) => {
    try {
      setVehicleDetails({ trip_id: id });
      setMarkers([]);
      setRoutes([]);
      setRouteList([]);
      setIsMapLoading(true);
      const tripData = await axiosRequest({
        url: `/bolNoVerification/tripMap/${id}`,
        method: "GET",
      });
      if (Object.keys(tripData?.data)?.length) {
        setVehicleDetails({
          number: tripData?.data?.vehicle[0]?.vehicle_num,
          trip_id: id,
        });
        setMarkers(tripData?.data?.markers);
        sortRouteList(tripData?.data?.markers);
        setRoutes(tripData?.data?.route);
        if (
          !tripData?.data?.vehicle[0]?.long ||
          !tripData?.data?.vehicle[0]?.lat
        ) {
          const startingPoint = tripData?.data?.markers?.find(
            (point) => point?.delivery_seq === 0
          );
          setVehicleCoordinates({
            longitude: startingPoint?.longitude || "",
            latitude: startingPoint?.latitude || "",
          });
        } else {
          setVehicleCoordinates({
            longitude: tripData?.data?.vehicle[0]?.long,
            latitude: tripData?.data?.vehicle[0]?.lat,
          });
        }
        setIsMapLoading(false);
      } else {
        setIsMapLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsMapLoading(false);
    }
  };
  const sortRouteList = (routes) => {
    if (routes?.length) {
      let sortedRoutes = routes.sort((a, b) => a.delivery_seq - b.delivery_seq);
      setRouteList(sortedRoutes);
    } else {
      setRouteList([]);
    }
  };
  return (
    <div
      className="d-flex flex-column align-items-start"
      style={{ height: "100vh" }}
    >
      <div className="trip-head">Trip Status</div>
      <div className="triplist">
        <table class="table m-3 ">
          <thead>
            <tr>
              <th scope="col">Trip ID</th>
              <th scope="col">FMS Location</th>
              <th scope="col">Trip Date</th>
              <th scope="col">Vehicle Number</th>
              <th scope="col">Gps track</th>
            </tr>
          </thead>
          <tbody>
            {(trips?.length &&
              trips?.map((trip, i) => (
                <tr key={i}>
                  <th scope="row" className="trip-text">
                    {trip?.trip_id}
                  </th>
                  <th scope="row" className="trip-text">
                    {trip?.fms_location_name}
                  </th>
                  <th scope="row" className="trip-text">
                    {trip?.created_date}
                  </th>
                  <th scope="row" className="trip-text">
                    {trip?.vehicle_num}
                  </th>
                  <th
                    scope="row"
                    className="trip-text"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    onClick={() => {
                      getTripData(trip?.trip_id);
                    }}
                  >
                    <img className="gps-icon" alt="gps" src={GpsIcon} />
                  </th>
                </tr>
              ))) ||
              ""}
          </tbody>
        </table>
      </div>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-xl" style={{ marginLeft: "20%" }}>
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body d-flex" style={{ height: "80vh" }}>
              <div style={{ width: "30%" }} className="border-end">
                <div className="d-flex flex-column align-items-start border-bottom px-3 py-2">
                  <div className="detail-head">{vehicleDetails.number}</div>
                  <div className="detail-head">
                    TRIP ID&nbsp;{vehicleDetails.trip_id}
                  </div>
                </div>
                {(routeList?.length && (
                  <div className="align-items-start d-flex flex-column h-75 justify-content-between px-3 py-2">
                    <ul class="bar">
                      {routeList.map((route, i) => {
                        return (
                          <li
                            key={i}
                            className={[
                              "d-flex flex-column align-items-start",
                              route?.isvisited && i !== 0 && "visit-green",
                              !route?.isvisited && i !== 0 && "visit-red",
                              (i === 0 || i === routeList?.length - 1) &&
                                "base-pointer",
                            ].join(" ")}
                            style={{
                              paddingBottom:
                                route?.intime || route?.outtime
                                  ? "15px"
                                  : "20px",
                              marginBottom: "5px",
                              display:
                                i === 0 || i === routeList?.length - 1
                                  ? "none !important"
                                  : "block",
                            }}
                          >
                            <div className="route-item route-text">
                              {route?.loc_name}
                            </div>
                            {(route?.intime || route?.outtime) && i !== 0 && (
                              <div className="time route-text d-flex flex-column">
                                <div>
                                  {(route?.intime &&
                                    moment.utc(route?.intime)
                                      .local()
                                      .format("DD-MM-YY hh:mm a")) ||
                                    "NA"}
                                  &nbsp;&nbsp;-&nbsp;&nbsp;
                                </div>
                                <div>
                                  {(route?.outtime &&
                                    moment.utc(route?.outtime)
                                      .local()
                                      .format("DD-MM-YY hh:mm a")) ||
                                    "NA"}
                                </div>
                              </div>
                            )}
                          </li>
                        );
                      })}
                      <li className="end-point route-text">
                        {routeList[0]?.loc_name}
                      </li>
                    </ul>
                  </div>
                )) ||
                  ""}
              </div>
              <div style={{ width: "70%" }}>
                {!isMapLoading && (
                  <MapContent
                    markers={markers}
                    routes={routes}
                    mapCoordinates={vehicleCoordinates}
                  />
                )}
                {isMapLoading && (
                  <div class="lds-spinner map-spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Trips;