import React from "react";
import { useState } from "react";
import { Input } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TripCreationStyle from "../../../assets/css/TripCreationStyle";
import { Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import clsx from "clsx";
import { MoreButton } from "../../Button/Button";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles(TripCreationStyle);

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 200,
      width: 200,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};


export default function Filter(props) {
  const classes = useStyles();
  const { handleConfirm,LocationOptions,fmsLocations,setFmsLocations } = props;
  const [startMonth, setStartMonth] = React.useState();
  const [endMonth, setEndMonth] = React.useState();

  const isAllSelected =
    LocationOptions.length > 0 &&
    fmsLocations.length === LocationOptions.length;

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      var fmsLocationsId = LocationOptions.map(
        (fmsLocation) => fmsLocation.fms_location_id
      );
      setFmsLocations(
        fmsLocations.length === LocationOptions.length ? [] : fmsLocationsId
      );
    } else {
      setFmsLocations(value);
    }
  };

  const handleStartMonth = (event) => {
    setStartMonth(event.target.value);
  };

  const handleEndMonth = (event) => {
    setEndMonth(event.target.value);
  };

  return (
    <React.Fragment>
      <Divider />
      <div className="flex justify-around items-center pa3">
        <div className="flex items-center">
          <span className={clsx(classes.fs12, "mr3")}>Start Date:</span>
          <Input
            type="month"
            label="Start Month"
            defaultValue=""
            className={clsx(classes.bgInputGray, classes.underline)}
            onChange={handleStartMonth}
          />
        </div>
        <div className="flex items-center">
          <span className={clsx(classes.fs12, "mr3")}>End Date:</span>
          <Input
            type="month"
            label="End Month"
            defaultValue=""
            className={clsx(classes.bgInputGray, classes.underline)}
            onChange={handleEndMonth}
          />
        </div>
        <div className="flex">
          <span className={clsx(classes.fs12, "mr3")}>
            Select a location, Multiple <br /> location or all locations
          </span>
          <Select
            multiple
            value={fmsLocations}
            onChange={handleChange}
            className={clsx(
              classes.inputDailySelect,
              classes.underline,
              classes.fs12,
              classes.bgInputGray
            )}
            renderValue={(fmsLocations) =>
              fmsLocations.length + " Locations selected"
            }
            MenuProps={MenuProps}
          >
            <MenuItem
              value="all"
              classes={{
                root: isAllSelected ? classes.selectedAll : "",
              }}
            >
              <ListItemIcon>
                <Checkbox
                  classes={{ indeterminate: classes.indeterminateColor }}
                  checked={isAllSelected}
                  indeterminate={
                    fmsLocations.length > 0 &&
                    fmsLocations.length < LocationOptions.length
                  }
                />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.selectAllText }}
                primary="Select All Locations"
              />
            </MenuItem>
            {LocationOptions.map((fmsLocation, index) => (
              <MenuItem key={index} value={fmsLocation.fms_location_id}>
                <ListItemIcon>
                  <Checkbox
                    checked={
                      fmsLocations.indexOf(fmsLocation.fms_location_id) > -1
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primary={fmsLocation.fms_location_name}
                  classes={{ primary: classes.selectAllText }}
                />
              </MenuItem>
            ))}
          </Select>
        </div>
        <div>
          <MoreButton
            variant="contained"
            disableElevation
            onClick={() => handleConfirm(startMonth, endMonth, fmsLocations)}
          >
            <Typography className={clsx(classes.fs12, "ttn")}>
              Generate summary
            </Typography>
          </MoreButton>
        </div>
      </div>
    </React.Fragment>
  );
}
