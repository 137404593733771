import React from "react";
import TripConfirmationStepper from "../../components/TripConfirmation/TripConfirmationStepper";
import Paper from "@material-ui/core/Paper";
import { Redirect } from "react-router";
import { checkUserDetails } from "../../helpers/RouteValidation";

export default function TripConfirmation() {
  const [isAuthenticated, setIsAuthenticated] = React.useState(true);

  React.useEffect(() => {
    var user = checkUserDetails();
    if (user) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  return (
    <React.Fragment>
      {isAuthenticated ? (
        <Paper elevation={0} square className="mt4">
          <TripConfirmationStepper />
        </Paper>
      ) : (
        <Redirect to="/login" />
      )}
    </React.Fragment>
  );
}
