import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { TableHeader, StyledTableRow } from "../Base/TableHeader";
import { getComparator, sortArray } from "../../helpers/sorting";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import PublishRoundedIcon from "@material-ui/icons/PublishRounded";
import { MoreButton, SuccessButton } from "../Button/Button";
import TripCreationStyle from "../../assets/css/TripCreationStyle";
import axiosRequest from "../../helpers/axios";
import Link from "@material-ui/core/Link";
import TripStoreDetailDialog from "../Base/TripStoreDetailDialogue";
import SnackBarComponent from "../../helpers/SnackBarComponent";
import StoreDetailTable from "../Base/StoreDetailTable";
import exportToCsv from "../../helpers/exportToCsv";
import { Input } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const headers = [
    // {
    //     id: "month",
    //     sortable: true,
    //     align: "center",
    //     label: "Month",
    //   },
    {
        id: "fms_location_name",
        sortable: true,
        align: "center",
        label: "FMS Location",
      },
      {
        id: "trip_created_time",
        sortable: true,
        align: "center",
        label: "Trip Date",
      },
      {
        id: "trip_id",
        sortable: true,
        align: "center",
        label: "Trip ID",
      },
      {
        id: "vendor_name",
        sortable: true,
        align: "center",
        label: "Vendor Name",
      },
      {
        id: "vehicle_num",
        sortable: true,
        align: "center",
        label: "Vehicle Number",
      },
      {
        id: "approval_status",
        sortable: true,
        align: "center",
        label: "Approval Status",
      },
      {
        id: "stores_list",
        sortable: true,
        align: "center",
        label: "Store Names",
      },
      {
        id: "trip_dist_fms_est",
        sortable: true,
        align: "center",
        label: "Trip Distance (FMS)",
      },
      {
        id: "trip_dist_dc_input",
        sortable: true,
        align: "center",
        label: "Trip Distance (DC)",
      },
      
      {
        id: "bol_value",
        sortable: true,
        align: "center",
        label: "BOL Value",
      },
      {
        id: "contract_type",
        sortable: true,
        align: "center",
        label: "Contract Type",
      },
      {
        id: "total_vehicles",
        sortable: true,
        align: "center",
        label: "Total Number of Vehicles",
      },
      {
        id: "fixed_cost",
        sortable: true,
        align: "center",
        label: "Fixed Cost",
      },
      {
        id: "trip_wt",
        sortable: true,
        align: "center",
        label: "Trip Weight (Kgs)",
      },
      {
        id: "vehicle_vol_util",
        sortable: true,
        align: "center",
        label: "Volume Utilization",
      },
      {
        id: "vehicle_wt_util",
        sortable: true,
        align: "center",
        label: "Weight Utilization",
      },
      {
        id: "payload_in_mt",
        sortable: true,
        align: "center",
        label: "Payload",
      },
      {
        id: "freight_cost_fms",
        sortable: true,
        align: "center",
        label: "Trip Cost (FMS estimate)",
      },
    
      {
        id: "freight_cost_dc_input",
        sortable: true,
        align: "center",
        label: "Trip Cost (DC Input)",
      },
      {
        id: "ntm_trip_cost",
        sortable: false,
        align: "center",
        label: "NTM Trip Cost",
      },
      {
        id: "toll_charges",
        sortable: false,
        align: "center",
        label: "Toll Charges",
      },
      {
        id: "halting_charges",
        sortable: false,
        align: "center",
        label: "Halting Charges",
      },
      {
        id: "other_charges",
        sortable: false,
        align: "center",
        label: "Other Charges",
      },
      {
        id: "damage_charges",
        sortable: false,
        align: "center",
        label: "Other Charges",
      },
      {
        id: "total_number_of_crates_dc_input",
        sortable: false,
        align: "center",
        label: "Lpn Count",
      },
];

const useStyles = makeStyles(TripCreationStyle);
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      width: 300,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};


const names = [
"Pending NTM",
"NTM Approved",
];
export function CostingHeader(props) {
  const { downloadReport,handleStartMonth,handleEndMonth,handleConfirm,startMonth,endMonth,LocationOptions,fmsLocations,setFmsLocations,setVendor,vendorOptions,vendor} = props;
  const classes = useStyles();
 
  

  const isAllSelected =
    LocationOptions.length > 0 &&
    fmsLocations.length === LocationOptions.length;

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      var fmsLocationsId = LocationOptions.map(
        (fmsLocation) => fmsLocation.fms_location_id
      );
      setFmsLocations(
        fmsLocations.length === LocationOptions.length ? [] : fmsLocationsId
      );
    } else {
      setFmsLocations(value);
    }
  };

  const isAllSelectedVendor=
  vendorOptions.length > 0 &&
  vendor.length === vendorOptions.length;


const handleChangeVendor = (event) => {
const value = event.target.value;
if (value[value.length - 1] === "all") {
  var vendorId = vendorOptions.map(
    (vendors) => vendors.vendor_id
  );
  setVendor(
    vendor.length === vendorOptions.length ? [] : vendorId
  );
} else {
  setVendor(value);
}
};


  return (
    <React.Fragment>
      <div className="flex flex-column justify-between pa3">
        <Typography variant="body2">Dc-Vendor-Trip</Typography>
        <div className="flex pb3">
          {/* <div className="flex flex-column"> */}
          <div className="flex items-center">
          <span className={clsx(classes.fs12, "mr3")}>Start Month:</span>
          <Input
            type="month"
            label="Start Month"
            defaultValue=""
            className={clsx(classes.bgInputGray, classes.underline)}
            onChange={(event)=>handleStartMonth(event)}
          />
        </div>
        <div className="flex items-center">
          <span className={clsx(classes.fs12, "mr3")}>End Month:</span>
          <Input
            type="month"
            label="End Month"
            defaultValue=""
            className={clsx(classes.bgInputGray, classes.underline)}
            onChange={(event)=>handleEndMonth(event)}
          />
        </div>
         <span className={clsx(classes.fs12, "mr3")}>
            Selection of locations
          </span>
          <Select
            multiple
            value={fmsLocations}
            onChange={handleChange}
            className={clsx(
              classes.inputDailySelect,
              classes.underline,
              classes.fs12,
              classes.bgInputGray
            )}
            renderValue={(fmsLocations) =>
              fmsLocations.length + " Locations selected"
            }
            MenuProps={MenuProps}
          >
            <MenuItem
              value="all"
              classes={{
                root: isAllSelected ? classes.selectedAll : "",
              }}
            >
              <ListItemIcon>
                <Checkbox
                  classes={{ indeterminate: classes.indeterminateColor }}
                  checked={isAllSelected}
                  indeterminate={
                    fmsLocations.length > 0 &&
                    fmsLocations.length < LocationOptions.length
                  }
                />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.selectAllText }}
                primary="Select All Locations"
              />
            </MenuItem>
            {LocationOptions.map((fmsLocation, index) => (
              <MenuItem key={index} value={fmsLocation.fms_location_id}>
                <ListItemIcon>
                  <Checkbox
                    checked={
                      fmsLocations.indexOf(fmsLocation.fms_location_id) > -1
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primary={fmsLocation.fms_location_name}
                  classes={{ primary: classes.selectAllText }}
                />
              </MenuItem>
            ))}
          </Select>
          <span className={clsx(classes.fs12, "mr3")}>
            Selection of vendors 
          </span>
          <Select
            multiple
            value={vendor}
            onChange={handleChangeVendor}
            className={clsx(
              classes.inputDailySelect,
              classes.underline,
              classes.fs12,
              classes.bgInputGray
            )}
            renderValue={(vendor) =>
              vendor.length + " Vendors selected"
            }
            MenuProps={MenuProps}
          >
            <MenuItem
              value="all"
              classes={{
                root: isAllSelectedVendor ? classes.selectedAll : "",
              }}
            >
              <ListItemIcon>
                <Checkbox
                  classes={{ indeterminate: classes.indeterminateColor }}
                  checked={isAllSelectedVendor}
                  indeterminate={
                    vendor.length > 0 &&
                    vendor.length < vendorOptions.length
                  }
                />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.selectAllText }}
                primary="Select All Vendors"
              />
            </MenuItem>
            {vendorOptions.map((vendors, index) => (
              <MenuItem key={index} value={vendors.vendor_id}>
                <ListItemIcon>
                  <Checkbox
                    checked={
                      vendor.indexOf(vendors.vendor_id) > -1
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primary={vendors.vendor_name}
                  classes={{ primary: classes.selectAllText }}
                />
              </MenuItem>
            ))}
          </Select>
           <MoreButton
            variant="contained"
            disableElevation
            onClick={() => handleConfirm(startMonth, endMonth,fmsLocations,vendor)}
            className="ml3"
          >
            <Typography className={clsx(classes.fs12, "ttn")}>
              Generate Costing Summary
            </Typography>
          </MoreButton>
          <MoreButton
          variant="contained"
          disableElevation
          className={clsx(classes.button,"pl4")}
          startIcon={<GetAppRoundedIcon fontSize="small" />}
          onClick={downloadReport}
        >
          <Typography className={clsx(classes.fs12, "ttn")}>
            Download
          </Typography>
        </MoreButton>
        </div>
       
          {/* <Button
            variant="contained"
            disableElevation
            className={classes.button}
            onClick={() => costBasedOnProperty("freight_cost_fms")}
          >
            <Typography className={clsx(classes.fs12, "ttn")}>
              I/P Cost Based on FMS Estimate
            </Typography>
          </Button>
          <Button
            variant="contained"
            disableElevation
            className={classes.button}
            onClick={() => costBasedOnProperty("freight_cost_dc_input")}
          >
            <Typography className={clsx(classes.fs12, "ttn")}>
              I/P Cost Based on DC Input
            </Typography>
          </Button> */}
          
        </div>
    </React.Fragment>
  );
}

export default function TripVendorDC(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("trip_id");
  const [tableData, setTableData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [storeDetails, setStoreDetails] = React.useState([]);
  const [severity, setSeverity] = React.useState("");
  const [content, setContent] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);
  const [startDate,setStartDate]=React.useState("");
  const [endDate,setEndDate]=React.useState("")
  const [isSummaryGenerated, setIsSummaryGenerated] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [fmsLocationid, setFmsLocationid] = React.useState([]);
  const [LocationOptions,setLocationOptions]=React.useState([]);
  const [vendor, setVendor] = React.useState([]);
  const [vendorOptions,setVendorOptions]=React.useState([]);
  const [startMonth, setStartMonth] = React.useState("");
  const [endMonth, setEndMonth] = React.useState("");
  let fmsLocationID = [];
  let vendorId=[]
  const handleClose = () => {
    setOpensb(false);
  };

  const handleStartDate = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDate = (event) => {
    setEndDate(event.target.value);
  };

  const handleStartMonth = (event) => {
    console.log(endDate,"strt")
    setStartMonth(event.target.value);
  };

  const handleEndMonth = (event) => {
    console.log(startDate,"end")
    setEndMonth(event.target.value);
  };

  const handleConfirm = (startMonth, endMonth,fmsLocations,vendor) => {
     console.log(startMonth,endMonth,"months")

    if (startMonth && endMonth) {
      getCostingData(startMonth,endMonth,fmsLocations,vendor)
      // getDateLevelSummary(startDate, endDate);
      // getDateVendorLevelSummary(startDate, endDate);
      setIsSummaryGenerated(true);
    } else {
      handleSnackBar("Select Start & End Month", "error");
    }
  };





const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const getMonthRange = (startDate, endDate) => {
  var start = startDate.split("-");
  var end = endDate.split("-");
  var startYear = parseInt(start[0]);
  var endYear = parseInt(end[0]);
  var months = [];

  for (var i = startYear; i <= endYear; i++) {
    var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
    var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
    for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      var reqMonth = {};
      var month = j;
      if (month === 0) {
        reqMonth.startDate = 12;
        reqMonth.month = monthNames[month];
        reqMonth.startDate = [i - 1, reqMonth.startDate, "26"].join("-");
      } else {
        reqMonth.startDate = month < 10 ? "0" + month : month;
        reqMonth.month = monthNames[month];
        reqMonth.startDate = [i, reqMonth.startDate, "26"].join("-");
      }
      reqMonth.endDate = month + 1 < 10 ? "0" + (month + 1) : month + 1;
      reqMonth.endDate = [i, reqMonth.endDate, "26"].join("-");
      reqMonth.month = reqMonth.month + " - " + i;
      months.push(reqMonth);
    }
  }
  return months;
};
  const DialogOpen = (row) => {
    setStoreDetails(row.stores_list);
    setOpen(true);
  };

  const formatDate = (date) => {
    var d = new Date(date);
    var month = "" + (d.getMonth() + 1);
    var day = "" + d.getDate();
    var year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const DialogClose = () => {
    setOpen(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

 



  const downloadReport = () => {
    let csvData = []
    let title = [
        "fms_location_name",
        "trip_created_time",
        "trip_id",
        "vendor_name",
        "vehicle_num",
        "approval_status",
        "store_name",
        "trip_dist_fms_est",
        "trip_dist_dc_input",
        "bol_value",
        "contract_type",
        "total_vehicles",
        "trip_wt",
        "vehicle_vol_util",
        "vehicle_wt_util",
        "payload_in_mt",
        "toll_charges",
        "halting_charges",
        "other_charges",
        "damage_charges",
        "total_number_of_crates_dc_input",
        "freight_cost_fms",
        "freight_cost_dc_input",
        "ntm_trip_cost",
        "is_combi_trip",
      ];
      let rows = tableData.map((x) => [
        x[title[0]],
        formatDate(x[title[1]]),
        x[title[2]],
        x[title[3]],
        x[title[4]],
        x[title[5]],
        x[title[6]],
        x[title[7]],
        x[title[8]],
        x[title[9]],
        x[title[10]],
        x[title[11]],
        x[title[12]],
        x[title[13]],
        x[title[14]],
        x[title[15]],
        x[title[16]],
        x[title[17]],
        x[title[18]],
        x[title[19]],
        x[title[20]],
        x[title[21]],
        x[title[22]],
        x[title[23]],
        x[title[24]] === null ? false : x[title[23]],
      ]);
  
    csvData = [title, ...rows];
    exportToCsv("DC level vendor level trip report on " + new Date() + ".csv", csvData);
    handleSnackBar("Downloaded Successfully", "success");
  };

  const getCostingData = async (startMonth,endMonth,fmsLocations,vendor) => {
    var months = getMonthRange(startMonth, endMonth);

    await axiosRequest({
      url: "tripCommercials/dcvendorleveltrips/",
      method: "POST",
      data: {
        month:months,
        fmsLocationId:fmsLocations,
        vendorName:vendor,
      },
    }).then((data) => {
      if (data.status === 200) {
        setTableData(data.data);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };
  
  const getLocationData = async () => {
    await axiosRequest({
      url: "master/getlocations/",
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        setLocationOptions(data.data);
        console.log(LocationOptions)
        for (let i in data.data) {
          fmsLocationID.push(data.data[i]["fms_location_id"]);
        }
        console.log(fmsLocationID);
        setFmsLocationid(fmsLocationID);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };
 
  const getVendorData = async () => {
    await axiosRequest({
      url: "master/vendor/",
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        setVendorOptions(data.data);
        console.log(vendorOptions)
        for (let i in data.data) {
          vendorId.push(data.data[i]["vendor_id"]);
        }
        console.log(vendorId);
        setVendor(vendorId);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  React.useEffect(() => {
    setRows(tableData);
  }, [tableData]);

  React.useEffect(() => {
    getLocationData();
    getVendorData();
  }, []);

 
  const handleAllSearch = (event, property) => {
    const searchResult = tableData.filter((row) => {
      return row[property]
        .toString()
        .toLowerCase()
        .includes(event.target.value.toLowerCase());
    });
    setRows(searchResult);
  };
  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };
  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <CostingHeader
          downloadReport={downloadReport}
          handleStartMonth={handleStartMonth}
          handleEndMonth={handleEndMonth}
          handleConfirm={handleConfirm}
          startMonth={startMonth} endMonth={endMonth}
          LocationOptions={LocationOptions}
          fmsLocations={fmsLocationid}
          setFmsLocations={setFmsLocationid}
          vendorOptions={vendorOptions} 
          vendor={vendor} 
          setVendor={setVendor}
        />
        <TableContainer className={classes.costingContainer}>
          <Table stickyHeader>
            <TableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={tableData.length}
              headers={headers}
              handleSearch={handleAllSearch}
              isCheckboxEnabled={false}
            />
            <TableBody>
              {sortArray(rows, getComparator(order, orderBy)).map(
                (row, index) => {
                  return (
                    <StyledTableRow key={index}>
                    {/* <TableCell align="center" className={classes.fs12}>
                        {row.month}
                      </TableCell> */}
                      <TableCell align="center" className={classes.fs12}>
                        {row.fms_location_name}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_created_time.substr(0, 10)}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_id}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.vendor_name}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.vehicle_num}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.approval_status}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {String(row.store_name)}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_dist_fms_est}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_dist_dc_input}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        &#8377; {Number(row.bol_value).toFixed(2)}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.contract_type}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.total_vehicles}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.fixed_cost}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_wt}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.vehicle_vol_util}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.vehicle_wt_util}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.payload_in_mt}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        &#8377; {Number(row.freight_cost_fms).toFixed(2)}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        &#8377; {Number(row.freight_cost_dc_input).toFixed(2)}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        &#8377; {Number(row.ntm_trip_cost).toFixed(2)}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.toll_charges}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.halting_charges}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.other_charges}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.damage_charges}
                      </TableCell>
                       <TableCell align="center" className={classes.fs12}>
                        {row.total_number_of_crates_dc_input}
                      </TableCell>
                    </StyledTableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <TripStoreDetailDialog
        DialogClose={DialogClose}
        open={open}
        storeDetails={storeDetails}
      />
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </Grid>
  );
}
