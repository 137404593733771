import React from "react";
import { Container, Toolbar, Typography } from "@material-ui/core";
import LeftPost from "../../components/Dashboard/LeftCardsGrid";
import Grid from "@material-ui/core/Grid";
import { CardsContent } from "../../components/Dashboard/Data";
import RightCard from "../../components/Dashboard/RightCardGrid";
import BottomCard from "../../components/Dashboard/BottomCardGrid";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router";
import { checkUserDetails } from "../../helpers/RouteValidation";

const useStyles = makeStyles({
  bottomGridDetails: {
    paddingTop: 20,
  },
  boottomHeaderDetails: {
    paddingRight: 70,
  },
});

const Home = () => {
  const classes = useStyles();
  const [isAuthenticated, setIsAuthenticated] = React.useState(true);

  React.useEffect(() => {
    var user = checkUserDetails();
    console.log(user)
    if (user) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  return (
    <React.Fragment>
      {isAuthenticated ? (
        <Container>
          <Typography variant="h5">Dashboard</Typography>
          <Grid className="mt4">
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12} sm={8} spacing={2}>
                <Typography variant="h6">Live Summary</Typography>
                <Grid container spacing={2}>
                  {CardsContent.map((post) => (
                    <LeftPost key={post.actualno} post={post} />
                  ))}
                </Grid>
              </Grid>

              <Grid item xs={12} sm={4} spacing={2}>
                <Typography variant="h6">Vehicle Availablity</Typography>
                <RightCard />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              spacing={2}
              className={classes.bottomGridDetails}
            >
              <Toolbar>
                <div className={classes.boottomHeaderDetails}>
                  <Typography variant="h6">eWaybill Summary</Typography>
                </div>
                <ButtonGroup variant="contained" color="inherit">
                  <Button>Today's Summary</Button>
                  <Button>MTD Summary</Button>
                </ButtonGroup>
              </Toolbar>
              <BottomCard />
            </Grid>
          </Grid>
        </Container>
      ) : (
        <Redirect to="/login" />
      )}
    </React.Fragment>
  );
};

export default Home;
