import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { ReactComponent as UnFoldMoreIcon } from "../../assets/icons/unfold_more_18px.svg";
import SearchIcon from "@material-ui/icons/Search";
import { Input, InputAdornment } from "@material-ui/core";
import clsx from "clsx";
import TripCreationStyle from "../../assets/css/TripCreationStyle";

const useStyles = makeStyles(TripCreationStyle);

const TableHeader = (props) => {
  const classes = useStyles();
  const {
    headers,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    isCheckboxEnabled,
    handleSearch,
    disabled
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const createSearchHandler = (property) => (event) => {
    handleSearch(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {isCheckboxEnabled ? (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              disabled={disabled}
            />
          </TableCell>
        ) : (
          ""
        )}
        {headers.map((header, index) => (
          <TableCell key={index} className={classes.fs12} align={header.align}>
            {header.sortable ? (
              <TableSortLabel
                active={orderBy === header.id}
                direction={orderBy === header.id ? order : "asc"}
                onClick={createSortHandler(header.id)}
                IconComponent={UnFoldMoreIcon}
              >
                {header.label}
              </TableSortLabel>
            ) : (
              <span>{header.label}</span>
            )}
            {header.search ? (
              <Input
                onChange={createSearchHandler(header.id)}
                type="text"
                className={clsx(classes.searchBarStyle, classes.underline)}
                endAdornment={
                  <InputAdornment position="start">
                    <SearchIcon className={classes.icon} />
                  </InputAdornment>
                }
              />
            ) : (
              " "
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export { StyledTableRow, TableHeader };
