import {
  drawerWidth,
  transition,
  container,
  defaultFont,
} from "./BaseStyles";

const appStyle = (theme) => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
  },
  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch",
    background: "#eeeeee",
    height: "100%",
    ...defaultFont,
    padding: "40px 30px",
  },
  container,
});

export default appStyle;
