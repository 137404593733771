import * as React from "react";

function SvgTripPlanningActive(props) {
  return (
    <svg
      id="trip_planning_active_svg__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      {...props}
    >
      <defs>
        <style>{".trip_planning_active_svg__cls-1{fill:#f74e10}"}</style>
      </defs>
      <path
        className="trip_planning_active_svg__cls-1"
        d="M18.86 16.14h-6.79a1.27 1.27 0 000 2.54h6.79a1.27 1.27 0 000-2.54zM18.86 22.92h-6.79a1.28 1.28 0 000 2.55h6.79a1.28 1.28 0 000-2.55zM18.86 29.71h-6.79a1.27 1.27 0 000 2.54h6.79a1.27 1.27 0 000-2.54z"
      />
      <path
        className="trip_planning_active_svg__cls-1"
        d="M37.09 27.16V15.54a6.27 6.27 0 00-1.82-4.45l-5.16-5.34a6.37 6.37 0 00-4.53-1.9H9.19a3.82 3.82 0 00-3.82 3.8v33a3.83 3.83 0 003.82 3.82h19.66a10.11 10.11 0 108.24-17.31zM7.92 40.65v-33a1.27 1.27 0 011.27-1.27h16.38a3.88 3.88 0 012.72 1.14l5.16 5.34a3.75 3.75 0 011.1 2.67v11.63a10.09 10.09 0 00-7.63 14.76H9.19a1.27 1.27 0 01-1.27-1.27zm27.9 4.07a7.55 7.55 0 117.64-7.55 7.56 7.56 0 01-7.64 7.55z"
      />
      <path
        className="trip_planning_active_svg__cls-1"
        d="M38.76 34.16a1.28 1.28 0 00-1.8.16l-2.14 2.58-.49-1a1.27 1.27 0 10-2.28 1.14l1.36 2.72a1.28 1.28 0 001 .69h.15a1.3 1.3 0 001-.46L38.92 36a1.27 1.27 0 00-.16-1.79zM25.64 18.68H27a1.27 1.27 0 000-2.54h-1.36a1.27 1.27 0 000 2.54zM28.27 24.19A1.27 1.27 0 0027 22.92h-1.36a1.28 1.28 0 000 2.55H27a1.27 1.27 0 001.27-1.28z"
      />
    </svg>
  );
}

export default SvgTripPlanningActive;
