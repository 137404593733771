import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { TableHeader, StyledTableRow } from "../Base/TableHeader";
import { getComparator, sortArray } from "../../helpers/sorting";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import PublishRoundedIcon from "@material-ui/icons/PublishRounded";
import { MoreButton, SuccessButton } from "../Button/Button";
import TripCreationStyle from "../../assets/css/TripCreationStyle";
import axiosRequest from "../../helpers/axios";
import Link from "@material-ui/core/Link";
import TripStoreDetailDialog from "../Base/TripStoreDetailDialogue";
import SnackBarComponent from "../../helpers/SnackBarComponent";
import StoreDetailTable from "../Base/StoreDetailTable";
import exportToCsv from "../../helpers/exportToCsv";
import { Input } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TablePagination from "@material-ui/core/TablePagination";

const headers = [
    {
        id: "fms_location_name",
        sortable: true,
        align: "center",
        label: "FMS Location",
      },
      {
        id: "trip_created_time",
        sortable: true,
        align: "center",
        label: "Trip Date",
      },
      {
        id: "trip_id",
        sortable: true,
        align: "center",
        label: "Trip ID",
      },
      {
        id: "trip_status",
        sortable: true,
        align: "center",
        label: "Trip Status",
      },
      {
        id: "approval_status",
        sortable: true,
        align: "center",
        label: "Approval Status",
      },
      {
        id: "store_drops",
        sortable: true,
        align: "center",
        label: "Store Drops",
      },
      {
        id: "vehicle_num",
        sortable: true,
        align: "center",
        label: "Vehicle Number",
      },
      {
        id: "trip_dist_fms_est",
        sortable: true,
        align: "center",
        label: "Trip Distance (FMS)",
      },
      {
        id: "trip_dist_dc_input",
        sortable: true,
        align: "center",
        label: "Trip Distance (DC)",
      },
      {
        id: "Locations_Serviced",
        sortable: false,
        align: "center",
        label: "Locations Serviced",
      },
      {
        id: "bol_value",
        sortable: true,
        align: "center",
        label: "BOL Value",
      },
      {
        id: "tot_crate_value",
        sortable: true,
        align: "center",
        label: "Total Crate Value",
      },
      {
        id: "vendor_name",
        sortable: true,
        align: "center",
        label: "Vendor",
      },
    
      {
        id: "contract_type",
        sortable: true,
        align: "center",
        label: "Contract Type",
      },
      {
        id: "trip_wt",
        sortable: true,
        align: "center",
        label: "Trip Weight (Kgs)",
      },
      {
        id: "payload_in_mt",
        sortable: true,
        align: "center",
        label: "Payload",
      },
      {
        id: "freight_cost_fms",
        sortable: true,
        align: "center",
        label: "Trip Cost (FMS estimate)",
      },
    
      {
        id: "freight_cost_dc_input",
        sortable: true,
        align: "center",
        label: "Trip Cost (DC Input)",
      },
      {
        id: "ntm_trip_cost",
        sortable: false,
        align: "center",
        label: "NTM Trip Cost",
      },
      {
        id: "toll_charges",
        sortable: false,
        align: "center",
        label: "Toll Charges",
      },
      {
        id: "halting_charges",
        sortable: false,
        align: "center",
        label: "Halting Charges",
      },
      {
        id: "other_charges",
        sortable: false,
        align: "center",
        label: "Other Charges",
      },
      {
        id: "damage_charges",
        sortable: false,
        align: "center",
        label: "Other Charges",
      },
      {
        id: "total_number_of_crates_dc_input",
        sortable: false,
        align: "center",
        label: "Lpn Count",
      },
];

const useStyles = makeStyles(TripCreationStyle);
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      width: 300,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};


const names = [
"Pending NTM",
"NTM Approved",
];
export function CostingHeader(props) {
  const { downloadReport,handleStartDate,handleEndDate,handleConfirm,startDate,endDate,LocationOptions,fmsLocations,setFmsLocations} = props;
  const classes = useStyles();

  

  const isAllSelected =
    LocationOptions.length > 0 &&
    fmsLocations.length === LocationOptions.length;

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      var fmsLocationsId = LocationOptions.map(
        (fmsLocation) => fmsLocation.fms_location_id
      );
      setFmsLocations(
        fmsLocations.length === LocationOptions.length ? [] : fmsLocationsId
      );
    } else {
      setFmsLocations(value);
    }
  };


  return (
    <React.Fragment>
      <div className="flex flex-column justify-between pa3">
        <Typography variant="body2">Costing</Typography>
        <div className="flex pb3">
          {/* <div className="flex flex-column"> */}
          <span className={clsx(classes.fs12, "mr3")}>Start Date:</span>
          <Input
            type="date"
            label="Start Date"
            defaultValue=""
            className={clsx(classes.bgInputGray, classes.underline,"mr3")}
            onChange={handleStartDate}
          />
          <span className={clsx(classes.fs12, "mr3")}>End Date:</span>
          <Input
            type="date"
            label="End Date"
            defaultValue=""
            className={clsx(classes.bgInputGray, classes.underline,"mr3")}
            onChange={handleEndDate}
          />
         <span className={clsx(classes.fs12, "mr3")}>
            Selection of locations
          </span>
          <Select
            multiple
            value={fmsLocations}
            onChange={handleChange}
            className={clsx(
              classes.inputDailySelect,
              classes.underline,
              classes.fs12,
              classes.bgInputGray
            )}
            renderValue={(fmsLocations) =>
              fmsLocations.length + " Locations selected"
            }
            MenuProps={MenuProps}
          >
            <MenuItem
              value="all"
              classes={{
                root: isAllSelected ? classes.selectedAll : "",
              }}
            >
              <ListItemIcon>
                <Checkbox
                  classes={{ indeterminate: classes.indeterminateColor }}
                  checked={isAllSelected}
                  indeterminate={
                    fmsLocations.length > 0 &&
                    fmsLocations.length < LocationOptions.length
                  }
                />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.selectAllText }}
                primary="Select All Locations"
              />
            </MenuItem>
            {LocationOptions.map((fmsLocation, index) => (
              <MenuItem key={index} value={fmsLocation.fms_location_id}>
                <ListItemIcon>
                  <Checkbox
                    checked={
                      fmsLocations.indexOf(fmsLocation.fms_location_id) > -1
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primary={fmsLocation.fms_location_name}
                  classes={{ primary: classes.selectAllText }}
                />
              </MenuItem>
            ))}
          </Select>
           <MoreButton
            variant="contained"
            disableElevation
            onClick={() => handleConfirm(startDate, endDate,fmsLocations)}
            className={classes.ml30}
          >
            <Typography className={clsx(classes.fs12, "ttn")}>
              Generate Costing Summary
            </Typography>
          </MoreButton>
          <MoreButton
          variant="contained"
          disableElevation
          className={clsx(classes.button,"pl4")}
          startIcon={<GetAppRoundedIcon fontSize="small" />}
          onClick={downloadReport}
        >
          <Typography className={clsx(classes.fs12, "ttn")}>
            Download
          </Typography>
        </MoreButton>
        </div>
       
          {/* <Button
            variant="contained"
            disableElevation
            className={classes.button}
            onClick={() => costBasedOnProperty("freight_cost_fms")}
          >
            <Typography className={clsx(classes.fs12, "ttn")}>
              I/P Cost Based on FMS Estimate
            </Typography>
          </Button>
          <Button
            variant="contained"
            disableElevation
            className={classes.button}
            onClick={() => costBasedOnProperty("freight_cost_dc_input")}
          >
            <Typography className={clsx(classes.fs12, "ttn")}>
              I/P Cost Based on DC Input
            </Typography>
          </Button> */}
          
        </div>
    </React.Fragment>
  );
}

export default function Tripdc(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("trip_id");
  const [tableData, setTableData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [storeDetails, setStoreDetails] = React.useState([]);
  const [severity, setSeverity] = React.useState("");
  const [content, setContent] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);
  const [startDate,setStartDate]=React.useState("");
  const [endDate,setEndDate]=React.useState("")
  const [isSummaryGenerated, setIsSummaryGenerated] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [fmsLocationid, setFmsLocationid] = React.useState([]);
  const [LocationOptions,setLocationOptions]=React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, tableData.length - page * rowsPerPage);

  let fmsLocationID = [];
  const handleClose = () => {
    setOpensb(false);
  };

  const handleStartDate = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDate = (event) => {
    setEndDate(event.target.value);
  };

  const handleConfirm = (startDate, endDate) => {
    if (startDate && endDate) {
      endDate = new Date(endDate);
      endDate.setDate(endDate.getDate() + 1);
      endDate = formatDate(endDate);
      getCostingData(startDate,endDate)
      // getDateLevelSummary(startDate, endDate);
      // getDateVendorLevelSummary(startDate, endDate);
      setIsSummaryGenerated(true);
    } else {
      handleSnackBar("Select Start & End date", "error");
    }
  };

  const getLocationDetailData = async (trip_id) => {
    await axiosRequest({
      url: `bolNoVerification/locationStoreDetail?trip_id=${trip_id}`,
      method: "GET"
    }).then((data) => {
      if (data.status === 200) {
        setStoreDetails(data.data);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const DialogOpen = (row) => {
    setStoreDetails([])
    getLocationDetailData(row.trip_id);
    setOpen(true);
  };

  const formatDate = (date) => {
    var d = new Date(date);
    var month = "" + (d.getMonth() + 1);
    var day = "" + d.getDate();
    var year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const DialogClose = () => {
    setOpen(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

 



  const downloadReport = () => {
    let csvData = []
    let title = [
        "fms_location_name",
        "trip_created_time",
        "trip_id",
        "trip_status",
        "approval_status",
        "store_drops",
        "vehicle_num",
        "trip_dist_fms_est",
        "trip_dist_dc_input",
        "bol_value",
        "tot_crate_value",
        "vendor_name",
        "contract_type",
        "trip_wt",
        "payload_in_mt",
        "toll_charges",
        "halting_charges",
        "other_charges",
        "damage_charges",
        "total_number_of_crates_dc_input",
        "freight_cost_fms",
        "freight_cost_dc_input",
        "ntm_trip_cost",
        "is_combi_trip",
      ];
      let rows = tableData.map((x) => [
        x[title[0]],
        formatDate(x[title[1]]),
        x[title[2]],
        x[title[3]],
        x[title[4]],
        x[title[5]],
        x[title[6]],
        x[title[7]],
        x[title[8]],
        x[title[9]],
        x[title[10]],
        x[title[11]],
        x[title[12]],
        x[title[13]],
        x[title[14]],
        x[title[15]],
        x[title[16]],
        x[title[17]],
        x[title[18]],
        x[title[19]],
        x[title[20]],
        x[title[21]],
        x[title[22]],
        x[title[23]] === null ? false : x[title[23]],
      ]);
  
    csvData = [title, ...rows];
    exportToCsv("DC level trip report on " + new Date() + ".csv", csvData);
    handleSnackBar("Downloaded Successfully", "success");
  };

  const getCostingData = async () => {
    await axiosRequest({
      url: "bolNoVerification/dcleveltrips/",
      method: "POST",
      data: {
        startDate: startDate,
        endDate: endDate,
        fmsLocationId:fmsLocationid,
      },
    }).then((data) => {
      if (data.status === 200) {
        setTableData(data.data);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };
  
  const getLocationData = async () => {
    await axiosRequest({
      url: "master/getlocations/",
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        setLocationOptions(data.data);
        console.log(LocationOptions)
        for (let i in data.data) {
          fmsLocationID.push(data.data[i]["fms_location_id"]);
        }
        console.log(fmsLocationID);
        setFmsLocationid(fmsLocationID);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };
 
  React.useEffect(() => {
    setRows(tableData);
  }, [tableData]);

  React.useEffect(() => {
    getLocationData();
  }, []);

 
  const handleAllSearch = (event, property) => {
    const searchResult = tableData.filter((row) => {
      return row[property]
        .toString()
        .toLowerCase()
        .includes(event.target.value.toLowerCase());
    });
    setRows(searchResult);
  };
  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };
  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <CostingHeader
          downloadReport={downloadReport}
          handleStartDate={handleStartDate}
          handleEndDate={handleEndDate}
          handleConfirm={handleConfirm}
          startDate={startDate} endDate={endDate}
          LocationOptions={LocationOptions}
          fmsLocations={fmsLocationid}
          setFmsLocations={setFmsLocationid}
        />
        <TableContainer className={classes.costingContainer}>
          <Table stickyHeader>
            <TableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={tableData.length}
              headers={headers}
              handleSearch={handleAllSearch}
              isCheckboxEnabled={false}
            />
            <TableBody>
              {sortArray(rows, getComparator(order, orderBy)).map(
                (row, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <TableCell align="center" className={classes.fs12}>
                        {row.fms_location_name}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_created_time.substr(0, 10)}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_id}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_status}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.approval_status}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.store_drops}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.vehicle_num}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_dist_fms_est}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_dist_dc_input}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        <Link
                          component="button"
                          variant="body2"
                          className={clsx(classes.fs12, classes.textColor)}
                          onClick={() => DialogOpen(row)}
                        >
                          {"View Stores"}
                        </Link>
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        &#8377; {Number(row.bol_value).toFixed(2)}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.tot_crate_value}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.vendor_name}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.contract_type}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_wt}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.payload_in_mt}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        &#8377; {Number(row.freight_cost_fms).toFixed(2)}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        &#8377; {Number(row.freight_cost_dc_input).toFixed(2)}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        &#8377; {Number(row.ntm_trip_cost).toFixed(2)}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.toll_charges}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.halting_charges}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.other_charges}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.damage_charges}
                      </TableCell>
                       <TableCell align="center" className={classes.fs12}>
                        {row.total_number_of_crates_dc_input}
                      </TableCell>
                    </StyledTableRow>
                  );
                }
              )}
               {emptyRows > 0 && (
                            <StyledTableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={6} />
                            </StyledTableRow>
                           )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                            rowsPerPageOptions={[50, 100, 250]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
      </Grid>
      <TripStoreDetailDialog
        DialogClose={DialogClose}
        open={open}
        storeDetails={storeDetails}
      />
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </Grid>
  );
}
