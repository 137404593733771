import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import clsx from "clsx";
import { Typography } from "@material-ui/core";
import DateLevelSummary from "./DateLevelSummary";
import DateVendorSummary from "./DateVendorSummary";
import Grid from "@material-ui/core/Grid";
import { moreColor } from "../../../assets/css/BaseStyles";
import { MoreButton } from "../../Button/Button";
import Divider from "@material-ui/core/Divider";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import exportToCsv from "../../../helpers/exportToCsv";
import SnackBarComponent from "../../../helpers/SnackBarComponent";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel">
      {value === index && (
        <Paper elevation={0} square>
          {children}
        </Paper>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  fs12: {
    fontSize: "12px",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  selectedTab: {
    color: moreColor[0],
  },
  tab: {
    textTransform: "none",
    fontSize: "12px",
  },
}));

export default function Results(props) {
  const classes = useStyles();
  const { dateLevelSummary, dateVendorLevelSummary } = props;
  const [value, setValue] = React.useState(0);
  const [severity, setSeverity] = React.useState("");
  const [content, setContent] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);

  const handleClose = () => {
    setOpensb(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };

  const download = () => {
    if (value === 0) {
      let csvData = [];
      let title = [
        "date",
        "fms_location_name",
        "trip_cost_fms",
        "trip_cost_dc",
        "trip_cost_ntm",
      ];
      let rows = dateLevelSummary.map((x) => [
        x[title[0]],
        x[title[1]],
        x[title[2]],
        x[title[3]],
        x[title[4]],
      ]);
      csvData = [title, ...rows];
      exportToCsv("Daily Summary Report on " + new Date() + ".csv", csvData);
      handleSnackBar("Downloaded Successfully", "success");
    } else {
      let csvData = [];
      let title = [
        "date",
        "fms_location_name",
        "vendor_name",
        "trip_cost_fms",
        "trip_cost_dc",
        "trip_cost_ntm",
      ];
      let rows = dateVendorLevelSummary.map((x) => [
        x[title[0]],
        x[title[1]],
        x[title[2]],
        x[title[3]],
        x[title[4]],
        x[title[5]],
      ]);
      csvData = [title, ...rows];
      exportToCsv(
        "Daily Vendor Level Summary Report on " + new Date() + ".csv",
        csvData
      );
      handleSnackBar("Downloaded Successfully", "success");
    }
  };

  return (
    <Paper elevation={0} square>
      <Divider />
      <Grid container justify="space-between">
        <Grid item>
          <Tabs
            value={value}
            onChange={handleChange}
            classes={{ root: classes.ttn }}
          >
            <Tab
              classes={{ selected: classes.selectedTab, root: classes.tab }}
              label="Date Level Summary"
            />
            <Tab
              classes={{ selected: classes.selectedTab, root: classes.tab }}
              label="Date Vendor level summary"
            />
          </Tabs>
        </Grid>
        <Grid item className="self-center mr3">
          <MoreButton
            className={clsx(classes.button, "mr3")}
            variant="contained"
            disableElevation
            onClick={download}
            startIcon={<GetAppRoundedIcon fontSize="small" />}
          >
            <Typography className={clsx(classes.fs12, "ttn")}>
              Download
            </Typography>
          </MoreButton>
        </Grid>
      </Grid>
      <TabPanel value={value} index={0}>
        <DateLevelSummary DateLevel={dateLevelSummary} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DateVendorSummary DateVendor={dateVendorLevelSummary} />
      </TabPanel>
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </Paper>
  );
}
