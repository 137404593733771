import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Checkbox from "@material-ui/core/Checkbox";
import { TableHeader, StyledTableRow } from "../../../Base/TableHeader";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";
import Typography from "@material-ui/core/Typography";
import { getComparator, sortArray } from "../../../../helpers/sorting";
import TripCreationStyle from "../../../../assets/css/TripCreationStyle";
import axiosRequest from "../../../../helpers/axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackBarComponent from "../../../../helpers/SnackBarComponent";
import { getLocationId } from "../../../../helpers/RouteValidation"

const headCells = [
  {
    id: "fms_location_name",
    label: "Store Name",
    sortable: true,
    align: "center",
    search: true,
  },
  {
    id: "fms_location_id",
    label: "Store ID",
    sortable: true,
    align: "center",
    search: true,
  },
];

const useStyles = makeStyles(TripCreationStyle);

export default function StoreTable(props) {
  const classes = useStyles();
  const { selectedStores, handleSelectedStores } = props;
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("fms_location_id");
  const [selectedOrder, setSelectedOrder] = React.useState("desc");
  const [selectedOrderBy, setSelectedOrderBy] =
    React.useState("fms_location_id");
  const [rows, setRows] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [allStores, setAllStores] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [severity, setSeverity] = React.useState("");
  const [content, setContent] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);

  const handleClose = () => {
    setOpensb(false);
  };

  React.useEffect(() => {
    setSelectedRows(selectedStores);
  }, [selectedStores]);

  React.useEffect(() => {
    getStoresList();
  }, []);

  const handleAllSearch = (event, property) => {
    const searchResult = allStores.filter((row) => {
      return row[property]
        .toString()
        .toLowerCase()
        .includes(event.target.value.toLowerCase());
    });
    setRows(searchResult);
  };

  const handleSelectedSearch = (event, property) => {
    const selectedSearchResult = selectedStores.filter((row) => {
      return row[property]
        .toString()
        .toLowerCase()
        .includes(event.target.value.toLowerCase());
    });
    setSelectedRows(selectedSearchResult);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectedRequestSort = (event, property) => {
    const isAsc = selectedOrderBy === property && selectedOrder === "asc";
    setSelectedOrder(isAsc ? "desc" : "asc");
    setSelectedOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n);
      handleSelectedStores(newSelecteds);
      return;
    }
    handleSelectedStores([]);
  };

  const handleClick = (event, row) => {
    const selectedIndex = selectedStores.findIndex(
      (obj) => obj.fms_location_id === row.fms_location_id
    );

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedStores, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedStores.slice(1));
    } else if (selectedIndex === selectedStores.length - 1) {
      newSelected = newSelected.concat(selectedStores.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedStores.slice(0, selectedIndex),
        selectedStores.slice(selectedIndex + 1)
      );
    }
    handleSelectedStores(newSelected);
  };

  const isSelected = (row) => {
    var selectedIndex = selectedStores.findIndex(
      (obj) => obj.fms_location_id === row.fms_location_id
    );
    return selectedIndex !== -1 ? true : false;
  };

  const getStoresList = async () => {
    setLoader(true);
    let fmsLocationId = getLocationId();
    await axiosRequest({
      url: "bolNoVerification/getStoreList/" + fmsLocationId,
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        setAllStores(data.data);
        setRows(data.data);
      } else {
        handleSnackBar(data, "error");
      }
    });
    setLoader(false);
  };

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };

  return (
    <React.Fragment>
      {loader === true ? (
        <Grid className={classes.loader}>
          <CircularProgress disableShrink status="loading" />
        </Grid>
      ) : (
        <>
          <Grid container spacing={4} justify="center">
            <Grid item xs={12} sm={12} md={6} lg={5} xl={6}>
              <Grid container alignItems="center">
                <Typography variant="body2">Selected Stores</Typography>
                <Badge
                  badgeContent={selectedStores.length}
                  color="primary"
                  className="ml4"
                ></Badge>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Grid container alignItems="center">
                <Typography variant="body2">All Stores</Typography>
                <Badge
                  badgeContent={rows.length}
                  color="primary"
                  className="ml4"
                ></Badge>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={4} justify="center">
            <Grid item xs={12} sm={12} md={6} lg={5} xl={6}>
              <TableContainer className={classes.container}>
                <Table stickyHeader>
                  <TableHeader
                    numSelected={selectedStores.length}
                    order={selectedOrder}
                    orderBy={selectedOrderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleSelectedRequestSort}
                    rowCount={rows.length}
                    headers={headCells}
                    isCheckboxEnabled={false}
                    handleSearch={handleSelectedSearch}
                  />
                  <TableBody>
                    {sortArray(
                      selectedRows,
                      getComparator(selectedOrder, selectedOrderBy)
                    ).map((row, index) => {
                      return (
                        <StyledTableRow key={index}>
                          <TableCell className={classes.fs12}>
                            {row.fms_location_name}
                          </TableCell>
                          <TableCell className={classes.fs12} align="right">
                            {row.fms_location_id}
                          </TableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TableContainer className={classes.container}>
                <Table stickyHeader>
                  <TableHeader
                    numSelected={selectedStores.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                    headers={headCells}
                    isCheckboxEnabled={true}
                    handleSearch={handleAllSearch}
                  />
                  <TableBody>
                    {sortArray(rows, getComparator(order, orderBy)).map(
                      (row, index) => {
                        const isItemSelected = isSelected(row);
                        return (
                          <StyledTableRow
                            onClick={(event) => handleClick(event, row)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={index}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox checked={isItemSelected} />
                            </TableCell>
                            <TableCell className={classes.fs12}>
                              {row.fms_location_name}
                            </TableCell>
                            <TableCell className={classes.fs12} align="right">
                              {row.fms_location_id}
                            </TableCell>
                          </StyledTableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </>
      )}
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </React.Fragment>
  );
}
