import React, { useState } from "react";
import { TextField, Button } from "@material-ui/core";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import { InputAdornment } from "@material-ui/core";
import VpnKeyOutlinedIcon from "@material-ui/icons/VpnKeyOutlined";
import { Grid } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import LoginImg from "../../../src/assets/images/LoginBG.png";
import MoreLogo from "../../../src/assets/images/MoreLogo.png";
import "./Login.css";
import axiosRequest from "../../helpers/axios";
import SnackBarComponent from "../../helpers/SnackBarComponent";
import cookie from "react-cookies";
import { Redirect } from "react-router";
import { checkUserDetails } from "../../helpers/RouteValidation";

export default function Login() {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [severity, setSeverity] = React.useState("success");
  const [content, setContent] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);
  const [userLogged, setUserLogged] = React.useState(false);

  const LoginFun = async () => {
    if (userName !== "" && password !== "") {
      await axiosRequest({
        url: "user/login",
        method: "POST",
        data: {
          username: userName,
          password: password,
        },
      }).then((data) => {
        if (data.status === 200) {
          handleSnackBar(data.data.detail, "success");
          cookie.save("user", data.data.user, { path: "/" });
          setUserLogged(true);
        } else {
          handleSnackBar(data, "error");
        }
      });
    } else {
      handleSnackBar("Please check Username & Password", "error");
    }
  };

  const handleClose = () => {
    setOpensb(false);
  };

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };

  const handleUserName = (event) => {
    setUserName(event.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(showPassword ? false : true);
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  React.useEffect(() => {
    var user = checkUserDetails();
    if (user) {
      setUserLogged(true);
    }
  }, []);

  if (userLogged) {
    return <Redirect to="/fms/dashboard" />;
  }

  return (
    <div>
      <Grid
        container
        style={{ minHeight: "80vh", objectFit: "fill", justify: "center" }}
      >
        <Grid className="pdl-200" item xs={12} sm={6}>
          <Grid className="logo">
            <img
              src={MoreLogo}
              style={{ width: "30%", height: "10%", objectFit: "fill" }}
              alt="logo"
            />
          </Grid>
          <span>
            <h1 className="text">
              Freight Management<br></br>
              System
            </h1>
            <p className="content">
              Manage all your BOL Nos,Trips,eWaybills<br></br>
              and lot more,login to get started
            </p>
          </span>

          <img
            src={LoginImg}
            style={{
              width: "320%",
              height: "55%",
              objectFit: "cover",
              paddingLeft: "120px",
              paddingTop: "20px",
            }}
            alt="brand"
          />
        </Grid>

        <Grid className="pdt-150" item xs={12} sm={6}>
          <Grid className="bd-10">
            <Grid className="bl-5px">Login</Grid>
            <Grid className="wi-400">
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
                id="userName"
                type="text"
                onChange={handleUserName}
                variant="outlined"
                placeholder=" User Name*"
                fullWidth
              />
            </Grid>
            <Grid className="wi-400">
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <VpnKeyOutlinedIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={togglePasswordVisibility}
                    >
                      {" "}
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </InputAdornment>
                  ),
                }}
                id="password"
                onChange={handlePassword}
                type={showPassword ? "text" : "password"}
                variant="outlined"
                placeholder=" Password*"
                fullWidth
              />
            </Grid>
            <Grid className="button">
              <Button
                onClick={LoginFun}
                style={{ backgroundColor: "orangered" }}
                size="medium"
                variant="contained"
                color="primary"
              >
                Sign In
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </div>
  );
}
