import { Toolbar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import background from "../../assets/images/Dashboard.png";
// import { CircularProgressbar } from "react-circular-progressbar";
// import "react-circular-progressbar/dist/styles.css";

const useStyles = makeStyles((theme) => ({
  tagline: {
    fontSize: 12,
  },
  root: {
    flexGrow: 1,
  },
  cover: {
    backgroundImage: `url(${background})`,
    backgroundPosition: "center",
    height: "11.2vw",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 10,
  },
}));

export default function RightCard() {
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <Card className={classes.cover}>
        <Toolbar>
          <div style={{ width: 160, height: 160 }} className={classes.details}>
            {/* <CircularProgressbar value={26} text={`${26}`} /> */}
            <CardContent>
              <Typography className={classes.tagline}>
                Total Number of Vehicles
              </Typography>
            </CardContent>
          </div>
          <CardContent className={classes.ContentDetails}>
            <Typography className={classes.tagline}>
              4 Vehicles Available
            </Typography>
            <Typography className={classes.tagline}>
              22 Vehicles on Trip
            </Typography>
          </CardContent>
        </Toolbar>
      </Card>
    </Grid>
  );
}
