// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";

// Components / Views
import Home from "./pages/Dashboard/Dashboard";
import TripCreation from "./pages/TripCreation/TripCreation";
import Costing from "./pages/Costing/Costing";
import PTL from "./pages/PTL/PTL";
import TripConfirmation from "./pages/TripConfirmation/TripConfirmation";
import { ReactComponent as DashboardInactive } from "./assets/icons/Left Menu/dashboard_inactive1.svg";
import { ReactComponent as DashboardActive } from "./assets/icons/Left Menu/dashboard_active1.svg";
import { ReactComponent as TripCreationActive } from "./assets/icons/Left Menu/tripcreation_active.svg";
import { ReactComponent as TripCreationInactive } from "./assets/icons/Left Menu/tripcreation_inactive.svg";
import { ReactComponent as PTLInactive } from "./assets/icons/Left Menu/PTL_inactive.svg";
import { ReactComponent as PTLActive } from "./assets/icons/Left Menu/PTL_active.svg";
import NTM from "./pages/Summary/NTM";
import Master from "./pages/Master/Master";
import TripSummary from "./pages/TripSummary/TripSummary";
import TripStatus from "./pages/TripStatus/TripStatus";

const dashboardRoutes = [{
        path: "/dashboard",
        name: "Dashboard",
        component: Home,
        layout: "/fms",
        activeIcon: DashboardActive,
        inactiveIcon: DashboardInactive,
    },
    {
        path: "/tripCreation",
        name: "Trip Creation",
        component: TripCreation,
        layout: "/fms",
        activeIcon: TripCreationActive,
        inactiveIcon: TripCreationInactive,
    },
    {
        path: "/master",
        name: "Master",
        component: Master,
        layout: "/fms",
        activeIcon: PTLActive,
        inactiveIcon: PTLInactive,
    },
    {
        path: "/tripConfirmation",
        name: "Trip Confirmation",
        component: TripConfirmation,
        layout: "/fms",
        activeIcon: TripCreationActive,
        inactiveIcon: TripCreationInactive,
    },
    {
        path: "/costing",
        name: "Costing",
        component: Costing,
        layout: "/fms",
        activeIcon: TripCreationActive,
        inactiveIcon: TripCreationInactive,
    },
    {
        path: "/transporationCommercials",
        name: "Transportation Commercials",
        component: NTM,
        layout: "/fms",
        activeIcon: DashboardActive,
        inactiveIcon: Dashboard,
    },
    {
        path: "/tripSummary",
        name: "Trip Summary",
        component: TripSummary,
        layout: "/fms",
        activeIcon: TripCreationActive,
        inactiveIcon: TripCreationActive,
    },
    {
        path: "/ptl",
        name: "PTL",
        component: PTL,
        layout: "/fms",
        activeIcon: PTLActive,
        inactiveIcon: PTLInactive,
    },
    {

    path: "/tripStatus",
    name: "Trip Status",
    component: TripStatus,
    layout: "/fms",
    activeIcon: TripCreationActive,
    inactiveIcon: TripCreationActive,
  },
];

export default dashboardRoutes;