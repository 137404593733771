import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Normal from "../../Typography/Normal";
import Input from "@material-ui/core/Input";
import CloseIcon from "@material-ui/icons/Close";
import TripCreationStyle from "../../../assets/css/TripCreationStyle";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { Button, DialogActions } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { SuccessButton } from "../../Button/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import axiosRequest from "../../../helpers/axios";
import SnackBarComponent from "../../../helpers/SnackBarComponent";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getLocationId, getLocationName } from "../../../helpers/RouteValidation";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(TripCreationStyle);
const useStyles1 = makeStyles((theme) => ({
  root: {
    "& .MuiFormLabel-root": {
      fontSize: "12px",
      lineHeight: 0,
    },
  },
}));
export default function AddOriginDestination(props) {
  const classes = useStyles();
  const classes1 = useStyles1();
  
  const { open, DialogClose } = props;
  const [originid, setOriginId] = React.useState([]);
  const [origin,setOrigin] = React.useState("")
  const [storecode,setStoreCode] = React.useState("")
  const [originname, setOriginName] = React.useState("");
  const [destinationid, setDestinationID] = React.useState([]);
  const [destination,setDestination] = React.useState("")
  const [destinationname, setDestinationName] = React.useState("");
  const [dslstart, setDslStart] = React.useState("");
  const [dslend, setDslEnd] = React.useState("");
  const [routingflag, setRoutingFlag] = React.useState("");
  const [unloadingtime, setUnloadingTime] = React.useState("");
  const [tag, setTag] = React.useState("");
  const [cityzone, setCityZone] = React.useState("");
  const [dcleavingtime, setDcLeavingTime] = React.useState("");
  const [returntodctime, setReturnToDcTime] = React.useState("");
  const [highestcapacityvehicle, setHighestCapacityVehicle] =
    React.useState("");
  const [originData,setOriginData] = React.useState([])
  const [destinationData,setDestinationData] = React.useState([])
  const [status,setStatus] =React.useState("")
  const [content, setContent] = React.useState("");
  const [severity,setSeverity]=React.useState("")
  const [opensb, setOpensb] = React.useState(false);
  const [loader,setLoader] = React.useState(false);
  const handleClose = () => {
    setOpensb(false);
  };
  let Locationid=getLocationId()
  let LocationName=getLocationName()
  let originID=[];
  let originName
  let destinationID=[];
  let destinationName
  const handleOriginid = (event,value) => {
    setOrigin(value);
    if(value == ""){
      setOriginName("")
    }else{
      for(let i in originData){
        if(originData[i]["fms_location_id"]==value){
          originName=originData[i]["fms_location_name"]
        }
        setOriginName(originName)
      }
    }
  };

  const handleStoreCode=(event)=>{
     setStoreCode(event.target.value)
  }

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };

  const handleDestinationID = (event,value) => {
    setDestination(value);
    if(value == ""){
      setDestinationName("")
    }else{
      for(let i in destinationData){
        if(destinationData[i]["fms_location_id"]==value){
          destinationName=destinationData[i]["fms_location_name"]
        }
        setDestinationName(destinationName)
      }
    }
  };



  const handleDslStart = (event) => {
    setDslStart(event.target.value);
  };
  const handleDslEnd = (event) => {
    setDslEnd(event.target.value);
  };

  const handleUnloadingTime = (event) => {
    setUnloadingTime(event.target.value);
  };

  const handleTag = (event) => {
    setTag(event.target.value);
  };
  const handleCityZone = (event) => {
    setCityZone(event.target.value);
  };

  const handleDcLeaving = (event) => {
    setDcLeavingTime(event.target.value);
  };
  const handleReturnToDc = (event) => {
    setReturnToDcTime(event.target.value);
  };

  const handleHighestCapacity = (event) => {
    setHighestCapacityVehicle(event.target.value);
  };

  const getOriginData = async () => {
    await axiosRequest({
      url: "master/get_origin/1/",
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        setOriginData(data.data);

        for(let i in data.data){
          originID.push(data.data[i]['fms_location_id']);
        }
        console.log(originID)
        setOriginId(originID)
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const getDestinationData = async () => {
    await axiosRequest({
      url: "master/get_origin/0/",
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        setDestinationData(data.data);
        for(let i in data.data){
          destinationID.push(data.data[i]['fms_location_id']);
        }
        console.log(destinationID)
        setDestinationID(destinationID)
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  React.useEffect(()=>{
     getOriginData()
     getDestinationData()
  },[])

  const getAddStoreData = async () => {
    await axiosRequest({
      url: "master/nsocheck/"+Number(storecode),
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
       setStatus(data.data.msg)
       console.log(data,data.data.msg,"----")
       console.log(status)
      } else {
        setStatus("")
        handleSnackBar(data.msg, "error");
      }
    });
  };

  const saveOriginDestinationDetails = async () => {
    setLoader(true)
    await axiosRequest({
      url: "master/add_origin_details/",
      method: "POST",
      data: {
        origin_id: Number(Locationid),
        dest_id:Number(destination),
        dsl_start:String("26/05/2021"+" "+dslstart+":00"),
        dsl_end:String("25/06/2021"+" "+dslend+":00"),
        unloading_time:Number(unloadingtime),
        tag: tag,
        city_zone:cityzone ,
        dc_leave_time:String("26/05/2021"+" "+dcleavingtime+":00"),
        dc_return_time:String("25/06/2021"+" "+returntodctime+":00"),
        highest_cap_veh:Number(highestcapacityvehicle)
      },
    }).then((data) => {
      if (data.status === 200) {
        setLoader(false)
        DialogClose()
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      aria-labelledby="confirmation-dialog-title"
      classes={{ paperWidthSm: classes.OriginDestinationDialog }}
    >
      <DialogTitle id="confirmation-dialog-title">
        <div className="flex justify-between items-center">
          <Normal>
            <h3 className={classes.textColor}>Add Origin Details</h3>
          </Normal>
          <IconButton>
            <CloseIcon onClick={DialogClose} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>
      <div className="flex flex-row mb3">
      <TextField
            id="outlined-basic"
            label="Store Code"
            variant="outlined"
            className={clsx(classes.inputmargin,classes1.root,"mr2")}
            onChange={handleStoreCode}

          />
          <SuccessButton onClick={getAddStoreData} variant="contained" disableElevation>
            <Typography className={clsx(classes.fs12, "ttn")}>CHECK STORE</Typography>
          </SuccessButton>
        </div>
        {status=="true"?(<React.Fragment>
          <div className="flex flex-row justify-between mb3">
         {/* <Autocomplete
            className={clsx(classes.w200p,classes1.root)}
            autoComplete
            options={originid}
            getOptionLabel={option =>option?String(option):null}
            getOptionSelected={(option, value) =>
                              option === value
                            }
            onChange={(event,value)=>handleOriginid(event,value)}
            value={origin}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Origin ID"
                variant="outlined"
                key={params}
                value={params}

              />
            )}
          /> */}
          <TextField
            id="outlined-basic"
            label="Origin Id"
            variant="outlined"
            className={clsx(classes.inputmargin,classes1.root)}
            value={Locationid}

          />
          <TextField
            id="outlined-basic"
            label="Origin Name"
            variant="outlined"
            className={clsx(classes.inputmargin,classes1.root)}
            value={LocationName}

          />
           <Autocomplete
            className={clsx(classes.w200p,classes1.root)}
            autoComplete
            options={destinationid}
            getOptionLabel={option =>String(option)}
            getOptionSelected={(option, value) =>
                              option === value
                            }
            onChange={(event,value)=>handleDestinationID(event,value)}
            value={destination}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Destination ID"
                variant="outlined"
                key={params}
                value={params}

              />
            )}
          />
          <TextField
            id="outlined-basic"
            label="Destination Name"
            className={clsx(classes.inputmargin,classes1.root)}
            variant="outlined"
            value={destinationname !== undefined ? destinationname : ""}

          />
        </div>
        <div className="flex flex-row justify-between mb3">
          <label>
            DSL_Start_Time
            <div className={classes.bgInputGray}>
              <Input
                type="time"
                onChange={handleDslStart}
                className={clsx(classes.underline, classes.fs12)}
              />
            </div>
          </label>
          <label>
            DSL_End_Time
            <div className={classes.bgInputGray}>
              <Input
                type="time"
                onChange={handleDslEnd}
                className={clsx(classes.underline, classes.fs12)}
              />
            </div>
          </label>
              {/* <p>routing flag</p>
          <RadioGroup
            aria-label="Routing_flag"
            name="Routing flag"
            value={routingflag}
            onChange={handleRoutingFlag}
            row
          >
            <FormControlLabel value="false" control={<Radio />} label="0" />
            <FormControlLabel value="true" control={<Radio />} label="1." />
          </RadioGroup> */}
           <TextField
            id="outlined-basic"
            label="Unloading time in mins"
             className={clsx(classes.inputmargin,classes1.root)}
            variant="outlined"
            onChange={handleUnloadingTime}
          />
           <select
            onChange={handleTag}
            className={clsx(classes.fs12, classes.SelectStyle, "mr2 mb4")}
          >
            <option
              value={""}
              key={-1}
              className={clsx(classes.fs12, classes.underline)}
            >
               Tag
            </option>
            <option value="Local" className={classes.fs12}>
              Local
            </option>
            <option value="Semi UPC" className={classes.fs12}>
              Semi UPC
            </option>
            <option value="UPC" className={classes.fs12}>
              UPC
            </option>
          </select>
        </div>
        <div className="flex flex-row justify-between mb3">
          <TextField
            id="outlined-basic"
            label="City Zone"
            className={clsx(classes.inputmargin,classes1.root)}
            variant="outlined"
            onChange={handleCityZone}
          />
        <label>
            DC_leaving_time
            <div className={classes.bgInputGray}>
              <Input
                type="time"
                onChange={handleDcLeaving}
                className={clsx(classes.underline, classes.fs12)}
              />
            </div>
          </label>
          <label>
            Return_to_DC_time
            <div className={classes.bgInputGray}>
              <Input
                type="time"
                onChange={handleReturnToDc}
                className={clsx(classes.underline, classes.fs12)}
              />
            </div>
          </label>
          <TextField
            id="outlined-basic"
            label="Highest capacity vehicle"
            className={clsx(classes.inputmargin,classes1.root)}
            variant="outlined"
            onChange={handleHighestCapacity}
          />
          </div>
          </React.Fragment>):("")}
      </DialogContent>
      <DialogActions>
      {loader === true ? (
            <Grid className={classes.loader}>
              <CircularProgress disableShrink status="loading" />
            </Grid>
          ) : (
        <div className="flex justify-center">
          <SuccessButton onClick={saveOriginDestinationDetails} variant="contained" disableElevation>
            <Typography className={clsx(classes.fs12, "ttn")}>Save</Typography>
          </SuccessButton>
        </div>)}
      </DialogActions>
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </Dialog>
  );
}
