import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Normal from "../../../Typography/Normal";
import CloseIcon from "@material-ui/icons/Close";
import BOLTable from "./BOLTable";
import Typography from "@material-ui/core/Typography";
import { SuccessButton } from "../../../Button/Button";
import TripCreationStyle from "../../../../assets/css/TripCreationStyle";

const useStyles = makeStyles(TripCreationStyle);

export default function BOLDialog(props) {
  const classes = useStyles();
  const {
    onClose,
    open,
    selectedBOL,
    handleSelectedBOL,
    setSelectedBOL,
    pendingBOL,
    storeCode,
    storeBasedBols,
  } = props;


  // React.useEffect(() => {
  //    let selected_Bol = pendingBOL.filter(x => x.trip_id !== null)
  //   console.log(selected_Bol,"--^^^----")
  //   setSelectedBOL(selected_Bol)
  // }, []);


  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    onClose(true);
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      classes={{ paperWidthSm: classes.BOLTableDialog }}
    >
      <DialogTitle id="confirmation-dialog-title">
        <div className="flex justify-between items-center">
          <Normal>
            <h3 className={classes.textColor}>Store Code : {storeCode}</h3>
          </Normal>
          <IconButton>
            <CloseIcon onClick={handleCancel} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <BOLTable
          selectedBOL={selectedBOL}
          pendingBOL={pendingBOL}
          handleSelectedBOL={handleSelectedBOL}
         
         
        />
      </DialogContent>
      <DialogActions>
        <SuccessButton variant="contained" disableElevation onClick={handleOk}>
          <Typography className={clsx(classes.fs12, "ttn")}>Save</Typography>
        </SuccessButton>
      </DialogActions>
    </Dialog>
  );
}
