import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { TableHeader, StyledTableRow } from "../Base/TableHeader";
import { getComparator, sortArray } from "../../helpers/sorting";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import PublishRoundedIcon from "@material-ui/icons/PublishRounded";
import { MoreButton, SuccessButton } from "../Button/Button";
import TripCreationStyle from "../../assets/css/TripCreationStyle";
import axiosRequest from "../../helpers/axios";
import Link from "@material-ui/core/Link";
import TripStoreDetailDialog from "../Base/TripStoreDetailDialogue";
import SnackBarComponent from "../../helpers/SnackBarComponent";
import StoreDetailTable from "../Base/StoreDetailTable";
import exportToCsv from "../../helpers/exportToCsv";
import { Input } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const headers = [
    {
        id: "ship_date",
        sortable: true,
        align: "center",
        label: "Ship Date",
      },
      {
        id: "from_loc",
        sortable: true,
        align: "center",
        label: "from Location",
      },
      {
        id: "to_loc",
        sortable: true,
        align: "center",
        label: "To Location",
      },
      {
        id: "bol_no",
        sortable: true,
        align: "center",
        label: "Bol No" 
      },
      {
        id: "process_flag",
        sortable: true,
        align: "center",
        label: "Bol Flag",
      },
];

const useStyles = makeStyles(TripCreationStyle);
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      width: 300,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};


const names = [
"Pending NTM",
"NTM Approved",
];
export function CostingHeader(props) {
  const { downloadReport,handleStartDate,handleEndDate,handleConfirm,startDate,endDate,LocationOptions,fmsLocations,setFmsLocations} = props;
  const classes = useStyles();

 

  const isAllSelected =
    LocationOptions.length > 0 &&
    fmsLocations.length === LocationOptions.length;

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      var fmsLocationsId = LocationOptions.map(
        (fmsLocation) => fmsLocation.fms_location_id
      );
      setFmsLocations(
        fmsLocations.length === LocationOptions.length ? [] : fmsLocationsId
      );
    } else {
      setFmsLocations(value);
    }
  };


  return (
    <React.Fragment>
      <div className="flex flex-column justify-between pa3">
        {/* <Typography variant="body2">Costing</Typography> */}
        <div className="flex pb3">
          {/* <div className="flex flex-column"> */}
          <span className={clsx(classes.fs12, "mr3")}>Ship Date:</span>
          <Input
            type="date"
            label="Start Date"
            defaultValue=""
            className={clsx(classes.bgInputGray, classes.underline,"mr3")}
            onChange={handleStartDate}
          />
          {/* <span className={clsx(classes.fs12, "mr3")}>End Date:</span>
          <Input
            type="date"
            label="End Date"
            defaultValue=""
            className={clsx(classes.bgInputGray, classes.underline,"mr3")}
            onChange={handleEndDate}
          /> */}
         <span className={clsx(classes.fs12, "mr3")}>
            Selection of locations
          </span>
          <Select
            multiple
            value={fmsLocations}
            onChange={handleChange}
            className={clsx(
              classes.inputDailySelect,
              classes.underline,
              classes.fs12,
              classes.bgInputGray
            )}
            renderValue={(fmsLocations) =>
              fmsLocations.length + " Locations selected"
            }
            MenuProps={MenuProps}
          >
            <MenuItem
              value="all"
              classes={{
                root: isAllSelected ? classes.selectedAll : "",
              }}
            >
              <ListItemIcon>
                <Checkbox
                  classes={{ indeterminate: classes.indeterminateColor }}
                  checked={isAllSelected}
                  indeterminate={
                    fmsLocations.length > 0 &&
                    fmsLocations.length < LocationOptions.length
                  }
                />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.selectAllText }}
                primary="Select All Locations"
              />
            </MenuItem>
            {LocationOptions.map((fmsLocation, index) => (
              <MenuItem key={index} value={fmsLocation.fms_location_id}>
                <ListItemIcon>
                  <Checkbox
                    checked={
                      fmsLocations.indexOf(fmsLocation.fms_location_id) > -1
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primary={fmsLocation.fms_location_name}
                  classes={{ primary: classes.selectAllText }}
                />
              </MenuItem>
            ))}
          </Select>
           <MoreButton
            variant="contained"
            disableElevation
            onClick={() => handleConfirm(startDate, endDate,fmsLocations)}
            className={classes.ml30}
          >
            <Typography className={clsx(classes.fs12, "ttn")}>
              Generate Costing Summary
            </Typography>
          </MoreButton>
          <MoreButton
          variant="contained"
          disableElevation
          className={classes.ml30}
          startIcon={<GetAppRoundedIcon fontSize="small" />}
          onClick={downloadReport}
        >
          <Typography className={clsx(classes.fs12, "ttn")}>
            Download
          </Typography>
        </MoreButton>
        </div>
       
          {/* <Button
            variant="contained"
            disableElevation
            className={classes.button}
            onClick={() => costBasedOnProperty("freight_cost_fms")}
          >
            <Typography className={clsx(classes.fs12, "ttn")}>
              I/P Cost Based on FMS Estimate
            </Typography>
          </Button>
          <Button
            variant="contained"
            disableElevation
            className={classes.button}
            onClick={() => costBasedOnProperty("freight_cost_dc_input")}
          >
            <Typography className={clsx(classes.fs12, "ttn")}>
              I/P Cost Based on DC Input
            </Typography>
          </Button> */}
         
        </div>
    </React.Fragment>
  );
}

export default function TosReport(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("trip_id");
  const [tableData, setTableData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [storeDetails, setStoreDetails] = React.useState([]);
  const [severity, setSeverity] = React.useState("");
  const [content, setContent] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);
  const [startDate,setStartDate]=React.useState("");
  const [endDate,setEndDate]=React.useState("")
  const [isSummaryGenerated, setIsSummaryGenerated] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [fmsLocationid, setFmsLocationid] = React.useState([]);
  const [LocationOptions,setLocationOptions]=React.useState([]);
 
  let fmsLocationID = [];
  const handleClose = () => {
    setOpensb(false);
  };

  const handleStartDate = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDate = (event) => {
    setEndDate(event.target.value);
  };

  const handleConfirm = (startDate) => {
    if (startDate ) {
    //   endDate = new Date(endDate);
    //   endDate.setDate(endDate.getDate() + 1);
    //   endDate = formatDate(endDate);
      getCostingData(startDate)
      // getDateLevelSummary(startDate, endDate);
      // getDateVendorLevelSummary(startDate, endDate);
      setIsSummaryGenerated(true);
    } else {
      handleSnackBar("Select Ship date", "error");
    }
  };

  const DialogOpen = (row) => {
    setStoreDetails(row.stores_list);
    setOpen(true);
  };

  const formatDate = (date) => {
    var d = new Date(date);
    var month = "" + (d.getMonth() + 1);
    var day = "" + d.getDate();
    var year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const DialogClose = () => {
    setOpen(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

 



  const downloadReport = () => {
    let csvData = []
    let title = [
        "ship_date",
        "from_loc",
        "to_loc",
        "bol_no",
        "process_flag",
      ];
      let rows = tableData.map((x) => [
        x[title[0]],
        x[title[1]],
        x[title[2]],
        x[title[3]],
        x[title[4]],
        x[title[5]],
      ]);
 
    csvData = [title, ...rows];
    exportToCsv("DC level trip report on " + new Date() + ".csv", csvData);
    handleSnackBar("Downloaded Successfully", "success");
  };

  const getCostingData = async () => {
    await axiosRequest({
      url: "bolNoVerification/missingbols/",
      method: "POST",
      data: {
        ship_date: startDate,
        fms_location:fmsLocationid,
      },
    }).then((data) => {
      if (data.status === 200) {
        setTableData(data.data);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };
 
  const getLocationData = async () => {
    await axiosRequest({
      url: "master/getlocations/",
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        setLocationOptions(data.data);
        console.log(LocationOptions)
        for (let i in data.data) {
          fmsLocationID.push(data.data[i]["fms_location_id"]);
        }
        console.log(fmsLocationID);
        setFmsLocationid(fmsLocationID);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };
 
  React.useEffect(() => {
    setRows(tableData);
  }, [tableData]);

  React.useEffect(() => {
    getLocationData();
  }, []);

 
  const handleAllSearch = (event, property) => {
    const searchResult = tableData.filter((row) => {
      return row[property]
        .toString()
        .toLowerCase()
        .includes(event.target.value.toLowerCase());
    });
    setRows(searchResult);
  };
  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };
  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <CostingHeader
          downloadReport={downloadReport}
          handleStartDate={handleStartDate}
          handleEndDate={handleEndDate}
          handleConfirm={handleConfirm}
          startDate={startDate} endDate={endDate}
          LocationOptions={LocationOptions}
          fmsLocations={fmsLocationid}
          setFmsLocations={setFmsLocationid}
        />
        <TableContainer className={classes.costingContainer}>
          <Table stickyHeader>
            <TableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={tableData.length}
              headers={headers}
              handleSearch={handleAllSearch}
              isCheckboxEnabled={false}
            />
            <TableBody>
              {sortArray(rows, getComparator(order, orderBy)).map(
                (row, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <TableCell align="center" className={classes.fs12}>
                        {row.ship_date}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.from_loc}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.to_loc}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.bol_no}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.process_flag}
                      </TableCell>
                    </StyledTableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <TripStoreDetailDialog
        DialogClose={DialogClose}
        open={open}
        storeDetails={storeDetails}
      />
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </Grid>
  );
}