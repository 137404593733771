import React from "react";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import BOLVerificationStepper from "../../components/TripCreation/BOLVerification/BOLVerificationStepper";
import TripInitiationStepper from "../../components/TripCreation/TripInitiation/TripInitiationStepper";
import TripCreationStepper from "../../components/TripCreation/TripCreation/TripCreationStepper";
import EWayBillPartB from "../../components/TripCreation/eWayPartB/eWayBillPartB";
import TripEndStepper from "../../components/TripCreation/TripEnd/TripEndStepper";
import StepConnector from "@material-ui/core/StepConnector";
import { moreColor } from "../../assets/css/BaseStyles";
import BOLInactive from "../../assets/icons/Trip Creation/BOL_Inactive1.png";
import BOLActive from "../../assets/icons/Trip Creation/BOL_Active1.png";
import TripInitiationActive from "../../assets/icons/Trip Creation/Initiation_Active.png";
import TripInitiationInactive from "../../assets/icons/Trip Creation/trip_initiation_inactive1.png";
import EwaybillActive from "../../assets/icons/Trip Creation/ewaybill_active.png";
import EwaybillInactive from "../../assets/icons/Trip Creation/eWaybill_inactive1.png";
import TripendActive from "../../assets/icons/Trip Creation/tripend_active1.png";
import TripendInactive from "../../assets/icons/Trip Creation/tripend_inactive1.png";
import TripCreationActive from "../../assets/icons/Trip Creation/trip_creation_active1.png";
import TripCreationInactive from "../../assets/icons/Trip Creation/trip_creation_Inactive1.png";
import { Redirect } from "react-router";
import { checkUserDetails } from "../../helpers/RouteValidation";

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 15,
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
    zIndex: 1,
    width: 30,
    height: 30,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    padding: "3px",
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const InactiveIcons = {
    1: <img src={BOLInactive} alt="" />,
    2: <img src={TripInitiationInactive} alt="" />,
    3: <img src={TripCreationInactive} alt="" />,
    4: <img src={EwaybillInactive} alt="" />,
    5: <img src={TripendInactive} alt="" />,
  };

  const ActiveIcons = {
    1: <img src={BOLActive} alt="" />,
    2: <img src={TripInitiationActive} alt="" />,
    3: <img src={TripCreationActive} alt="" />,
    4: <img src={EwaybillActive} alt="" />,
    5: <img src={TripendActive} alt="" />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {active
        ? ActiveIcons[String(props.icon)]
        : InactiveIcons[String(props.icon)]}
    </div>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    background: "none",
  },
  fs12: {
    fontSize: "12px",
  },
  active: {
    color: `${moreColor[0]} !important`,
  },
  fwn: {
    fontWeight: "lighter",
  },
}));

function getSteps() {
  return [
    "BOL NO Verification",
    "Trip Initiation",
    "Trip Creation",
    "eWayBill",
    "Trip End",
  ];
}

function StepperHeader(props) {
  const classes = useStyles();
  const { handleStep, activeStep, steps } = props;
  return (
    <Stepper
      nonLinear
      activeStep={activeStep}
      classes={{ root: classes.root }}
      connector={<ColorlibConnector />}
      alternativeLabel
    >
      {steps.map((label, index) => (
        <Step key={label}>
          <StepButton onClick={handleStep(index)}>
            <StepLabel
              StepIconComponent={ColorlibStepIcon}
              classes={{ active: classes.active }}
            >
              <Typography className={clsx(classes.fs12, classes.fwn)}>
                {label}
              </Typography>
            </StepLabel>
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );
}

export default function TripCreation() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedStores, setSelectedStores] = React.useState([]);
  const [selectedBOL, setSelectedBOL] = React.useState([]);
  const [isAuthenticated, setIsAuthenticated] = React.useState(true);

  React.useEffect(() => {
    var user = checkUserDetails();
    if (user) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleSelectedStores = (selectedStores) => {
    setSelectedStores(selectedStores);
  };

  const handleSelectedBOL = (selectedBOL) => {
    setSelectedBOL(selectedBOL);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <BOLVerificationStepper
            proceedToTripInitiation={handleNext}
            selectedStores={selectedStores}
            handleSelectedStores={handleSelectedStores}
            selectedBOL={selectedBOL}
            handleSelectedBOL={handleSelectedBOL}
          />
        );
      case 1:
        return (
          <TripInitiationStepper
            proceedToTripCreation={handleNext}
            selectedStores={selectedStores}
            selectedBOL={selectedBOL}
          />
        );
      case 2:
        return <TripCreationStepper proceedToeWayBillPartB={handleNext} />;
      case 3:
        return <EWayBillPartB proceedToTripEnd={handleNext} />;
      case 4:
        return <TripEndStepper />;
      default:
        return "Unknown step";
    }
  };

  return (
    <React.Fragment>
      {isAuthenticated ? (
        <div className={classes.root}>
          <StepperHeader
            activeStep={activeStep}
            handleStep={handleStep}
            steps={steps}
          />
          <div>{getStepContent(activeStep)}</div>
        </div>
      ) : (
        <Redirect to="/login" />
      )}
    </React.Fragment>
  );
}
