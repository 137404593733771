import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TripCreationStyle from "../../../../assets/css/TripCreationStyle";
import AddTrip from "./AddTrip";
import TripsLanding from "./TripsLanding";
import BOLDialog from "./BOLDialog";
import axiosRequest from "../../../../helpers/axios";
const useStyles = makeStyles(TripCreationStyle);

export default function Trips(props) {
  const classes = useStyles();
  const { handleNext, handleStepper, verifiedBOL, selectedStores } = props;
  const [isAddTrip, setIsAddTrip] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [pendingBOL, setPendingBOL] = React.useState([]);
  const [storeCode, setStoreCode] = React.useState(0);
  const [selectedBOL, setSelectedBOL] = React.useState([]);
  const [tempSelectedBOL, setTempSelectedBOL] = React.useState(verifiedBOL);
  const [selectedTripId, setSelectedTripId] = React.useState(0);
  const [recall, setRecall] = React.useState(false);
  const [storeBasedBols, setStoreBasedBols] = React.useState([]);
  let isStoreSelected = false;

  const handleAddTrip = (tripId) => {
    console.log("addtrip");
    setSelectedTripId(tripId);
    setIsAddTrip(true);
    setStoreBasedBols([]);
    handleStepper(true);
  };

  const selectAllBols = (row) => {
    let RemoveIndiceStore;
    let RemoveIndicesBol = [];
    let temp_bol = {};
    let isThere = false;

    for (let eachitem of storeBasedBols) {
      if (eachitem["to_loc"] === row.to_loc) {
        isThere = true;
      }
    }

    if (isThere) {
      for (let i = 0; i < storeBasedBols.length; i++) {
        if (storeBasedBols[i]["to_loc"] === row.to_loc) {
          RemoveIndiceStore = i;
        }
      }
      storeBasedBols.splice(RemoveIndiceStore, 1);
      isStoreSelected = false;
    } else {
      temp_bol["to_loc"] = row.to_loc;
      temp_bol["bol_nos"] = row.bol_no;
      temp_bol["isSaved"] = false;
      temp_bol["isEditTrip"] = false;
      setTempSelectedBOL(row.bol_no);
      storeBasedBols.push(temp_bol);
      isStoreSelected = true;
    }
  };

  const backToLanding = () => {
    setIsAddTrip(false);
    handleStepper(false);
  };

  const openDialog = () => {
    setOpen(true);
  };

  const handleTripsLandingStoreCode = (storeCode, pendingBOL) => {
    setPendingBOL(pendingBOL);
    setStoreCode(storeCode);
    openDialog();
  };

  const handleStoreCode = (storeCode, pendingBOL) => {
    console.log(selectedTripId, "handle store code");
    let temp_store_check = false;
    let isSave = false;
    let isEditTrip = false;
    let selectedBolsSaved = [];
    let selected_Bol = [];
    setPendingBOL(pendingBOL);
    setStoreCode(storeCode);
    for (let item of storeBasedBols) {
      if (item["to_loc"] === storeCode) {
        temp_store_check = true;
        selectedBolsSaved = item["bol_nos"];
        isSave = item["isSaved"];
        isEditTrip = item["isEditTrip"];
      }
    }
    console.log(temp_store_check, "checkinggg");
    if (temp_store_check) {
      if (isEditTrip) {
        selected_Bol = pendingBOL.filter((x) => x.trip_id === selectedTripId);
        setTempSelectedBOL(selected_Bol);
        closeDialog(true);
      } else {
        console.log(isSave, "handle store save value");
        if (isSave !== true) {
          setTempSelectedBOL(pendingBOL);
        } else {
          console.log(selectedBolsSaved, "handle store bols saved");
          setTempSelectedBOL(selectedBolsSaved);
        }
        temp_store_check = false;
      }
    } else {
      setTempSelectedBOL([]);
    }
    openDialog();
  };

  const closeDialog = (value) => {
    console.log("close dialog called");
    let data_bol = {};
    let tempbol = {};
    if (value) {
      tempbol["to_loc"] = storeCode;
      tempbol["bol_nos"] = tempSelectedBOL;
      for (let i = 0; i < storeBasedBols.length; i++) {
        if (storeBasedBols[i]["to_loc"] === tempbol["to_loc"]) {
          storeBasedBols[i]["bol_nos"] = tempbol["bol_nos"];
          storeBasedBols[i]["isSaved"] = true;
          storeBasedBols[i]["isEditTrip"] = false;
        }
      }
    }
    setOpen(false);
  };

  const handleSelectedBOL = (bols) => {
    console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", bols);
    setTempSelectedBOL(bols);
    for (let i = 0; i < storeBasedBols.length; i++) {
      if (storeBasedBols[i]["to_loc"] === bols["to_loc"]) {
        storeBasedBols[i]["bol_nos"] = bols;
      }
    }
    console.log(storeBasedBols, "handleselectedbol store detais");
  };

  return (
    <React.Fragment>
      {isAddTrip ? (
        <AddTrip
          backToLanding={backToLanding}
          handleStoreCode={handleStoreCode}
          verifiedStores={selectedStores}
          selectedBOL={tempSelectedBOL}
          storeBasedBols={storeBasedBols}
          pendingBOL={pendingBOL}
          tripId={selectedTripId}
          isAddTrip={isAddTrip}
          selectAllBols={selectAllBols}
          setStoreBasedBols={setStoreBasedBols}
        />
      ) : (
        <TripsLanding
          handleAddTrip={handleAddTrip}
          proceedToTripSummary={handleNext}
          handleTripsLandingStoreCode={handleTripsLandingStoreCode}
          backToLanding={backToLanding}
        />
      )}
      <BOLDialog
        open={open}
        storeCode={storeCode}
        onClose={closeDialog}
        pendingBOL={pendingBOL}
        selectedBOL={tempSelectedBOL}
        setSelectedBOL={setTempSelectedBOL}
        handleSelectedBOL={handleSelectedBOL}
        storeBasedBols={storeBasedBols}
      />
    </React.Fragment>
  );
}
