import React from "react"
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  maxWidth: 1000,
  maxheight:300,
  close: {
    padding: theme.spacing(0.5),
  },
}));

export default function SnackBarComponent(props) {
  const classes = useStyles();
  const { opensb, handleClose, content, severity } = props;

  return (
    <Snackbar
      open={opensb}
      message={content}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Alert
        severity={severity}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleClose}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        {content}
      </Alert>
    </Snackbar>
  );
}
