import React from "react";
import TripCreationStyle from "../../assets/css/TripCreationStyle";
import { Input } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { MoreButton } from "../Button/Button";
import Typography from "@material-ui/core/Typography";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import cookie from "react-cookies";
import { Redirect } from "react-router";
import { getLocationName } from "../../helpers/RouteValidation";

const useStyles = makeStyles(TripCreationStyle);

export default function Header(props) {
  const classes = useStyles();
  const [userLogged, setUserLogged] = React.useState(true);

  const logout = () => {
    cookie.remove("user", { path: "/" });
    setUserLogged(false);
  };

  if (!userLogged) {
    return <Redirect to="/login" />;
  }

  return (
    <div className="flex justify-between">
      {getLocationName().length > 0 ? (
        <div className="flex items-center">
          <div className={classes.fs12}>FMS Location</div>
          <div className={clsx(classes.bgInput, "ml3")}>
            <span className={clsx(classes.completedStepLabel, classes.fs12)}>
              {getLocationName()}
            </span>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <MoreButton
        onClick={logout}
        className={classes.button}
        variant="contained"
        disableElevation
        startIcon={<ExitToAppIcon fontSize="small" />}
      >
        <Typography className={clsx(classes.fs12, "ttn")}>Logout</Typography>
      </MoreButton>
    </div>
  );
}
