import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import ViewPTL from "../../components/PTL/ViewPTL/ViewPTL";
import CreatePTL from "../../components/PTL/CreatePTL/CreatePTL";
import Button from "@material-ui/core/Button";
import PublishRoundedIcon from "@material-ui/icons/PublishRounded";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import { moreColor } from "../../assets/css/BaseStyles";
import { MoreButton } from "../../components/Button/Button";
import { Redirect } from "react-router";
import { checkUserDetails } from "../../helpers/RouteValidation";
import axiosRequest from "../../helpers/axios";
import SnackBarComponent from "../../helpers/SnackBarComponent";
import {downloadFile} from "../../helpers/RouteValidation";
import Tripdc from "../../components/Reports/Tripdc";
import TripVendorDC from "../../components/Reports/TripVendorDC";
import MTDReport from "../../components/Reports/MTDReport";
import TosReport from "../../components/Reports/TosReport";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel">
      {value === index && (
        <Paper elevation={0} square>
          {children}
        </Paper>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  fs12: {
    fontSize: "12px",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  selectedTab: {
    color: moreColor[0],
  },
  tab: {
    textTransform: "none",
    fontSize: "12px",
  },
}));

function TabButton(props) {
  const classes = useStyles();
  const [uploadCsv, setuploadCsv] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const [content, setContent] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);

  const handleClose = () => {
    setOpensb(false);
  };

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };

  const handleImageChange = (event) => {
    let img = event.target.files[0];
    // var filename="ptl.csv";
    var formData = new FormData();
    formData.append("file",img);
    // console.log(formData);
    setuploadCsv(formData);
  };


  const uploadPicture = async () => {
    if (uploadCsv) {
      await axiosRequest({
        url: "ptl/add_ptl/",
        method: "POST",
        data: uploadCsv,
        headers: {
          "content-type": "multipart/form-data",
        },
      }).then((data) => {
        if (data.status === 200) {
        } else {
          handleSnackBar(data, "error");
        }
      });
    }
  };


 const downloadCsv = async (row) => {
    await axiosRequest({
      url: "ptl/upload_csv/",
      method: "PUT",
      data: {
        folderName: "ptl",
        fileName: "ptl.csv",
      },
    }).then((data) => {
      if (data.status === 200) {
        downloadFile(data.data);
        handleSnackBar("Downloaded Successfully", "success");
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

   React.useEffect(() => {
    uploadPicture();
  }, [uploadCsv]);

 
  return (
    <React.Fragment>
     <MoreButton
        className={classes.button}
        variant="contained"
        disableElevation
        onClick={downloadCsv}
        startIcon={<GetAppRoundedIcon fontSize="small" />}
      >
        <Typography className={clsx(classes.fs12, "ttn")}>
          Download Template
        </Typography>
      </MoreButton>
          <input
            className={classes.input}
             multiple
             style={{ visibility: 'hidden'}}
             type="file"
             id="fileupload"
             onChange={handleImageChange}
                 />
       <label htmlFor={'fileupload'}>
      <Button
        className={classes.button}
        variant="contained"
        component="span"
        disableElevation
        startIcon={<PublishRoundedIcon fontSize="small" />}  
      >
        <Typography className={clsx(classes.fs12, "ttn")}>
          Upload Shipment
        </Typography>
      </Button>
      {/* <MoreButton
        className={classes.button}
        variant="contained"
        disableElevation
        onClick={downloadCsv}
        startIcon={<GetAppRoundedIcon fontSize="small" />}
      >
        <Typography className={clsx(classes.fs12, "ttn")}>
          Download Template
        </Typography>
      </MoreButton>  */}
      {/* <input
            sclassName={classes.input}
             multiple
             style={{ visibility: 'hidden'}}
             type="file"
             id="fileupload"
             onChange={handleImageChange}
                 />
        <label htmlFor={'fileupload'}>
          <MoreButton
              variant="contained"
              color="primary"
              component="span"
               startIcon={<PublishRoundedIcon fontSize="small" />}
                          >
              <Typography className={clsx(classes.fs12, "ttn")}>
               Upload Shipment
              </Typography>
          </MoreButton> */}
        </label>
         <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </React.Fragment>
  );
}

export default function Report() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [isAuthenticated, setIsAuthenticated] = React.useState(true);

  React.useEffect(() => {
    var user = checkUserDetails();
    if (user) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <React.Fragment>
      {isAuthenticated ? (
        <div className={clsx(classes.root, "mt4")}>
          <Grid container justify="space-between">
            <Grid item>
              <Tabs
                value={value}
                onChange={handleChange}
                classes={{ root: classes.ttn }}
              >
                <Tab
                  label="Trip DC level Report"
                  classes={{ selected: classes.selectedTab, root: classes.tab }}
                />
                <Tab
                  label="Trip Vendor DC level Report"
                  classes={{ selected: classes.selectedTab, root: classes.tab }}
                />
                <Tab
                  label="MTD Reports"
                  classes={{ selected: classes.selectedTab, root: classes.tab }}
                />
                 <Tab
                  label="Unattached TO Reports"
                  classes={{ selected: classes.selectedTab, root: classes.tab }}
                />
              </Tabs>
            </Grid>
            <Grid item className="self-center">
              {/* {value !== 0 ? (
                <TabButton
                />
              ) : (
                ""
              )} */}
            </Grid>
          </Grid>
          <TabPanel value={value} index={0}>
              <Tripdc/>
          </TabPanel>
          <TabPanel value={value} index={1}>
              <TripVendorDC/>
          </TabPanel>
          <TabPanel value={value} index={2}>
              <MTDReport/>
          </TabPanel>
          <TabPanel value={value} index={3}>
              <TosReport/>
          </TabPanel>
        </div>
      ) : (
        <Redirect to="/login" />
      )}
     
    </React.Fragment>
  );
}