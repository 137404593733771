import {
  drawerWidth,
  transition,
  defaultFont,
  moreColor,
  blackColor,
  hexToRgb,
} from "./BaseStyles";

const sidebarStyle = (theme) => ({
  drawerPaper: {
    position: "fixed",
    top: "0",
    bottom: "0",
    left: "0",
    width: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      position: "fixed",
      height: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: drawerWidth,
      position: "fixed",
      display: "block",
      top: "0",
      height: "100vh",
      right: "0",
      left: "auto",
      zIndex: "1032",
      visibility: "visible",
      overflowY: "visible",
      borderTop: "none",
      textAlign: "left",
      paddingRight: "0px",
      paddingLeft: "0",
      transform: `translate3d(${drawerWidth}px, 0, 0)`,
      ...transition,
    },
  },
  logo: {
    padding: "25px 15px",
    display: "flex",
    margin: "auto",
  },
  logoImage: {
    width: "30px",
    display: "inline-block",
    maxHeight: "30px",
    marginLeft: "10px",
    marginRight: "15px",
  },
  img: {
    width: "35px",
    top: "22px",
    position: "absolute",
    verticalAlign: "middle",
    border: "0",
  },
  background: {
    position: "absolute",
    zIndex: "1",
    height: "100%",
    width: "100%",
    display: "block",
    top: "0",
    left: "0",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    "&:after": {
      position: "absolute",
      zIndex: "3",
      width: "100%",
      height: "100%",
      content: '""',
      display: "block",
      background: blackColor,
      opacity: ".8",
    },
  },
  list: {
    marginTop: "20px",
    paddingLeft: "0",
    paddingTop: "0",
    paddingBottom: "0",
    marginBottom: "0",
    listStyle: "none",
    position: "unset",
  },
  item: {
    position: "relative",
    display: "block",
    textDecoration: "none",
    margin: "10px 0",
  },
  itemLink: {
    width: "auto",
    borderRadius: "3px",
    position: "relative",
    padding: "10px 15px",
    backgroundColor: "transparent",
    ...defaultFont,
  },
  itemIcon: {
    width: "24px",
    height: "30px",
    fontSize: "24px",
    lineHeight: "30px",
    float: "left",
    marginRight: "15px",
    textAlign: "center",
    verticalAlign: "middle",
  },
  itemText: {
    ...defaultFont,
    margin: "0",
    fontSize: "12px",
    color: blackColor,
  },
  activeFont: {
    color: moreColor[0],
  },
  red: {
    borderLeft: `5px solid ${moreColor[0]}`,
  },
  sidebarWrapper: {
    position: "relative",
    height: "calc(100vh - 75px)",
    overflow: "auto",
    width: `${drawerWidth}`,
    zIndex: "4",
    overflowScrolling: "touch",
  },
  pa10: {
    padding: "10px",
  },
  px15: {
    padding: "0 15px",
  },
  mt2: {
    marginTop: theme.spacing(2),
  },
});

export default sidebarStyle;
