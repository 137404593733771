import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import SelectStore from "./SelectStore/SelectStore";
import PendingBOL from "./PendingBOL/PendingBOL";
import Typography from "@material-ui/core/Typography";
import BOLVerificationTable from "./BOLVerification/BOLVerificationTable";
import Grid from "@material-ui/core/Grid";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import AddIcon from "@material-ui/icons/Add";
import SelectStoreDialog from "./SelectStore/SelectStoreDialog";
import { QontoConnector, QontoStepIcon } from "../../Base/StepperStyle";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { MoreButton } from "../../Button/Button";
import TripCreationStyle from "../../../assets/css/TripCreationStyle";
import axiosRequest from "../../../helpers/axios";
import SnackBarComponent from "../../../helpers/SnackBarComponent";
import { getLocationId } from "../../../helpers/RouteValidation";

const useStyles = makeStyles(TripCreationStyle);

function getSteps() {
  return ["Select Store", "Pending BOLs", "BOL_NO Verification"];
}

function StepperHeader(props) {
  const classes = useStyles();
  const { activeStep, steps } = props;
  return (
    <Stepper activeStep={activeStep} connector={<QontoConnector />}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel
            StepIconComponent={QontoStepIcon}
            classes={{
              completed: classes.completedStepLabel,
            }}
          >
            <Typography className={classes.fs12}>{label}</Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

function PendingBOLsStepperButton(props) {
  const classes = useStyles();
  const { handleSelectStoreDialogOpen } = props;
  return (
    <React.Fragment>
      <MoreButton
        onClick={handleSelectStoreDialogOpen}
        className={classes.button}
        variant="contained"
        disableElevation
        color="secondary"
        startIcon={<AddIcon />}
      >
        <Typography className={clsx(classes.fs12, "ttn")}>
          Add Stores
        </Typography>
      </MoreButton>
      <Button
        className={classes.button}
        variant="contained"
        disableElevation
        startIcon={<DeleteIcon className={classes.w20p} />}
      >
        <Typography className={clsx(classes.fs12, "ttn")}>Delete</Typography>
      </Button>
    </React.Fragment>
  );
}

function BackButton(props) {
  const classes = useStyles();
  const { handleBack } = props;
  return (
    <Button
      onClick={handleBack}
      className={clsx(classes.button)}
      variant="contained"
      disableElevation
      startIcon={<ChevronLeftIcon fontSize="small" />}
    >
      <Typography className={clsx(classes.fs12, "ttn")}>Back</Typography>
    </Button>
  );
}

export default function BOLVerificationStepper(props) {
  const {
    proceedToTripInitiation,
    selectedStores,
    handleSelectedStores,
    selectedBOL,
    handleSelectedBOL,
  } = props;

  // BOL Verifcation Stepper
  const [activeStep, setActiveStep] = React.useState(0);
  const [severity, setSeverity] = React.useState("");
  const [content, setContent] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);
  const handleClose = () => {
    setOpensb(false);
  };
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // Select Store
  const [open, setOpen] = React.useState(false);

  const handleSelectStoreDialogOpen = () => {
    setOpen(true);
  };

  const handleSelectStoreDialogClose = () => {
    setOpen(false);
  };

  const handleSelectStoreDialogConfirm = () => {
    if (selectedStores.length > 0) {
      handleSelectStoreDialogClose();
      getPendingBols();
      if (activeStep === 0) {
        handleNext();
      }
    } else {
      handleSnackBar("Please select stores", "error");
    }
  };

  // Pending BOL
  const [pendingBOL, setPendingBOL] = React.useState([]);
  const getPendingBols = async () => {
    var stores = [];
    selectedStores.forEach((store) => {
      stores.push(store.fms_location_id);
    });
    await axiosRequest({
      url: "bolNoVerification/getPendingBols",
      method: "POST",
      data: {
        stores,
        fmsLocationId: getLocationId(),
      },
    }).then((data) => {
      if (data.status === 200) {
        setPendingBOL(data.data);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };

  // Table Header
  const headCells = [
    { id: "ship_date", label: "Date", sortable: true, align: "center" },
    {
      id: "fms_location_name",
      label: "FMS Location",
      sortable: true,
      align: "center",
    },
    {
      id: "store_id",
      label: "Store Code",
      sortable: true,
      align: "center",
      search: true,
    },
    {
      id: "store_name",
      label: "Store Name",
      sortable: true,
      align: "center",
    },
    {
      id: "bol_no",
      label: "BOL NO",
      sortable: true,
      align: "center",
      search: true,
    },
    { id: "bol_value", label: "Value", sortable: true, align: "center" },
  ];

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <SelectStore handleDialogOpen={handleSelectStoreDialogOpen} />;
      case 1:
        return (
          <PendingBOL
            pendingBOL={pendingBOL}
            selectedBOL={selectedBOL}
            handleSelectedBOL={handleSelectedBOL}
            proceedToBOLVerification={handleNext}
            headCells={headCells}
          />
        );
      case 2:
        return (
          <BOLVerificationTable
            selectedBOL={selectedBOL}
            proceedToTripInitiation={proceedToTripInitiation}
            handleBack={handleBack}
            headCells={headCells}
          />
        );
      default:
        return "";
    }
  };

  return (
    <React.Fragment>
      <Paper elevation={0} square>
        <Grid container justify="space-between">
          <Grid item>
            <StepperHeader activeStep={activeStep} steps={steps} />
          </Grid>
          <Grid item className="self-center">
            {activeStep === 1 ? (
              <PendingBOLsStepperButton
                handleSelectStoreDialogOpen={handleSelectStoreDialogOpen}
              />
            ) : activeStep === 2 ? (
              <BackButton handleBack={handleBack} />
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={0} square>
        <Divider />
        <React.Fragment>{getStepContent(activeStep)}</React.Fragment>
      </Paper>
      <SelectStoreDialog
        activeStep={activeStep}
        open={open}
        onClose={handleSelectStoreDialogClose}
        selectedStores={selectedStores}
        handleSelectedStores={handleSelectedStores}
        handleSelectStoreDialogConfirm={handleSelectStoreDialogConfirm}
      />
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </React.Fragment>
  );
}
