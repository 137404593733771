import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { TableHeader, StyledTableRow } from "../../../Base/TableHeader";
import { getComparator, sortArray } from "../../../../helpers/sorting";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import Divider from "@material-ui/core/Divider";
import { MoreButton, SuccessButton } from "../../../Button/Button";
import TripCreationStyle from "../../../../assets/css/TripCreationStyle";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Checkbox from "@material-ui/core/Checkbox";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import Button from "@material-ui/core/Button";
import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Input from "@material-ui/core/Input";
import Link from "@material-ui/core/Link";
import axiosRequest from "../../../../helpers/axios";
import SnackBarComponent from "../../../../helpers/SnackBarComponent";
import {
  getLocationId,
  getLocationName,
} from "../../../../helpers/RouteValidation";
import { save } from "react-cookies";

const useStyles = makeStyles(TripCreationStyle);

const headers = [
  {
    id: "to_loc",
    label: "Store Code",
    sortable: true,
    align: "center",
  },
  {
    id: "bcount",
    label: "BOL Count",
    sortable: true,
    align: "center",
  },
  {
    id: "bol_value",
    label: "Value",
    sortable: true,
    align: "center",
    prefix: `&#8377;`,
  },
  {
    id: "fms_location_name",
    label: "Store Name",
    sortable: true,
    align: "center",
  },
  {
    id: "Tag",
    label: "Store Tag",
    sortable: true,
    align: "center",
  },
  {
    id: "cityzone",
    label: "Store City",
    sortable: true,
    align: "center",
  },
];

export default function AddTrip(props) {
  const classes = useStyles();
  const {
    backToLanding,
    handleStoreCode,
    verifiedStores,
    pendingBOL,
    selectedBOL,
    tripId,
    isAddTrip,
    // recall,
    selectAllBols,
    storeBasedBols,
    setStoreBasedBols,
    // selectedStoreUpdate
  } = props;
  const [km, setKm] = React.useState(0);
  const [time, setTime] = React.useState("0:0:0");
  const [selectedStores, setSelectedStores] = React.useState([]);
  const [stores, setStores] = React.useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("storeCode");
  const [destination, setDestination] = React.useState("");
  const [returnToOrigin, setReturnToOrigin] = React.useState(false);
  const [tripFinalDestination, settripFinalDestination] = React.useState(false);
  const [destinationType, setDestinationType] = React.useState("CC");
  const [departureTime, setDepartureTime] = React.useState("");
  const [storeDetailsEta, setstoreDetailsEta] = React.useState([]);
  const [dataChange, setDataChange] = React.useState(false);
  const [severity, setSeverity] = React.useState("");
  const [content, setContent] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);
  const [combiDC, setCombiDC] = React.useState(false);

  const handleClose = () => {
    setOpensb(false);
  };
  let time_array = time.split(":");

  // let datachange = false;
  let storesList = [];
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  React.useEffect(() => {
    console.log(tripId, "***");
    if (tripId !== 0) {
      editTrip();
      console.log("edit");
    } else {
      getStoresList();
      console.log("add");
    }
  }, []);
  // React.useEffect(() => {
  //   //  console.log(tripId)
  //   if (tripId !== 0) {
  //     editTrip();
  //   }
  // }, [recall]);

  const handleSelectAllClick = (event) => {
    // setStoreBasedBols([])
    const newSelecteds = stores.map((n) => n);
    for (let row of newSelecteds) {
      selectAllBols(row);
    }
    if (event.target.checked) {
      setSelectedStores(newSelecteds);
      // selectAllStoreBols(newSelecteds);
      return;
    }
    setSelectedStores([]);
    // selectAllStoreBols([]);
  };

  const isSelected = (row) => {
    var selectedIndex = selectedStores.findIndex(
      (obj) => obj.to_loc === row.to_loc
    );
    return selectedIndex !== -1 ? true : false;
  };

  const handleClick = (event, row) => {
    setDataChange(false);

    if (selectedStores.filter((x) => x.to_loc === row.to_loc).length === 2) {
      var tempSelectedStores = selectedStores;
      tempSelectedStores.splice(
        selectedStores.findIndex((x) => x.to_loc === row.to_loc),
        1
      );
      setSelectedStores([...tempSelectedStores]);
    } else {
      selectAllBols(row);

      const selectedIndex = selectedStores.findIndex(
        (obj) => obj.to_loc === row.to_loc
      );

      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedStores, row);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedStores.slice(1));
      } else if (selectedIndex === selectedStores.length - 1) {
        newSelected = newSelected.concat(selectedStores.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedStores.slice(0, selectedIndex),
          selectedStores.slice(selectedIndex + 1)
        );
      }
      setSelectedStores(newSelected);
    }
    // if(tripId!==0){
    //   selectedStoreUpdate(row)
    // }
  };
  // React.useEffect(() => {
  //   console.log(selectedStores, "!!!!!!!!!!!!!!");
  //   for (let bolno of selectedStores) {
  //     selectedBOL.push(bolno.bol_no);
  //   }
  //   console.log(selectedBOL);
  // }, [selectedStores]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    setDataChange(false);
    if (!result.destination) {
      return;
    }

    const reOrderedSelectedStores = reorder(
      selectedStores,
      result.source.index,
      result.destination.index
    );

    setSelectedStores(reOrderedSelectedStores);
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: "10px",
    margin: "10px 0",
    display: "flex",
    fontSize: "12px",
    alignItems: "center",
    background: "#fafafa",
    ...draggableStyle,
  });

  const getListStyle = (isDragging) => ({
    padding: "16px",
    borderLeft: "1px solid #e0e0e0",
    marginLeft: "3px",
    minHeight: "calc(100vh - 510px)",
    maxHeight: "calc(100vh - 510px)",
    overflow: "auto",
  });

  const handleDestination = (event) => {
    if (event.target.value === "yes") {
      let locationName = getLocationName();
      setDestination(locationName);
      setReturnToOrigin(true);
      settripFinalDestination(false);
    } else {
      setReturnToOrigin(false);
      settripFinalDestination(true);
    }
  };

  const handleDestinationType = (event) => {
    setDestinationType(event.target.value);
  };

  const handleDestinationName = (event) => {
    setDestination(event.target.value);
  };

  const handleDepartureTime = (event) => {
    setDepartureTime(event.target.value);
  };

  const showBolNo = async (storeCode, pendingBOL) => {
    handleStoreCode(storeCode, pendingBOL);
  };
  const calculateEstimate = async () => {
    storesList = [];

    if (departureTime === "" || selectedStores.length === 0) {
      alert("please select the departureTime or Stores");
    } else {
      if (selectedStores.length !== 0) {
        for (let i = 0; i < selectedStores.length; i++) {
          let temp = {};
          temp["storeId"] = selectedStores[i]["to_loc"];
          temp["deliverySeq"] = i + 1;
          storesList.push(temp);
        }
      }
      await axiosRequest({
        url: "tripInitiation/estimateTripTime",
        method: "POST",
        data: {
          calculateTime: {
            dispatchTime: departureTime,
            fmsLocationId: getLocationId(),
            destinationId: getLocationId(),
            stores: storesList,
          },
        },
      }).then((data) => {
        if (data.status === 200) {
          setDataChange(true);
          setstoreDetailsEta(data.data);
        } else {
          handleSnackBar(data, "error");
        }
      });
    }
  };
  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };
  // if(storeDetailsEta.length !==0){
  //     console.log(storeDetailsEta,"$$$$$$$$$$$")
  //     setTime(storeDetailsEta["TotalTime"])
  //     setKm(storeDetailsEta["TotalDistance"])
  // }
  React.useEffect(() => {
    if (storeDetailsEta.length !== 0) {
      setTime(storeDetailsEta[0]["TotalTime"]);
      setKm(storeDetailsEta[0]["TotalDistance"]);
      setSelectedStores(storeDetailsEta[0]["stores"]);
    }
    // console.log(storeDetailsEta[0]["TotalTime"],storeDetailsEta[0]["TotalDistance"],"^^^^^^^")
  }, [storeDetailsEta]);

  const getStoresList = async () => {
    let fmsLocationId = getLocationId();
    await axiosRequest({
      url: "tripInitiation/addTrip/" + fmsLocationId,
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        setStores(data.data);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };
  const filldata = (data) => {
    // setStores(data['leftSideData'])
    // setstoreDetailsEta(data['RightSideData'])

    setstoreDetailsEta(data["leftSideData"]);
    setStores(data["RightSideData"]);
    console.log(selectedStores, "selectedStores");
    console.log(storeBasedBols, "filldata all bols");
    for (let store of data["RightSideData"]) {
      let temp_store = {};
      console.log(tripId, "inedit");
      if (store["trip_id"] === tripId) {
        let bol_sel = [];
        for (let bolno of store["bol_no"]) {
          if (bolno["trip_id"] === tripId) {
            bol_sel.push(bolno);
          }
        }
        temp_store["to_loc"] = store["to_loc"];
        temp_store["bol_nos"] = bol_sel;
        temp_store["isSaved"] = true;
        temp_store["isEditTrip"] = true;
      }
      let isThere = false;

      for (let eachitem of storeBasedBols) {
        if (eachitem["to_loc"] === temp_store["to_loc"]) {
          isThere = true;
        }
      }
      if (isThere) {
        for (let i = 0; i < storeBasedBols.length; i++) {
          if (storeBasedBols[i]["to_loc"] === temp_store["to_loc"]) {
            storeBasedBols[i]["bol_nos"] = temp_store["bol_nos"];
          }
        }
      } else {
        if (temp_store["bol_nos"] !== undefined) {
          console.log(temp_store, "edit trip filldata fn");
          storeBasedBols.push(temp_store);
          console.log(storeBasedBols, "end fill data");
        }
      }
    }
    setDepartureTime(data["leftSideData"][0]["dispatchTime"]);
    if (data["leftSideData"][0]["Dest_Id"] === 17) {
      setDestinationType(data["leftSideData"][0]["Dest_Type"]);
      setDestination(data["leftSideData"][0]["Dest_name"]);
      setReturnToOrigin(true);
      settripFinalDestination(false);
      // setDestination(17)
    } else {
      setDestinationType(data["leftSideData"][0]["Dest_Type"]);
      setDestination(data["leftSideData"][0]["Dest_name"]);
      setReturnToOrigin(false);
      settripFinalDestination(true);
    }
  };
  const editTrip = async () => {
    await axiosRequest({
      url: "tripInitiation/editTrip/" + tripId,
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        filldata(data.data);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const savetripfn = () => {
    let stores_list = [];
    let tempSelectedStores = [];
    if (combiDC) {
      tempSelectedStores = selectedStores.filter(
        (thing, index, self) =>
          index ===
          self.findIndex((t) => t.fms_location_name === thing.fms_location_name)
      );
      for (let i = 0; i < tempSelectedStores.length; i++) {
        tempSelectedStores[i]["deliverySeq"] = i + 1;
        console.log(tempSelectedStores[i]);
      }
    } else {
      tempSelectedStores = selectedStores;
    }
    for (let id of stores) {
      let store_temp = {};
      for (let id_sel of tempSelectedStores) {
        if (id["to_loc"] === id_sel["to_loc"]) {
          store_temp["storeId"] = id["to_loc"];
          store_temp["storeName"] = id_sel["fms_location_name"];
          store_temp["storeValue"] = id["bol_value"];
          store_temp["storeETA"] = id_sel["storeETA"];
          store_temp["storeETD"] = id_sel["storeETD"];
          store_temp["dsl"] = id_sel["dsl"];
          store_temp["deliverySeq"] = id_sel["deliverySeq"];
          for (let bols of storeBasedBols) {
            if (bols["to_loc"] === id["to_loc"]) {
              store_temp["bolNos"] = bols["bol_nos"];
            }
          }

          let bol_temp = [];
        }
      }

      if (store_temp["storeId"] !== undefined) {
        stores_list.push(store_temp);
      }
    }

    let trip_pass;
    if (tripId === 0) {
      trip_pass = null;
    } else {
      trip_pass = tripId;
    }

    // calculateEstimate() //need to call data not fetching here
    let data_savetrip = {
      fmsLocationId: getLocationId(),
      tripId: trip_pass,
      destinationType: "cc",
      destinationId: getLocationId(),
      totalDistance: km,
      totalTimeEst: time,
      dispatchTime: departureTime,
      stores: stores_list,
      isCombiTrip: combiDC,
    };

    console.log(data_savetrip);
    return data_savetrip;
  };
  const postSaveTrip = async () => {
    if (dataChange === false) {
      alert("Please Click the Estimate Trip");
    } else {
      await axiosRequest({
        url: "tripInitiation/saveTrip",
        method: "POST",
        data: savetripfn(),
      }).then((data) => {
        if (data.status === 200) {
        } else {
          handleSnackBar(data, "error");
        }
      });
      backToLanding();
    }
  };

  const makeCombiDC = async () => {
    var s = selectedStores.reduce(function (res, current, index, array) {
      return res.concat([current, current]);
    }, []);
    setDataChange(false);
    setSelectedStores(s);
  };

  React.useEffect(() => {
    let selectedStoresNameArray = selectedStores.map(
      (store) => store.fms_location_name
    );
    let isDuplicate = selectedStoresNameArray.some(
      (val, i) => selectedStoresNameArray.indexOf(val) !== i
    );
    if (isDuplicate) {
      setCombiDC(true);
    } else {
      setCombiDC(false);
    }
  }, [selectedStores]);

  return (
    <React.Fragment>
      <Paper elevation={0} square>
        <div className="flex pa2 items-center">
          <div className="w-60">
            <Typography variant="h6">Trip ID : {tripId}</Typography>
          </div>
          <div className="w-40 flex justify-around">
            <div className="flex flex-column">
              <div className="flex items-center">
                <Avatar>T</Avatar>
                <div className="ml2">
                  <Typography className={classes.fs12}>
                    Total Distance Covered
                  </Typography>
                  <Typography className={classes.fs12}>{km} Kms</Typography>
                </div>
              </div>
            </div>
            <div className="flex flex-column">
              <div className="flex items-center">
                <Avatar>E</Avatar>
                <div className="ml2">
                  <Typography className={classes.fs12}>
                    Estimated Time
                  </Typography>
                  <Typography className={classes.fs12}>
                    {time_array[0] +
                      " hours " +
                      time_array[1] +
                      " minutes " +
                      time_array[2] +
                      " secs"}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Divider />
        <div className="flex">
          <div className="w-40 pa3">
            <div className="flex items-center">
              <Typography className={classes.fs12}>
                <span>Selected Store</span>
                <Badge
                  badgeContent={selectedStores.length}
                  color="primary"
                  className="ml3"
                ></Badge>
                <span className="ml3">(Re arrange store as per delivery)</span>
                <span className="ml5">Set Departure Time</span>
              </Typography>
              <span className="ml2">
                <div className={clsx(classes.bgInputGray, "flex")}>
                  <Input
                    value={departureTime}
                    type="time"
                    className={clsx(classes.underline, classes.fs12)}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                    onChange={handleDepartureTime}
                  />
                </div>
              </span>
            </div>
            <Typography className={clsx(classes.fs12, "pt3")}>
              <span>Store Code | Name</span>
              <span className="ml4">Store ETA</span>
              <span className="ml4">Store ETD</span>
              <span className="ml5">DSL</span>
            </Typography>
            <Divider />
            <div
              className={clsx(
                classes.StartCircle,
                "mt3",
                "flex",
                "justify-between",
                "items-center"
              )}
            >
              <span className={clsx(classes.fs12, "ml3", "absolute")}>
                {getLocationName()}
              </span>
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {selectedStores.map((store, index) => (
                      <Draggable
                        key={index}
                        draggableId={index.toString()}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <DragIndicatorIcon fontSize="small" />
                            {store.to_loc} | {store.fms_location_name} |{" "}
                            {store.storeETA} | {store.storeETD} | {store.dsl}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <div className={clsx(classes.EndCircle)}>
              <span className={clsx(classes.fs12, "ml3", "absolute")}>
                {destination ? destination : "Not Specified"}
              </span>
            </div>
            <div className="flex justify-between mt3">
              <MoreButton
                variant="contained"
                disableElevation
                onClick={makeCombiDC}
                disabled={combiDC}
              >
                <Typography className={clsx(classes.fs12, "ttn")}>
                  Combi Delivery
                </Typography>
              </MoreButton>
              <SuccessButton
                variant="contained"
                disableElevation
                onClick={calculateEstimate}
              >
                <Typography className={clsx(classes.fs12, "ttn")}>
                  Estimate Trip
                </Typography>
              </SuccessButton>
            </div>
          </div>

          <Divider orientation="vertical" flexItem />
          <div className="w-60 pa3">
            <Typography className={classes.fs12}>
              <span>Select Stores for Trip: {tripId}</span>
              <Badge
                badgeContent={stores.length}
                color="primary"
                className="ml3"
              ></Badge>
              <span className="ml3">(List of total stores)</span>
            </Typography>
            <TableContainer className={clsx(classes.AddTripContainer, "pt3")}>
              <Table stickyHeader>
                <TableHeader
                  numSelected={selectedStores.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={stores.length}
                  headers={headers}
                  isCheckboxEnabled={true}
                />
                <TableBody>
                  {sortArray(stores, getComparator(order, orderBy)).map(
                    (row, index) => {
                      const isItemSelected = isSelected(row);
                      return (
                        <StyledTableRow key={index}>
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onClick={(event) => handleClick(event, row)}
                            />
                          </TableCell>
                          {/* {headers.map((header, index) => {
                            return (
                              <TableCell className={classes.fs12} align="left">
                                {header.prefix ? header.prefix : ""}
                                {row[header.id]}
                              </TableCell>
                            );
                          })} */}
                          <TableCell className={classes.fs12} align="center">
                            <Link
                              component="button"
                              variant="body2"
                              className={clsx(classes.fs12, classes.textColor)}
                              onClick={() => {
                                showBolNo(row.to_loc, row.bol_no);
                              }}
                            >
                              {row.to_loc}
                            </Link>
                          </TableCell>
                          <TableCell className={classes.fs12} align="center">
                            {row.bcount}
                          </TableCell>
                          <TableCell className={classes.fs12} align="center">
                            &#8377; {row.bol_value.toFixed(2)}
                          </TableCell>
                          <TableCell className={classes.fs12} align="center">
                            {row.fms_location_name}
                          </TableCell>
                          <TableCell className={classes.fs12} align="center">
                            {row.Tag}
                          </TableCell>
                          <TableCell className={classes.fs12} align="center">
                            {row.cityzone}
                          </TableCell>
                        </StyledTableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        <Divider />
        <div className="flex justify-between items-center pa3">
          <div className="flex">
            <div className="flex flex-column">
              <FormLabel component="legend" className={classes.fs12}>
                Return to Origin *
              </FormLabel>
              <RadioGroup row onChange={handleDestination}>
                <FormControlLabel
                  value="yes"
                  control={<Radio color="primary" />}
                  label="Yes"
                  checked={returnToOrigin === true}
                />
                <FormControlLabel
                  value="no"
                  control={<Radio color="primary" />}
                  label="Trip Final Destination"
                  checked={tripFinalDestination === true}
                />
              </RadioGroup>
            </div>
            {returnToOrigin ? (
              ""
            ) : (
              <React.Fragment>
                <div className="ml3 flex flex-column justify-between">
                  <FormLabel component="legend" className={classes.fs12}>
                    Destination Type *
                  </FormLabel>
                  <select
                    name="contract_type"
                    className={clsx(classes.inputSelect, classes.fs12)}
                    value={destinationType}
                    onChange={(event) => handleDestinationType(event)}
                  >
                    {/* <option value="CC">CC</option> */}
                    <option value="DC">DC</option>
                  </select>
                </div>
                <div className="ml5 flex flex-column justify-between">
                  <FormLabel component="legend" className={classes.fs12}>
                    Location Name *
                  </FormLabel>
                  <select
                    name="contract_type"
                    className={clsx(classes.inputSelect, classes.fs12)}
                    value={destination}
                    onChange={(event) => handleDestinationName(event)}
                  >
                    <option value="">Select Location</option>
                    <option value="Bangalore DC">Bangalore DC</option>
                  </select>
                </div>
              </React.Fragment>
            )}
          </div>
          <div>
            <Button
              className={classes.button}
              variant="contained"
              disableElevation
              startIcon={<ChevronLeftRoundedIcon fontSize="small" />}
              onClick={backToLanding}
            >
              <Typography className={clsx(classes.fs12, "ttn")}>
                Cancel
              </Typography>
            </Button>
            <SuccessButton
              variant="contained"
              disableElevation
              onClick={postSaveTrip}
            >
              <Typography className={clsx(classes.fs12, "ttn")}>
                Save Trip
              </Typography>
            </SuccessButton>
          </div>
        </div>
      </Paper>
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </React.Fragment>
  );
}
