import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { TableHeader, StyledTableRow } from "../../../Base/TableHeader";
import { getComparator, sortArray } from "../../../../helpers/sorting";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import Input from "@material-ui/core/Input";
import { SuccessButton, MoreButton } from "../../../Button/Button";
import TripCreationStyle from "../../../../assets/css/TripCreationStyle";
import axiosRequest from "../../../../helpers/axios";
import { SettingsPowerRounded } from "@material-ui/icons";
import SnackBarComponent from "../../../../helpers/SnackBarComponent";
import Link from "@material-ui/core/Link";
import { getLocationId } from "../../../../helpers/RouteValidation";
import { downloadFile } from "../../../../helpers/RouteValidation";
import moment from "moment";


const headers = [
  {
    id: "fms_location_name",
    sortable: true,
    align: "center",
    label: "FMS Location",
  },
  {
    id: "trip_ID",
    sortable: true,
    align: "center",
    label: "Trip ID",
    search:true,
  },
  {
    id: "vehicle_num",
    sortable: true,
    align: "center",
    label: "Vehicle Number",
  },
  {
    id: "trip_created_time",
    sortable: true,
    align: "center",
    label: "Created Date",
  },
  {
    id: "store_id",
    sortable: true,
    align: "center",
    label: "Store",
  },
  {
    id: "store_name",
    sortable: true,
    align: "center",
    label: "Store Name",
  },
  {
    id: "pod_1",
    sortable: false,
    align: "center",
    label: "POD 1",
  },
  {
    id: "pod_2",
    sortable: false,
    align: "center",
    label: "POD 2",
  },
  {
    id: "actual_arr_time",
    sortable: true,
    align: "center",
    label: "Vehicle Arrival Time",
  },
  {
    id: "actual_dep_time",
    sortable: true,
    align: "center",
    label: "Vehicle Dispatch Time",
  },
];

const useStyles = makeStyles(TripCreationStyle);

export default function TripEnd(props) {
  const classes = useStyles();
  const { handleNext } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("trip_created_time");
  const [tableData, setTableData] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [uploadPod, setuploadPod] = React.useState();
  const [podType, setPodType] = React.useState(0);
  const [severity, setSeverity] = React.useState("");
  const [content, setContent] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);
  const handleClose = () => {
    setOpensb(false);
  };
  const setDate = (row, event, property, index) => {
    // row[property] = event.target.value;
    row[property] = moment(event.target.value)
      .subtract(0, "days")
      .subtract(5, "hours")
      .subtract(30, "minutes")
      .format("YYYY-MM-DDTHH:mm:ss");
    var tempTableData = [...rows];
    tempTableData[index] = row;
    setRows(tempTableData);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  React.useEffect(() => {
    setRows(tableData);
  }, [tableData]);

  const handleSearchBar = (event, property) => {
    const searchResult = tableData.filter((row) => {
      return row[property]
        .toString()
        .toLowerCase()
        .includes(event.target.value.toLowerCase());
    });
    setRows(searchResult);
  };
  const getCreatedTrips = async () => {
    let fmsLocationId = getLocationId();
    await axiosRequest({
      url: "tripEnd/getCreatedTripStores/" + fmsLocationId,
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        setTableData(data.data);
        setPodType(0);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  React.useEffect(() => {
    getCreatedTrips();
  }, []);

  const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  };

  const getUpdateData = () => {
    var groupedData = groupBy(tableData, "trip_ID");
    var errorCount = 0;
    var tripID = [];
    var tripStoresList = [];
    var vehiclesList = [];
    for (var tripId in groupedData) {
      var tripStores = groupedData[tripId];
      var tripStoresLength = 0;
      for (var store in tripStores) {
        if (
          (tripStores[store]["actual_arr_time"] != null &&
            tripStores[store]["actual_arr_time"] != "" &&
            (tripStores[store]["actual_dep_time"] == null ||
              tripStores[store]["actual_dep_time"] == "")) ||
          (tripStores[store]["actual_dep_time"] != "" &&
            tripStores[store]["actual_dep_time"] != null &&
            (tripStores[store]["actual_arr_time"] == null ||
              tripStores[store]["actual_arr_time"] == ""))
        ) {
          errorCount += 1;
          break;
        } else if (
          tripStores[store]["actual_arr_time"] != null &&
          tripStores[store]["actual_arr_time"] != "" &&
          tripStores[store]["actual_dep_time"] != null &&
          tripStores[store]["actual_dep_time"] != ""
        ) {
          tripStoresLength += 1;
        }
      }
      if (tripStores.length === tripStoresLength) {
        tripID.push(tripId);
        vehiclesList.push(tripStores[store]["vehicle_master_id"]);
        for (var store in tripStores) {
          tripStoresList.push(tripStores[store]);
        }
      }
    }
    var updateData = new Object();
    updateData["tripData"] = tripStoresList;
    updateData["trips"] = tripID;
    updateData["vehiclesList"] = vehiclesList;

    return updateData;
  };

  const updateTripStores = async () => {
    var requestData = getUpdateData();
    await axiosRequest({
      url: "tripEnd/updateTripStores",
      method: "PUT",
      data: {
        tripData: requestData["tripData"],
        trips: requestData["trips"],
        vehiclesList: requestData["vehiclesList"],
      },
    }).then((data) => {
      if (data.status === 200) {
        handleNext();
        getCreatedTrips();
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const handlePod1Change = (event, row, property) => {
    console.log(event);
    let img = event.target.files[0];
    var filename =
      row["trip_store_details_id"] + "_" + row[property] + "_" + img.name;
    var formData = new FormData();
    formData.append("POD_Img", img, filename);
    console.log(formData);
    setPodType(1);
    setuploadPod(formData);
  };

  const handlePod2Change = (event, row, property) => {
    console.log(event);
    let img = event.target.files[0];
    var filename =
      row["trip_store_details_id"] + "_" + row[property] + "_" + img.name;
    var formData = new FormData();
    formData.append("POD_Img", img, filename);
    console.log(formData);
    setPodType(2);
    setuploadPod(formData);
  };

  const updatePOD1ImagePath = async (path) => {
    await axiosRequest({
      url: "tripEnd/updatePOD1ImagePath",
      method: "PUT",
      data: {
        tripStoreDetailId: Number(path.split("/")[1].split("_")[0]),
        filePath: path,
      },
    }).then((data) => {
      if (data.status === 200) {
        getCreatedTrips();
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const updatePOD2ImagePath = async (path) => {
    await axiosRequest({
      url: "tripEnd/updatePOD2ImagePath",
      method: "PUT",
      data: {
        tripStoreDetailId: Number(path.split("/")[1].split("_")[0]),
        filePath: path,
      },
    }).then((data) => {
      if (data.status === 200) {
        getCreatedTrips();
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const uploadPicture = async () => {
    if (uploadPod) {
      await axiosRequest({
        url: "pod/",
        method: "POST",
        data: uploadPod,
        headers: {
          "content-type": "multipart/form-data",
        },
      }).then((data) => {
        if (data.status === 200) {
          if (podType === 1) {
            updatePOD1ImagePath(data.data.path);
          }
          if (podType === 2) {
            updatePOD2ImagePath(data.data.path);
          }
        } else {
          handleSnackBar(data, "error");
        }
      });
    }
  };

  const downloadImagePOD1 = async (row) => {
    await axiosRequest({
      url: "pod/",
      method: "PUT",
      data: {
        folderName: String(row.pod_1.split("/")[0]),
        fileName: String(row.pod_1.split("/")[1]),
      },
    }).then((data) => {
      if (data.status === 200) {
        downloadFile(data.data);
        handleSnackBar("Downloaded Successfully", "success");
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const downloadImagePOD2 = async (row) => {
    await axiosRequest({
      url: "pod/",
      method: "PUT",
      data: {
        folderName: String(row.pod_2.split("/")[0]),
        fileName: String(row.pod_2.split("/")[1]),
      },
    }).then((data) => {
      if (data.status === 200) {
        downloadFile(data.data);
        handleSnackBar("Downloaded Successfully", "success");
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };
  React.useEffect(() => {
    uploadPicture();
  }, [uploadPod]);

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TableContainer className={classes.container}>
          <Table stickyHeader>
            <TableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headers={headers}
              isCheckboxEnabled={false}
              handleSearch={handleSearchBar}
            />
            <TableBody>
              {sortArray(rows, getComparator(order, orderBy)).map(
                (row, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <TableCell align="center" className={classes.fs12}>
                        {row.fms_location_name}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_ID}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_id.vehicle_num}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_created_time.substr(0,10)}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.store_id}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.store_name}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.pod_1 != null ? (
                          <a
                            className={clsx("pointer")}
                            onClick={() => downloadImagePOD1(row)}
                          >
                            View Pictures
                          </a>
                        ) : (
                          ""
                        )}
                        <input
                          accept="image/*"
                          className={classes.input}
                          id={index}
                          multiple
                          type="file"
                          onChange={(event) =>
                            handlePod1Change(event, row, "trip_ID")
                          }
                        />
                        <label htmlFor={index}>
                          <MoreButton
                            variant="contained"
                            color="primary"
                            component="span"
                            className={classes.tripendbutton}
                          >
                            Upload POD 1
                          </MoreButton>
                        </label>
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.pod_2 != null ? (
                          <a
                            className={clsx("pointer")}
                            onClick={() => downloadImagePOD2(row)}
                          >
                            View Pictures
                          </a>
                        ) : (
                          ""
                        )}
                        <input
                          accept="image/*"
                          className={classes.input}
                          id={index * 1000}
                          multiple
                          type="file"
                          onChange={(event) =>
                            handlePod2Change(event, row, "trip_ID")
                          }
                        />
                        <label htmlFor={index * 1000}>
                          <MoreButton
                            variant="contained"
                            color="primary"
                            component="span"
                            className={classes.tripendbutton}
                          >
                            Upload POD 2
                          </MoreButton>
                        </label>
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        <div className={classes.bgInputGray}>
                          <Input
                            type="datetime-local"
                            // value={row.actual_arr_time || ""}
                            value={
                              moment
                                .utc(row.actual_arr_time).local()
                                .format("YYYY-MM-DDTHH:mm:ss") || ""
                            }
                            onChange={(event) =>
                              setDate(row, event, "actual_arr_time", index)
                            }
                            className={clsx(classes.underline, classes.fs12)}
                          />
                        </div>
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        <div className={classes.bgInputGray}>
                          <Input
                            type="datetime-local"
                            // value={row.actual_dep_time || ""}
                            value={
                              moment
                                .utc(row.actual_dep_time).local()
                                .format("YYYY-MM-DDTHH:mm:ss") || ""
                            }
                            onChange={(event) =>
                              setDate(row, event, "actual_dep_time", index)
                            }
                            className={clsx(classes.underline, classes.fs12)}
                          />
                        </div>
                      </TableCell>
                    </StyledTableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="flex justify-center pa3">
          <SuccessButton
            variant="contained"
            disableElevation
            onClick={updateTripStores}
          >
            <Typography className={clsx(classes.fs12, "ttn")}>
              Confirm Trip End
            </Typography>
          </SuccessButton>
        </div>
      </Grid>
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </Grid>
  );
}
