import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as AddStore } from "../../../../assets/icons/addStore.svg";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import SelectStoreDialog from "./SelectStoreDialog";
import { MoreButton } from "../../../Button/Button";
import TripCreationStyle from "../../../../assets/css/TripCreationStyle";

const useStyles = makeStyles(TripCreationStyle);

export default function SelectStore(props) {
  const classes = useStyles();
  const { handleDialogOpen } = props;
  return (
    <React.Fragment>
      <div className={classes.block}>
        <AddStore className={classes.imageWidth} />
        <Typography variant="body2" className={classes.imageText}>
          Add stores for which you want <br /> to validate BOL Nos.
        </Typography>
        <MoreButton
          variant="contained"
          color="secondary"
          onClick={handleDialogOpen}
          startIcon={<AddIcon fontSize="small" />}
          disableElevation
        >
          <Typography className={clsx(classes.fs12, "ttn")}>
            Add Stores
          </Typography>
        </MoreButton>
      </div>
    </React.Fragment>
  );
}
