import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Checkbox from "@material-ui/core/Checkbox";
import { TableHeader, StyledTableRow } from "../../../Base/TableHeader";
import Grid from "@material-ui/core/Grid";
import { ReactComponent as DeleteIconFms } from "../../../../assets/icons/delete.svg";
import { getComparator, sortArray } from "../../../../helpers/sorting";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { SuccessButton } from "../../../Button/Button";
import TripCreationStyle from "../../../../assets/css/TripCreationStyle";

const useStyles = makeStyles(TripCreationStyle);

export default function PendingBOL(props) {
  const classes = useStyles();
  const {
    pendingBOL,
    selectedBOL,
    handleSelectedBOL,
    proceedToBOLVerification,
    headCells,
  } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("store_code");
  const [headers, setHeaders] = React.useState(headCells);
  const [rows, setRows] = React.useState([]);

  const isSelected = (row) => {
    var selectedIndex = selectedBOL.findIndex(
      (obj) => obj.bol_no === row.bol_no
    );
    return selectedIndex !== -1 ? true : false;
  };

  const handleSelectedRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = pendingBOL.map((n) => n);
      handleSelectedBOL(newSelecteds);
      return;
    }
    handleSelectedBOL([]);
  };

  const handleSearchBar = (event, property) => {
    const searchResult = pendingBOL.filter((row) => {
      return row[property]
        .toString()
        .toLowerCase()
        .includes(event.target.value.toLowerCase());
    });
    setRows(searchResult);
  };

  const handleClick = (event, row) => {
    const selectedIndex = selectedBOL.findIndex(
      (obj) => obj.bol_no === row.bol_no
    );

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedBOL, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedBOL.slice(1));
    } else if (selectedIndex === selectedBOL.length - 1) {
      newSelected = newSelected.concat(selectedBOL.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedBOL.slice(0, selectedIndex),
        selectedBOL.slice(selectedIndex + 1)
      );
    }
    handleSelectedBOL(newSelected);
  };

  const checkSelectedBOLs = () => {
    if (selectedBOL.length > 0) {
      proceedToBOLVerification();
    } else {
      alert("Select BOL's to Verify");
    }
  };

  React.useEffect(() => {
    headCells.push({ id: "actions", label: "Actions", align: "center" });
    setHeaders([...headCells]);
  }, []);

  React.useEffect(() => {
    setRows(pendingBOL);
  }, [pendingBOL]);

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TableContainer className={classes.container}>
          <Table stickyHeader>
            <TableHeader
              numSelected={selectedBOL.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleSelectedRequestSort}
              rowCount={rows.length}
              headers={headers}
              isCheckboxEnabled={true}
              disabled={pendingBOL.length === rows.length ? false : true}
              handleSearch={handleSearchBar}
            />
            <TableBody>
              {sortArray(rows, getComparator(order, orderBy)).map(
                (row, index) => {
                  const isItemSelected = isSelected(row);
                  return (
                    <StyledTableRow tabIndex={-1} key={index}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          role="checkbox"
                          checked={isItemSelected}
                          aria-checked={isItemSelected}
                          selected={isItemSelected}
                          onClick={(event) => handleClick(event, row)}
                        />
                      </TableCell>
                      <TableCell className={classes.fs12} align="center">
                        {row.ship_date}
                      </TableCell>
                      <TableCell className={classes.fs12} align="center">
                        {row.fms_location_name}
                      </TableCell>
                      <TableCell className={classes.fs12} align="center">
                        {row.store_id}
                      </TableCell>
                      <TableCell className={classes.fs12} align="center">
                        {row.store_name}
                      </TableCell>
                      <TableCell className={classes.fs12} align="center">
                        {row.bol_no}
                      </TableCell>
                      <TableCell className={classes.fs12} align="center">
                        {row.bol_value}
                      </TableCell>
                      <TableCell className={classes.fs12} align="center">
                        {
                          <div className={classes.BgDeleteIcon}>
                            <DeleteIconFms className={classes.DeleteIcon} />
                          </div>
                        }
                      </TableCell>
                    </StyledTableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="flex justify-center pa3">
          <SuccessButton
            variant="contained"
            disableElevation
            onClick={checkSelectedBOLs}
          >
            <Typography className={clsx(classes.fs12, "ttn")}>
              Proceed to BOL Verification
            </Typography>
          </SuccessButton>
        </div>
      </Grid>
    </Grid>
  );
}
