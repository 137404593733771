import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Checkbox from "@material-ui/core/Checkbox";
import { TableHeader, StyledTableRow } from "./TableHeader";
import Grid from "@material-ui/core/Grid";
import { getComparator, sortArray } from "../../helpers/sorting";

const headCells = [
  {
    id: "trip_id",
    label: "Trip Id",
    sortable: true,
    align: "right",
  },
  {
    id: "to_loc",
    label: "Store Code",
    sortable: true,
    align: "right",
  },
  {
    id: "total_bol_value",
    label: "BOL value",
    sortable: true,
    align: "right",
  },
  {
    id: "total_bol_weight",
    label: "BOL weight",
    sortable: true,
    align: "right",
  },
  {
    id: "total_bol_volume",
    label: "BOL volume",
    sortable: true,
    align: "right",
  },
  {
    id: "store_name",
    label: "store name",
    sortable: true,
    align: "right",
  },
];

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: 440,
  },
  fs12: {
    fontSize: "12px",
  },
}));

export default function StoreDetailTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("bol_no");
  const {storeDetails}=props
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <React.Fragment>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TableContainer className={classes.container}>
          <Table stickyHeader>
            <TableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={headCells}
            />
            <TableBody>
              {sortArray(storeDetails, getComparator(order, orderBy)).map(
                (row, index) => {
                  return (
                    <StyledTableRow role="checkbox" tabIndex={-1} key={index}>
                      <TableCell className={classes.fs12} align="right">
                        {row.trip_id}
                      </TableCell>
                      <TableCell className={classes.fs12} align="right">
                        {row.to_loc}
                      </TableCell>
                      <TableCell className={classes.fs12} align="right">
                        {row.total_bol_value}
                      </TableCell>
                      <TableCell className={classes.fs12} align="right">
                        {row.total_bol_weight}
                      </TableCell>
                      <TableCell className={classes.fs12} align="right">
                        {row.total_bol_volume}
                      </TableCell>
                      <TableCell className={classes.fs12} align="right">
                        {row.store_name}
                      </TableCell>
                    </StyledTableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </React.Fragment>
  );
}
