import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import CardHeader from "@material-ui/core/CardHeader";

const useStyles = makeStyles({
  card: {
    display: "flex",
  },
  cardDetails: {
    flex: 1,
  },
  tagline: {
    fontSize: 10,
  },
  avatar: {
    backgroundColor: "white",
  },
});

export default function LeftPost({ post }) {
  const classes = useStyles();
  const { headericon, actualno, description } = post;
  return (
    <Grid item xs={12} md={3}>
      <Card className={classes.card}>
        <div className={classes.cardDetails}>
          <Grid>
            {headericon.map((network) => (
              <CardHeader
                avatar={
                  <Avatar variant="square" className={classes.avatar}>
                    <network.icon />
                  </Avatar>
                }
                align="end"
              />
            ))}
          </Grid>
          <CardContent>
            <Typography variant="h5">{actualno}</Typography>
            <Typography className={classes.tagline}>{description}</Typography>
          </CardContent>
        </div>
      </Card>
    </Grid>
  );
}
