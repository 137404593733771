import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { TableHeader, StyledTableRow } from "../../Base/TableHeader";
import { getComparator, sortArray } from "../../../helpers/sorting";
import clsx from "clsx";
import { MoreButton } from "../../Button/Button";
import Grid from "@material-ui/core/Grid";
import TripCreationStyle from "../../../assets/css/TripCreationStyle";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { SuccessButton } from "../../Button/Button";
import axiosRequest from "../../../helpers/axios";
import SnackBarComponent from "../../../helpers/SnackBarComponent";
import CircularProgress from "@material-ui/core/CircularProgress";
import { downloadFile, checkUserDetails} from "../../../helpers/RouteValidation";
import exportToCsv from "../../../helpers/exportToCsv";
import DocumentDialog from "./DocumentDialog";
import GetAppIcon from "@material-ui/icons/GetApp";
import moment from 'moment';
import XLSX from "xlsx";
const headers = [
  {
    id: "trip_id",
    sortable: true,
    align: "center",
    label: "Trip ID",
  },
  {
    id: "bol_no",
    sortable: true,
    align: "center",
    label: "BOL Number",
    search: true,
  },
  {
    id: "vehicle_number",
    align: "center",
    sortable: true,
    label: "Vehicle Number",
  },
  {
    id: "einvoice_required",
    align: "center",
    sortable: true,
    label: "E-Invoice Required",
  },
  {
    id: "taxinvoice_required",
    align: "center",
    sortable: true,
    label: "TaxInvoice Required",
  },
  {
    id: "irn_number",
    align: "center",
    sortable: true,
    label: "IRN",
  },
  {
    id: "bol_details",
    align: "center",
    label: "Download eWaybill data",
  },
  {
    id: "eWaybill_no",
    align: "center",
    label: "eWaybill",
  },
  {
    id: "tax_invoice",
    align: "center",
    label: "Tax Invoice/Delivery Challan",
  },
];

const useStyles = makeStyles(TripCreationStyle);

function GenerateEwayBillHandler(props) {
  const classes = useStyles();
  const { generateEWayBill, row, showLoader, eWayBolNumber } = props;
  return (
    <React.Fragment>
      {!showLoader ? (
        <MoreButton
          className={classes.button}
          variant="contained"
          disableElevation
          onClick={() => generateEWayBill(row)}
          disabled={
            row.einvoice_required === "Y" && row.irn_number === ""
              ? true
              : false
          }
        >
          <Typography className={clsx(classes.fs12, "ttn")}>
            Generate eWaybill
          </Typography>
        </MoreButton>
      ) : (
        <Typography
          className={clsx(
            classes.fs12,
            "ttn",
            "flex",
            "items-center",
            "justify-center"
          )}
        >
          <CircularProgress size={20} />
          {eWayBolNumber === row.bol_no ? (
            <span className="ml2">Generating...</span>
          ) : (
            <span className="ml2">Please wait...</span>
          )}
        </Typography>
      )}
    </React.Fragment>
  );
}

function GeneratenewEwayBillHandler(props) {
  const classes = useStyles();
  const { generatenewEWayBill, row, showLoader, eWayBolNumber } = props;
  return (
    <React.Fragment>
      {!showLoader ? (
        <MoreButton
          className={classes.button}
          variant="contained"
          disableElevation
          onClick={() => generatenewEWayBill(row)}
          disabled={
            row.einvoice_required === true || row.einvoice_required === false 
              ? false
              : true
          }
        >
          {row.einvoice_required===true?(
            <React.Fragment>
              <Typography className={clsx(classes.fs12, "ttn")}>
            Generate IRN/eWaybill 
           </Typography>
            </React.Fragment>
            ):
         (
           <React.Fragment>
             <Typography className={clsx(classes.fs12, "ttn")}>
            Generate eWaybill
             </Typography>
           </React.Fragment>
          )}
        </MoreButton>
      ) : (
        <Typography
          className={clsx(
            classes.fs12,
            "ttn",
            "flex",
            "items-center",
            "justify-center"
          )}
        >
          <CircularProgress size={20} />
          {eWayBolNumber === row.bol_no ? (
            <span className="ml2">Generating...</span>
          ) : (
            <span className="ml2">Please wait...</span>
          )}
        </Typography>
      )}
    </React.Fragment>
  );
}

export default function EWayBill(props) {
  const { handleNext, eWayBillData, getBOLNumbers ,getnewBOLNumbers} = props;
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("trip_id");
  const [tableData, setTableData] = React.useState([]);
  const [showLoader, setShowLoader] = React.useState(false);
  const [eWayBolNumber, setEWayBolNumber] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const [content, setContent] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [type,setType] = React.useState("")
  const [bolno,setBolno] = React.useState("")
  const [boldetails,setBolDetails] = React.useState([])
  const [checkAllEwayCompleted, setCheckAllEwayCompleted] = React.useState(false);
  let userrole=checkUserDetails()
  function TaxDocumentDialogOpen(row) {
    setOpen(true);
    setType("taxinvoice")
    setBolno(row.bol_no)
  }

  function DeliveryDocumentDialogOpen(row) {
    setOpen(true);
    setType("delivery")
    setBolno(row.bol_no)
  }

  function DocumentDialogClose() {
    setOpen(false);
  }
  const handleClose = () => {
    setOpensb(false);
  };

  const handleSelectedRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const generateEWayBill = async (row) => {
    setShowLoader(true);
    setEWayBolNumber(row.bol_no);
    await axiosRequest({
      url: "eWayBillPartB/generate",
      method: "POST",
      data: {
        bol_no: row.bol_no,
        vendorName: row.trip_data.vendor_id.vendor_name,
        vehicleNumber: row.vehicle_number,
        irnNumber: row.irn_number ? row.irn_number : "sample",
      },
    }).then((data) => {
      if (data.status === 200) {
        setShowLoader(false);
        setEWayBolNumber("");
        getBOLNumbers();
        handleSnackBar("E-Way Bill Generated Successfully", "success");
      } else {
        handleSnackBar(data, "error");
        setShowLoader(false);
      }
    });
  };
  const generatenewEWayBill = async (row) => {
    setShowLoader(true);
    setEWayBolNumber(row.bol_no);
    await axiosRequest({
      url: "eWayBillPartB/generateirn",
      method: "POST",
      data: {
        bol_no: row.bol_no,
        vendorName: row.trip_data.vendor_id.vendor_name,
        vehicleNumber: row.vehicle_number,
        irnNumber: row.irn_number ? row.irn_number : "sample",
        einvoice_required:row.einvoice_required
      },
    }).then((data) => {
      if (data.status === 200) {
        setShowLoader(false);
        setEWayBolNumber("");
        getnewBOLNumbers();
        handleSnackBar("E-Way Bill Generated Successfully", "success");
      } else {
        handleSnackBar(data, "error");
        setShowLoader(false);
      }
    });
  };

  const handleNewTaxInvoice = async (row) => {
    await axiosRequest({
      url: "eWayBillPartB/taxinvoice/"+row.bol_no,
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        console.log(data)
        downloadFile(data.data);
        handleSnackBar("Downloaded Successfully", "success");
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const handleTaxInvoice = async (row) => {
    await axiosRequest({
      url: "eWayBillPartB/taxinvoice/generate/"+row.bol_no,
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        console.log(data)
        downloadFile(data.data);
        handleSnackBar("Downloaded Successfully", "success");
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const handleDeliveryChallan = async (row) => {
    await axiosRequest({
      url: "eWayBillPartB/deliverychallan/generate/"+row.bol_no,
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        downloadFile(data.data);
        handleSnackBar("Downloaded Successfully", "success");
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const handleNewDeliveryChallan = async (row) => {
    await axiosRequest({
      url: "eWayBillPartB/taxinvoice/"+row.bol_no,
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        downloadFile(data.data);
        handleSnackBar("Downloaded Successfully", "success");
      } else {
        handleSnackBar(data, "error");
      }
    });
  };
function setBolDetailsdata(data){
  setBolDetails(data)
  download(items)
}

const DownloadBolDetails = async(row)=>{
  await axiosRequest({
    url: "eWayBillPartB/getjsondata",
    method: "POST",
    data: {
      bol_no: row.bol_no,
      vendorName: row.trip_data.vendor_id.vendor_name,
      vehicleNumber: row.vehicle_number,
      irnNumber: row.irn_number ? row.irn_number : "sample",
    },
  }).then((data) => {
    if (data.status === 200) {
      // debugger;
      setBolDetailsdata(data.data);
      handleSnackBar("Downloaded Successfully", "success");
      } else {
        handleSnackBar(data, "error");
  }
});
}

function changeDateFormat(inputDate){  
  var splitDate = inputDate.split('-');
  console.log(splitDate[0],splitDate[1],splitDate[2])
  if(splitDate.count == 0){
      return null;
  }

  var year = splitDate[2];
  var month = splitDate[1];
  var day = splitDate[0]; 
  console.log(month + '\\' + day + '\\' + year)
  return splitDate[0];
}

const items = boldetails.map((item) => {
  return{
    "SUPPLY_TYPE":item.SUPPLY_TYPE,
    "SUB_TYPE":item.SUB_TYPE,
    "DOC_TYPE":item.DOC_TYPE,
    "DOC_NO":item.DOC_NO,
    "DOC_DATE":item.DOC_DATE,
    "TRANSACTION_TYPE":item.TRANSACTION_TYPE,
    "FROM_OTHERPARTYNAME":item.FROM_OTHERPARTYNAME,
    "FROM_GSTIN":item.FROM_GSTIN,
    "FROM_ADDRESS1":item.FROM_ADDRESS1.replace("&","").replace("$","").replace("#","").replace("à",'a').replace(":","").replace("'","").replace("?","").replace("+","").replace("=","").replace("{","").replace("[","").replace("–",""),
    "FROM_ADDRESS2":item.FROM_ADDRESS2.replace("&","").replace("$","").replace("#","").replace("à",'a').replace(":","").replace("'","").replace("?","").replace("+","").replace("=","").replace("{","").replace("[","").replace("–",""),
    "FROM_PLACE":item.FROM_PLACE,
    "FROM_PIN_CODE":item.FROM_PIN_CODE,
    "FROM_STATE":item.FROM_STATE,
    "DISPATCH_STATE":item.DISPATCH_STATE,
    "TO_OTHERPARTYNAME":item.TO_OTHERPARTYNAME,
    "TO_GSTIN":item.TO_GSTIN,
    "TO_ADDRESS1":item.TO_ADDRESS1.replace("&","").replace("$","").replace("#","").replace("à",'a').replace(":","").replace("'","").replace("?","").replace("+","").replace("=","").replace("{","").replace("[","").replace("–",""),
    "TO_ADDRESS2":item.TO_ADDRESS2.replace("&","").replace("$","").replace("#","").replace("à",'a').replace(":","").replace("'","").replace("?","").replace("+","").replace("=","").replace("{","").replace("[","").replace("–",""),
    "TO_PLACE":item.TO_PLACE,
    "TO_PIN_CODE":item.TO_PIN_CODE,
    "TO_STATE":item.TO_STATE,
    "SHIP_TO_STATE":item.SHIP_TO_STATE,
    "PRODUCT":"null",
    "DESCRIPTION":"null",
    "HSN_CODE":item.HSN_CODE,
    "UNITS":item.UNITS,
    "QTY":item.QTY,
    "ASSESSABLE_VALUE":item.ASSESSABLE_VALUE,
    "TAX_RATE":item.TAX_RATE,
    "CGST_AMOUNT":item.CGST_AMOUNT,
    "SGST_AMOUNT":item.SGST_AMOUNT,
    "IGST_AMOUNT":item.IGST_AMOUNT,
    "CESS_AMOUNT":item.CESS_AMOUNT,
    "ADVLRM_AMOUNT":item.ADVLRM_AMOUNT,
    "OTHERS_AMOUNT":item.OTHERS_AMOUNT,
    "TOTAL INVOICE VALUE":item.TOTAL_INVOICE_VALUE,
    "EINVOICE_APPLICABILITY":item.EINVOICE_APPLICABILITY,
    "EINVOICE_GENERATED":item.EINVOICE_GENERATED,
    "IRN":item.IRN,
    "EWAY_BILL_GENERATION_SOURCE":item.EWAY_BILL_GENERATION_SOURCE
  }
   
 
});

const download=(items)=>{
  const data = XLSX.utils.json_to_sheet(items);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, data, "E-Way Bill transfer Report");
  XLSX.writeFile(wb,'E-Way Bill transfer Report on ' + new Date() + '.xlsx');
  // exportToCsv(, csvData);
  handleSnackBar("Downloaded Successfully", "success");
}

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };

  const handleSearchBar = (event, property) => {
    const searchResult = eWayBillData.filter((row) => {
      return row["bol_no"]
        .toString()
        .toLowerCase()
        .includes(event.target.value.toLowerCase());
    });
    setTableData(searchResult);
  };

  const checkIfEwayBillPending = (data) => {
    const pendingExist = data.find(
      (item) => !item.ewaybill_file && item.einvoicerequired && item.vehicle_number !== "data_veh" && !item.irn
    );
    if (pendingExist) return true;
    return false;
  };

  React.useEffect(() => {
    setTableData(eWayBillData);
  }, [eWayBillData]);

  React.useEffect(() => {
    if (tableData?.length) {
      const ewayIsPending = checkIfEwayBillPending(tableData);
      if (ewayIsPending) setCheckAllEwayCompleted(true);
      else setCheckAllEwayCompleted(false);
    }
  }, [tableData]);

  // React.useEffect(()=>{
  //   download()
  // },[boldetails]);

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TableContainer className={classes.container}>
          <Table stickyHeader>
            <TableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleSelectedRequestSort}
              rowCount={tableData.length}
              headers={headers}
              isCheckboxEnabled={false}
              handleSearch={handleSearchBar}
            />
            <TableBody>
              {sortArray(tableData, getComparator(order, orderBy)).map(
                (row, index) => {
                  return (
                    <StyledTableRow tabIndex={-1} tabIndex={-1} key={index}>
                      <TableCell className={classes.fs12} align="center">
                        {row.trip_id}
                      </TableCell>
                      <TableCell className={classes.fs12} align="center">
                        {row.bol_no}
                      </TableCell>
                      <TableCell className={classes.fs12} align="center">
                        {row.vehicle_number}
                      </TableCell>
                      <TableCell className={classes.fs12} align="center">
                        {row.einvoice_required === true || row.einvoice_required==='Y'? "Yes" :row.einvoice_required===false || row.einvoice_required==='N'?"No":""}
                      </TableCell>
                      <TableCell className={classes.fs12} align="center">
                        {row.taxinvoice_required === true || row.taxinvoice_required==='Y'? "Yes" :row.taxinvoice_required===false || row.taxinvoice_required==='N'?"No":""}
                      </TableCell>
                      <TableCell className={classes.fs12} align="center">
                        {row.irn_number}
                      </TableCell>
                      <TableCell className={classes.fs12} align="center">
                      {userrole.role=="logisticsirn"?(
                      <MoreButton
                           variant="contained"
                           color="primary"
                           component="span"
                           className={classes.deliverychallanbutton}
                           endIcon={<GetAppIcon fontSize="small" />}
                           onClick={()=>DownloadBolDetails(row)}
                           disabled={
                            row.einvoice_required === true || row.einvoice_required === false 
                              ? false
                              : true
                          }
                         >
                          eWaybill Data
                        </MoreButton>):("")}
                      </TableCell>
                      {userrole.role=="logisticsirn"?(<TableCell className={classes.fs12} align="center">
                        {row.ewaybill_no == 0 
                        ? (
                          <GeneratenewEwayBillHandler
                            generatenewEWayBill={generatenewEWayBill}
                            row={row}
                            showLoader={showLoader}
                            eWayBolNumber={eWayBolNumber}
                          />
                        ) :
                        (
                          <a
                            component="button"
                            variant="body2"
                            className={clsx(classes.fs12, classes.textColor)}
                            href={row.ewaybill_file}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {row.ewaybill_no}
                          </a>
                        )}
                      </TableCell>):(<TableCell className={classes.fs12} align="center">
                        {row.ewaybill_no == 0 
                        ? (
                          <GenerateEwayBillHandler
                            generateEWayBill={generateEWayBill}
                            row={row}
                            showLoader={showLoader}
                            eWayBolNumber={eWayBolNumber}
                          />
                        ) :
                        (
                          <a
                            component="button"
                            variant="body2"
                            className={clsx(classes.fs12, classes.textColor)}
                            href={row.ewaybill_file}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {row.ewaybill_no}
                          </a>
                        )}
                      </TableCell>)}
                      {row.ewaybill_no != 0 && userrole.role=="logisticsirn" || userrole.role=="logistics"?(<TableCell className={classes.fs12} align="center">
                         {row.taxinvoice_required === true || row.taxinvoice_required==='Y' ? (
                           <MoreButton
                           variant="contained"
                           color="primary"
                           component="span"
                           className={classes.deliverychallanbutton}
                           disabled={
                            row.taxinvoice_required === true || row.taxinvoice_required === false || row.taxinvoice_required === 'Y' || row.taxinvoice_required === 'N'
                              ? false
                              : true
                          }
                           onClick={userrole.role=="logisticsirn"?(()=>handleNewTaxInvoice(row)):(()=>handleTaxInvoice(row))}
                         >
                           Generate Tax Invoice
                         </MoreButton>
                        ) : (
                          <MoreButton
                          variant="contained"
                          color="primary"
                          component="span"
                          className={classes.deliverychallanbutton}
                          disabled={
                            row.taxinvoice_required === true || row.taxinvoice_required === false || row.taxinvoice_required === 'Y' || row.taxinvoice_required === 'N'
                              ? false
                              : true
                          }
                          onClick={userrole.role=="logisticsirn"?(()=>handleNewDeliveryChallan(row)):(() => handleDeliveryChallan(row))}
                        >
                          Generate Delivery challan
                        </MoreButton>
                        )}
                      </TableCell>):(<TableCell className={classes.fs12} align="center">
                         {row.taxinvoice_required === true || row.taxinvoice_required==='Y'  ? (
                           <MoreButton
                           variant="contained"
                           color="primary"
                           component="span"
                           className={classes.deliverychallanbutton}
                           disabled={
                            row.taxinvoice_required === true || row.taxinvoice_required === false || row.taxinvoice_required === 'Y' || row.taxinvoice_required === 'N'
                              ? false
                              : true
                          }
                           onClick={()=>TaxDocumentDialogOpen(row)}
                         >
                           Generate Tax Invoice
                         </MoreButton>
                        ) : (
                          <MoreButton
                          variant="contained"
                          color="primary"
                          component="span"
                          className={classes.deliverychallanbutton}
                          disabled={
                            row.taxinvoice_required === true || row.taxinvoice_required === false || row.taxinvoice_required === 'Y' || row.taxinvoice_required === 'N'
                              ? false
                              : true
                          }
                          onClick={()=>DeliveryDocumentDialogOpen(row)}
                        >
                          Generate Delivery challan
                        </MoreButton>
                        )}
                      </TableCell>)}
                    </StyledTableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="flex justify-center pa3">
          <SuccessButton
            variant="contained"
            disableElevation
            onClick={handleNext}
            disabled={checkAllEwayCompleted}
          >
            <Typography className={clsx(classes.fs12, "ttn")}>
              Proceed to Image Upload
            </Typography>
          </SuccessButton>
        </div>
      </Grid>
      <DocumentDialog
      open={open}
      type={type}
      DialogClose={DocumentDialogClose}
      bolno={bolno}/>
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </Grid>
  );
}
