import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Normal from "../../Typography/Normal";
import CloseIcon from "@material-ui/icons/Close";
import TripCreationStyle from "../../../assets/css/TripCreationStyle";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { Button, DialogActions } from "@material-ui/core";
import { SuccessButton,FailureButton } from "../../Button/Button";
import axiosRequest from "../../../helpers/axios";
import SnackBarComponent from "../../../helpers/SnackBarComponent";
import { checkUserDetails, downloadFile } from "../../../helpers/RouteValidation";
import { TextField } from "@material-ui/core";

const useStyles = makeStyles(TripCreationStyle);

const useStyles1 = makeStyles((theme) => ({
  root: {
    "& .MuiFormLabel-root": {
      fontSize: "12px",
      lineHeight: 0,
    },
  },
}));


export default function DocumentDialog(props) {
  const classes = useStyles();
  const classes1=useStyles1();
  const { open, DialogClose,type,bolno } = props;
  const [severity, setSeverity] = React.useState("");
  const [content, setContent] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);
  const [ewaybillno,setEwaybillno] = React.useState("")
  let userrole=checkUserDetails()
  const handleClose = () => {
    setOpensb(false);
  };

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };

  const handleTaxInvoice = async () => {
    EwaybillNoUpdate()
    await axiosRequest({
      url: "eWayBillPartB/taxinvoice/generate/"+bolno,
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        console.log(data)
        downloadFile(data.data);
        handleClose()
        handleSnackBar("Downloaded Successfully", "success");
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const handleNewTaxInvoice = async () => {
    EwaybillNoUpdate()
    await axiosRequest({
      url: "eWayBillPartB/taxinvoice/"+bolno,
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        console.log(data)
        downloadFile(data.data);
        handleSnackBar("Downloaded Successfully", "success");
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const handleDeliveryChallan = async () => {
    EwaybillNoUpdate()  
    await axiosRequest({
      url: "eWayBillPartB/deliverychallan/generate/"+bolno,
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        downloadFile(data.data);
        handleClose()
        handleSnackBar("Downloaded Successfully", "success");
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const handleNewDeliveryChallan = async () => {
    EwaybillNoUpdate()  
    await axiosRequest({
      url: "eWayBillPartB/taxinvoice/"+bolno,
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        downloadFile(data.data);
        handleSnackBar("Downloaded Successfully", "success");
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const EwaybillNoUpdate= async () => {
    await axiosRequest({
      url: "eWayBillPartB/ewaybillupdate",
      method: "PUT",
      data:{
        bol_no:bolno,
        ewaybill_no:ewaybillno
      }
    }).then((data) => {
      if (data.status === 200) {
        handleSnackBar("Updated Successfully", "success");
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const handleValue=(event)=>{
    setEwaybillno(event.target.value)
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      aria-labelledby="confirmation-dialog-title"
      classes={{ paperWidthSm: classes.vehicleMasterDialog }}
    >
      <DialogTitle id="confirmation-dialog-title">
        <div className="flex justify-between items-center">
          <Normal>
            <h3 className={classes.textColor}>Document</h3>
          </Normal>
          <IconButton>
            <CloseIcon onClick={DialogClose} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>
      <h1 class={classes.fs24}>Ewaybill is not auto generated yet. If you want ewaybill no in transfer document,please enter the ewaybill no in the below field.</h1>
      <label>Ewaybill No:</label>
      <div className={classes.bgInput1}>
                          <TextField
                            variant="outlined"
                            InputProps={{
                              inputProps: {
                                min: 0,
                              },
                            }}
                            className={clsx(
                              classes.inputNumber,
                              classes.underline,
                              classes.fs12
                            )}
                            type="number"
                            onChange={
                              handleValue
                            }
                          />
      </div>
      </DialogContent>
      <DialogActions>
          <FailureButton
            variant="contained"
            onClick={DialogClose}
            disableElevation
          >
            <Typography className={clsx(classes.fs12, "ttn")}>No</Typography>
          </FailureButton>
          {type==="taxinvoice"?(<div className="flex justify-center">
          <SuccessButton
            variant="contained"
            onClick={userrole.role=="logisticsirn"?(handleNewTaxInvoice):(handleTaxInvoice)}
            disableElevation
          >
            <Typography className={clsx(classes.fs12, "ttn")}>Yes</Typography>
          </SuccessButton>
        </div>):(<div className="flex justify-center">
          <SuccessButton
            variant="contained"
            onClick={userrole.role=="logisticsirn"?(handleNewDeliveryChallan):(handleDeliveryChallan)}
            disableElevation
          >
            <Typography className={clsx(classes.fs12, "ttn")}>Yes</Typography>
          </SuccessButton>
        </div>)}
      </DialogActions>
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </Dialog>
  );
}
