import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { TableHeader, StyledTableRow } from "../../Base/TableHeader";
import { getComparator, sortArray } from "../../../helpers/sorting";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { MoreButton, SuccessButton } from "../../Button/Button";
import TripCreationStyle from "../../../assets/css/TripCreationStyle";
import axiosRequest from "../../../helpers/axios";
import Link from "@material-ui/core/Link";
import TripStoreDetailDialog from "../../Base/TripStoreDetailDialogue";
import SnackBarComponent from "../../../helpers/SnackBarComponent";
import FandVCrateDialog from "./FandVCrateModal";
import { Button } from "@material-ui/core";
import { getLocationId, downloadFile } from "../../../helpers/RouteValidation";
import { TextField } from "@material-ui/core";
import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";

const headers = [
  {
    id: "location",
    sortable: true,
    align: "center",
    label: "Location",
  },
  {
    id: "location_name",
    sortable: true,
    align: "center",
    label: "Location Name",
  },
  {
    id: "transfer_no",
    align: "center",
    label: "Bol No",
  },
  {
    id: "blue_tub_lpn",
    sortable: true,
    align: "center",
    label: "Blue Tub Lpn",
  },
  {
    id: "shipper_box_lpn",
    sortable: true,
    align: "center",
    label: "Shipper Box Lpn",
  },
  {
    id: "red_tub_lpn",
    sortable: true,
    align: "center",
    label: "Red Tub Lpn",
  },
  {
    id: "hoffman_shipper",
    sortable: true,
    align: "center",
    label: "Hoffman Shipper",
  },
  {
    id: "generic_lpn",
    sortable: true,
    align: "center",
    label: "Generic Lpn",
  },
  {
    id: "crate_big",
    sortable: true,
    align: "center",
    label: "Crate Big",
  },
  {
    id: "crate_medium",
    sortable: true,
    align: "center",
    label: "Crate Medium",
  },
  {
    id: "crate_small",
    sortable: true,
    align: "center",
    label: "Crate Small",
  },
  {
    id: "shipper_box",
    align: "center",
    sortable: true,
    label: "Shipper Box",
  },
  {
    id: "others_1",
    align: "center",
    sortable: true,
    label: "Others",
  },
  {
    id: "total_pkg",
    align: "center",
    sortable: true,
    label: "Total pkg",
  },
  {
    id: "update_crates",
    align: "center",
    sortable: true,
    label: "Update Crate Values",
  },
];

const useStyles = makeStyles(TripCreationStyle);

export function TripSheetHeader(props) {
  const { tripid, handleBackToVehicleImage, dc, setSealNo, sealno } = props;
  const classes = useStyles();
  const handleSealNo = (event) => {
    setSealNo(event.target.value);
  };
  return (
    <React.Fragment>
      <div className="flex justify-between pa3">
        <Typography variant="body2">TripSheet:{tripid}</Typography>
        <Typography variant="body2">
          SealNo:
          <div className={"mb1"}>
            <TextField
              variant="outlined"
              value={sealno}
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
              className={clsx(
                classes.sealNumber,
                classes.underline,
                classes.fs12
              )}
              type="number"
              onChange={handleSealNo}
            />
          </div>
        </Typography>
        <Typography variant="body2">DC:{dc}</Typography>
        <div>
          <Button
            variant="contained"
            disableElevation
            className={classes.button}
            onClick={handleBackToVehicleImage}
            startIcon={<ChevronLeftRoundedIcon fontSize="small" />}
          >
            <Typography className={clsx(classes.fs12, "ttn")}>Back</Typography>
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default function TripSheet(props) {
  const classes = useStyles();
  const { tripid, handleBackToVehicleImage } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("trip_id");
  const [tableData, setTableData] = React.useState([]);
  const [severity, setSeverity] = React.useState("");
  const [content, setContent] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);
  const [sealno, setSealNo] = React.useState("");
  const [contextdata, setContextData] = React.useState("");
  const [dc, setDC] = React.useState("");
  const handleClose = () => {
    setOpensb(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };

  React.useEffect(() => {
    getTripSheet();
  }, []);

  const handleTotal = (row, event, property, index) => {
    row[property] = event.target.value;
    row["total_pkg"] =
      row["blue_tub_lpn"] +
      row["shipper_box_lpn"] +
      row["red_tub_lpn"] +
      row["hoffman_shipper"] +
      row["generic_lpn"] +
      Number(row["crate_big"]) +
      Number(row["crate_medium"]) +
      Number(row["crate_small"]) +
      Number(row["shipper_box"]) +
      Number(row["others_1"]);
    var tempTableData = [...tableData];
    tempTableData[index] = row;
    setTableData(tempTableData);
  };

  const getTripSheet = async () => {
    await axiosRequest({
      url: "eWayBillPartB/tripsheet/" + tripid,
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        console.log(data.data);
        setContextData(data.data);
        setTableData(data.data["tableData"]);
        setDC(data.data["dc"]);
        console.log(dc);
        // handleSnackBar("Downloaded Successfully", "success");
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const CrateBigTotalFunction = () => {
    let crate_big_total = 0;
    for (let i in tableData) {
      crate_big_total += Number(tableData[i]["crate_big"]);
    }
    return crate_big_total;
  };

  const CrateMediumTotalFunction = () => {
    let crate_medium_total = 0;
    for (let i in tableData) {
      crate_medium_total += Number(tableData[i]["crate_medium"]);
    }
    return crate_medium_total;
  };

  const CrateSmallTotalFunction = () => {
    let crate_small_total = 0;
    for (let i in tableData) {
      crate_small_total += Number(tableData[i]["crate_small"]);
    }
    return crate_small_total;
  };
  const ShipperBoxTotalFunction = () => {
    let shipper_box_total = 0;
    for (let i in tableData) {
      shipper_box_total += Number(tableData[i]["shipper_box"]);
    }
    return shipper_box_total;
  };
  const OthersTotalFunction = () => {
    let others_total = 0;
    for (let i in tableData) {
      others_total += Number(tableData[i]["others_1"]);
    }
    return others_total;
  };
  const TotalPkgFunction = () => {
    let total_total_pkg = 0;
    for (let i in tableData) {
      total_total_pkg += Number(tableData[i]["total_pkg"]);
    }
    return total_total_pkg;
  };

  const updatecratevalues=async(row)=>{
    await axiosRequest({
      url:"eWayBillPartB/updatecrates/",
      method:"POST",
      data:{
        trip_id:tripid,
        store_id:row.location,
        crate_small:row.crate_small,
        crate_medium:row.crate_medium,
        crate_big:row.crate_big,
        shipper_box:row.shipper_box,
        others:row.others_1,
      }
    }).then((data) => {
      if (data.status === 200) {
        handleSnackBar("Updated crates Successfully", "success");
      } else {
        handleSnackBar(data, "error");
      }
    });
  }
  const generateTripSheet = async () => {
    await axiosRequest({
      url: "eWayBillPartB/tripsheet/generate/",
      method: "POST",
      data: {
        context_data: {
          printing_date: contextdata["printing_date"],
          trip_sheet_id: tripid,
          vehicle_number: contextdata["vehicle_number"],
          dc: dc,
          seal_number: sealno,
          created_by: "",
          lr_no: 0,
          route_id: 0,
          in_time: "",
          out_time: "",
          created_date: "",
          tableData: tableData,
          blue_tab_lpn_total: contextdata["blue_tab_lpn_total"],
          shipper_box_lpn_total: contextdata["shipper_box_lpn_total"],
          red_tub_lpn_total: contextdata["red_tub_lpn_total"],
          hoffman_shipper_total: contextdata["hoffman_shipper_total"],
          generic_lpn_total: contextdata["generic_lpn_total"],
          crate_big_total: CrateBigTotalFunction(),
          crate_medium_total: CrateMediumTotalFunction(),
          crate_small_total: CrateSmallTotalFunction(),
          shipper_box_total: ShipperBoxTotalFunction(),
          others_1_total: OthersTotalFunction(),
          others_2_total: 0,
          total: TotalPkgFunction(),
        },
        trip_sheet_id: tripid,
        total: TotalPkgFunction(),
      },
    }).then((data) => {
      if (data.status === 200) {
        downloadFile(data.data);
        handleSnackBar("Downloaded Successfully", "success");
      } else {
        handleSnackBar(data, "error");
      }
    });
    handleBackToVehicleImage()
  };

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TripSheetHeader
          tripid={tripid}
          handleBackToVehicleImage={handleBackToVehicleImage}
          dc={dc}
          setSealNo={setSealNo}
          sealno={sealno}
        />
        <TableContainer className={classes.container}>
          <Table stickyHeader>
            <TableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={tableData.length}
              headers={headers}
              isCheckboxEnabled={false}
            />
            <TableBody>
              {sortArray(tableData, getComparator(order, orderBy)).map(
                (row, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <TableCell align="center" className={classes.fs12}>
                        {row.location}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.location_name}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {String(row.transfer_no)}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.blue_tub_lpn}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.shipper_box_lpn}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.red_tub_lpn}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.hoffman_shipper}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.generic_lpn}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        <div className={classes.bgInput1}>
                          <TextField
                            variant="outlined"
                            value={row.crate_big}
                            InputProps={{
                              inputProps: {
                                min: 0,
                              },
                            }}
                            className={clsx(
                              classes.inputNumber,
                              classes.underline,
                              classes.fs12
                            )}
                            type="number"
                            onChange={(e) =>
                              handleTotal(row, e, "crate_big", index)
                            }
                          />
                        </div>
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        <div className={classes.bgInput1}>
                          <TextField
                            variant="outlined"
                            value={row.crate_medium}
                            InputProps={{
                              inputProps: {
                                min: 0,
                              },
                            }}
                            className={clsx(
                              classes.inputNumber,
                              classes.underline,
                              classes.fs12
                            )}
                            type="number"
                            onChange={(e) =>
                              handleTotal(row, e, "crate_medium", index)
                            }
                          />
                        </div>
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        <div className={classes.bgInput1}>
                          <TextField
                            variant="outlined"
                            value={row.crate_small}
                            InputProps={{
                              inputProps: {
                                min: 0,
                              },
                            }}
                            className={clsx(
                              classes.inputNumber,
                              classes.underline,
                              classes.fs12
                            )}
                            type="number"
                            onChange={(e) =>
                              handleTotal(row, e, "crate_small", index)
                            }
                          />
                        </div>
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        <div className={classes.bgInput1}>
                          <TextField
                            variant="outlined"
                            value={row.shipper_box}
                            InputProps={{
                              inputProps: {
                                min: 0,
                              },
                            }}
                            className={clsx(
                              classes.inputNumber,
                              classes.underline,
                              classes.fs12
                            )}
                            type="number"
                            onChange={(e) =>
                              handleTotal(row, e, "shipper_box", index)
                            }
                          />
                        </div>
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        <div className={classes.bgInput1}>
                          <TextField
                            variant="outlined"
                            value={row.others_1}
                            InputProps={{
                              inputProps: {
                                min: 0,
                              },
                            }}
                            className={clsx(
                              classes.inputNumber,
                              classes.underline,
                              classes.fs12
                            )}
                            type="number"
                            onChange={(e) =>
                              handleTotal(row, e, "others_1", index)
                            }
                          />
                        </div>
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.total_pkg}
                      </TableCell>
                      <MoreButton
                          variant="contained"
                          disableElevation
                          component="span"
                          className={clsx(classes.tripsheetbutton,"mt7")}
                          onClick={() => updatecratevalues(row)}
                        >
                          Update crate values
                        </MoreButton>
                    </StyledTableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="flex justify-center pa3">
          <SuccessButton
            variant="contained"
            disableElevation
            onClick={generateTripSheet}
          >
            <Typography className={clsx(classes.fs12, "ttn")}>save</Typography>
          </SuccessButton>
        </div>
      </Grid>
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </Grid>
  );
}
