import ToBolActiveIcon from "../../assets/images/ToBolActive";
import TripCreationAciveIcon from "../../assets/images/TripCreationAcive";
import TripPlanningActiveIcon from "../../assets/images/TripPlanningActive";
import PtlIcon from "../../assets/images/Ptl";

export const CardsContent = [
  {
    headericon: [{ icon: ToBolActiveIcon }],
    actualno: "1000",
    description: "BOL_NOs are available for the trip initiation",
  },
  {
    headericon: [{ icon: TripPlanningActiveIcon }],
    actualno: "500",
    description: "BOL_NOs are added to a trip (temporary trip ID available)",
  },
  {
    headericon: [{ icon: TripCreationAciveIcon }],
    actualno: "0",
    description: "Number of temporary trip ID (no truck assigned)",
  },
  {
    headericon: [{ icon: PtlIcon }],
    actualno: "2",
    description:
      "Number of trip ID created (truck assigned but pending dispatch)",
  },
];
