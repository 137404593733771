import * as React from "react";

function SvgTripCreationAcive(props) {
  return (
    <svg
      id="trip_creation_acive_svg__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      {...props}
    >
      <defs>
        <style>{".trip_creation_acive_svg__cls-1{fill:#f74e10}"}</style>
      </defs>
      <path
        className="trip_creation_acive_svg__cls-1"
        d="M22.42 37.59h9.15l.07.23a5.52 5.52 0 0010.67 0l.07-.23h4.47A1.16 1.16 0 0048 36.44V27a1.18 1.18 0 00-.24-.7l-3-3.81h.07L40.63 14a1.15 1.15 0 00-1-.63h-6.57v-1.8a1.14 1.14 0 00-1.15-1.14H12.78a1.15 1.15 0 000 2.29h18v10.53a1.14 1.14 0 001.14 1.15h11.5l2.29 3v7.94H42.4l-.06-.23a5.53 5.53 0 00-10.73 0l-.06.23h-9.1l-.06-.23a5.53 5.53 0 00-10.73 0l-.06.23h-4a1.15 1.15 0 000 2.3h4l.07.23a5.52 5.52 0 0010.67 0zm10.64-15.48v-6.47h5.83l3.25 6.47zm3.91 11a3.23 3.23 0 11-3.23 3.23A3.24 3.24 0 0137 33.16zM17 39.62a3.23 3.23 0 113.24-3.23A3.23 3.23 0 0117 39.62z"
      />
      <path
        className="trip_creation_acive_svg__cls-1"
        d="M12.55 27.9H5.83a1.15 1.15 0 100 2.29h6.72a1.15 1.15 0 000-2.29zM15.9 22.15H3.45a1.15 1.15 0 100 2.3H15.9a1.15 1.15 0 000-2.3zM18.29 16.41H5.83a1.15 1.15 0 100 2.3h12.46a1.15 1.15 0 100-2.3z"
      />
    </svg>
  );
}

export default SvgTripCreationAcive;
