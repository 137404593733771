import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { TableHeader, StyledTableRow } from "../../Base/TableHeader";
import { getComparator, sortArray } from "../../../helpers/sorting";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { SuccessButton } from "../../Button/Button";
import TripCreationStyle from "../../../assets/css/TripCreationStyle";

function createData(
  con_number,
  con_date,
  service_provider,
  from,
  to,
  weight,
  noOfPieces,
  totalFreightCost
) {
  return {
    con_number,
    con_date,
    service_provider,
    from,
    to,
    weight,
    noOfPieces,
    totalFreightCost,
  };
}

const rows = [
  // createData(4511241234, "10-Jun-21", "DHL", "Chennai DC", "MRL HO", 3, 6, 783),
];

const headers = [
  {
    id: "con_number",
    sortable: true,
    align: "center",
    label: "Consignment number",
  },
  {
    id: "con_date",
    sortable: true,
    align: "center",
    label: "Consignment date",
  },
  {
    id: "service_provider",
    sortable: true,
    align: "center",
    label: "Service provider",
  },
  {
    id: "from",
    sortable: true,
    align: "center",
    label: "From Location",
  },

  {
    id: "to",
    sortable: true,
    align: "center",
    label: "To Location",
  },
  {
    id: "weight",
    sortable: true,
    align: "center",
    label: "Weight (Kgs)",
  },
  {
    id: "noOfPieces",
    sortable: true,
    align: "center",
    label: "Number of Pieces",
  },

  {
    id: "totalFreightCost",
    sortable: true,
    align: "center",
    label: "Total Freight Cost",
  },
];

const useStyles = makeStyles(TripCreationStyle);

export default function CreatePTL() {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("con_number");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TableContainer className={classes.PTLContainer}>
          <Table stickyHeader>
            <TableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headers={headers}
              isCheckboxEnabled={false}
            />
            <TableBody>
              {sortArray(rows, getComparator(order, orderBy)).map(
                (row, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <TableCell align="center" className={classes.fs12}>
                        {row.con_number}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.con_date}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.service_provider}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.from}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.to}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.weight}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.noOfPieces}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        &#8377; {row.totalFreightCost.toFixed(2)}
                      </TableCell>
                    </StyledTableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="flex justify-center pa3">
          <SuccessButton variant="contained" disableElevation>
            <Typography className={clsx(classes.fs12, "ttn")}>Save</Typography>
          </SuccessButton>
        </div>
      </Grid>
    </Grid>
  );
}
