import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import { Link, Route, BrowserRouter as Router } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MenuList from "@material-ui/core/MenuList";
import MenuListMaster from "./MenuList";
import VehicleMaster from "../../components/Master/VehicleMaster";
import { Container, Switch } from "@material-ui/core";
import OriginDestinationMaster from "../../components/Master/OriginDestinationMaster/OriginDestinationMaster";
import LocationMaster from "../../components/Master/LocationMaster/LocationMaster";
import ContractMaster from "../../components/Master/ContractMaster/ContractMaster";
import VendorMaster from "../../components/Master/VendorMaster/VendorMaster";
import ItemSufMaster from "../../components/Master/ItemSufMaster/ItemSufMaster";
import {checkUserDetails} from "../../helpers/RouteValidation"
import { Redirect } from "react-router";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
    // marginTop: theme.spacing(2),
  },
}));

export default function Master(props) {
  const classes = useStyles();
  const [state, setState] = React.useState("");
  const [isAuthenticated, setIsAuthenticated] = React.useState(true);
  const [userrole,setUserRole]=React.useState("")

  function handleMenuItem() {
    setState(0);
    return state;
    // handlevehiclemaster(state);
  }

  function handleMenuItem1() {
    setState(1);
    return state;
    // handlevehiclemaster(state);
  }

  function handleMenuItem2() {
    setState(2);
    return state;
    // handlevehiclemaster(state);
  }

  function handleMenuItem3() {
    setState(3);
    return state;
    // handlevehiclemaster(state);
  }

  function handleMenuItem4() {
    setState(4);
    return state;
  }

  function handleMenuItem5() {
    setState(5);
    return state;
  }
  // function backToMenuList(){
  //   setState(10)
  //   return state;
  // }
  // function handleMenuItem() {
  //   setState(true);
  //   console.log(state);
  //   return state;
  //   // handlevehiclemaster(state);
  // }
  //   function handlevehiclemaster(state) {
  //     console.log(state)
  function handleClose() {
    setState("");
    return state;
    // handlevehiclemaster(state);
  }


  React.useEffect(() => {
    var user = checkUserDetails();
    if (user) {
      setUserRole(user.role)
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);
  // }
  return (
    <React.Fragment>
        {isAuthenticated ? 
        <React.Fragment>
        {/* <div className={classes.root}> */}
        <Paper elevation={0} square className={clsx(classes.paper,"mt4")}>
        {state == "0" ? (
        <VehicleMaster handleBack={handleClose}/>
      ) : state == "1" ? (
        <OriginDestinationMaster handleClose={handleClose} />
      ) :state=="2"?(
        <LocationMaster handleBack={handleClose}/>
      ):state=="3"?(
        <ContractMaster handleBack={handleClose}/>
      ):state=="4"?(
        <VendorMaster handleBack={handleClose}/>
      ):state=="5"?(
        <ItemSufMaster handleBack={handleClose}/>
      ):(
        <MenuListMaster state={state} handleMenuItem={handleMenuItem} handleMenuItem1={handleMenuItem1} handleMenuItem2={handleMenuItem2} handleMenuItem3={handleMenuItem3} handleMenuItem4={handleMenuItem4} handleMenuItem5={handleMenuItem5} userrole={userrole}/>
      )}
      </Paper>
      {/* </div> */}
        </React.Fragment>
       : (
        <Redirect to="/login" />
      )}
    </React.Fragment>
  );
}
