import React from "react";
import clsx from "clsx";
import StepConnector from "@material-ui/core/StepConnector";
import { moreColor } from "../../assets/css/BaseStyles";
import Check from "@material-ui/icons/Check";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: moreColor[0],
    },
    "& $text": {
      borderColor: moreColor[0],
    },
  },
  completed: {
    "& $line": {
      borderColor: moreColor[0],
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 2,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: moreColor[0],
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: moreColor[0],
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

export { QontoConnector, QontoStepIcon };
