import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { TableHeader, StyledTableRow } from "../../Base/TableHeader";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TripCreationStyle from "../../../assets/css/TripCreationStyle";
import { getComparator, sortArray } from "../../../helpers/sorting";

const headers = [
  {
    id: "date",
    align: "center",
    label: "Date",
    sortable: true,
  },
  {
    id: "fms_location_name",
    align: "center",
    label: "FMS Location Name",
    sortable: true,
  },
  {
    id: "trip_cost_fms",
    align: "center",
    label: "Trip Cost (FMS Estimate)",
    sortable: true,
  },
  {
    id: "trip_cost_ntm",
    align: "center",
    label: "Trip Cost (NTM Estimate)",
    sortable: true,
  },
];

const useStyles = makeStyles(TripCreationStyle);

export default function DateLevelSummary(props) {
  const classes = useStyles();

  const { DateLevel } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("created_date");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TableContainer>
          <Table stickyHeader>
            <TableHeader
              headers={headers}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {sortArray(DateLevel, getComparator(order, orderBy)).map(
                (row, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <TableCell align="center" className={classes.fs12}>
                        {row.date}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.fms_location_name}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        &#8377; {row.trip_cost_fms.toFixed(2)}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        &#8377; {row.trip_cost_ntm.toFixed(2)}
                      </TableCell>
                    </StyledTableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
