import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import {
  moreColor,
  successColor,
  whiteColor,
  dangerColor,
} from "../../assets/css/BaseStyles";

const MoreButton = withStyles(() => ({
  root: {
    color: whiteColor,
    backgroundColor: moreColor[0],
    "&:hover": {
      backgroundColor: moreColor[0],
    },
  },
}))(Button);


const FailureButton = withStyles(() => ({
  root: {
    color: whiteColor,
    backgroundColor: dangerColor[2],
    "&:hover": {
      backgroundColor: dangerColor[2],
    },
  },
}))(Button);

const SuccessButton = withStyles(() => ({
  root: {
    color: whiteColor,
    backgroundColor: successColor[2],
    "&:hover": {
      backgroundColor: successColor[2],
    },
  },
}))(Button);

const MoreIconButton = withStyles(() => ({
  root: {
    color: moreColor[0],
  },
}))(IconButton);

export { MoreButton, SuccessButton,FailureButton,MoreIconButton };
