import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
// import Card from "@material-ui/core/Card";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  tagline: {
    fontSize: 12,
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 580,
    height: 120,
  },
  root: {
    flexGrow: 1,
  },
}));

export default function BottomCard() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h5">40</Typography>
              <Typography className={classes.tagline}>
                Number of Trips Completed
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h5">35</Typography>
              <Typography className={classes.tagline}>
                Number of eWaybills created
              </Typography>
              <Typography className={classes.tagline}>
                (trucks dispatched)
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
