import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Normal from "../../Typography/Normal";
import CloseIcon from "@material-ui/icons/Close";
import TripCreationStyle from "../../../assets/css/TripCreationStyle";
import axiosRequest from "../../../helpers/axios";
import SnackBarComponent from "../../../helpers/SnackBarComponent";
import { Button, DialogActions } from "@material-ui/core";
import { SuccessButton,FailureButton } from "../../Button/Button";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";

const useStyles = makeStyles(TripCreationStyle);

export default function DeactivateDialog(props) {
  const classes = useStyles();
  
  const {open , DialogClose,contractid,contractdetailsid} = props;
  const [severity, setSeverity] = React.useState("");
  const [content, setContent] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);
  const handleClose = () => {
    setOpensb(false);
  };

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };
  const handleDeactivate = async () => {
    await axiosRequest({
      url: "master/deactivatecontract/",
      method: "POST",
      data:{
          contractid:contractid,
          contractdetailsid:contractdetailsid,
      }
    }).then((data) => {
      if (data.status === 200) {
        handleClose()
        handleSnackBar("deactivated contract successfully", "success");
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      aria-labelledby="confirmation-dialog-title"
      classes={{ paperWidthSm: classes. StoreTableDialog}}
    >
      <DialogTitle id="confirmation-dialog-title">
      <div className="flex justify-between items-center">
          <Normal>
            <h3 className={classes.textColor}>Trip Rates:</h3>
          </Normal>
          <IconButton>
            <CloseIcon onClick={DialogClose} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>
      <h1 class={classes.fs24}>Do you want to deactivate the contract?</h1>
      </DialogContent>
      <DialogActions>
          <FailureButton
            variant="contained"
            onClick={DialogClose}
            disableElevation
          >
            <Typography className={clsx(classes.fs12, "ttn")}>No</Typography>
          </FailureButton>
          <div className="flex justify-center">
          <SuccessButton
            variant="contained"
            onClick={handleDeactivate}
            disableElevation
          >
            <Typography className={clsx(classes.fs12, "ttn")}>Yes</Typography>
          </SuccessButton>
        </div>
      </DialogActions>
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </Dialog>
  );
}
