import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { TableHeader, StyledTableRow } from "../../Base/TableHeader";
import { getComparator, sortArray } from "../../../helpers/sorting";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { MoreButton, SuccessButton } from "../../Button/Button";
import TripCreationStyle from "../../../assets/css/TripCreationStyle";
import { Paper } from "@material-ui/core";
import AddContractMasterDialog from "./AddContractMasterDialog";
import RenewDialog from "./RenewDialog";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import EditDialog from "./EditDialog";
import TripRateDialog from "./TripRateDialog";
import Link from "@material-ui/core/Link";
import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";
import Button from "@material-ui/core/Button";
import axiosRequest from "../../../helpers/axios";
import SnackBarComponent from "../../../helpers/SnackBarComponent";
import exportToCsv from "../../../helpers/exportToCsv";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import DeactivateDialog from "./DeactivateDialog";

const headers = [
  {
    id: "vendor_id",
    sortable: true,
    align: "center",
    label: "Vendor_ID",
  },
  {
    id: "vendor_name",
    sortable: true,
    align: "center",
    label: "Vendor_name",
  },
  {
    id: "Payload",
    sortable: true,
    align: "center",
    label: "Payload",
  },
  {
    id: "contract_type",
    sortable: true,
    align: "center",
    label: "Contract Type",
  },
  {
    id: "contract_act_start_date",
    sortable: true,
    align: "center",
    label: "Contract Start Date",
  },

  {
    id: "contract_act_end_date",
    sortable: true,
    align: "center",
    label: "Contract End Date",
  },
  {
    id: "min_agreed_km",
    sortable: true,
    align: "center",
    label: "Min Agreed KM",
  },
  {
    id: "fixed_cost",
    sortable: true,
    align: "center",
    label: "Fixed Cost",
  },
  {
    id: "fixed_rate",
    sortable: true,
    align: "center",
    label: "Fixed Rate",
  },
  {
    id: "variable_rate",
    sortable: false,
    align: "center",
    label: "Variable Rate",
  },
  {
    id: "trip_rate",
    sortable: false,
    align: "center",
    label: "Trip rate",
  },
  {
    id: "status",
    sortable: false,
    align: "center",
    label: "status",
  },
  {
    id: "Action",
    sortable: false,
    align: "center",
    label: "Action",
  },
  {
    id: "Deactivate",
    sortable: false,
    align: "center",
    label: "Deactiavate contract",
  },
];

const useStyles = makeStyles(TripCreationStyle);
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      width: 300,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};
export function ContractHeader(props) {
  const classes = useStyles();
  const { handleBack,handleConfirm,downloadReport,vendorOptions,vendor,setVendor} = props;
  const [open, setOpen] = React.useState(false);
  function AddContractMasterDialogOpen() {
    setOpen(true);
  }
  function DialogClose() {
    setOpen(false);
  }
  const isAllSelected =
    vendorOptions.length > 0 &&
    vendor.length === vendorOptions.length;
  

const handleChange = (event) => {
  const value = event.target.value;
  if (value[value.length - 1] === "all") {
    var vendorId = vendorOptions.map(
      (vendors) => vendors.vendor_id
    );
    setVendor(
      vendor.length === vendorOptions.length ? [] : vendorId
    );
  } else {
    setVendor(value);
  }
};

  return (
    <React.Fragment>
      <div className="flex flex-column justify-between pb3 pl3">
        <Typography variant="body2">Contract Master</Typography>
        <span className={clsx(classes.fs12, "mr3")}>
            Selection of Vendors
          </span>
        <div className="flex justify-between pb3 ">
          <Select
            multiple
            value={vendor}
            onChange={handleChange}
            className={clsx(
              classes.inputDailySelect,
              classes.underline,
              classes.fs12,
              classes.bgInputGray
            )}
            renderValue={(vendor) =>
              vendor.length + " Vendors selected"
            }
            MenuProps={MenuProps}
          >
            <MenuItem
              value="all"
              classes={{
                root: isAllSelected ? classes.selectedAll : "",
              }}
            >
              <ListItemIcon>
                <Checkbox
                  classes={{ indeterminate: classes.indeterminateColor }}
                  checked={isAllSelected}
                  indeterminate={
                    vendor.length > 0 &&
                    vendor.length < vendorOptions.length
                  }
                />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.selectAllText }}
                primary="Select All Vendors"
              />
            </MenuItem>
            {vendorOptions.map((vendors, index) => (
              <MenuItem key={index} value={vendors.vendor_id}>
                <ListItemIcon>
                  <Checkbox
                    checked={
                      vendor.indexOf(vendors.vendor_id) > -1
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primary={vendors.vendor_name}
                  classes={{ primary: classes.selectAllText }}
                />
              </MenuItem>
            ))}
          </Select>
          <MoreButton
            variant="contained"
            disableElevation
            onClick={() => handleConfirm(vendor)}
            className="pl3"
          >
            <Typography className={clsx(classes.fs12, "ttn")}>
              Generate Contracts 
            </Typography>
          </MoreButton>
        <MoreButton
          variant="contained"
          disableElevation
          onClick={downloadReport}
          className={classes.button,"pl3"}
          startIcon={<GetAppRoundedIcon fontSize="small" />}
        >
          <Typography className={clsx(classes.fs12, "ttn")}>
           Download Contract master
          </Typography>
        </MoreButton>
          <Button
            variant="contained"
            disableElevation
            className={classes.button}
            onClick={handleBack}
            startIcon={<ChevronLeftRoundedIcon fontSize="small" />}
          >
            <Typography className={clsx(classes.fs12, "ttn")}>Back</Typography>
          </Button>
        <MoreButton
          variant="contained"
          disableElevation
          onClick={AddContractMasterDialogOpen}
        >
          <Typography className={clsx(classes.fs12, "ttn")}>Add</Typography>
        </MoreButton>
        </div>
      </div>
      <AddContractMasterDialog open={open} DialogClose={DialogClose} handleConfirm={handleConfirm}/>
    </React.Fragment>
  );
}

export default function ContractMaster(props) {
  const classes = useStyles();
  const { handleBack } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("vendor_id");
  const [tableData, setTableData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openedit, setOpenEdit] = React.useState(false);
  const [opentrip, setOpentrip] = React.useState(false);
  const [contractdetailsid,setContractDetailsID]=React.useState("")
  const [vendorid, setVendorId] = React.useState("");
  const [vendorName, setVendorName] = React.useState("");
  const [contract, setContract] = React.useState("");
  const [payload, setPayload] = React.useState("");
  const [contractid, setContractID] = React.useState("");
  const [contractstartdate, setContractStartDate] = React.useState("");
  const [contractenddate, setContractEndDate] = React.useState("");
  const [content, setContent] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);
  const [tripratedetails,setTripRateDetails]=React.useState([])
  const [vendor,setVendor]=React.useState([])
  const [vendorOptions,setVendorOptions]=React.useState([]);
  const [dialogopen, setdialogOpen] = React.useState(false);
  function DeactivateDialogOpen(row) {
    handleDialogValues(row)
    setdialogOpen(true);
  }
  function DeactivateDialogClose() {
    setdialogOpen(false);
  }
  let vendorId = [];
  const handleClose = () => {
    setOpensb(false);
  };

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleContractid=(row)=>{
    setContractDetailsID(row.contract_details_id)
  }
  function DialogOpenTripRate(row){
    // handleContractid(row)
    // console.log(contractdetailsid)
    setTripRateDetails(row.slab_data)
    setOpentrip(true)
    // getTripRateData()
  }

  function DialogCloseTripRate(){
    setOpentrip(false)
  }

  const handleDialogValues=(row)=>{
    setVendorId(row.vendor_id)
    setVendorName(row.vendor_name)
    setContract(row.contract_type)
    setContractID(row.contract_id)
    setPayload(row. Payload)
    setContractStartDate(row.contract_act_start_date)
    setContractEndDate(row.contract_act_end_date)
    setContractDetailsID(row.contract_details_id)
  }
  const editDialogOpen=(row)=>{
    
    handleDialogValues(row)
    setOpenEdit(true);
   
  }

  function DialogEditClose() {
    setOpenEdit(false);
  }

  const handleRenewDialogOpen=(row)=>{

    handleDialogValues(row)
    setOpen(true);
  }

  function DialogClose() {
    setOpen(false);
  }

  // const getTripRateData = async () => {
  //   await axiosRequest({
  //     url: "master/get_slab_rate/"+Number(contractdetailsid)+"/",
  //     method: "GET",
  //   }).then((data) => {
  //     if (data.status === 200) {
  //       setTripRateDetails(data.data);
  //     } else {
  //       handleSnackBar(data, "error");
  //     }
  //   });


  // };

  const getVendorData = async () => {
    await axiosRequest({
      url: "master/vendor/",
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        setVendorOptions(data.data);
        console.log(vendorOptions)
        for (let i in data.data) {
          vendorId.push(data.data[i]["vendor_id"]);
        }
        console.log(vendorId);
        setVendor(vendorId);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };
  const downloadReport = () => {
    let csvData = [];
    let title = [
      "vendor_id",
      "vendor_name",
      "Payload",
      "contract_act_end_date",
      "contract_act_start_date",
      "contract_details_id",
      "contract_id",
      "contract_type",
      "fixed_rate",
      "fixed_cost",
      "min_agreed_km",
      "primary_cost",
      "slab_data",
      "status",
      "variable_rate",
    ];
    let rows = tableData.map((x) => [
      x[title[0]],
      x[title[1]],
      x[title[2]],
      x[title[3]],
      x[title[4]],
      x[title[5]],
      x[title[6]],
      x[title[7]],
      x[title[8]],
      x[title[9]],
      x[title[10]],
      x[title[11]],
      x[title[12]],
      x[title[13]],
      x[title[14]],
    ]);
    csvData = [title, ...rows];
    exportToCsv("Contract master data" + new Date() + ".csv", csvData);
    handleSnackBar("Downloaded Successfully", "success");
  };

  const handleConfirm = async (vendor) => {
    await axiosRequest({
      url: "master/get_contract_master/",
      method: "POST",
      data:{
        vendorId:vendor
      }
    }).then((data) => {
      if (data.status === 200) {
        setTableData(data.data);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };


  React.useEffect(()=>{
    // getContractMasterData()
    getVendorData()
  },[])
  return (
    <div className="pa3">
      <Paper elevation={0} square>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <ContractHeader handleBack={handleBack} handleConfirm={handleConfirm} vendorOptions={vendorOptions} vendor={vendor} setVendor={setVendor} downloadReport={downloadReport}/>
            <TableContainer className={classes.costingContainer}>
              <Table stickyHeader>
                <TableHeader
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={tableData.length}
                  headers={headers}
                  isCheckboxEnabled={false}
                />
                <TableBody>
                  {sortArray(tableData, getComparator(order, orderBy)).map(
                    (row, index) => {
                      return (
                        <StyledTableRow key={index}>
                          <TableCell align="center" className={classes.fs12}>
                            {row.vendor_id}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.vendor_name}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.Payload}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.contract_type}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.contract_act_start_date}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.contract_act_end_date}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.min_agreed_km}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.fixed_cost}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.fixed_rate}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.variable_rate}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                          {tripratedetails==[]?"":<Link
                              component="button"
                              variant="body2"
                              className={clsx(classes.fs12, classes.textColor)}
                              onClick={() => DialogOpenTripRate(row)}
                            >
                              {"View Trip Rate"}
                            </Link>}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.status==true?"Active":"InActive"}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            <IconButton>
                              <EditIcon onClick={()=>editDialogOpen(row)} />
                            </IconButton>
                            <SuccessButton
                              className={clsx(classes.button, "mr3")}
                              variant="contained"
                              disableElevation
                              onClick={()=>handleRenewDialogOpen(row)}
                            >
                              <Typography className={clsx(classes.fs12, "ttn")}>
                                Renew
                              </Typography>
                            </SuccessButton>
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                          <MoreButton
                            variant="contained"
                            disableElevation
                            onClick={()=>DeactivateDialogOpen(row)}
                            className={classes.button}
                            >
                          <Typography className={clsx(classes.fs12, "ttn")}>
                              Deactivate
                          </Typography>
                          </MoreButton>
                          </TableCell>
                        </StyledTableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <TripRateDialog open={opentrip} DialogClose={DialogCloseTripRate} tripratedetails={tripratedetails}/>
        <EditDialog open={openedit} DialogClose={DialogEditClose} vendorid={vendorid} vendorName={vendorName} contractid={contractid} payload={payload} contract={contract} contractstartdate={contractstartdate} contractenddate={contractenddate} contractdetailsid={contractdetailsid}/>
        <RenewDialog open={open} DialogClose={DialogClose} vendorid={vendorid} vendorName={vendorName} contractid={contractid} payload={payload} contract={contract} contractstartdate={contractstartdate} contractenddate={contractenddate} contractdetailsid={contractdetailsid}/>
        <DeactivateDialog open={dialogopen} DialogClose={DeactivateDialogClose} contractid={contractid} contractdetailsid={contractdetailsid} handleConfirm={handleConfirm}/>
        <SnackBarComponent
                opensb={opensb}
                handleClose={handleClose}
                severity={severity}
                content={content}
              />
      </Paper>
    </div>
  );
}
