import { useState } from "react";
import MapGL, {
  FullscreenControl,
  Marker,
  NavigationControl,
  Popup,
  Source,
  Layer,
} from "react-map-gl";
import PinRed from "../../assets/icons/marker-red.svg";
import PinGreen from "../../assets/icons/marker-green.svg";
import PinGray from "../../assets/icons/marker-gray.svg";
import CarPin from "../../assets/images/logistic.png";
import "./Maps.css";
import "mapbox-gl/dist/mapbox-gl.css";

const Maps = ({ markers, routes, mapCoordinates }) => {
  const [viewport, setViewport] = useState({
    longitude: mapCoordinates.longitude,
    latitude: mapCoordinates.latitude,
    zoom: 11,
    bearing: 0,
    pitch: 0,
  });
  const [popupInfo, setPopupInfo] = useState(null);

  const fullscreenControlStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    padding: "10px",
  };

  const navStyle = {
    position: "absolute",
    top: 36,
    left: 0,
    padding: "10px",
  };

  const layerStyle = {
    type: "line",

    layout: {
      "line-join": "round",
      "line-cap": "round",
    },
    paint: {
      "line-color": "#679DF5",
      "line-width": 3,
    },
  };

  const CityPin = ({ size = 20, onClick, visited = false, itemIndex }) =>
    itemIndex === 0 ? (
      <img
        src={PinGray}
        onClick={onClick}
        alt="imgpin"
        style={{ cursor: "pointer" }}
      />
    ) : (
      <>
        {visited ? (
          <img
            src={PinGreen}
            onClick={onClick}
            alt="imgpin"
            style={{ cursor: "pointer" }}
          />
        ) : (
          <img
            src={PinRed}
            onClick={onClick}
            alt="imgpin"
            style={{ cursor: "pointer" }}
          />
        )}
      </>
    );

  const CityInfo = ({ info }) => {
    return (
      <div className="d-flex flex-column">
        <div className="pop-text">{info.store_id}</div>
        <div className="pop-text">{info.loc_name}</div>
      </div>
    );
  };

  const updateViewport = (viewport) => {
    setViewport(viewport);
  };

  const renderCityMarker = (city, index) => {
    return (
      <Marker
        key={`marker-${index}`}
        longitude={city.longitude}
        latitude={city.latitude}
      >
        <CityPin
          size={20}
          onClick={() => {
            setPopupInfo(city);
          }}
          visited={city?.isvisited}
          itemIndex={city?.delivery_seq}
        />
      </Marker>
    );
  };

  const vehicleMarker = (loc) => (
    <Marker longitude={loc.longitude} latitude={loc.latitude}>
      <img
        src={CarPin}
        on
        alt="imgpin"
        style={{ cursor: "pointer", width: "45px", height: "45px" }}
      />
    </Marker>
  );

  const renderPopup = () => {
    return (
      popupInfo && (
        <Popup
          tipSize={5}
          anchor="top"
          longitude={popupInfo?.longitude}
          latitude={popupInfo?.latitude}
          closeOnClick={false}
          onClose={() => setPopupInfo(null)}
        >
          <CityInfo info={popupInfo} />
        </Popup>
      )
    );
  };

  return (
    <MapGL
      initialViewState={viewport}
      {...viewport}
      width="100vw"
      height="100vh"
      mapStyle="mapbox://styles/mapbox/streets-v8"
      onMove={(res) => {
        updateViewport(res);
      }}
      mapboxAccessToken={
        "pk.eyJ1IjoibXJwbG1hcHMiLCJhIjoiY2w3aXlwbTcxMGM1djNxbnMzb3VjMHl5dSJ9.ZC-5Xl_ZXeeSPzQ8zRNnYw"
      }
    >
      {markers.map(renderCityMarker)}
      {vehicleMarker(mapCoordinates)}

      {renderPopup()}

      <div className="fullScreen" style={fullscreenControlStyle}>
        <FullscreenControl />
      </div>
      <div className="nav" style={navStyle}>
        <NavigationControl visualizePitch={true} showCompass={true} />
      </div>

      {routes.map((item, i) => (
        <Source id={`my-data-${i}`} type="geojson" data={item}>
          <Layer id={`point-${i}`} {...layerStyle} />
        </Source>
      ))}
    </MapGL>
  );
};
export default Maps;