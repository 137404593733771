import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { TableHeader, StyledTableRow } from "../../../Base/TableHeader";
import { getComparator, sortArray } from "../../../../helpers/sorting";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import Divider from "@material-ui/core/Divider";
import AddIcon from "@material-ui/icons/Add";
import { MoreButton, SuccessButton } from "../../../Button/Button";
import TripCreationStyle from "../../../../assets/css/TripCreationStyle";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Link from "@material-ui/core/Link";
import axiosRequest from "../../../../helpers/axios";
import SnackBarComponent from "../../../../helpers/SnackBarComponent";
import { getLocationId } from "../../../../helpers/RouteValidation";
import Chip from "@material-ui/core/Chip";

const headers = [
  {
    id: "fms_location_id",
    sortable: true,
    align: "center",
    label: "FMS Location",
  },
  {
    id: "store_id",
    sortable: true,
    align: "center",
    label: "Store code",
  },
  {
    id: "fms_location_name",
    sortable: true,
    align: "center",
    label: "Store name",
  },
  {
    id: "tag",
    sortable: true,
    align: "center",
    label: "Store tag",
  },
  {
    id: "city_zone",
    sortable: true,
    align: "center",
    label: "Store city zone",
  },
  {
    id: "totalLpnWgt",
    sortable: true,
    align: "center",
    label: "Total Weight Kgs",
  },
  {
    id: "totalLpnVol",
    sortable: true,
    align: "center",
    label: "Total Volume Cft",
  },
  {
    id: "lpncount",
    sortable: true,
    align: "center",
    label: "Crate Count",
  },
  {
    id: "bol_no",
    sortable: true,
    align: "center",
    label: "Bolno Count",
  },
  {
    id: "value",
    sortable: true,
    align: "center",
    label: "Value",
  },
  {
    id: "store_eta",
    sortable: true,
    align: "center",
    label: "Store Eta",
  },
  {
    id: "store_etd",
    sortable: true,
    align: "center",
    label: "Store Etd",
  },
];

const useStyles = makeStyles(TripCreationStyle);

export default function TripsLanding(props) {
  const classes = useStyles();
  const {
    proceedToTripSummary,
    handleAddTrip,
    handleTripsLandingStoreCode,
    backToLanding,
  } = props;

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("store_id");
  const [pendingTrip, setPendingTrip] = React.useState(false);
  const [tableData, settableData] = React.useState([]);
  const [tripsData, setTripsData] = React.useState([]);
  const [tripID, settripID] = React.useState(0);
  const [severity, setSeverity] = React.useState("");
  const [content, setContent] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);

  const handleClose = () => {
    setOpensb(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handlePendingTrip = (row) => {
    settripID(row.trip_id);
    handleTempTrips(row.trip_id);
    setPendingTrip(true);
  };

  // const handleDeleteTrip = (value) => {
  //     deleteTrip();
  //     backToLanding()
  //   };
  const handleDeleteTrip = async (value) => {
    await axiosRequest({
      url: "tripInitiation/deleteTrip/" + value,
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
      } else {
        handleSnackBar(data, "error");
      }
    });
    setPendingTrip(false);
    handleTripsList();
  };

  const handleTempTrips = async (value) => {
    await axiosRequest({
      url: "tripInitiation/tempTripData/" + value,
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        settableData(data.data);
        settripID(data.data[0]["trip_id"]);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };
  const handleTripsList = async () => {
    let fmsLocationId = getLocationId();
    await axiosRequest({
      url: "tripInitiation/tempTripList/" + fmsLocationId,
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        setTripsData(data.data);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };
  //  React.useEffect(() => {
  //     handleTempTrips()
  //   }, [tripID]);

  React.useEffect(() => {
    handleTripsList();
  }, []);

  const showBolNo = (storeCode) => {
    var pendingBOL = [];
    // var selectedBOL = [];
    handleTripsLandingStoreCode(storeCode, pendingBOL);
  };

  return (
    <React.Fragment>
      <Grid container spacing={0} justify="space-around">
        <Grid item xs={12} sm={12} md={12} lg={2} xl={2} className="pv3">
          <Grid container spacing={0}>
            <Typography variant="body2">Trips</Typography>
            <MoreButton
              variant="contained"
              disableElevation
              startIcon={<AddIcon fontSize="small" />}
              className="w-100"
              onClick={() => handleAddTrip(0)}
            >
              <Typography className={clsx(classes.fs12, "ttn")}>
                Add Trip
              </Typography>
            </MoreButton>
          </Grid>
          {tripsData.map((row, index) => {
            return (
              <div className="flex flex-column" key={index}>
                <div className="mt3" key={index}>
                  <Button
                    key={index}
                    variant="contained"
                    disableElevation
                    className="w-100 flex-column"
                    onClick={() => handlePendingTrip(row)}
                  >
                    <Typography key={index} className={clsx(classes.fs12)}>
                      {row.trip_id}
                    </Typography>
                    {row.is_combi_trip ? (
                      <Chip
                        label="Combi Trip"
                        size="small"
                        variant="outlined"
                        color="primary"
                        className="ml3"
                      />
                    ) : (
                      ""
                    )}
                  </Button>
                </div>
              </div>
            );
          })}
        </Grid>

        <Divider orientation="vertical" flexItem />

        <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className={clsx(classes.container)}
            >
              <div className={pendingTrip ? "" : "dn"}>
                <Paper
                  elevation={0}
                  square
                  className="flex justify-between items-center"
                >
                  <Typography variant="h6">Trip Id : {tripID}</Typography>
                  <div>
                    <IconButton onClick={() => handleAddTrip(tripID)}>
                      <EditOutlinedIcon fontSize="small" />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteTrip(tripID)}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </div>
                </Paper>
                <Divider />
                <TableContainer className={clsx(classes.tempViewTripcontainer)}>
                  <Table stickyHeader>
                    <TableHeader
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={tableData.length}
                      headers={headers}
                      isCheckboxEnabled={false}
                    />
                    <TableBody>
                      {sortArray(tableData, getComparator(order, orderBy)).map(
                        (row, index) => {
                          return (
                            <StyledTableRow key={index}>
                              <TableCell
                                align="center"
                                className={classes.fs12}
                              >
                                {row.fms_location_id}
                              </TableCell>
                              <TableCell
                                align="center"
                                className={classes.fs12}
                              >
                                <Link
                                  component="button"
                                  variant="body2"
                                  className={clsx(
                                    classes.fs12,
                                    classes.textcolor
                                  )}
                                  onClick={() => {
                                    showBolNo(row.store_id);
                                  }}
                                >
                                  {row.store_id}
                                </Link>
                              </TableCell>
                              <TableCell
                                align="center"
                                className={classes.fs12}
                              >
                                {row.fms_location_name}
                              </TableCell>
                              <TableCell
                                align="center"
                                className={classes.fs12}
                              >
                                {row.tag}
                              </TableCell>
                              <TableCell
                                align="center"
                                className={classes.fs12}
                              >
                                {row.city_zone}
                              </TableCell>
                              <TableCell
                                align="center"
                                className={classes.fs12}
                              >
                                {row.totalLpnWgt}
                              </TableCell>
                              <TableCell
                                align="center"
                                className={classes.fs12}
                              >
                                {row.totalLpnVol}
                              </TableCell>
                              <TableCell
                                align="center"
                                className={classes.fs12}
                              >
                                {row.lpncount}
                              </TableCell>
                              <TableCell
                                align="center"
                                className={classes.fs12}
                              >
                                {row.bol_no}
                              </TableCell>
                              <TableCell
                                align="center"
                                className={classes.fs12}
                              >
                                &#8377;
                                {row.value}
                              </TableCell>
                              <TableCell
                                align="center"
                                className={classes.fs12}
                              >
                                {row.store_eta}
                              </TableCell>
                              <TableCell
                                align="center"
                                className={classes.fs12}
                              >
                                {row.store_etd}
                              </TableCell>
                            </StyledTableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <div className="flex justify-center pa3">
        <SuccessButton
          variant="contained"
          disableElevation
          onClick={proceedToTripSummary}
        >
          <Typography className={clsx(classes.fs12, "ttn")}>
            Proceed to Trip Summary
          </Typography>
        </SuccessButton>
      </div>
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </React.Fragment>
  );
}
