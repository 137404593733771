import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { MoreButton, SuccessButton } from "../../components/Button/Button";
import TripCreationStyle from "../../assets/css/TripCreationStyle";
import axiosRequest from "../../helpers/axios";
import PublishRoundedIcon from "@material-ui/icons/PublishRounded";
import SnackBarComponent from "../../helpers/SnackBarComponent";
import Paper from "@material-ui/core/Paper";
import { Redirect } from "react-router";
import { checkUserDetails } from "../../helpers/RouteValidation";
import { saveAs } from "file-saver";


const useStyles = makeStyles(TripCreationStyle);
const useStyles1 = makeStyles((theme) => ({
  root: {
    "& .MuiFormLabel-root": {
      fontSize: "12px",
      lineHeight: 0,
    },
  },
  root1: {
    "& .MuiFormLabel-root": {
      fontSize: "12px",
      lineHeight: 0,
    },
    marginTop: "15px",
    width: "473px",
  },
  ml3: {
    marginLeft: "20px",
  },
}));
export default function BolFlagupdate() {
  const classes = useStyles();
  const classes1 = useStyles1();
  const [uploadCsv, setuploadCsv] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const [content, setContent] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [isAuthenticated, setIsAuthenticated] = React.useState(true);

  React.useEffect(() => {
    var user = checkUserDetails();
    if (user) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleClose = () => {
    setOpensb(false);
  };

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };

  const handleImageChange = (event) => {
    let img = event.target.files[0];
    console.log(event.target.files[0], "file");
    // var filename="ptl.csv";
    var formData = new FormData();
    formData.append("file", img);
    formData.append("key", value);
    console.log(formData);
    setuploadCsv(formData);
  };

  
//   const downloadReport = () => {
//     let csvData = []
//     let title = [
//       "bol_no",
      
//     ];
    // let rows = tableData.map((x) => [
    //   x[title[0]],
    //   x[title[1]],
    //   x[title[2]],
    //   x[title[3]],
    //   x[title[4]],
    //   x[title[5]],
    //   x[title[6]],
    //   x[title[7]],
    //   x[title[8]],
    //   x[title[9]],
    // ]);
    // csvData = [title, ...rows];
//     exportToCsv("NTM Report on " + new Date() + ".csv", csvData);
//     handleSnackBar("Downloaded Successfully", "success");
//   };

  const uploadPicture = async () => {
    if (uploadCsv) {
      await axiosRequest({
        url: "bolNoVerification/updatebols/",
        method: "POST",
        data: uploadCsv,
        headers: {
          "content-type": "multipart/form-data",
        },
      }).then((data) => {
        if (data.status === 200) {
          handleSnackBar(data.data.message, "success");
        } else {
          handleSnackBar(data.message, "error");
        }
      });
    }
  };

  React.useEffect(() => {
    uploadPicture();
  }, [uploadCsv]);

  const downloadTemplate = async () => {
    await axiosRequest({
      url: "master/download/template/",
      method: "GET",
      responseType:'arraybuffer',
    }).then(function (response) {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "default_format.xlsx");
    });
  };


  return (
    <React.Fragment>
    {isAuthenticated ? (
        <Paper elevation={0} square className="mt4">
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <span>Bol Flag Update</span>
        <div style={{ display: "flex", flexDirection: "row" }}>
        <MoreButton
            variant="contained"
            disableElevation
            onClick={downloadTemplate}
            className="ml3"
          >
            <Typography className={clsx(classes.fs12, "ttn")}>
              Download Template for uploading
            </Typography>
          </MoreButton>

          <input
            className={classes.input}
            style={{ visibility: "hidden" }}
            type="file"
            id="fileupload"
            onChange={handleImageChange}
          />
          <label htmlFor={"fileupload"}>
            <SuccessButton
              className={clsx(classes.button, classes1.ml3)}
              variant="contained"
              component="span"
              disableElevation
              startIcon={<PublishRoundedIcon fontSize="small" />}
            >
              <Typography className={clsx(classes.fs12, "ttn")}>
                Upload Excel File
              </Typography>
            </SuccessButton>
          </label>
        </div>

        <SnackBarComponent
          opensb={opensb}
          handleClose={handleClose}
          severity={severity}
          content={content}
        />
      </Grid>
    </Grid>
  </Paper>
  ) : (
    <Redirect to="/login" />
  )}
</React.Fragment>
  );
}
