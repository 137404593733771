import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { TableHeader, StyledTableRow } from "../../Base/TableHeader";
import { getComparator, sortArray } from "../../../helpers/sorting";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { MoreButton, SuccessButton } from "../../Button/Button";
import TripCreationStyle from "../../../assets/css/TripCreationStyle";
import { Paper } from "@material-ui/core";
import LocationMasterDialog from "./LocationMasterDialog";
import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";
import Button from "@material-ui/core/Button";
import axiosRequest from "../../../helpers/axios";
import SnackBarComponent from "../../../helpers/SnackBarComponent";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";

const headers = [
  {
    id: "retek_location_id",
    sortable: true,
    align: "center",
    label: "Retek_Location_ID",
    search: true,
  },
  {
    id: "retek_location_name",
    sortable: true,
    align: "center",
    label: "Retek_Location_Name",
    search: true,
  },
  {
    id: "fms_origin_flag",
    sortable: true,
    align: "center",
    label: "FMS_Origin_Flag",
  },
  {
    id: "fms_location_id",
    sortable: true,
    align: "center",
    label: "FMS_Location_ID",
    search: true,
  },
  {
    id: "fms_location_name",
    sortable: true,
    align: "center",
    label: "FMS_Location_Name",
    search: true,
  },

  {
    id: "format",
    sortable: true,
    align: "center",
    label: "FMS_Format",
  },
  {
    id: "Action",
    sortable: true,
    align: "center",
    label: "Action",
  },
];

const headersEdit = [
  {
    id: "retek_location_id",
    sortable: true,
    align: "center",
    label: "Retek_Location_ID",
    search: true,
  },
  {
    id: "retek_location_name",
    sortable: true,
    align: "center",
    label: "Retek_Location_Name",
  },
  {
    id: "fms_origin_flag",
    sortable: true,
    align: "center",
    label: "FMS_Origin_Flag",
  },
  {
    id: "fms_location_id",
    sortable: true,
    align: "center",
    label: "FMS_Location_ID",
  },
  {
    id: "fms_location_name",
    sortable: true,
    align: "center",
    label: "FMS_Location_Name",
  },

  {
    id: "format",
    sortable: true,
    align: "center",
    label: "FMS_Format",
  },
  {
    id: "Action",
    sortable: true,
    align: "center",
    label: "Action",
  },
    {
    id: "status",
    align: "center",
    label: "Status",
  },
];
const useStyles = makeStyles(TripCreationStyle);

export function LocationHeader(props) {
  const classes = useStyles();
  const { handleBack } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <div className="flex justify-between pa3">
        <Typography variant="body2">FMS Location Master</Typography>
        <div>
          <Button
            variant="contained"
            disableElevation
            className={classes.button}
            onClick={handleBack}
            startIcon={<ChevronLeftRoundedIcon fontSize="small" />}
          >
            <Typography className={clsx(classes.fs12, "ttn")}>Back</Typography>
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}

export function LocationEditHeader(props) {
  const classes = useStyles();
  const { handleNormal } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <div className="flex justify-between pa3">
        <Typography variant="body2">FMS Location Master-Edit</Typography>
        <div>
          <Button
            variant="contained"
            disableElevation
            className={classes.button}
            onClick={handleNormal}
            startIcon={<ChevronLeftRoundedIcon fontSize="small" />}
          >
            <Typography className={clsx(classes.fs12, "ttn")}>Back</Typography>
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default function LocationMaster(props) {
  const classes = useStyles();
  const { handleBack } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("retek_location_id");
  const [tableData, setTableData] = React.useState([]);
  const [locationdata, setLocationData] = React.useState([]);
  const [locationid, setLocationID] = React.useState([]);
  const [location,setLocation]=React.useState("")
  const [locationname,setLocationName]=React.useState("")
  const [fmslocationid,setFmsLocationID] = React.useState("")
  const [reteklocationid,setRetekLocationID] = React.useState("")
  const [state, setState] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [originFlag, setOriginFlag] = React.useState("");
  const [format, setFormat] = React.useState("");
  const [content, setContent] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);

  let fmsLocationID = [];
  let locationName;
  const handleClose = () => {
    setOpensb(false);
  };

//  const handleData=(row)=>{
//    const result = tableData.filter((row)=>row);
//   setData(result);
//  }

const handleOriginFlag = (e,row,value,property,index) => {
  var tempTableData = [...tableData];
   tempTableData[index] = row;

    // handleData(row)
    setOriginFlag(e.target.value);
    row["fms_origin_flag"]=e.target.value
    row["status"]="edited"
    setTableData(tempTableData);

  };

  const handleFormat = (e,row,value,property,index) => {
    var tempTableData = [...tableData];
     tempTableData[index] = row;
    setFormat(e.target.value);

     row["format"]=e.target.value
     row["status"]="edited"
     setTableData(tempTableData);
 
    // handleData(row)
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };

  const handleEdit = () => {
    setState(1);
    console.log(state);
  };

 const handleNormal = () => {
    setState(0);
    console.log(state);
  };

  React.useEffect(() => {
    setRows(tableData);
  }, [tableData]);
  function AddNewDialogOpen(row) {
    setRetekLocationID(row.retek_location_id)
    setOpen(true);
    getFmsLocationID()
  }
  function DialogClose() {
    setOpen(false);
  }

  const handleLocationid = async(e,row,value,property,index) => {
    var tempTableData = [...tableData];
    if (value === null) {
      if (property === "fms_location_id") {
        delete row["fms_location_name"];
      }
      tempTableData[index] = row;
    }
    if (property === "fms_location_id") {
      console.log("----")
      if (value !== null) {
      // console.log("----",e.target.value)
        for(let i in locationdata){
          if(locationdata[i]["fms_location_id"]==value){
                row["fms_location_id"]=locationdata[i]["fms_location_id"]
                row["fms_location_name"] = locationdata[i]["fms_location_name"]
                row["status"]="edited"
      }
    }
  }
    // handleData(row)
    setTableData(tempTableData);
  };
}

  const handleSearchBar = (event, property) => {
    const searchResult = tableData.filter((row) => {
      return row[property]
        .toString()
        .toLowerCase()
        .includes(event.target.value.toLowerCase());
    });
    setRows(searchResult);
  };

  const getLocationMasterData = async () => {
    await axiosRequest({
      url: "master/get_location_master/",
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        setTableData(data.data);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const getLocationData = async () => {
    await axiosRequest({
      url: "master/get_origin/1/",
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        setLocationData(data.data);
        console.log(locationdata)
        for (let i in data.data) {
          fmsLocationID.push(data.data[i]["fms_location_id"]);
        }
        console.log(fmsLocationID);
        setLocationID(fmsLocationID);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const getFmsLocationID = async () => {
    await axiosRequest({
      url: "master/get_unique_fms_location_id/",
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        setFmsLocationID(data.data);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const SaveLocationData = async () => {
    await axiosRequest({
      url: "master/update_location_master/",
      method: "POST",
      data: 
        tableData.filter((row)=>(row.status=="edited"))
    
    }).then((data) => {
      if (data.status === 200) {
        handleNormal()
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  React.useEffect(() => {
    getLocationData();
  }, [state]);
  React.useEffect(() => {
    getLocationMasterData();
  }, []);

  return (
    <React.Fragment>
      {state == 1 ? (
        <>
          <div className="pa3">
            <Paper elevation={0} square>
              <Grid container spacing={2} justify="center">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <LocationEditHeader handleNormal={handleNormal} />
                  <TableContainer className={classes.costingContainer}>
                    <Table stickyHeader>
                      <TableHeader
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                        headers={headersEdit}
                        isCheckboxEnabled={false}
                        handleSearch={handleSearchBar}
                      />
                      <TableBody>
                        {sortArray(rows, getComparator(order, orderBy)).map(
                          (row, index) => {
                            return (
                              <StyledTableRow key={index}>
                                <TableCell
                                  align="center"
                                  className={classes.fs12}
                                >
                                  {row.retek_location_id}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.fs12}
                                >
                                  {row.retek_location_name}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.fs12}
                                >
                                  <select
                                    onChange={(e,value)=>handleOriginFlag(e,row,value,"fms_origin_flag",index)}
                                    className={clsx(
                                      classes.fs12,
                                      classes.SelectStyle,
                                      "mr2 mb4"
                                    )}
                                    defaultValue={row.fms_origin_flag==false?"0":"1"}
                                  >
                                    <option value="" className={classes.fs12}>
                                      fms Origin Flag
                                    </option>
                                    <option
                                      value="0"
                                      className={classes.fs12}
                                    >
                                      0
                                    </option>
                                    <option
                                      value="1"
                                      className={classes.fs12}
                                    >
                                      1
                                    </option>
                                  </select>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.fs12}
                                >
                                   <Autocomplete
                                         className={clsx(classes.w200p,classes.root)}
                                         autoComplete
                                         options={locationid}
                                         getOptionLabel={option =>option?String(option):null}
                                         getOptionSelected={(option, value) =>
                                                                     option === value
                                                            }
                                         onChange={(e,value)=>handleLocationid(e,row,value,"fms_location_id",index)}
                                         defaultValue={row.fms_location_id}
                                         value={row.fms_location_id !== undefined ? row.fms_location_id : {}}
                                         renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Fms Location ID"
                                          variant="outlined"
                                          key={params}
                                          value={params}

                                        />
                                      )}
                                    />
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.fs12}
                                >
                                  {row.fms_location_name}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.fs12}
                                >
                                  <select
                                    onChange={(e,value)=>handleFormat(e,row,value,"format",index)}
                                    className={clsx(
                                      classes.fs12,
                                      classes.SelectStyle,
                                      "mr2 mb4"
                                    )}
                                    defaultValue={row.format}
                                  >
                                    <option
                                      value="fms format"
                                      className={classes.fs12}
                                    >
                                      fms format
                                    </option>
                                      <option
                                      value="DC"
                                      className={classes.fs12}
                                    >
                                      DC
                                    </option>
                                      <option
                                      value="Store"
                                      className={classes.fs12}
                                    >
                                      Store
                                    </option>
                                  </select>
                                  {/* {row.FMS_format} */}
                                </TableCell>
                              
                                <TableCell
                                  align="center"
                                  className={classes.fs12}
                                >
                                  <MoreButton
                                    className={clsx(classes.button, "mr3")}
                                    variant="contained"
                                    disableElevation
                                    onClick={()=>AddNewDialogOpen(row)}
                                  >
                                    <Typography
                                      className={clsx(classes.fs12, "ttn")}
                                    >
                                      + Add new fms location id
                                    </Typography>
                                  </MoreButton>
                                </TableCell>
                                  <TableCell>
                                 {row.status}
                                </TableCell>
                              </StyledTableRow>
                            );
                          }
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className="flex justify-center pa3">
                    <SuccessButton variant="contained" onClick={SaveLocationData} disableElevation>
                      <Typography className={clsx(classes.fs12, "ttn")}>
                        save
                      </Typography>
                    </SuccessButton>
                  </div>
                </Grid>
              </Grid>
              <LocationMasterDialog open={open} DialogClose={DialogClose} fmslocationid={fmslocationid} reteklocationid={reteklocationid}/>
            </Paper>
          </div>
        </>
      ) : (
        <>
          <div className="pa3">
            <Paper elevation={0} square>
              <Grid container spacing={2} justify="center">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <LocationHeader handleBack={handleBack} />
                  <TableContainer className={classes.costingContainer}>
                    <Table stickyHeader>
                      <TableHeader
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                        headers={headers}
                        isCheckboxEnabled={false}
                        handleSearch={handleSearchBar}
                      />
                      <TableBody>
                        {sortArray(rows, getComparator(order, orderBy)).map(
                          (row, index) => {
                            return (
                              <StyledTableRow key={index}>
                                <TableCell
                                  align="center"
                                  className={classes.fs12}
                                >
                                  {row.retek_location_id}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.fs12}
                                >
                                  {row.retek_location_name}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.fs12}
                                >
                                  {row.fms_origin_flag == true ? "1" : "0"}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.fs12}
                                >
                                  {row.fms_location_id}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.fs12}
                                >
                                  {row.fms_location_name}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.fs12}
                                >
                                  {row.format}
                                </TableCell>
                              </StyledTableRow>
                            );
                          }
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className="flex justify-center pa3">
                    <SuccessButton
                      variant="contained"
                      disableElevation
                      onClick={handleEdit}
                    >
                      <Typography className={clsx(classes.fs12, "ttn")}>
                        Edit
                      </Typography>
                    </SuccessButton>
                  </div>
                </Grid>
              </Grid>
              <SnackBarComponent
                opensb={opensb}
                handleClose={handleClose}
                severity={severity}
                content={content}
              />
            </Paper>
          </div>
        </>
      )}
    </React.Fragment>
  );
}
