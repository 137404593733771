const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const sortArray = (array, comparator) => {
  if(array===[]){
    return array;
  }else{
  array.sort((a, b) => {
    return comparator(a, b);
  });
  return array;
}
}

export { getComparator, sortArray };
