import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import { Link, Route, BrowserRouter as Router } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MenuList from "@material-ui/core/MenuList";
import VehicleMaster from "../../components/Master/VehicleMaster";
import { Container, Switch } from "@material-ui/core";
import Master from "./Master";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

export default function MenuListMaster(props) {
  const classes = useStyles();
  const {handleMenuItem,handleMenuItem1,handleMenuItem2,handleMenuItem3,handleMenuItem4,handleMenuItem5,userrole}=props;
//   const [state, setState] = React.useState(false);

 
  //   function handlevehiclemaster(state) {
  //     console.log(state)

  // }
  return (
    <React.Fragment>
      {userrole=="location_manager"?
    <div>

      {/* <Paper className={classes.paper}> */}
        <MenuList>

          {/* <MenuItem>Retek location master</MenuItem> */}
          <MenuItem value="2" onClick={handleMenuItem2}>FMS location master</MenuItem>
          <MenuItem value="1" onClick={handleMenuItem1}>Origin-destination master</MenuItem>
          <MenuItem value="4" onClick={handleMenuItem4}>Vendor master</MenuItem>
          <MenuItem value="5" onClick={handleMenuItem5}>Item-SUF master</MenuItem>
          {/* <MenuItem>Time matrix</MenuItem> */}
          {/* <MenuItem>Vendor master</MenuItem> */}
          {/* <MenuItem >Origin-vendor master</MenuItem> */}
          {/* <MenuItem value="3" onClick={handleMenuItem3}>Contract ID master</MenuItem> */}
          {/* <MenuItem>Rate master</MenuItem> */}
          <MenuItem value="0" onClick={handleMenuItem}>Vehicle master</MenuItem>
          {/* <MenuItem>Item-SUF master</MenuItem> */}
          {/* <MenuItem>Crate master</MenuItem> */}
          {/* <MenuItem>item weight and volume master</MenuItem> */}
          {/* <MenuItem>month master</MenuItem> */}
        </MenuList>
      {/* </Paper> */}
       </div>
        :userrole=="logistics"?
    <div >

      {/* <Paper className={classes.paper}> */}
        <MenuList>

          {/* <MenuItem>Retek location master</MenuItem> */}
          <MenuItem value="2" onClick={handleMenuItem2}>FMS location master</MenuItem>
          <MenuItem value="1" onClick={handleMenuItem1}>Origin-destination master</MenuItem>
          {/* <MenuItem>Time matrix</MenuItem> */}
          {/* <MenuItem>Vendor master</MenuItem> */}
          {/* <MenuItem >Origin-vendor master</MenuItem> */}
          {/* <MenuItem value="3" onClick={handleMenuItem3}>Contract ID master</MenuItem> */}
          {/* <MenuItem>Rate master</MenuItem> */}
          <MenuItem value="0" onClick={handleMenuItem}>Vehicle master</MenuItem>
          {/* <MenuItem>Item-SUF master</MenuItem> */}
          {/* <MenuItem>Crate master</MenuItem> */}
          {/* <MenuItem>item weight and volume master</MenuItem> */}
          {/* <MenuItem>month master</MenuItem> */}
        </MenuList>
      {/* </Paper> */}
    </div>
    :userrole=="logistics_user"?
    // <React.Fragment>
    //  <div className={classes.root}>

      <Paper className={classes.paper}>
        <MenuList>

          {/* <MenuItem>Retek location master</MenuItem> */}
          <MenuItem value="2" onClick={handleMenuItem2}>FMS location master</MenuItem>
          <MenuItem value="1" onClick={handleMenuItem1}>Origin-destination master</MenuItem>
          {/* <MenuItem value="4" onClick={handleMenuItem4}>Vendor master</MenuItem> */}
          {/* <MenuItem value="5" onClick={handleMenuItem5}>Item-SUF master</MenuItem> */}
          {/* <MenuItem>Time matrix</MenuItem> */}
          {/* <MenuItem>Vendor master</MenuItem> */}
          {/* <MenuItem >Origin-vendor master</MenuItem> */}
          {/* <MenuItem value="3" onClick={handleMenuItem3}>Contract ID master</MenuItem> */}
          {/* <MenuItem>Rate master</MenuItem> */}
          <MenuItem value="0" onClick={handleMenuItem}>Vehicle master</MenuItem>
          {/* <MenuItem>Item-SUF master</MenuItem> */}
          {/* <MenuItem>Crate master</MenuItem> */}
          {/* <MenuItem>item weight and volume master</MenuItem> */}
          {/* <MenuItem>month master</MenuItem> */}
        </MenuList>
      </Paper> 
    :userrole=="NTM"?
    // <React.Fragment>
    //  <div className={classes.root}>

      <Paper className={classes.paper}>
        <MenuList>

          {/* <MenuItem>Retek location master</MenuItem> */}
          <MenuItem value="2" onClick={handleMenuItem2}>FMS location master</MenuItem>
          <MenuItem value="1" onClick={handleMenuItem1}>Origin-destination master</MenuItem>
          <MenuItem value="4" onClick={handleMenuItem4}>Vendor master</MenuItem>
          <MenuItem value="5" onClick={handleMenuItem5}>Item-SUF master</MenuItem>
          {/* <MenuItem>Time matrix</MenuItem> */}
          {/* <MenuItem>Vendor master</MenuItem> */}
          {/* <MenuItem >Origin-vendor master</MenuItem> */}
          <MenuItem value="3" onClick={handleMenuItem3}>Contract ID master</MenuItem>
          {/* <MenuItem>Rate master</MenuItem> */}
          <MenuItem value="0" onClick={handleMenuItem}>Vehicle master</MenuItem>
          {/* <MenuItem>Item-SUF master</MenuItem> */}
          {/* <MenuItem>Crate master</MenuItem> */}
          {/* <MenuItem>item weight and volume master</MenuItem> */}
          {/* <MenuItem>month master</MenuItem> */}
        </MenuList>
      </Paper>
      :userrole=="logisticsirn"?
      // <React.Fragment>
      //  <div className={classes.root}>
  
        <Paper className={classes.paper}>
          <MenuList>
  
            {/* <MenuItem>Retek location master</MenuItem> */}
            <MenuItem value="2" onClick={handleMenuItem2}>FMS location master</MenuItem>
            <MenuItem value="1" onClick={handleMenuItem1}>Origin-destination master</MenuItem>
            {/* <MenuItem value="4" onClick={handleMenuItem4}>Vendor master</MenuItem> */}
            {/* <MenuItem value="5" onClick={handleMenuItem5}>Item-SUF master</MenuItem> */}
            {/* <MenuItem>Time matrix</MenuItem> */}
            {/* <MenuItem>Vendor master</MenuItem> */}
            {/* <MenuItem >Origin-vendor master</MenuItem> */}
            {/* <MenuItem value="3" onClick={handleMenuItem3}>Contract ID master</MenuItem> */}
            {/* <MenuItem>Rate master</MenuItem> */}
            <MenuItem value="0" onClick={handleMenuItem}>Vehicle master</MenuItem>
            {/* <MenuItem>Item-SUF master</MenuItem> */}
            {/* <MenuItem>Crate master</MenuItem> */}
            {/* <MenuItem>item weight and volume master</MenuItem> */}
            {/* <MenuItem>month master</MenuItem> */}
          </MenuList>
        </Paper> 
      :""
        }
        </React.Fragment>
  );
}

