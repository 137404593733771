import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { MoreButton } from "../../Button/Button";
import { Paper } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Divider from "@material-ui/core/Divider";
import { moreColor } from "../../../assets/css/BaseStyles";
import { OriginDestinationHeader } from "./OriginDestinationHeader";
import { OriginDestinationDetails } from "./OriginDestinationDetails";
import AddOriginDestination from "./AddOriginDestination";
import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";
import Button from "@material-ui/core/Button";
import { getLocationId } from "../../../helpers/RouteValidation";
import axiosRequest from "../../../helpers/axios";
import SnackBarComponent from "../../../helpers/SnackBarComponent";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel">
      {value === index && (
        <Paper elevation={0} square>
          {children}
        </Paper>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  fs12: {
    fontSize: "12px",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  selectedTab: {
    color: moreColor[0],
  },
  tab: {
    textTransform: "none",
    fontSize: "12px",
  },
}));

export default function OriginDestinationMaster(props) {
  const classes = useStyles();
  const { handleClose } = props;
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [origindestinationheader, setOriginDestinationHeader] = React.useState(
    []
  );
  const [origindestinationdetails, setOriginDestinationDetails] =
    React.useState([]);
  const [tableData, setTableData] = React.useState([]);
  const [content, setContent] = React.useState("");
  const [severity,setSeverity]=React.useState("")
  const [opensb, setOpensb] = React.useState(false);
  const handleClosesb = () => {
    setOpensb(false);
  };
  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };

  function OriginDetailDialogOpen() {
    setOpen(true);
  }
  function DialogClose() {
    setOpen(false);
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getOriginDestinationHeaderData = async () => {
    let fmsLocationId = getLocationId();
    await axiosRequest({
      url: "master/origin_destination_header/" + fmsLocationId,
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        setTableData(data.data);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };
  return (
    <Paper elevation={0} square>
      <Divider />
      <Grid container justify="space-between">
        <Grid item>
          <Tabs
            value={value}
            onChange={handleChange}
            classes={{ root: classes.ttn }}
          >
            <Tab
              classes={{ selected: classes.selectedTab, root: classes.tab }}
              label="Origin destination header"
            />
            <Tab
              classes={{ selected: classes.selectedTab, root: classes.tab }}
              label="Origin destination details"
            />
          </Tabs>
        </Grid>
        <Grid item className="self-center mr3">
          <Button
            variant="contained"
            disableElevation
            className={classes.button}
            onClick={handleClose}
            startIcon={<ChevronLeftRoundedIcon fontSize="small" />}
          >
            <Typography className={clsx(classes.fs12, "ttn")}>Back</Typography>
          </Button>
          <MoreButton
          variant="contained"
          disableElevation
          onClick={getOriginDestinationHeaderData}
          className={classes.button}
          // startIcon={<GetAppRoundedIcon fontSize="small" />}
        >
          <Typography className={clsx(classes.fs12, "ttn")}>
            Generate origin data
          </Typography>
        </MoreButton>
          <MoreButton
            className={clsx(classes.button, "mr3")}
            variant="contained"
            disableElevation
            onClick={OriginDetailDialogOpen}
          >
            <Typography className={clsx(classes.fs12, "ttn")}>
              + Add NSO
            </Typography>
          </MoreButton>
        </Grid>
        <AddOriginDestination open={open} DialogClose={DialogClose} />
      </Grid>
      <TabPanel value={value} index={0}>
        <OriginDestinationHeader OriginHeader={origindestinationheader} tableData={tableData} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <OriginDestinationDetails OriginDetails={origindestinationdetails} />
      </TabPanel>
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClosesb}
        severity={severity}
        content={content}
      />
    </Paper>
  );
}
