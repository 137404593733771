import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { TableHeader, StyledTableRow } from "../../Base/TableHeader";
import { getComparator, sortArray } from "../../../helpers/sorting";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { MoreButton, SuccessButton } from "../../Button/Button";
import TripCreationStyle from "../../../assets/css/TripCreationStyle";
import axiosRequest from "../../../helpers/axios";
import Link from "@material-ui/core/Link";
import TripStoreDetailDialog from "../../Base/TripStoreDetailDialogue";
import SnackBarComponent from "../../../helpers/SnackBarComponent";
import FandVCrateDialog from "./FandVCrateModal";
import { Button } from "@material-ui/core";
import { getLocationId, downloadFile } from "../../../helpers/RouteValidation";

const headers = [
  {
    id: "fms_location_name",
    sortable: true,
    align: "center",
    label: "FMS Location",
  },
  {
    id: "trip_id",
    sortable: true,
    align: "center",
    label: "Trip ID",
  },
  {
    id: "Store_details",
    align: "center",
    label: "Store Details",
  },
  {
    id: "trip_wt",
    sortable: true,
    align: "center",
    label: "Total Weight (Kg)",
  },
  {
    id: "trip_vol",
    sortable: true,
    align: "center",
    label: "Total Volume (Cft)",
  },
  {
    id: "store_drops",
    sortable: true,
    align: "center",
    label: "Store Drops",
  },
  {
    id: "trip_dist_fms_est",
    sortable: true,
    align: "center",
    label: "Distance Travelled",
  },
  {
    id: "vehicle_num",
    sortable: true,
    align: "center",
    label: "Vehicle Number",
  },
  {
    id: "vendor_name",
    sortable: true,
    align: "center",
    label: "Vendor Name",
  },
  {
    id: "vehicle_wt_capacity",
    sortable: true,
    align: "center",
    label: "Vehicle Weight Capacity",
  },
  {
    id: "vehicle_vol_capacity",
    sortable: true,
    align: "center",
    label: "Vehicle Volume Capacity",
  },
  {
    id: "vehicle_picture",
    sortable: true,
    align: "center",
    label: "Vehicle Picture",
  },
  {
    id: "",
    align: "center",
    label: "Tripsheet",
  },
];

const useStyles = makeStyles(TripCreationStyle);

export default function VehicleImageUpload(props) {
  const classes = useStyles();
  const { proceedToTripEnd, handleTripSheet, setTripid } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("trip_id");
  const [tableData, setTableData] = React.useState([]);
  const [uploadPhoto, setuploadPhoto] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [storeDetails, setStoreDetails] = React.useState([]);
  const [severity, setSeverity] = React.useState("");
  const [content, setContent] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);

  const handleClose = () => {
    setOpensb(false);
  };

  const DialogOpen = (row) => {
    setStoreDetails(row.stores_list);
    setOpen(true);
  };

  const DialogClose = () => {
    setOpen(false);
  };

  const handleTripId = (row) => {
    setTripid(row.trip_id);
    handleTripSheet();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const tripSummary = async () => {
    let fmsLocationId = getLocationId();
    await axiosRequest({
      url: "eWayBillPartB/tripSummary/" + fmsLocationId,
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        setTableData(data.data);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };

  React.useEffect(() => {
    tripSummary();
  }, []);

  const updateTripStatus = async () => {
    await axiosRequest({
      url: "eWayBillPartB/updateTripEnd",
      method: "PUT",
      data: {
        tripData: tableData,
      },
    }).then((data) => {
      if (data.status === 200) {
        proceedToTripEnd();
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const handleImageChange = (event, index, row, property) => {
    console.log(event, row);
    let img = event.target.files[0];
    var filename = row.trip_id + "_" + img.name;
    var formData = new FormData();
    formData.append("Vehicle_Img", img, filename);
    console.log(formData);
    setuploadPhoto(formData);
  };

  const updateVehicleImagePath = async (path) => {
    await axiosRequest({
      url: "eWayBillPartB/updateVehicleImagePath",
      method: "PUT",
      data: {
        tripId: Number(path.split("/")[1].split("_")[0]),
        filePath: path,
      },
    }).then((data) => {
      if (data.status === 200) {
        tripSummary();
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const uploadPicture = async () => {
    if (uploadPhoto) {
      await axiosRequest({
        url: "vehicle/",
        method: "POST",
        data: uploadPhoto,
        headers: {
          "content-type": "multipart/form-data",
        },
      }).then((data) => {
        if (data.status === 200) {
          updateVehicleImagePath(data.data.path);
        } else {
          handleSnackBar(data, "error");
        }
      });
    }
  };
  const downloadImage = async (row) => {
    await axiosRequest({
      url: "vehicle/",
      method: "PUT",
      data: {
        folderName: String(row.truck_img.split("/")[0]),
        fileName: String(row.truck_img.split("/")[1]),
      },
    }).then((data) => {
      if (data.status === 200) {
        downloadFile(data.data);
        handleSnackBar("Downloaded Successfully", "success");
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const downloadTripSheet = async (row) => {
    await axiosRequest({
      url: "eWayBillPartB/tripsheet/",
      method: "PUT",
      data: {
        folderName: String(row.trip_sheet_path.split("/")[0]),
        fileName: String(row.trip_sheet_path.split("/")[1]),
      },
    }).then((data) => {
      if (data.status === 200) {
        downloadFile(data.data);
        handleSnackBar("Downloaded Successfully", "success");
      } else {
        handleSnackBar(data, "error");
      }
    });
  };
  // const handleCrate = (row) => {
  //   setTripId(row.trip_id);
  //   setOpenCrate(true);
  // };

  React.useEffect(() => {
    uploadPicture();
  }, [uploadPhoto]);

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TableContainer className={classes.container}>
          <Table stickyHeader>
            <TableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={tableData.length}
              headers={headers}
              isCheckboxEnabled={false}
            />
            <TableBody>
              {sortArray(tableData, getComparator(order, orderBy)).map(
                (row, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <TableCell align="center" className={classes.fs12}>
                        {row.fms_location_name}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_id}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        <Link
                          component="button"
                          variant="body2"
                          className={clsx(classes.fs12, classes.textColor)}
                          onClick={() => DialogOpen(row)}
                        >
                          {"View Stores"}
                        </Link>
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_wt}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_vol}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.store_drops}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_dist_fms_est}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.vehicle_num}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.vendor_name}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.vehicle_wt_capacity}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.vehicle_vol_capacity}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.truck_img != null ? (
                          <a
                            className={clsx("pointer")}
                            onClick={() => downloadImage(row)}
                          >
                            View Pictures
                          </a>
                        ) : (
                          ""
                        )}
                        <input
                          accept="image/*"
                          className={classes.input}
                          id={index}
                          multiple
                          type="file"
                          onChange={(event) =>
                            handleImageChange(event, index, row, "trip_id")
                          }
                        />
                        <label htmlFor={index}>
                          <MoreButton
                            variant="contained"
                            color="primary"
                            component="span"
                          >
                            Upload
                          </MoreButton>
                        </label>
                      </TableCell>
                      <TableCell>
                        {row.trip_sheet_path != null ? (
                          <MoreButton
                            variant="contained"
                            color="primary"
                            component="span"
                            className={classes.tripsheetbutton}
                            onClick={() => downloadTripSheet(row)}
                          >
                            Download Tripsheet
                          </MoreButton>
                        ) : (
                          <MoreButton
                            variant="contained"
                            color="primary"
                            component="span"
                            className={classes.tripsheetbutton}
                            onClick={() => handleTripId(row)}
                          >
                            Generate Trip Sheet
                          </MoreButton>
                        )}
                      </TableCell>
                    </StyledTableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="flex justify-center pa3">
          <SuccessButton
            variant="contained"
            disableElevation
            onClick={updateTripStatus}
          >
            <Typography className={clsx(classes.fs12, "ttn")}>
              Proceed to Trip End
            </Typography>
          </SuccessButton>
        </div>
      </Grid>
      <TripStoreDetailDialog
        open={open}
        DialogClose={DialogClose}
        storeDetails={storeDetails}
      />
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </Grid>
  );
}
