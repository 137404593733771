import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { TableHeader, StyledTableRow } from "../../Base/TableHeader";
import { getComparator, sortArray } from "../../../helpers/sorting";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import TripCreationStyle from "../../../assets/css/TripCreationStyle";
import { Paper } from "@material-ui/core";
import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";
import Button from "@material-ui/core/Button";
import axiosRequest from "../../../helpers/axios";
import SnackBarComponent from "../../../helpers/SnackBarComponent";
import { MoreButton } from "../../Button/Button";
import {downloadFile} from "../../../helpers/RouteValidation";
import exportToCsv from "../../../helpers/exportToCsv";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";

const headers = [
  {
    id: "vendor_id",
    sortable: true,
    align: "center",
    label: "Vendor_ID",
  },
  {
    id: "vendor_name",
    sortable: true,
    align: "center",
    label: "Vendor_name",
  },
  {
    id: "vendor_type",
    sortable: true,
    align: "center",
    label: "Vendor_type",
  },
  {
    id: "operating_unit",
    sortable: true,
    align: "center",
    label: "Operating_unit",
  },

  {
    id: "address_line_1",
    sortable: true,
    align: "center",
    label: "address_line_1",
  },
  {
    id: "address_line_2",
    sortable: true,
    align: "center",
    label: "address_line_2",
  },
  {
    id: "address_line_3",
    sortable: true,
    align: "center",
    label: "address_line_3",
  },

  {
    id: "address_line_4",
    sortable: true,
    align: "center",
    label: "address_line_4",
  },
  {
    id: "city",
    sortable: true,
    align: "center",
    label: "city",
  },
  {
    id: "state",
    sortable: true,
    align: "center",
    label: "state",
  },
  {
    id: "country",
    sortable: true,
    align: "center",
    label: "country",
  },
  {
    id: "pincode",
    sortable: true,
    align: "center",
    label: "pincode",
  },
  {
    id: "pay_group",
    sortable: false,
    align: "center",
    label: "pay_group",
  },
  {
    id: "gst_num",
    sortable: false,
    align: "center",
    label: "gst_num",
  },
  {
    id: "tax_region",
    sortable: false,
    align: "center",
    label: "tax_region",
  },
  {
    id: "status",
    sortable: false,
    align: "center",
    label: "status",
  },
  {
    id: "created_date",
    sortable: false,
    align: "center",
    label: "created_date",
  },
  {
    id: "updated_date",
    sortable: false,
    align: "center",
    label: "updated_date",
  },
];

const useStyles = makeStyles(TripCreationStyle);

export function ContractHeader(props) {
  const classes = useStyles();
  const { handleBack,downloadReport} = props;
 
  return (
    <React.Fragment>
      <div className="flex justify-between pa3">
        <Typography variant="body2">Vendor Master</Typography>
        <div>
          <Button
            variant="contained"
            disableElevation
            className={classes.button}
            onClick={handleBack}
            startIcon={<ChevronLeftRoundedIcon fontSize="small" />}
          >
            <Typography className={clsx(classes.fs12, "ttn")}>Back</Typography>
          </Button>
          <MoreButton
          variant="contained"
          disableElevation
          onClick={downloadReport}
          className={classes.button}
          startIcon={<GetAppRoundedIcon fontSize="small" />}
        >
          <Typography className={clsx(classes.fs12, "ttn")}>
           Download vendor master
          </Typography>
        </MoreButton>
        {/* <Button
            variant="contained"
            disableElevation
            className={classes.button}
            onClick={backToMenuList}
          >
            <Typography className={clsx(classes.fs12, "ttn")}>
              Back
            </Typography>
          </Button> */}
       
        </div>
      </div>
    </React.Fragment>
  );
}

export default function ContractMaster(props) {
  const classes = useStyles();
  const { handleBack } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("vendor_id");
  const [tableData, setTableData] = React.useState([]);
  const [content, setContent] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);

  const handleClose = () => {
    setOpensb(false);
  };

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const downloadReport = () => {
    let csvData = [];
    let title = [
      "vendor_id",
      "vendor_name",
      "vendor_type",
      "address_line_1",
      "address_line_2", 
      "address_line_3",
      "address_line_4",
      "city",
      "country",
      "created_date",
      "gst_num",
      "operating_unit",
      "pay_group",
      "pincode",
      "state",
      "status",
      "tax_region",
      "updated_date"
       
    ];
    let rows = tableData.map((x) => [
      x[title[0]],
      x[title[1]],
      x[title[2]],
      x[title[3]],
      x[title[4]],
      x[title[5]],
      x[title[6]],
      x[title[7]],
      x[title[8]],
      x[title[9]],
      x[title[10]],
      x[title[11]],
      x[title[12]],
      x[title[13]],
      x[title[14]],
      x[title[15]],
      x[title[16]],
      x[title[17]]=== null ? false : x[title[17]],
    ]);
    csvData = [title, ...rows];
    exportToCsv("vendor master data" + new Date() + ".csv", csvData);
    handleSnackBar("Downloaded Successfully", "success");
  };

 
  

  const getVendorMasterData = async () => {
    await axiosRequest({
      url: "master/getvendormaster/",
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        setTableData(data.data);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  React.useEffect(()=>{
    getVendorMasterData()
  },[])
  return (
    <div className="pa3">
      <Paper elevation={0} square>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
           <ContractHeader handleBack={handleBack} downloadReport={downloadReport}/>
            <TableContainer className={classes.costingContainer}>
              <Table stickyHeader>
                <TableHeader
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={tableData.length}
                  headers={headers}
                  isCheckboxEnabled={false}
                />
                <TableBody>
                  {sortArray(tableData, getComparator(order, orderBy)).map(
                    (row, index) => {
                      return (
                        <StyledTableRow key={index}>
                          <TableCell align="center" className={classes.fs12}>
                            {row.vendor_id}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.vendor_name}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.vendor_type}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.operating_unit}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.address_line_1}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.address_line_2}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.address_line_3}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.address_line_4}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.city}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.state}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.country}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.pincode}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.pay_group}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.gst_num}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.tax_region}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.status}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.created_date}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.updated_date}
                          </TableCell>
                        </StyledTableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <SnackBarComponent
                opensb={opensb}
                handleClose={handleClose}
                severity={severity}
                content={content}
              />
      </Paper>
    </div>
  );
}
