import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { TableHeader, StyledTableRow } from "../../Base/TableHeader";
import { getComparator, sortArray } from "../../../helpers/sorting";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import TripCreationStyle from "../../../assets/css/TripCreationStyle";
import { Paper } from "@material-ui/core";
import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";
import Button from "@material-ui/core/Button";
import axiosRequest from "../../../helpers/axios";
import SnackBarComponent from "../../../helpers/SnackBarComponent";
import { MoreButton } from "../../Button/Button";
import AddItemSufMasterDialog from "./AddItemSufMasterDialog";
import exportToCsv from "../../../helpers/exportToCsv";
import {downloadFile, getLocationId} from "../../../helpers/RouteValidation";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import PublishRoundedIcon from "@material-ui/icons/PublishRounded";

const headers = [
  {
    id: "fms_item_suf_master_id",
    sortable: true,
    align: "center",
    label: "fms_item_suf_master_id",
  },
  {
    id: "item_id",
    sortable: true,
    align: "center",
    label: "item_id",
  },
  {
    id: "standard_uom",
    sortable: true,
    align: "center",
    label: "standard_uom",
  },
  {
    id: "each_to_kg",
    sortable: true,
    align: "center",
    label: "each_to_kg",
  },

  {
    id: "suf",
    sortable: true,
    align: "center",
    label: "suf",
  },
  {
    id: "suf_status",
    sortable: true,
    align: "center",
    label: "suf_status",
  },
  {
    id: "crate_type",
    sortable: true,
    align: "center",
    label: "crate_type",
  },

  {
    id: "created_date",
    sortable: true,
    align: "center",
    label: "created_date",
  },
  {
    id: "updated_date",
    sortable: true,
    align: "center",
    label: "updated_date",
  },
  {
    id: "retek_location_code",
    sortable: true,
    align: "center",
    label: "retek_location_code",
  },
  {
    id: "division_name",
    sortable: true,
    align: "center",
    label: "division_name",
  },
  {
    id: "department_name",
    sortable: true,
    align: "center",
    label: "department_name",
  },
  {
    id: "crate_wt",
    sortable: true,
    align: "center",
    label: "crate_wt",
  },
  {
    id: "crate_vol",
    sortable: true,
    align: "center",
    label: "crate_vol",
  },
  {
    id: "fms_location_master",
    sortable: true,
    align: "center",
    label: "fms_location_master",
  },
];

const useStyles = makeStyles(TripCreationStyle);

export function ContractHeader(props) {
  const classes = useStyles();
  const { handleBack,downloadReport,downloadItemReport,downloadCrateData} = props;
  const [open, setOpen] = React.useState(false);
  const [uploadCsv, setuploadCsv] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const [content, setContent] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);

  const handleClose = () => {
    setOpensb(false);
  };

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };

  function ItemsufmasterDialogOpen() {
    setOpen(true);
  }

  function DialogClose() {
    setOpen(false);
  }

  const handleImageChange = (event) => {
    let img = event.target.files[0];
    let fmslocationID=100008

    // var filename="ptl.csv";
    var formData = new FormData();
    formData.append("fmslocationid",fmslocationID);
    formData.append("file",img);
    console.log(formData);
    setuploadCsv(formData);
    console.log(uploadCsv)
  };


  const uploadPicture = async () => {
    if (uploadCsv) {
      await axiosRequest({
        url: "master/upload_csv/",
        method: "POST",
        data: uploadCsv,
        headers: {
          "content-type": "multipart/form-data",
        },
      }).then((data) => {
        if (data.status === 200) {
          handleSnackBar(data.msg,"success")
        } else {
          handleSnackBar(data.msg, "error");
        }
      });
    }
  };


 const downloadCsv = async (row) => {
    await axiosRequest({
      url: "ptl/upload_csv/",
      method: "PUT",
      data: {
        folderName: "itemsuf",
        fileName: "itemsuf.csv",
      },
    }).then((data) => {
      if (data.status === 200) {
        downloadFile(data.data);
        handleSnackBar("Downloaded Successfully", "success");
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

   React.useEffect(() => {
    uploadPicture();
  }, [uploadCsv]);

  return (
    <React.Fragment>
      <div className="flex justify-between pa3">
        <Typography variant="body2">Item SUF Master</Typography>
        <div className="flex justify-between">
          <Button
            variant="contained"
            disableElevation
            className={classes.button}
            onClick={handleBack}
            startIcon={<ChevronLeftRoundedIcon fontSize="small" />}
          >
            <Typography className={clsx(classes.fs12, "ttn")}>Back</Typography>
          </Button>
          <MoreButton
            className={clsx(classes.button, "mr3")}
            variant="contained"
            disableElevation
            onClick={ItemsufmasterDialogOpen}
            startIcon={<GetAppRoundedIcon fontSize="small" />}
          >
            <Typography className={clsx(classes.fs12, "ttn")}>
              Download
            </Typography>
          </MoreButton>
         <input
            className={classes.input}
            id="contained-button-file"
            multiple
            type="file"
            onChange={handleImageChange}
                />
        <label htmlFor="contained-button-file">
          <MoreButton
              variant="contained"
              color="primary"
              component="span"
              className={classes.button}
              startIcon={<PublishRoundedIcon fontSize="small" />}   
               >
           Upload csv
          </MoreButton>
          </label>
        </div>
      </div>
      <AddItemSufMasterDialog
        open={open}
        DialogClose={DialogClose}
        downloadReport={downloadReport}
        downloadItemReport={downloadItemReport}
        downloadCrateData={downloadCrateData}
        downloadCsv={downloadCsv}
      />
       <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </React.Fragment>
  );
}

export default function ContractMaster(props) {
  const classes = useStyles();
  const { handleBack } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("fms_item_suf_master_id");
  const [tableData, setTableData] = React.useState([]);
  const [content, setContent] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);
  const [itemData,setItemData] = React.useState([]);
  const [cratemaster,setCrateMaster] = React.useState([]);
  const [itemAttributesData,setItemAttributesData] = React.useState([])

  const handleClose = () => {
    setOpensb(false);
  };

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const downloadItemReport = () =>{
    let csvitemData=[];
    let itemtitle=[
      "item_no",
      "item_description",
      "department_name",
      "standard_uom"
    ]
    let rowsitem=itemAttributesData.map((x) => [
      x[itemtitle[0]],
      x[itemtitle[1]],
      x[itemtitle[2]],
      x[itemtitle[3]]
    ]);
    csvitemData = [itemtitle, ...rowsitem];
    exportToCsv("download item master" + new Date() + ".csv", csvitemData);
    handleSnackBar("Downloaded Successfully", "success");
  }

  const downloadReport = () => {
    let csvData = [];
    let title = [
        "fms_item_suf_master_id",
        "item_id",
        "standard_uom",
        "each_to_kg",
        "suf",
        "suf_status",
        "crate_type",
        "created_date",
        "updated_date",
        "retek_location_code",
        "division_name",
        "department_name",
        "crate_wt",
        "crate_vol",
        "fms_location_master"
    ];
    let rows = itemData.map((x) => [
      x[title[0]],
      x[title[1]],
      x[title[2]],
      x[title[3]],
      x[title[4]],
      x[title[5]],
      x[title[6]],
      x[title[7]],
      x[title[8]],
      x[title[9]],
      x[title[10]],
      x[title[11]],
      x[title[12]],
      x[title[13]],
      x[title[14]] === null ? false : x[title[14]],
    ]);
    csvData = [title, ...rows];
    exportToCsv("suf/crate master data" + new Date() + ".csv", csvData);
    handleSnackBar("Downloaded Successfully", "success");
  };

  const downloadCrateData = () => {
    let crateData = [];
    let title = [
      "fms_crate_id",
      "crate_name",
      "crate_type",
      "operating_type",
      "inner_length_mm",
      "inner_height_mm",
      "inner_breadth_mm",
      "outer_length_mm",
      "outer_breadth_mm",
      "outer_height_mm",
      "crate_inner_vol",
      "crate_outer_vol",
      "crate_wt_kg",
      "created_date",
      "updated_date"
    ];
    let rows = cratemaster.map((x) => [
      x[title[0]],
      x[title[1]],
      x[title[2]],
      x[title[3]],
      x[title[4]],
      x[title[5]],
      x[title[6]],
      x[title[7]],
      x[title[8]],
      x[title[9]],
      x[title[10]],
      x[title[11]],
      x[title[12]],
      x[title[13]],
      x[title[14]] === null ? false : x[title[14]],
    ]);
    crateData = [title, ...rows];
    exportToCsv("crate master data" + new Date() + ".csv", crateData);
    handleSnackBar("Downloaded Successfully", "success");
  };
  

  const getItemData = async () => {
      let fmsLocationId = getLocationId();
      await axiosRequest({
        url: "master/itemsufmaster/"+fmsLocationId,
        method: "GET",
      }).then((data) => {
        if (data.status === 200) {
          setItemData(data.data);
        } else {
          handleSnackBar(data, "error");
        }
      });
    };

    const getItemAttributeData = async () => {
      await axiosRequest({
        url: "master/getitemattributes/",
        method: "GET",
      }).then((data) => {
        if (data.status === 200) {
          setItemAttributesData(data.data);
        } else {
          handleSnackBar(data, "error");
        }
      });
    };

    const getCrateMasterData = async () => {
      await axiosRequest({
        url: "master/getcratemaster/",
        method: "GET",
      }).then((data) => {
        if (data.status === 200) {
          setCrateMaster(data.data);
        } else {
          handleSnackBar(data, "error");
        }
      });
    };
 
  

  const getItemSufMasterData = async () => {
    await axiosRequest({
      url: "master/getitemsufmaster/",
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        setTableData(data.data);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  React.useEffect(()=>{
    getItemSufMasterData()
    getItemData()
    getItemAttributeData()
    getCrateMasterData()
  },[])
  return (
    <div className="pa3">
      <Paper elevation={0} square>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ContractHeader handleBack={handleBack} downloadReport={downloadReport} downloadItemReport={downloadItemReport} downloadCrateData={downloadCrateData}/>
            <TableContainer className={classes.costingContainer}>
              <Table stickyHeader>
                <TableHeader
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={tableData.length}
                  headers={headers}
                  isCheckboxEnabled={false}
                />
                <TableBody>
                  {sortArray(tableData, getComparator(order, orderBy)).map(
                    (row, index) => {
                      return (
                        <StyledTableRow key={index}>
                          <TableCell align="center" className={classes.fs12}>
                            {row.fms_item_suf_master_id}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.item_id}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.standard_uom}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.each_to_kg}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.suf}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.suf_status==true?"True":"False"}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.crate_type}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.created_date}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.updated_date}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.retek_location_code}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.division_name}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.department_name}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.crate_wt}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.crate_vol}
                          </TableCell>
                          <TableCell align="center" className={classes.fs12}>
                            {row.fms_location_master}
                          </TableCell>
                        </StyledTableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <SnackBarComponent
                opensb={opensb}
                handleClose={handleClose}
                severity={severity}
                content={content}
              />
      </Paper>
    </div>
  );
}
