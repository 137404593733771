import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { TableHeader, StyledTableRow } from "../../../Base/TableHeader";
import { getComparator, sortArray } from "../../../../helpers/sorting";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import { SuccessButton } from "../../../Button/Button";
import TripCreationStyle from "../../../../assets/css/TripCreationStyle";
import axiosRequest from "../../../../helpers/axios";
import Link from "@material-ui/core/Link";
import TripStoreDetailDialog from "../../../Base/TripStoreDetailDialogue";
import { StepContent } from "@material-ui/core";
import SnackBarComponent from "../../../../helpers/SnackBarComponent";
import { TextField } from "@material-ui/core";
import { getLocationId } from "../../../../helpers/RouteValidation";

const headers = [
  {
    id: "fms_location_name",
    sortable: true,
    align: "center",
    label: "FMS Location",
  },
  {
    id: "trip_id",
    sortable: true,
    align: "center",
    label: "Trip ID",
    search:true,
  },
  {
    id: "Store",
    sortable: true,
    align: "center",
    label: "Store",
  },
  {
    id: "store_drops",
    sortable: true,
    align: "center",
    label: "Store Drops",
  },
  {
    id: "trip_dist_fms_est",
    sortable: true,
    align: "center",
    label: "Distance Travelled",
  },
  {
    id: "vehicle_num",
    sortable: true,
    align: "center",
    label: "Vehicle Number",
  },
  {
    id: "vendor_name",
    sortable: true,
    align: "center",
    label: "Vendor Name",
  },
  {
    id: "contract_type",
    sortable: true,
    align: "center",
    label: "Contract Type",
  },
  {
    id: "start_km",
    sortable: false,
    align: "center",
    label: "Start (Km)",
  },
  {
    id: "end_km",
    sortable: false,
    align: "center",
    label: "End (Km)",
  },
  {
    id: "trip_dist_fms_est",
    sortable: false,
    align: "center",
    label: "Actual Distance (Km)",
  },
  {
    id: "toll_charges",
    sortable: false,
    align: "center",
    label: "Toll Charges",
  },
  {
    id: "halting_charges",
    sortable: false,
    align: "center",
    label: "Halting Charges",
  },
  {
    id: "other_charges",
    sortable: false,
    align: "center",
    label: "Other Charges",
  },
  {
    id: "total_other_charges",
    sortable: false,
    align: "center",
    label: "Total Other Charges",
  },
];

const useStyles = makeStyles(TripCreationStyle);

export default function Costing(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("FMS_Location");
  const [tableData, setTableData] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [storeDetails, setStoreDetails] = React.useState([]);
  const [severity, setSeverity] = React.useState("");
  const [content, setContent] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);
  const handleClose = () => {
    setOpensb(false);
  };
  const DialogOpen = (row) => {
    setStoreDetails(row.stores_list);
    setOpen(true);
  };

  const DialogClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    setRows(tableData);
  }, [tableData]);

  const handleSearchBar = (event, property) => {
    const searchResult = tableData.filter((row) => {
      return row[property]
        .toString()
        .toLowerCase()
        .includes(event.target.value.toLowerCase());
    });
    setRows(searchResult);
  };

  const claculateTotalCharge = (row, event, property, index) => {
    row[property] = event.target.value;
    row["total_other_charges"] =
      Number(row["toll_charges"]) +
      Number(row["halting_charges"]) +
      Number(row["other_charges"]);
    var tempTableData = [...tableData];
    tempTableData[index] = row;
    setTableData(tempTableData);
  };

  const handleDistance = (row, event, property, index) => {
    row[property] = Number(event.target.value);
    row["trip_dist_fms_est_updated"] =
      Number(row["end_km"]) - Number(row["start_km"]);
    var tempTableData = [...tableData];
    tempTableData[index] = row;
    setTableData(tempTableData);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getEndedTrips = async () => {
    let fmsLocationId = getLocationId();
    await axiosRequest({
      url: "tripEnd/getEndedTrips/" + fmsLocationId,
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        setTableData(data.data);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  React.useEffect(() => {
    getEndedTrips();
  }, []);

  const updateTripEnd = async () => {
    await axiosRequest({
      url: "tripEnd/updateCosting",
      method: "PUT",
      data: {
        tripData: tableData.filter(
          (trip) => trip.trip_dist_fms_est_updated > 0
        ),
      },
    }).then((data) => {
      if (data.status === 200) {
        getEndedTrips();
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };
  // function showDialog(){
  //   DialogOpen();
  // };

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TableContainer className={classes.container}>
          <Table stickyHeader>
            <TableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headers={headers}
              isCheckboxEnabled={false}
              handleSearch={handleSearchBar}
            />
            <TableBody>
              {sortArray(rows, getComparator(order, orderBy)).map(
                (row, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <TableCell align="center" className={classes.fs12}>
                        {row.fms_location_name}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_id}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        <Link
                          component="button"
                          variant="body2"
                          className={clsx(classes.fs12, classes.textColor)}
                          onClick={() => DialogOpen(row)}
                        >
                          {"View Stores"}
                        </Link>
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.store_drops}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_dist_fms_est}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.vehicle_num}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.vendor_name}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.contract_type}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        <div className={classes.bgInput1}>
                          <TextField
                            value={Number(row.start_km)}
                            variant="outlined"
                            className={clsx(classes.fs12, classes.inputNumber)}
                            InputProps={{
                              inputProps: {
                                min: 0,
                              },
                            }}
                            type="number"
                            onChange={(e) =>
                              handleDistance(row, e, "start_km", index)
                            }
                          />
                        </div>
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        <div className={classes.bgInput1}>
                          <TextField
                            value={Number(row.end_km)}
                            variant="outlined"
                            className={clsx(classes.fs12, classes.inputNumber)}
                            InputProps={{
                              inputProps: {
                                min: 0,
                              },
                            }}
                            type="number"
                            onChange={(e) =>
                              handleDistance(row, e, "end_km", index)
                            }
                          />
                        </div>
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_dist_fms_est_updated}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        <div className={classes.bgInput1}>
                          <TextField
                            variant="outlined"
                            value={Number(row.toll_charges)}
                            className={clsx(classes.fs12, classes.inputNumber)}
                            InputProps={{
                              inputProps: {
                                min: 0,
                              },
                            }}
                            type="number"
                            onChange={(e) =>
                              claculateTotalCharge(
                                row,
                                e,
                                "toll_charges",
                                index
                              )
                            }
                            startAdornment={
                              <InputAdornment position="start">
                                <Typography className={classes.fs12}>
                                  &#8377;
                                </Typography>
                              </InputAdornment>
                            }
                          />
                        </div>
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        <div className={classes.bgInput1}>
                          <TextField
                            variant="outlined"
                            value={Number(row.halting_charges)}
                            className={clsx(classes.fs12, classes.inputNumber)}
                            InputProps={{
                              inputProps: {
                                min: 0,
                              },
                            }}
                            type="number"
                            onChange={(e) =>
                              claculateTotalCharge(
                                row,
                                e,
                                "halting_charges",
                                index
                              )
                            }
                            startAdornment={
                              <InputAdornment position="start">
                                <Typography className={classes.fs12}>
                                  &#8377;
                                </Typography>
                              </InputAdornment>
                            }
                          />
                        </div>
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        <div className={classes.bgInput1}>
                          <TextField
                            variant="outlined"
                            value={Number(row.other_charges)}
                            className={clsx(
                              classes.fs12,
                              classes.inputNumber,
                              classes.underline
                            )}
                            type="number"
                            onChange={(e) =>
                              claculateTotalCharge(
                                row,
                                e,
                                "other_charges",
                                index
                              )
                            }
                            startAdornment={
                              <InputAdornment position="start">
                                <Typography className={classes.fs12}>
                                  &#8377;
                                </Typography>
                              </InputAdornment>
                            }
                          />
                        </div>
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        &#8377; {row.total_other_charges.toFixed(2)}
                      </TableCell>
                    </StyledTableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="flex justify-center pa3">
          <SuccessButton
            variant="contained"
            disableElevation
            onClick={updateTripEnd}
          >
            <Typography className={clsx(classes.fs12, "ttn")}>
              End Trip
            </Typography>
          </SuccessButton>
        </div>
      </Grid>
      <TripStoreDetailDialog
        open={open}
        DialogClose={DialogClose}
        storeDetails={storeDetails}
      />
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </Grid>
  );
}
