import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import TripCreationStyle from "../../../../assets/css/TripCreationStyle";
import TextField from "@material-ui/core/TextField";
import { SuccessButton } from "../../../Button/Button";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { Button } from "@material-ui/core";
import SnackBarComponent from "../../../../helpers/SnackBarComponent";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axiosRequest from "../../../../helpers/axios";
import { getLocationId } from "../../../../helpers/RouteValidation";
import { InputAdornment } from "@material-ui/core";

const useStyles = makeStyles(TripCreationStyle);

const useStyles1 = makeStyles((theme) => ({
  root: {
    "& .MuiFormLabel-root": {
      fontSize: "12px",
      lineHeight: 0,
    },
  },
  w254p: {
    width: "254px",
  },
}));

export default function PrimaryTransport(props) {
  const classes = useStyles();
  const classes1 = useStyles1();

  const { open, dialogClose, dialogSuccess } = props;

  const [primaryTransport, setPrimaryTransport] = React.useState({});
  const [severity, setSeverity] = React.useState("error");
  const [content, setContent] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);
  const [vendorDetails, setVendorDetails] = React.useState([]);
  const [contractDetails, setContractDetails] = React.useState([]);
  const [selectedVendorId, setSelectedVendorId] = React.useState("");
  const [selectedVendor, setSelectedVendor] = React.useState("");
  const [selectedContract, setSelectedContract] = React.useState("");

  const handleClose = () => {
    setOpensb(false);
  };

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };

  const handleChange = (event, property) => {
    let temp = primaryTransport;
    if (event.target.value === "") {
      delete temp[property];
    } else {
      temp[property] = event.target.value;
    }
    setPrimaryTransport({ ...temp });
  };

  const handleVendorChange = (value, property) => {
    let temp = primaryTransport;
    if (value) {
      setSelectedVendorId(value[property]);
      setSelectedVendor(value);
      delete temp["tripCost"];
      delete temp["payloadMT"];
      setSelectedContract({});
    } else {
      setContractDetails([]);
      setSelectedVendorId("");
      setSelectedContract({});
      setSelectedVendor({});
      delete temp["tripCost"];
      delete temp["payloadMT"];
    }
    setPrimaryTransport({ ...temp });
  };

  const handleContractChange = (value, property) => {
    let temp = primaryTransport;
    if (value) {
      getContractRateDetails(value[property]);
      setSelectedContract(value);
    } else {
      setSelectedContract({});
      delete temp["tripCost"];
      delete temp["payloadMT"];
      setPrimaryTransport({ ...temp });
    }
  };

  const updatePrimaryTransport = () => {
    let temp = primaryTransport;

    if (Object.keys(selectedContract).length > 0) {
      temp["contract_id"] = selectedContract["contract_id"];
      temp["contract_type"] = selectedContract["contract_type"];
    }

    if (Object.keys(selectedVendor).length > 0) {
      temp["vendor_id"] = selectedContract["vendor_id"]["vendor_id"];
      temp["vendor_name"] = selectedContract["vendor_id"]["vendor_name"];
    }

    setPrimaryTransport({ ...temp });

    if (Object.keys(primaryTransport).length === 8) {
      dialogSuccess(primaryTransport);
      resetPrimaryTransport();
    } else {
      handleSnackBar("Please fill all the fields", "error");
    }
  };

  const resetPrimaryTransport = () => {
    setSelectedContract({});
    setSelectedVendor({});
    setPrimaryTransport({});
    dialogClose();
  };

  const getVendorDetails = async () => {
    let fmsLocationId = getLocationId();
    await axiosRequest({
      url: "tripCreation/getVendorDetails/" + fmsLocationId,
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        setVendorDetails(data.data);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const getContractDetails = async () => {
    let fmsLocationId = getLocationId();
    await axiosRequest({
      url: "tripCreation/getContractDetails",
      method: "POST",
      data: {
        vehicleVendorId: selectedVendorId,
        origin_id: fmsLocationId,
      },
    }).then((data) => {
      if (data.status === 200) {
        setContractDetails(data.data);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  const getContractRateDetails = async (contractId) => {
    await axiosRequest({
      url: "tripCreation/getContractRateDetails/" + contractId,
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        let temp = primaryTransport;
        temp["tripCost"] = data.data[0]["primary_cost"];
        temp["payloadMT"] = data.data[0]["contract_id"]["payload_in_mt"];
        setPrimaryTransport({ ...temp });
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  React.useEffect(() => {
    getVendorDetails();
  }, []);

  React.useEffect(() => {
    if (selectedVendorId) {
      getContractDetails();
    } else {
      setContractDetails([]);
    }
  }, [selectedVendorId]);

  return (
    <Dialog disableBackdropClick disableEscapeKeyDown open={open}>
      <DialogTitle>
        <div className="flex justify-center items-center">
          <span className={clsx(classes.textColor, classes.fs12)}>
            Primary Transportation
          </span>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <div className="mb3 flex">
          <Autocomplete
            autoComplete
            options={
              vendorDetails && vendorDetails.length > 0 ? vendorDetails : []
            }
            getOptionLabel={(option) => option.vendor_name}
            className={clsx(classes.w233p, classes1.root)}
            onChange={(e, value) => handleVendorChange(value, "vendor_id")}
            value={selectedVendor !== undefined ? selectedVendor : {}}
            renderInput={(params) => (
              <TextField {...params} label="Vendor Name" variant="outlined" />
            )}
          />
          <Autocomplete
            autoComplete
            options={
              contractDetails && contractDetails.length > 0
                ? contractDetails
                : []
            }
            getOptionLabel={(option) => option.contract_type}
            className={clsx(classes.ml20p, classes1.root, classes1.w254p)}
            onChange={(e, value) => handleContractChange(value, "contract_id")}
            value={selectedContract}
            renderInput={(params) => (
              <TextField {...params} label="Contract Type" variant="outlined" />
            )}
          />
        </div>
        <div className="mb3">
          <TextField
            label="Vehicle Number"
            variant="outlined"
            className={clsx(classes.w233p, classes1.root)}
            value={primaryTransport.vehicleNumber}
            onChange={(e) => handleChange(e, "vehicleNumber")}
          />
          <TextField
            label={
              primaryTransport.payloadMT > 0
                ? primaryTransport.payloadMT
                : "Payload Capacity"
            }
            variant="outlined"
            className={clsx(classes.ml20p, classes1.root)}
            disabled
            InputProps={{
              endAdornment: <InputAdornment position="end">MT</InputAdornment>,
            }}
          />
        </div>
        <div>
          <TextField
            label="Volume Capacity"
            variant="outlined"
            className={clsx(classes1.root)}
            value={primaryTransport.volCapacity}
            onChange={(e) => handleChange(e, "volCapacity")}
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">CFT</InputAdornment>,
              inputProps: { min: 1 },
            }}
          />
          <TextField
            label={
              primaryTransport.tripCost > 0
                ? primaryTransport.tripCost
                : "Trip Cost"
            }
            variant="outlined"
            className={clsx(classes.ml20p, classes.w225p, classes1.root)}
            disabled
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" className={classes.fs12}>
                  Rs
                </InputAdornment>
              ),
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disableElevation
          onClick={resetPrimaryTransport}
        >
          <Typography className={clsx(classes.fs12, "ttn")}>Cancel</Typography>
        </Button>
        <SuccessButton
          variant="contained"
          disableElevation
          onClick={updatePrimaryTransport}
        >
          <Typography className={clsx(classes.fs12, "ttn")}>Save</Typography>
        </SuccessButton>
      </DialogActions>
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </Dialog>
  );
}
