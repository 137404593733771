import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { TableHeader, StyledTableRow } from "../../Base/TableHeader";
import { getComparator, sortArray } from "../../../helpers/sorting";
import Grid from "@material-ui/core/Grid";
import TripCreationStyle from "../../../assets/css/TripCreationStyle";
import { Paper } from "@material-ui/core";
import axiosRequest from "../../../helpers/axios";
import SnackBarComponent from "../../../helpers/SnackBarComponent";
import { getLocationId } from "../../../helpers/RouteValidation";
const headers = [
  {
    id: "origin_id",
    sortable: true,
    align: "center",
    label: "Origin_ID",
  },
  {
    id: "origin_name",
    sortable: true,
    align: "center",
    label: "Origin_Name",
  },
  {
    id: "dest_id",
    sortable: true,
    align: "center",
    label: "Destination_ID",
  },
  {
    id: "dest_name",
    sortable: true,
    align: "center",
    label: "Destination_Name",
  },
  {
    id: "dsl_start",
    sortable: true,
    align: "center",
    label: "DSL_Start",
  },
  {
    id: "dsl_end",
    sortable: true,
    align: "center",
    label: "DSL_End",
  },
  {
    id: "unloading_time",
    sortable: true,
    align: "center",
    label: "Unloaded time in mins",
  },
  {
    id: "routing_flag",
    sortable: true,
    align: "center",
    label: "Routing Flag",
  },

  {
    id: "Actions",
    sortable: false,
    align: "center",
    label: "Actions",
  },
];

const useStyles = makeStyles(TripCreationStyle);

export function OriginDestinationHeader(props) {
  const classes = useStyles();
  const { OriginHeader,tableData } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("trip_id");
  const [content, setContent] = React.useState("");
  const [severity,setSeverity]=React.useState("")
  const [opensb, setOpensb] = React.useState(false);
  const handleClose = () => {
    setOpensb(false);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };


  // const getOriginDestinationHeaderData = async () => {
  //   let fmsLocationId = getLocationId();
  //   await axiosRequest({
  //     url: "master/origin_destination_header/" + fmsLocationId,
  //     method: "GET",
  //   }).then((data) => {
  //     if (data.status === 200) {
  //       setTableData(data.data);
  //     } else {
  //       handleSnackBar(data, "error");
  //     }
  //   });
  // };

  // React.useEffect(() => {
  //   getOriginDestinationHeaderData();
  // }, []);
  return (
    <Paper elevation={0} square>
      <Grid container spacing={2} justify="center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TableContainer className={classes.costingContainer}>
            <Table stickyHeader>
              <TableHeader
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={tableData.length}
                headers={headers}
                isCheckboxEnabled={false}
              />
              <TableBody>
                {sortArray(tableData, getComparator(order, orderBy)).map(
                  (row, index) => {
                    return (
                      <StyledTableRow key={index}>
                        <TableCell align="center" className={classes.fs12}>
                          {row.origin_id}
                        </TableCell>
                        <TableCell align="center" className={classes.fs12}>
                          {row.origin_name}
                        </TableCell>
                        <TableCell align="center" className={classes.fs12}>
                          {row.dest_id}
                        </TableCell>
                        <TableCell align="center" className={classes.fs12}>
                          {row.dest_name}
                        </TableCell>
                        <TableCell align="center" className={classes.fs12}>
                          {row.dsl_start}
                        </TableCell>
                        <TableCell align="center" className={classes.fs12}>
                          {row.dsl_end}
                        </TableCell>
                        <TableCell align="center" className={classes.fs12}>
                          {row.unloading_time}
                        </TableCell>
                        <TableCell align="center" className={classes.fs12}>
                         {row.routing_flag==true?"1":"0"}
                        </TableCell>
                        <TableCell align="center" className={classes.fs12}>
                          {row.Actions}
                        </TableCell>
                      </StyledTableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </Paper>
  );
}
