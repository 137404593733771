import React from "react";
import classNames from "classnames";
import { NavLink, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import styles from "../../assets/css/SidebarStyle";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import { getUserName } from "../../helpers/RouteValidation";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import "./sidebar.css";


const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const { logo, routes, color } = props;

  const classes = useStyles();
  let location = useLocation();

  function activeRoute(routeName) {
    return location.pathname === routeName;
  }

  function getIcon(prop) {
    if (location.pathname === prop.layout + prop.path) {
      return <prop.activeIcon className={classes.itemIcon} />;
    } else {
      return <prop.inactiveIcon className={classes.itemIcon} />;
    }
  }

  var links = (
    <List className={classNames(classes.list)}>
      {routes.map((prop, key) => {
        const ActiveFontClasses = classNames({
          [" " + classes.activeFont]: activeRoute(prop.layout + prop.path),
        });
        var listItemClasses = classNames({
          [" " + classes[color]]: activeRoute(prop.layout + prop.path),
          [" " + classes.pa10]: activeRoute(prop.layout + prop.path),
        });
        return (
          <NavLink
            to={prop.layout + prop.path}
            key={key}
            className={classes.item}
          >
            <ListItem button className={classes.itemLink + listItemClasses}>
              {getIcon(prop)}
              <ListItemText
                primary={prop.name}
                disableTypography={true}
                className={classes.itemText + ActiveFontClasses}
              />
            </ListItem>
          </NavLink>
        );
      })}
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <img src={logo} alt="More" width="150" />
    </div>
  );

  var user = (
    <div className={classes.px15}>
      <Typography component={"span"} className={classes.itemText}>
        <Box textAlign="center">Welcome,</Box>
      </Typography>
      <Typography component={"span"} variant="body2">
        <Box textAlign="center" className="ttc">{getUserName()}</Box>
      </Typography>
      <Divider className={classes.mt2} />
    </div>
  );

  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          anchor="left"
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: false, // Better open performance on mobile.
          }}
        >
        <Button variant="text" onClick={props.handleDrawerToggle} className="btn-close">
            <CloseIcon />
          </Button>
          {brand}
          <div className={classes.sidebarWrapper} onClick={props.handleDrawerToggle}>
            {user}
            {links}
          </div>
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {user}
            {links}
          </div>
        </Drawer>
      </Hidden>
    </div>
  );
}
