import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Checkbox from "@material-ui/core/Checkbox";
import { TableHeader, StyledTableRow } from "../../../Base/TableHeader";
import Grid from "@material-ui/core/Grid";
import { getComparator, sortArray } from "../../../../helpers/sorting";

const headCells = [
  {
    id: "bol_no",
    label: "BOL No",
    sortable: true,
    align: "center",
  },
  {
    id: "bol_value",
    label: "Value",
    sortable: true,
    align: "center",
  },
  {
    id: "bol_weight",
    label: "Weight (Kg)",
    sortable: true,
    align: "center",
  },
];

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: 440,
  },
  fs12: {
    fontSize: "12px",
  },
}));

export default function BOLTable(props) {
  const classes = useStyles();
  const { selectedBOL, handleSelectedBOL, pendingBOL } = props;
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("bol_no");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    console.log("checking")
    if (event.target.checked) {
      const newSelecteds = pendingBOL.map((n) => n);
      handleSelectedBOL(newSelecteds);
      return;
    }
    handleSelectedBOL([]);
  };

  const handleClick = (event, row) => {
    console.log("handleclick",row)
    
    const selectedIndex = selectedBOL.findIndex(
      (obj) => obj.bol_no === row.bol_no
    );

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedBOL, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedBOL.slice(1));
    } else if (selectedIndex === selectedBOL.length - 1) {
      newSelected = newSelected.concat(selectedBOL.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedBOL.slice(0, selectedIndex),
        selectedBOL.slice(selectedIndex + 1)
      );
    }
    handleSelectedBOL(newSelected);
  };

  const isSelected = (row) => {
    var selectedIndex = selectedBOL.findIndex((obj) => obj.bol_no === row.bol_no);
    // if(row.trip_id !== null){
    //   return true;
    // }
    return selectedIndex !== -1 ? true : false;
  };

  return (
    <React.Fragment>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TableContainer className={classes.container}>
          <Table stickyHeader>
            <TableHeader
              numSelected={selectedBOL.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={pendingBOL.length}
              headers={headCells}
              isCheckboxEnabled={true}
            />
            <TableBody>
              {sortArray(pendingBOL, getComparator(order, orderBy)).map(
                (row, index) => {
                  const isItemSelected = isSelected(row);
                  return (
                    <StyledTableRow
                      onClick={(event) => handleClick(event, row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox checked={isItemSelected} />
                      </TableCell>
                      <TableCell className={classes.fs12} align="center">
                        {row.bol_no}
                      </TableCell>
                      <TableCell className={classes.fs12} align="center">
                        &#8377;
                        {row.bol_value}
                      </TableCell>
                      <TableCell className={classes.fs12} align="center">
                        {row.bol_weight}
                      </TableCell>
                    </StyledTableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </React.Fragment>
  );
}
