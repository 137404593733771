import * as React from "react";

function SvgToBolActive(props) {
  return (
    <svg
      id="to_bol_active_svg__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      {...props}
    >
      <defs>
        <style>{".to_bol_active_svg__cls-1{fill:#f74e10}"}</style>
      </defs>
      <path
        className="to_bol_active_svg__cls-1"
        d="M24.85 17.52a1.34 1.34 0 00-1.9 0l-4.43 4.42-1.73-1.74a1.35 1.35 0 00-1.9 0 1.33 1.33 0 000 1.9l2.69 2.69a1.3 1.3 0 00.94.4 1.37 1.37 0 001-.39l5.37-5.38a1.35 1.35 0 00-.04-1.9zM24.85 28.27a1.34 1.34 0 00-1.9 0l-4.43 4.42L16.79 31a1.34 1.34 0 00-1.9 1.9l2.69 2.69a1.33 1.33 0 00.94.39 1.37 1.37 0 001-.39l5.37-5.38a1.35 1.35 0 00-.04-1.94zM34.65 19.81h-5.38a1.35 1.35 0 000 2.69h5.38a1.35 1.35 0 000-2.69zM34.65 30.56h-5.38a1.35 1.35 0 000 2.69h5.38a1.35 1.35 0 000-2.69z"
      />
      <path
        className="to_bol_active_svg__cls-1"
        d="M38.68 7.38H11.81a2.68 2.68 0 00-2.69 2.68v32.25A2.69 2.69 0 0011.81 45h26.87a2.7 2.7 0 002.69-2.69V10.06a2.69 2.69 0 00-2.69-2.68zm0 34.93H11.81V10.06h26.87z"
      />
    </svg>
  );
}

export default SvgToBolActive;
