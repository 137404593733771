import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { TableHeader, StyledTableRow } from "../Base/TableHeader";
import { getComparator, sortArray } from "../../helpers/sorting";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import TripCreationStyle from "../../assets/css/TripCreationStyle";
import { Paper } from "@material-ui/core";
import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";
import Button from "@material-ui/core/Button";
import axiosRequest from "../../helpers/axios";
import SnackBarComponent from "../../helpers/SnackBarComponent";
import { MoreButton } from "../Button/Button";
import AddItemSufMasterDialog from "../Master/ItemSufMaster/AddItemSufMasterDialog";
import exportToCsv from "../../helpers/exportToCsv";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import PublishRoundedIcon from "@material-ui/icons/PublishRounded";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
// import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import CircularProgress from "@material-ui/core/CircularProgress";


const headers = [
    {
        id: "file_name",
        sortable: true,
        align: "center",
        label: "Filename",
    },
    {
        id: "status",
        sortable: true,
        align: "center",
        label: "Status",
    },
    {
        id: "error_msg",
        sortable: true,
        align: "center",
        label: "Error Message",
    },
    {
        id: "count",
        sortable: true,
        align: "center",
        label: "No of records Processed",
    },
    {
        id: "log_file",
        sortable: true,
        align: "center",
        label: "Process Logs",
        downloadIcon: true,
    },
    {
        id: "created_date",
        sortable: true,
        align: "center",
        label: "Created Date",
    },
    {
        id: "modified_date",
        sortable: true,
        align: "center",
        label: "Modified Date",
    },


];

const useStyles = makeStyles(TripCreationStyle);
const loaderStyles = makeStyles((theme) => ({
    loader: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
}));

const UploadSuccessModal = ({ open, onClose }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>File Uploaded</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    The file has been uploaded successfully.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export function ContractHeader(props) {
    const classes = useStyles();
    const { handleBack, downloadReport, downloadItemReport, downloadCrateData } = props;
    const [open, setOpen] = React.useState(false);
    const [uploadCsv, setuploadCsv] = React.useState("");
    const [severity, setSeverity] = React.useState("");
    const [content, setContent] = React.useState("");
    const [opensb, setOpensb] = React.useState(false);
    const [showUploadSuccess, setShowUploadSuccess] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const handleClose = () => {
        setOpensb(false);
    };

    const handleSnackBar = (content, severity) => {
        setOpensb(true);
        setSeverity(severity);
        setContent(content);
    };

    function ItemsufmasterDialogOpen() {
        setOpen(true);
    }

    function DialogClose() {
        setOpen(false);
    }

    const handleImageChange = async (event) => {
        setLoading(true);
        let img = event.target.files[0];
        let fmslocationID = 100008;
        let s3_folder = "ntm_bulk_contract";
        let file_type = "contract"

        // var filename="ptl.csv";
        var formData = new FormData();
        formData.append("fmslocationid", fmslocationID);
        formData.append("s3_folder", s3_folder);
        formData.append("file_type", file_type);
        formData.append("file", img);
        formData.append("filename", img.name);
        console.log(formData);
        // setuploadCsv(formData);
        // console.log(uploadCsv)

        await uploadPicture(formData);
        event.target.value = "";

    };


    const uploadPicture = async (formData) => {
        try {
            if (formData) {
                await axiosRequest({
                    url: "costing/upload_ntm_cost/",
                    method: "POST",
                    data: formData,
                    headers: {
                        "content-type": "multipart/form-data",
                    },
                }).then((data) => {
                    if (data.status === 200) {
                        handleSnackBar(data.msg, "success")
                        setShowUploadSuccess(true);
                        setTimeout(() => {
                            setShowUploadSuccess(false);
                            // setuploadCsv("");
                        }, 5000);
                    } else {
                        handleSnackBar(data.msg, "error");
                    }
                });
            }
        } catch (error) {
            handleSnackBar("Failed to upload file", "error");
        } finally {
            setLoading(false);
        }
    };
    const handleDownloadSampleFile = async () => {
        try {
            const fileName = "sample_contract_bulk.xlsx"; // filename
            const response = await axiosRequest({
                url: "master/getsamplefile/",
                method: "POST",
                data: { fileName: fileName }, // Send filename as payload
            });

            const presignedUrl = response.data // Assuming the API response contains the presigned URL

            const fileResponse = await axiosRequest({
                url: presignedUrl,
                method: "GET",
                responseType: "blob",
            });

            const url = window.URL.createObjectURL(new Blob([fileResponse.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            handleSnackBar("Failed to download sample file", "error");
        }
    };

    // React.useEffect(() => {
    //     uploadPicture();
    // }, [uploadCsv]);

    return (
        <React.Fragment>
            <div className="flex justify-between pa3">
                <Typography variant="body2">Bulk Contract Updation</Typography>
                <div className="flex justify-between">
                    <Button
                        variant="contained"
                        disableElevation
                        className={classes.button}
                        onClick={handleBack}
                        startIcon={<ChevronLeftRoundedIcon fontSize="small" />}
                    >
                        <Typography className={clsx(classes.fs12, "ttn")}>Back</Typography>
                    </Button>
                    <MoreButton
                        className={clsx(classes.button, "mr3")}
                        variant="contained"
                        disableElevation
                        onClick={handleDownloadSampleFile}
                        startIcon={<GetAppRoundedIcon fontSize="small" />}
                    >
                        <Typography className={clsx(classes.fs12, "ttn")}>
                            Download
                        </Typography>
                    </MoreButton>
                    <input
                        className={classes.input}
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={handleImageChange}
                    />
                    <label htmlFor="contained-button-file">
                        <MoreButton
                            variant="contained"
                            color="primary"
                            component="span"
                            className={classes.button}
                            startIcon={<PublishRoundedIcon fontSize="small" />}
                        disabled={loading}
                        >
                            {loading ? "Uploading..." : "Upload Excel"}
                        </MoreButton>
                    </label>
                </div>
            </div>
            <SnackBarComponent
                opensb={opensb}
                handleClose={handleClose}
                severity={severity}
                content={content}
            />
            <UploadSuccessModal open={showUploadSuccess} onClose={() => setShowUploadSuccess(false)} />

        </React.Fragment>
    );
}

export default function BulkUpdate(props) {
    const classes = useStyles();
    const { handleBack } = props;
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("fms_item_suf_master_id");
    const [tableData, setTableData] = React.useState([]);
    const [content, setContent] = React.useState("");
    const [severity, setSeverity] = React.useState("");
    const [opensb, setOpensb] = React.useState(false);
    const loaderClasses = loaderStyles();
    const [loading, setLoading] = React.useState(false);


    const handleClose = () => {
        setOpensb(false);
    };

    const handleSnackBar = (content, severity) => {
        setOpensb(true);
        setSeverity(severity);
        setContent(content);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const getData = async () => {
        await axiosRequest({
            url: "costing/cost_file_data/",
            method: "POST",
            data: { file_type: "contract" },
        }).then((data) => {
            if (data.status === 200) {
                setTableData(data.data);
            } else {
                handleSnackBar(data, "error");
            }
        });
    };

    const handleDownloadFile = async (fileName) => {
        try {
            const response = await axiosRequest({
                url: "master/getfile/",
                method: "POST",
                data: { fileName, s3_folder: "ntm_bulk_contract" }, // Send filename as payload
            });

            const presignedUrl = response.data;

            const fileResponse = await axiosRequest({
                url: presignedUrl,
                method: "GET",
                responseType: "blob",
            });

            const url = window.URL.createObjectURL(new Blob([fileResponse.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            handleSnackBar("Failed to download file", "error");
        }
    };

    React.useEffect(() => {
        getData()
    }, [])
    return (
        <div className="pa3">
            <Paper elevation={0} square>
                <Grid container spacing={2} justify="center">
                    {loading && <CircularProgress className={loaderClasses.loader} />}
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ContractHeader handleBack={handleBack} />
                        <TableContainer className={classes.costingContainer}>
                            <Table stickyHeader>
                                <TableHeader
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={tableData.length}
                                    headers={headers}
                                    isCheckboxEnabled={false}
                                />
                                <TableBody>
                                    {sortArray(tableData, getComparator(order, orderBy)).map(
                                        (row, index) => {
                                            return (
                                                <StyledTableRow key={index}>
                                                    <TableCell align="center" className={classes.fs12}>
                                                        {row.file_name}
                                                    </TableCell>
                                                    <TableCell align="center" className={classes.fs12}>
                                                        {row.status}
                                                    </TableCell>
                                                    <TableCell align="center" className={classes.fs12}>
                                                        {row.error_msg}
                                                    </TableCell>
                                                    <TableCell align="center" className={classes.fs12}>
                                                        {row.count}
                                                    </TableCell>
                                                    {/* <TableCell align="center" className={classes.fs12}>
                                                        {row.process_logs}
                                                    </TableCell> */}
                                                    <TableCell align="center" className={classes.fs12}>
                                                        {row.log_file && row.log_file.length > 0 && (
                                                            <GetAppRoundedIcon
                                                                className={classes.downloadIcon}
                                                                onClick={() => handleDownloadFile(row.log_file)}
                                                            />
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="center" className={classes.fs12}>
                                                        {row.created_date}
                                                    </TableCell>
                                                    <TableCell align="center" className={classes.fs12}>
                                                        {row.modified_date}
                                                    </TableCell>
                                                </StyledTableRow>
                                            );
                                        }
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <SnackBarComponent
                    opensb={opensb}
                    handleClose={handleClose}
                    severity={severity}
                    content={content}
                />
            </Paper>
        </div>
    );
}


