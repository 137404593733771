import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { TableHeader, StyledTableRow } from "../../../Base/TableHeader";
import { getComparator, sortArray } from "../../../../helpers/sorting";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { SuccessButton } from "../../../Button/Button";
import TripCreationStyle from "../../../../assets/css/TripCreationStyle";
import axiosRequest from "../../../../helpers/axios";
import SnackBarComponent from "../../../../helpers/SnackBarComponent";
import { getLocationId } from "../../../../helpers/RouteValidation";

const headers = [
  {
    id: "trip_id",
    label: "Temp Trip Id",
    sortable: true,
    align: "center",
  },
  {
    id: "totalFnvWgt",
    sortable: true,
    align: "center",
    label: "Total Fnv Weight Kgs",
  },
  {
    id: "totalLpnWgt",
    sortable: true,
    align: "center",
    label: "Total Lpn Weight Kgs",
  },
  {
    id: "totalWgtDemand",
    sortable: true,
    align: "center",
    label: "Total Weight Demand",
  },
  {
    id: "totalFnvVol",
    sortable: true,
    align: "center",
    label: "Total Fnv Volume Cft",
  },
  {
    id: "totalLpnVol",
    sortable: true,
    align: "center",
    label: "Total Lpn Volume Cft",
  },
  {
    id: "totalVolDemand",
    sortable: true,
    align: "center",
    label: "Total Vol Demand",
  },
  {
    id: "store_id",
    sortable: true,
    align: "center",
    label: "Store Drops",
  },
  {
    id: "trip_dist_fms_est",
    sortable: true,
    align: "center",
    label: "Trip Distance (Kms)",
  },
];

const useStyles = makeStyles(TripCreationStyle);

export default function TripSummary(props) {
  const classes = useStyles();
  const { handleNext, handleTripSummaryData } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("trip_id");
  const [tableData, settableData] = React.useState([]);
  const [severity, setSeverity] = React.useState("");
  const [content, setContent] = React.useState("");
  const [opensb, setOpensb] = React.useState(false);
  const handleClose = () => {
    setOpensb(false);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleTripSummary = async (value) => {
    let fmsLocationId = getLocationId();
    await axiosRequest({
      url: "tripInitiation/tripSummary/" + fmsLocationId,
      method: "GET",
    }).then((data) => {
      if (data.status === 200) {
        settableData(data.data[1]);
        handleTripSummaryData(data.data[1]);
      } else {
        handleSnackBar(data, "error");
      }
    });
  };

  React.useEffect(() => {
    handleTripSummary();
  }, []);

  const handleSnackBar = (content, severity) => {
    setOpensb(true);
    setSeverity(severity);
    setContent(content);
  };

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TableContainer className={classes.container}>
          <Table stickyHeader>
            <TableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={tableData.length}
              headers={headers}
              isCheckboxEnabled={false}
            />
            <TableBody>
              {sortArray(tableData, getComparator(order, orderBy)).map(
                (row, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_id}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.totalFnvWgt}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.totalLpnWgt}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.totalWgtDemand}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.totalFnvVol}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.totalLpnVol}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.totalVolDemand}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.store_id}
                      </TableCell>
                      <TableCell align="center" className={classes.fs12}>
                        {row.trip_dist_fms_est}
                      </TableCell>
                    </StyledTableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="flex justify-center pa3">
          <SuccessButton
            variant="contained"
            disableElevation
            onClick={handleNext}
          >
            <Typography className={clsx(classes.fs12, "ttn")}>
              Proceed to Store Summary
            </Typography>
          </SuccessButton>
        </div>
      </Grid>
      <SnackBarComponent
        opensb={opensb}
        handleClose={handleClose}
        severity={severity}
        content={content}
      />
    </Grid>
  );
}
