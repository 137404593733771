import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Normal from "../../../Typography/Normal";
import CloseIcon from "@material-ui/icons/Close";
import StoreTable from "./StoreTable";
import Typography from "@material-ui/core/Typography";
import { MoreButton } from "../../../Button/Button";
import TripCreationStyle from "../../../../assets/css/TripCreationStyle";

const useStyles = makeStyles(TripCreationStyle);

export default function SelectStoreDialog(props) {
  const classes = useStyles();
  const {
    onClose,
    open,
    selectedStores,
    handleSelectedStores,
    handleSelectStoreDialogConfirm,
    activeStep,
  } = props;

  const handleCancel = () => {
    handleSelectedStores([]);
    onClose();
  };

  const handleOk = () => {
    handleSelectStoreDialogConfirm();
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      aria-labelledby="confirmation-dialog-title"
      classes={{ paperWidthSm: classes.storeTableDialog }}
    >
      <DialogTitle id="confirmation-dialog-title">
        <div className="flex justify-between items-center">
          <Normal>
            <h3 className="ma0">Select the stores to verify BOL_No's</h3>
          </Normal>
          {activeStep === 0 ? (
            <IconButton>
              <CloseIcon onClick={handleCancel} />
            </IconButton>
          ) : (
            ""
          )}
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <StoreTable
          selectedStores={selectedStores}
          handleSelectedStores={handleSelectedStores}
        />
      </DialogContent>
      <DialogActions>
        <MoreButton
          variant="contained"
          disableElevation
          onClick={handleOk}
          color="secondary"
        >
          <Typography className={clsx(classes.fs12, "ttn")}>
            Add Stores
          </Typography>
        </MoreButton>
      </DialogActions>
    </Dialog>
  );
}

SelectStoreDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
